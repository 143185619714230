import React, { Component } from 'react';
import { Button, CircularProgress, Box } from '@material-ui/core';
// import { Button, Card, CardHeader, CardTitle, Row, Col, Input } from "reactstrap";

import PropTypes from 'prop-types';
import { API } from 'aws-amplify';
import { injectStripe } from 'react-stripe-elements';

class StripePayment extends Component {
    constructor() {
        super();

        this.state = {
            loading: false
        };

        this.handlePay = this.handlePay.bind(this);
    }

    componentDidMount() {
        const { totalAmount, noOfLicense, licenseCost, totalLicenses, unassignedLicenses } = this.props;

        var objLicense = {
            totalValue: totalAmount,
            noOfLicense: noOfLicense,
            licenseCost: licenseCost,
            totalLicenses: totalLicenses,
            unassignedLicenses: unassignedLicenses
        }

        localStorage.setItem('licenseDetails', JSON.stringify(objLicense));
    }

    async handlePay() {
        this.setState({ loading: true })
        const body = {
            name: this.props.name,
            email: this.props.email,
            description: this.props.description,
            images: this.props.images,
            amount: this.props.amount,
            currency: this.props.currency,
            quantity: this.props.quantity,
            success_url: this.props.success_url,
            cancel_url: this.props.cancel_url,
        }
        const response = await API.post(this.props.apiName, this.props.apiEndpoint, { body });
        console.log("stripe response: " + JSON.stringify(response));
        console.log("stripe response1: " + response);
        this.props.stripe.redirectToCheckout({
            // Make the id field from the Checkout Session creation API response
            // available to this file, so you can provide it as parameter here
            // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
            sessionId: response.session.id
        }).then(function (result) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
        });
        this.setState({ loading: false })
    }

    render() {
        const { loading } = this.state;

        return (
            <Box display="flex" flexDirection="row-reverse" flexWrap="wrap" alignItems="center">
                <Box marginRight={2} />
                {
                    loading ?
                        <>
                            <Button
                                onClick={this.handlePay} disabled style={{ pointerEvents: "none", opacity: "0.6" }} id="buy"



                            >
                                {loading ? (
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                ) : (
                                    "Buy"
                                )}
                            </Button>
                            {/* <CircularProgress color="secondary" />

                            <Button onClick={this.handlePay} disabled style={{ pointerEvents: "none", opacity: "0.6" }} id="buy">Buy</Button> */}
                        </>
                        :

                        <Button onClick={this.handlePay} id="buy" >Buy</Button>
                }
            </Box>
        );
    }
}

StripePayment.propTypes = {
    apiName: PropTypes.string.isRequired,
    apiEndpoint: PropTypes.string.isRequired,

    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    images: PropTypes.array.isRequired,
    amount: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,

    noOfLicense: PropTypes.number.isRequired,
    totalAmount: PropTypes.number.isRequired,
    licenseCost: PropTypes.number.isRequired,
    totalLicenses: PropTypes.number.isRequired,
    unassignedLicenses: PropTypes.number.isRequired,

    success_url: PropTypes.string.isRequired,
    cancel_url: PropTypes.string.isRequired,
};

export default injectStripe(StripePayment);