import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import more from "../../../assests/images/more.svg";
import classNames from "classnames";
import "../../homepage/index.scss";
import styles from "../styles.module.scss";
import { Button, Card, CardHeader, CardBody, CardTitle, Row, Col, Form, Input, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledButtonDropdown } from "reactstrap";
import ReactTable from "../ReactTable";
import loader from "../../../assests/images/loading_dots.gif";
import Helper from "../../../services/helper";
import ViewMore from "../../../components/tooltip/viewMore";
import { AWSIoTProvider } from "@aws-amplify/pubsub/lib/Providers";

// import { API, graphqlOperation, Storage } from "aws-amplify";
import * as mutations from "../../../graphql/mutations";
import { Amplify, PubSub, API, Auth, graphqlOperation, Storage } from "aws-amplify";
import AWS from "aws-sdk";
import { getFleetOwners, getFleetUsers, getFleetUsersByFleetOwnerId, getLicenseByFleetOwnerId, getLicenses } from "../../../graphql/queries";
var licenseData = [];
var lstCodes = [];
var fleetUserData = [];
export default class Fleet extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchText: "",
            loading: true,
            fleetUsers: [],
            filteredUsers: [],
            pageSize: 50,
            hasNext: true,
            isLoading: true,
            nextNextToken: null,
            previousTokens: [],
            hasPrev: false,
            fleetOwnerId:
                localStorage.getItem("fleetOwnerId") === null
                    ? this.props.location.state
                        ? this.props.location.state.fleetOwnerId
                        : ""
                    : localStorage.getItem("fleetOwnerId"),
            showCurrentPageNo: 1,
            inProgress: false,
            riderAlerts: 0,
            data: [],
            userId: "",
            version: 0,
            allLicenses: [],
            appSyncApiId: '',
            companyType: localStorage.getItem("companyType"),
        };
    }

    componentDidMount = async () => {
        this.loadConfig();

        this.getFleetOwnerDetails();
        const secureKeys = await Helper.getSecureKeys();

        Amplify.addPluggable(
            new AWSIoTProvider({
                aws_pubsub_region: secureKeys.response.region,
                aws_pubsub_endpoint: secureKeys.response.pubsubEndpoint,
            })
        );

        await this.attachIoTPolicy();
        await this.subscribeDefaultTopic();
    };

    loadConfig = () => {
        let _this = this;

        fetch(window.location.origin + `/config.json`)
            .then((r) => r.json())
            .then((data) => {
                this.setState({ appSyncApiId: data.appSyncApiId }, function () {
                    _this.getLicenses();
                });
            });
    }

    getLicenses = async () => {
        var variables = {
            fleetOwnerId: this.state.fleetOwnerId,
            fleetOwnerPurchaseId: "Fleet Owner License",
            nextToken: this.state.nextToken,
            filter: {
                deletedLicense: { eq: "false" },
            },
            limit: this.state.pageSize,
            sortDirection: "DESC",
        };

        licenseData = await API.graphql(graphqlOperation(getLicenseByFleetOwnerId, variables, this.state.companyType));

        this.setState({ nextNextToken: licenseData.data.getLicenseByFleetOwnerId.nextToken });
            let objLicenses = licenseData.data.getLicenseByFleetOwnerId.items
                .filter(
                    (l) => l.fleetOwnerPurchaseId !== "Fleet Owner License"
                );
       

        this.setState({
            allLicenses: objLicenses,
            loading: false,
            hasNext: licenseData.data.getLicenseByFleetOwnerId.nextToken !== null,
            isLoading: false,
            showCurrentPageNo: this.state.currentPageNo,
            inProgress: false,
        }, function () {
            this.getFleetUsers();
        });
    };

   

    attachIoTPolicy = async () => {
        const authCredentials = await Auth.currentCredentials();

        const secureKeys = await Helper.getSecureKeys();

        new AWS.Iot({
            region: secureKeys.response.region,
            credentials: Auth.essentialCredentials(authCredentials),
        }).attachPrincipalPolicy({ policyName: "busby2", principal: authCredentials.identityId }, function (er, data) {
            if (er) {
                console.log("Error: " + er);
            }
        });
    };

    subscribeDefaultTopic() {
        const { userId } = this.state;
        PubSub.subscribe(`roadradar/v1/logoutUser/${userId}`, {
            userId: userId,
        }).subscribe({
            next: async (data) => {
                console.log("Message received", JSON.stringify(data.value));
                // alert("Your session has been deactivated please contact your fleet manager");
            },
            error: (error) => {
                console.error("Subscribe error: " + JSON.stringify(error));
            },
            close: () => console.log("Done"),
        });
    }

    handleConnect = async (userId) => {
        try {
            PubSub.publish(`roadradar/v1/logoutUser/${userId}`, {
                userId: userId,
            });
        } catch (e) {
            alert("error", e);
            console.log(e);
        }
    };

    getFleetOwnerDetails = async () => {
        let fId =
            localStorage.getItem("fleetOwnerId") === null
                ? this.props.location.state
                    ? this.props.location.state.fleetOwnerId
                    : ""
                : localStorage.getItem("fleetOwnerId");

        if (fId !== null && fId !== undefined) {
            await API.graphql(graphqlOperation(getFleetOwners, { id: fId })).then(async (result) => {
                this.setState({
                    userId: result.data.getFleetOwners.id,
                    version: result.data.getFleetOwners._version
                });
            });
        }
    };

    onPressEnter = (e) => {
        if (e.key === "Enter") this.handleSearch();
    };

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState(
            {
                [name]: value,
            },
            function () {
                if (name === "searchText") {
                    this.handleSearch();
                }
            }
        );
    };

    handleSearch = async () => {
        this.setState(
            {
                pageSize: 10000,
                nextToken: undefined,
                hasNext: false,
                hasPrev: false,
                nextNextToken: null,
                previousTokens: [],
            },
            async function () {
                /* var variables = {
                     fleetOwnerId: this.state.fleetOwnerId,
                     nextToken: this.state.nextToken,
                     filter: {
                         and: [
                             { isDeleted: { eq: false }, deletedFleetUser: { eq: "false" } },
                             {
                                 or: [
                                     { firstNameLower: { contains: this.state.searchText.trim().toLowerCase() } },
                                     { lastNameLower: { contains: this.state.searchText.trim().toLowerCase() } },
                                     { deviceTypeLower: { contains: this.state.searchText.trim().toLowerCase() } },
                                 ],
                             },
                         ],
                     },
                     limit: this.state.pageSize,
                     sortDirection: "DESC",
                 };*/

                let filteredUsers = []
                this.state.fleetUsers.map(user => {
                    let LicenseCode = this.state.allLicenses.filter(l => l.id === user.licenseId)
                    if (LicenseCode.length > 0) {
                        if (LicenseCode[0].code.toLowerCase().includes(this.state.searchText.trim().toLowerCase())) {
                            filteredUsers.push(user)
                        }
                    } if (user.firstNameLower.includes(this.state.searchText.trim().toLowerCase())) {
                        filteredUsers.push(user)
                    } if (user.lastNameLower.includes(this.state.searchText.trim().toLowerCase())) {
                        filteredUsers.push(user)
                    } if (user.deviceType.includes(this.state.searchText.trim().toLowerCase())) {
                        filteredUsers.push(user)
                    }
                })
                // fleetUserData = await API.graphql(graphqlOperation(getFleetUsersByFleetOwnerId, variables));

                // this.setState({ nextNextToken: fleetUserData.data.getFleetUsersByFleetOwnerId.nextToken });

                // var objFleetUser = fleetUserData.data.getFleetUsersByFleetOwnerId.items;

                this.setState({
                    fleetUserData: fleetUserData,
                    filteredUsers: filteredUsers,
                    loading: false,
                    hasNext: fleetUserData.data.getFleetUsersByFleetOwnerId.nextToken !== null,
                    isLoading: false,
                });
            }
        );
    };
    handleDisableFleet = async (userId, userStatus, version) => {
        if (userId !== null && userId !== "" && userId !== "0") {
            var status = userStatus ? "unassign" : "assign";

            if (window.confirm("Do you want to " + status + " this vehicle?")) {
                var objFleetUser = {
                    id: userId,
                    isActive: !userStatus,
                    _version: version
                };

                await API.graphql(graphqlOperation(mutations.updateFleetUsers, { input: objFleetUser }))
                    .then(async (data) => {
                        this.getFleetUsers();

                        if (status === "unassign") {
                            this.handleConnect(userId);
                        }
                    })
                    .catch((error) => {
                        alert(JSON.stringify(error));
                    });
            }
        }
    };

    getFleetUsers = async () => {
        let fId =
            localStorage.getItem("fleetOwnerId") === null
                ? this.props.location.state
                    ? this.props.location.state.fleetOwnerId
                    : ""
                : localStorage.getItem("fleetOwnerId");

        var variables = {
            fleetOwnerId: fId,
            nextToken: this.state.nextToken,
            filter: {
                isDeleted: { eq: false },
                deletedFleetUser: { eq: "false" },
            },
            limit: this.state.pageSize,
            sortDirection: "DESC",
        };

        fleetUserData = await API.graphql(graphqlOperation(getFleetUsersByFleetOwnerId, variables));
        this.setState({ nextNextToken: fleetUserData.data.getFleetUsersByFleetOwnerId.nextToken });
        let objFleetUser = fleetUserData.data.getFleetUsersByFleetOwnerId.items;
        var counts = 0;

        if (objFleetUser !== null && objFleetUser.length > 0) {
            counts = objFleetUser.map((x) => x.riderAlerts).reduce((alert, x) => x + alert);
        }

        this.setState({
            fleetUsers: objFleetUser,
            loading: false,
            hasNext: fleetUserData.data.getFleetUsersByFleetOwnerId.nextToken !== null,
            isLoading: false,
            riderAlerts: counts === null ? 0 : counts,
            showCurrentPageNo: this.state.currentPageNo,
            inProgress: false,
        });
    };

    handleRemoveFleet = async (userId, version) => {
        if (userId !== null && userId !== "" && userId !== "0") {
            if (window.confirm("Are you sure you want to log out this fleet user?")) {
                await API.graphql(graphqlOperation(getFleetUsers, { id: userId })).then(async (result) => {
                    var objFleetUser = {
                        id: userId,
                        isDeleted: true,
                        deletedFleetUser: "true",
                        _version: version
                    };

                    await API.graphql(graphqlOperation(mutations.updateFleetUsers, { input: objFleetUser }))
                        .then(async (data) => {
                            // Unassign license
                            await API.graphql(graphqlOperation(getLicenses, { id: result.data.getFleetUsers.licenseId })).then(async (licenseResult) => {
                                if (licenseResult.data.getLicenses !== null) {
                                    if (result.data.getFleetUsers.licenseId.trim() !== "") {
                                        var objLicense = {
                                            id: result.data.getFleetUsers.licenseId,
                                            fleetUserId: " ",
                                            isAssigned: false,
                                            assignedLicense: "false",
                                            updatedAt: new Date(),
                                            _version: licenseResult.data.getLicenses._version
                                        };

                                        await API.graphql(graphqlOperation(mutations.updateLicenses, { input: objLicense }))
                                            .then(async (data) => { })
                                            .catch((error) => {
                                                alert(JSON.stringify(error));
                                            });
                                    }
                                }
                            });

                            // Update unassigned license counter on dashboard
                            await API.graphql(graphqlOperation(getFleetOwners, { id: this.state.fleetOwnerId })).then(
                                async (result) => {
                                    var totalLicenses = result.data.getFleetOwners.totalLicenses;
                                    var unAssignedLicense = result.data.getFleetOwners.unassignedLicenses;

                                    let licenses = unAssignedLicense + 1;

                                    if (licenses > totalLicenses) {
                                        licenses = totalLicenses;
                                    }

                                    var objFleetOwner = {
                                        id: this.state.fleetOwnerId,
                                        unassignedLicenses: licenses,
                                        _version: result.data.getFleetOwners._version
                                    };

                                    await API.graphql(graphqlOperation(mutations.updateFleetOwners, { input: objFleetOwner }))
                                        .then(async (data) => {
                                            this.loadProfilePicture();
                                            this.getFleetUsers();
                                            this.handleConnect(userId);
                                        })
                                        .catch((error) => {
                                            alert(JSON.stringify(error));
                                        });
                                }
                            );
                        })
                        .catch((error) => {
                            alert(JSON.stringify(error));
                        });
                });
            }
        }
    };

    handleSaveImage = async (e) => {
        let fleetOwnerId = this.state.fleetOwnerId;

        const fileName = this.state.profilePicture.name;

        await Storage.put(fileName, this.state.profilePicture, {
            contentType: this.state.profilePicture.type,
        })
            .then((data) => {
                console.log("Data : " + JSON.stringify(data));
                this.setState({ uploadedProfilePicture: data.key });
            })
            .catch((err) => console.log("Err : " + JSON.stringify(err)));

        if (fleetOwnerId !== null && fleetOwnerId !== "" && fleetOwnerId !== "0") {
            var objFleetOwner = {
                id: fleetOwnerId,
                profilePicture: this.state.uploadedProfilePicture,
                updatedAt: new Date(),
                _version: this.state.version
            };

            await API.graphql(graphqlOperation(mutations.updateFleetOwners, { input: objFleetOwner }))
                .then(async (data) => {
                    this.setState({ imageButtonText: "Change Image" });
                })
                .catch((error) => {
                    alert(JSON.stringify(error));
                });

            await this.loadProfilePicture();
        }
    };

    loadProfilePicture = async () => {
        if (this.state.fleetOwnerId !== "0") {
            await API.graphql(graphqlOperation(getFleetOwners, { id: this.state.fleetOwnerId })).then(async (result) => {
                let profilePic = "";

                if (result.data.getFleetOwners.profilePicture !== "") {
                    await Storage.get(result.data.getFleetOwners.profilePicture, { level: "public" })
                        .then((url) => {
                            profilePic = url;
                        })
                        .catch((err) => console.log("error = " + err));
                }

                this.setState({ profilePicture: profilePic, isImageChanged: false });
            });
        } else this.setState({ profilePicture: "", isImageChanged: false });
    };

    render() {
        const { searchText, loading, fleetUsers, data, allLicenses, filteredUsers } = this.state;

        return (
            <>
                {/* <div><Sidebar /></div> */}
                <div className={styles.homepageContent}>
                    <Row className={styles.dataTable}>
                        
                        {(this.state.companyType === "Standard Customer") ?
                            <Col md="12">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">FLEET</CardTitle>
                                        <Row className={classNames("align-items-center", styles.search)}>
                                            <Col md="3">                                            
                                            <Input
                                                type="text"
                                                className="search"
                                                id="searchText"
                                                name="searchText"
                                                value={searchText}
                                                onChange={this.handleChange}
                                                placeholder="Search"
                                                onKeyDown={(e) => {
                                                    this.onPressEnter(e);
                                                }}
                                            />
                                        </Col>
                                        <Col md="2.01">
                                            <Button
                                                className={classNames("btn-round", styles.searchButton)}
                                                onClick={this.handleSearch}
                                                color="warning"
                                                type="button"
                                            >
                                                Search
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardHeader>

                                    {loading ? (
                                        <div className="d-flex justify-content-center">
                                            <img src={loader} alt="loading" style={{ width: "32px", height: "32px" }} />
                                        </div>
                                    ) : (
                                            <ReactTable
                                                data={this.state.searchText.trim().length > 0 ? filteredUsers : fleetUsers}
                                                columns={[
                                                    {
                                                        Header: "License",
                                                        accessor: "code",
                                                        Cell: (props) => {
                                                            return (allLicenses.filter(l => l.id === props.row.original.licenseId).length > 0) ? allLicenses.filter(l => l.id === props.row.original.licenseId)[0].code : ""
                                                        }
                                                    },
                                                    {
                                                        Header: "LAST ACCESSED",
                                                        accessor: "lastLoginDate",
                                                        Cell: (props) => Helper.formatDate(props.value),
                                                    },
                                                    {
                                                        Header: "DEVICE TYPE",
                                                        accessor: "deviceType",
                                                    },
                                                    {
                                                        Header: "Status",
                                                        accessor: "isActive",
                                                        Cell: (props) => (props.value ? "Logged In" : "Logged out"),
                                                    },
                                                    {
                                                        Header: "Actions",
                                                        accessor: "actions",
                                                        sortable: false,
                                                        filterable: false,
                                                        Cell: (props) => (
                                                            // we've added some custom button actions
                                                            <div className="App">
                                                                <div className="overflow-hidden-container">
                                                                    <UncontrolledButtonDropdown style={{ textAlign: "center" }}>
                                                                        <DropdownToggle className={styles.dropdownToggle} color="wight" size="sm">
                                                                            <img src={more} className={styles.actionimg} alt="more" />
                                                                        </DropdownToggle>
                                                                        <DropdownMenu positionFixed={true} className={styles.dropdownShadow}>
                                                                            <DropdownItem href="#">
                                                                                <div style={{ fontWeight: "600" }} onClick={() => this.handleRemoveFleet(props.row.original.id, props.row.original._version)}>Logout</div>
                                                                            </DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledButtonDropdown>
                                                                </div>
                                                            </div>
                                                        ),
                                                    },
                                                ]}
                                            />
                                    )}
                                </Card>
                            </Col>
                            :
                            (this.state.companyType === "Enterprise Customer" ?
                                <Col md="12">
                                    <Card>
                                        <CardHeader>
                                            <CardTitle tag="h4">FLEET</CardTitle>
                                            <Row className={classNames("align-items-center", styles.search)}>
                                                <Col md="3">
                                                    <Input
                                                        type="text"
                                                        className="search"
                                                        id="searchText"
                                                        name="searchText"
                                                        value={searchText}
                                                        onChange={this.handleChange}
                                                        placeholder="Search"
                                                        onKeyDown={(e) => {
                                                            this.onPressEnter(e);
                                                        }}
                                                    />
                                                </Col>
                                                <Col md="2.01">
                                                    <Button
                                                        className={classNames("btn-round", styles.searchButton)}
                                                        onClick={this.handleSearch}
                                                        color="warning"
                                                        type="button"
                                                    >
                                                        Search
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        {loading ? (
                                            <div className="d-flex justify-content-center">
                                                <img src={loader} alt="loading" style={{ width: "32px", height: "32px" }} />
                                            </div>
                                        ) : (
                                                <ReactTable
                                                    data={this.state.searchText.trim().length > 0 ? filteredUsers : fleetUsers}
                                                    columns={[
                                                        {
                                                            Header: "License",
                                                            accessor: "code",
                                                            Cell: (props) => {
                                                                return (allLicenses.filter(l => l.id === props.row.original.licenseId).length > 0) ? allLicenses.filter(l => l.id === props.row.original.licenseId)[0].code : ""
                                                            }
                                                        },
                                                        {
                                                            Header: "LAST ACCESSED",
                                                            accessor: "lastLoginDate",
                                                            Cell: (props) => Helper.formatDate(props.value),
                                                        },
                                                        {
                                                            Header: "DEVICE TYPE",
                                                            accessor: "deviceType",
                                                        },
                                                        {
                                                            Header: "Status",
                                                            accessor: "isActive",
                                                            Cell: (props) => (props.value ? "Logged In" : "Logged out"),
                                                        },
                                                        ,
                                                    ]}
                                                />
                                        )}
                                    </Card>
                                </Col>
                                : null)}
                    </Row>
                </div>
            </>
        );
    }
}
