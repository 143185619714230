import React from "react";
import Header from "../header";
import styles from "./styles.module.scss";
import Helper from "../../services/helper";
import { withRouter } from "react-router-dom";
import Sidebar from "./Sidebar";
import routes from "../../Router/routes";
import logo from "../../assests/images/logo.png";

import { API, graphqlOperation, Storage } from "aws-amplify";

import { getFleetOwners } from "../../graphql/queries";

class LandingLayout extends React.Component {
    constructor(props) {
        super(props);
        this.logout = this.logout.bind(this);
        this.state = {
            activeColor: "info",
            backgroundColor: "black",
            sidebarMini: false,
            profilePicture: logo,
        };
    }
    componentDidMount() {
        this.getFleetOwnerDetails();
    }
    getFleetOwnerDetails = async () => {
        let fId =
            localStorage.getItem("fleetOwnerId") === null
                ? this.props.location.state
                    ? this.props.location.state.fleetOwnerId
                    : ""
                : localStorage.getItem("fleetOwnerId");

        if (fId !== null && fId !== undefined) {
            await API.graphql(graphqlOperation(getFleetOwners, { id: fId })).then(async (result) => {
                let profileImg = "";
                if (result.data.getFleetOwners !== null) {
                    if (result.data.getFleetOwners.profilePicture.trim() !== "") {
                        await Storage.get(result.data.getFleetOwners.profilePicture, { level: "public" })
                            .then((url) => {
                                profileImg = url;
                            })
                            .catch((err) => console.log("error = " + err));
                    } else {
                        profileImg = logo;
                    }
                    localStorage.setItem("profileImg", profileImg);
                    localStorage.setItem("companyType", (result.data.getFleetOwners.companyType === undefined || result.data.getFleetOwners.companyType === null) ? "Standard Customer" : result.data.getFleetOwners.companyType);
                    this.setState({ profilePicture: profileImg });
                }
            });
        }
    };

    handleActiveClick = (color) => {
        this.setState({ activeColor: color });
    };
    handleBgClick = (color) => {
        this.setState({ backgroundColor: color });
    };
    handleMiniClick = () => {
        if (document.body.classList.contains("sidebar-mini")) {
            this.setState({ sidebarMini: false });
        } else {
            this.setState({ sidebarMini: true });
        }
        document.body.classList.toggle("sidebar-mini");
    };

    logout() {
        Helper.Logout();
        this.props.history.push("/login");
    }

    render() {
        const { children, ...rest } = this.props;
        const { backgroundColor, activeColor } = this.state;
        const filterRoutes = routes.filter((route) => !route.isPublic && route.isInSidebar);

        return (
            <div id="landing-container" className={styles.layoutWrapper}>
                <div className={styles.mainContent}>
                    <div className="main-panel">
                        <Header logout={this.logout} {...rest} handleMiniClick={this.handleMiniClick} />
                    </div>
                    <div className="center-content">
                        <Sidebar {...rest} routes={filterRoutes} bgColor={backgroundColor} activeColor={activeColor} />
                        <div className="main-panel">{children} </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(LandingLayout);
