import RoadRadarSignUp from '../views/auth/signup/signup';
import RoadRadarLogin from '../views/auth/login';
import ForgotPassword from '../views/auth/forgotPassword';
import EmailSent from '../views/auth/emailSent';
import ChangePassword from '../views/auth/changePassword';
import PasswordChanged from '../views/auth/passwordChanged';
import RoadRadarHomepage from '../views/homepage';
import VerifyAccount from '../views/auth/verification/verifyAccount';
import RoadRadarManageLicenses from '../views/license/manageLicenses';
import PaymentFailed from '../views/homepage/paymentFailed';
import Fleet from '../views/homepage/fleet/Fleet';
import FleetMap from '../views/homepage/fleetMep/FleetMap';
// import Sidebar from '../views/homepage/Sidebar';
import Profile from 'views/homepage/profile/Profile';


// {
//     path: "/dashboard",
//     name: "Dashboard",
//     component: Dashboard,
//     layout: "/admin",
//   },
const routes = [
    {
        id: 1,
        path: '/',
        component: RoadRadarLogin,
        isPublic: true,
    },
    {
        id: 2,
        path: '/sign-up',
        component: RoadRadarSignUp,
        isPublic: true,
    },
    {
        id: 3,
        path: '/login',
        component: RoadRadarLogin,
        isPublic: true,
    },
    {
        id: 4,
        path: '/dashboard/:cid?',
        component: RoadRadarHomepage,
        isPublic: false,
        name: "Dashboard",
        icon: "nc-icon nc-bank",
        isInSidebar: true
    },
    {
        id: 5,
        path: '/verify-account',
        component: VerifyAccount,
        isPublic: true,
    },
    {
        id: 6,
        path: '/manage-licenses/:cid?',
        component: RoadRadarManageLicenses,
        isPublic: false,
        icon: "nc-icon nc-ruler-pencil",
        name: "licenses",
        isInSidebar: true
    },
    {
        id: 7,
        path: '/forgot-password',
        component: ForgotPassword,
        isPublic: true,
    },
    {
        id: 8,
        path: '/email-sent',
        component: EmailSent,
        isPublic: true,
    },
    {
        id: 9,
        path: '/change-password',
        component: ChangePassword,
        isPublic: true,
    },
    {
        id: 10,
        path: '/password-changed',
        component: PasswordChanged,
        isPublic: true,
    },
    {
        id: 11,
        path: '/payment-failed',
        component: PaymentFailed,
        isPublic: false,
    },

    {
        id: 12,
        path: "/fleet",
        name: "Fleet ",
        component: Fleet,
        isPublic: false,
        icon: "nc-icon nc-paper",
        isInSidebar: true
    },
    {
        id: 13,
        path: "/map",
        name: "Fleet Map ",
        component: FleetMap,
        isPublic: false,
        icon: "nc-icon nc-ruler-pencil",
        isInSidebar: true
    },
    {
        id: 14,
        path: '/profile',
        component: Profile,
        isPublic: false,

    }

];

export default routes;
