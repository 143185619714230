import React, { Component } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

class PaymentFailed extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        var _this = this;

        setTimeout(function () {
            _this.props.history.push('/dashboard/0');
        }, 5000);
    }

    render() {
        return (
            <div className={styles.homepageContent}>
                <div className={styles.topLine}>
                    <div className="title">Payment Failed</div>
                </div>
                <div className={styles.content}>
                    <p>Payment was unsuccessful. Please try again.</p>
                </div>
            </div>
        );
    }
}

export default PaymentFailed;