import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import Routing from "./Router/Routing";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/general.scss";

import "./assets/scss/paper-dashboard.scss?v=1.3.0";
import "./assets/demo/demo.css";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Routing />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
