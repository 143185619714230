import React from "react";
import { useTable, useFilters, useSortBy, usePagination } from "react-table";
import classnames from "classnames";

function Table({ columns, data }) {
  const { getTableProps, headerGroups, page, prepareRow } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 50, pageIndex: 0 },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <div className="ReactTable -striped -highlight primary-pagination p-1">
      <div className="pagination-top"></div>
      <table {...getTableProps()} className="rt-table">
        <thead className="rt-thead -header">
          {headerGroups.map((headerGroup) => (
            <tr className="rt-tr">
              {headerGroup.headers.map((column, key) => (
                  <th

                      key={key}
                      width={column.marginLeft} {...column.getHeaderProps(column.getSortByToggleProps())}
                      {...column.getHeaderProps(column.isSortingEnable !== false ? column.getSortByToggleProps() : {})}
                      className={classnames("rt-th rt-resizable-header", {
                          "-cursor-pointer": headerGroup.headers.length - 1 !== key && column.isSortingEnable !== false,
                          "-sort-asc": column.isSorted && !column.isSortedDesc,
                          "-sort-desc": column.isSorted && column.isSortedDesc,

                      })}

                  >
                      <div className="rt-resizable-header-content">{column.render("Header")}</div>

                  </th>
              ))}
            </tr>
          ))}
        </thead>

        {page.length > 0 ? (
          page.map((row, i) => {
            prepareRow(row);
            return (
              <tr className={classnames("rt-tr", { " -odd": i % 2 === 0 }, { " -even": i % 2 === 1 })}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} className="rt-td">
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })
        ) : (
          <p className="text-center mt-5 mb-5 text-bold">No records found</p>
        )}
      </table>
    </div>
  );
}

export default Table;
