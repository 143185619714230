// import React, { useEffect } from 'react';
// // import { Navbar, Nav } from 'react-bootstrap';
// import { NavLink } from 'react-router-dom';
// import classNames from 'classnames';
// import BurgerIcon from '../burgericon';
// import styles from './styles.module.scss';
// import search from '../../assests/images/search.svg';
// import lock from '../../assests/images/lock.svg';
// import { Button } from 'reactstrap';

// import classnames from "classnames";
// import { useLocation } from "react-router-dom";
// import {
//     Button,
//     Collapse,
//     DropdownToggle,
//     DropdownMenu,
//     DropdownItem,
//     UncontrolledDropdown,
//     Form,
//     Input,
//     InputGroupAddon,
//     InputGroupText,
//     InputGroup,
//     NavbarBrand,
//     Navbar,
//     NavItem,
//     NavLink,
//     Nav,
//     Container,
// } from "reactstrap";
// class Header extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             searchText: '',
//             color: "navbar-transparent",
//             collapseOpen: 'false',
//             sidebarOpen: 'false',


//         }
//         this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
//     }

//     handleSearchTextChange(e) {
//         const { name, value } = e.target;
//         this.setState({
//             [name]: value
//         });
//     }



// render() {

//     return (
//         <div className={styles.headerContent}>
//            
//             {/* <Navbar className={styles.topBar} collapseOnSelect fixed="top" expand="lg">
//                     <Navbar.Toggle aria-controls="responsive-navbar-nav">
//                         <BurgerIcon />
//                     </Navbar.Toggle>
//                     <Navbar.Collapse id="responsive-navbar-nav" className={styles.navbarCollapse}>
//                         <form action="">
//                             <div style={{ display: "none" }} className={classNames('form-group mb-0', styles.formGroup)}>
//                                 <input
//                                     type="text"
//                                     className={classNames('form-control', styles.formControl)}
//                                     placeholder="Search"
//                                     name="searchText"
//                                     value={this.state.searchText}
//                                     onChange={this.handleSearchTextChange}
//                                 />
//                                 <img src={search} alt="search" />
//                             </div>
//                         </form>
//                         <Nav className={styles.colapseSection}>
//                             <div className={styles.userContent}>
//                                 <div className={styles.avatar}>
//                                     {localStorage.getItem("fleetOwnerAcronym")}
//                                 </div>
//                                 <div className={styles.userName}>
//                                     {localStorage.getItem("fleetOwnerName")}
//                                 </div>
//                             </div>
//                             <div className={styles.logout}>
//                                 <Button
//                                     onClick={this.props.logout}
//                                     color="warning"
//                                     className={classNames('btn-round')}
//                                 >
//                                     <span className="btn-label mr-2">
//                                         <i className="fa fa-lock" />
//                                     </span>
//                                     Logout
//                                 </Button>
//                             </div>
//                         </Nav>
//                     </Navbar.Collapse>
//                 </Navbar> */}
//         </div>
//     );
// }


// }

// export default Header;

import React from "react";
import classnames from "classnames";
import styles from './styles.module.scss';
import { useLocation } from "react-router-dom";
import {
    Button,
    Collapse,

    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container,
} from "reactstrap";

function Header(props) {
    const [collapseOpen, setCollapseOpen] = React.useState(false);
    const [sidebarOpen, setSidebarOpen] = React.useState(false);
    const [color, setColor] = React.useState("navbar-transparent");
    const location = useLocation();
    React.useEffect(() => {
        window.addEventListener("resize", updateColor);
    });
    React.useEffect(() => {
        if (
            window.outerWidth < 993 &&
            document.documentElement.className.indexOf("nav-open") !== -1
        ) {
            document.documentElement.classList.toggle("nav-open");
        }
    }, [location]);

    const updateColor = () => {
        if (window.innerWidth < 993 && collapseOpen) {
            setColor("bg-white");
        } else {
            setColor("navbar-transparent");
        }
    };

    const toggleSidebar = () => {
        document.documentElement.classList.toggle("nav-open");
        setSidebarOpen(!sidebarOpen);
    };

    const toggleCollapse = () => {
        if (!collapseOpen) {
            setColor("bg-white");
        } else {
            setColor("navbar-transparent");
        }
        setCollapseOpen(!collapseOpen);
    };
    return (
        <>
            <Navbar
                className={classnames(color)}
                expand="lg"
            >
                <Container fluid>
                    <div className="navbar-wrapper">
                        <div className="navbar-minimize">
                            <Button
                                className="btn-icon btn-round"
                                color="default"
                                id="minimizeSidebar"
                                onClick={props.handleMiniClick}
                            >
                                <i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini" />
                                <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular" />
                            </Button>
                        </div>
                        <div
                            className={classnames("navbar-toggle", {
                                toggled: sidebarOpen,
                            })}
                        >
                            <button
                                className="navbar-toggler"
                                type="button"
                                onClick={toggleSidebar}
                            >
                                <span className="navbar-toggler-bar bar1" />
                                <span className="navbar-toggler-bar bar2" />
                                <span className="navbar-toggler-bar bar3" />
                            </button>
                        </div>

                    </div>
                    <button
                        aria-controls="navigation-index"
                        aria-expanded={collapseOpen}
                        aria-label="Toggle navigation"
                        className="navbar-toggler"
                        // data-target="#navigation"
                        data-toggle="collapse"
                        type="button"
                        onClick={toggleCollapse}
                    >
                        <span className="navbar-toggler-bar navbar-kebab" />
                        <span className="navbar-toggler-bar navbar-kebab" />
                        <span className="navbar-toggler-bar navbar-kebab" />
                    </button>
                    <Collapse
                        className="justify-content-end"
                        navbar
                        isOpen={collapseOpen}
                    >

                        <div className={styles.logout}>
                            <Button
                                onClick={props.logout}
                                color="warning"
                                className={classnames('btn-round')}
                            >
                                <span className="btn-label mr-2">
                                    <i className="fa fa-lock" />
                                </span>
                                Logout
                            </Button>
                        </div>
                    </Collapse>
                </Container>
            </Navbar>
        </>
    );
}

export default Header;
