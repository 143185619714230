
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
  Modal,
} from "reactstrap";
import { NavLink } from "react-router-dom";
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import Helper from "../../../services/helper";
import { API, graphqlOperation, Auth } from "aws-amplify";
import { getFleetOwners } from "../../../graphql/queries";
import Geocode from "react-geocode";


function RoadRadarLogin(props) {
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessages, setErrorMessages] = useState("");
  const [loading, setLoading] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {

       const getGeoCodeKey = async() => {
           var secureKeys = await Helper.getSecureKeys();
           Geocode.setApiKey(secureKeys.response.googleApiKey);
           Geocode.setLanguage("en");
        }

        getGeoCodeKey();
      
    document.body.classList.toggle("login-page");
    return function cleanup() {
      document.body.classList.toggle("login-page");
    };
  }, []);

  const setisPasswordShown = (e) => {
    setIsPasswordShown(!isPasswordShown);
  };

  const toggleModalClassic = () => {
    setModalOpen(true);
  };
  const toggleCloseModalClassic = () => {
    setModalOpen(false);
  };
    const handleLogin = async (e) => {

    setLoading(true);
    e.preventDefault();
    let isValid = true;

    const messages = [];

    if (email === "") {
      isValid = false;
      messages.push("- Please enter email\n");
    }

    if (password === "") {
      isValid = false;
      messages.push("- Please enter password\n");
    }

    if (!isValid) {
      setLoading(false);
      alert(Helper.arrayToString(messages));
      return;
    }

    await Auth.signIn(email, password)
      .then(async (result) => {
        const credentials = await Auth.currentCredentials();

        await API.graphql(graphqlOperation(getFleetOwners, { id: credentials.identityId }))
          .then(async (result) => {
            setLoading(false);
            localStorage.setItem("fleetOwnerId", result.data.getFleetOwners.id);
            localStorage.setItem(
              "fleetOwnerName",
              result.data.getFleetOwners.firstName + " " + result.data.getFleetOwners.lastName
              );
                        
              let { addressLine1, addressLine2, city, country } = result.data.getFleetOwners;         
              var fullAddress = (addressLine1.trim() !== "" ? addressLine1.trim() + "," : '') + (addressLine2.trim() !== "" ? addressLine2.trim() + "," : '')
                  + (city !== "" ? "," + city.trim() : '') + (country !== "" ? "," + country : '') ;
              Geocode.fromAddress(fullAddress).then(
                  (response) => {
                      console.log("responce", response);
                      const { lat, lng } = response.results[0].geometry.location;
                      console.log(lat, lng);
                      localStorage.setItem("latitude", lat);
                      localStorage.setItem("longitude", lng);
                      navigateToDashboard(result);
                  },
                  (error) => {
                      console.error("geo rrrr", error);
                      navigateToDashboard(result);
                      navigator.geolocation.getCurrentPosition(function (position) {
                          localStorage.setItem("Latitude is :", position.coords.latitude);
                          localStorage.setItem("Longitude is :", position.coords.longitude);
                      });
                  }
              );

           
          })
          .catch((err) => {
            setLoading(false);
            console.log(JSON.stringify(err));
            setErrorMessages("Something went wrong. Please try again later.");
          });
      })
      .catch((err) => {
        setLoading(false);
        console.log(JSON.stringify(err));
        if (err.code === "NotAuthorizedException") {
          if (err.message === "Incorrect username or password.")
            setErrorMessages("Invalid username or password. Please try again.");
          else setErrorMessages("This account is disabled. Please contact your administrator.");
        } else setErrorMessages("Invalid username or password. Please try again.");
      });
    };

    const navigateToDashboard = (result)=> {
        var str = result.data.getFleetOwners.firstName + " " + result.data.getFleetOwners.lastName;
        var matches = str.match(/\b(\w)/g);
        var acronym = matches.join("");

        localStorage.setItem("fleetOwnerAcronym", acronym);

        props.history.push("/dashboard");

    }

    const handleKeypress = e => {
        //it triggers by pressing the enter key
        if (e.keyCode === 13) {           
            handleLogin(e);
        }
    };

  return (
    <div>
      <AuthNavbar />
      <div className="wrapper wrapper-full-page">
        <div className="full-page section-image">
          <div className="login-page">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" lg="4" md="6">
                  <Form action="" className="form" method="">
                    <Card className="card-login">
                      <CardHeader>
                        <CardHeader>
                          <h3 className="header text-center mb-0">Login</h3>
                          <p className="header text-center">Login to your portal below</p>
                        </CardHeader>
                      </CardHeader>
                      <CardBody>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="nc-icon nc-single-02" style={{ marginRight: "10px" }} />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="email"
                            placeholder="Email address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          onKeyUp={(e) => handleKeypress(e)}
                          />
                        </InputGroup>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="nc-icon nc-key-25" style={{ marginRight: "10px" }} />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Enter password"
                            type="password"
                            autoComplete="off"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onKeyUp={(e) => handleKeypress(e)}
                          />
                          {/* <NavLink to="#" onClick={this.setisPasswordShown}>
                                    <img src={!isPasswordShown ? hide : eye} alt="password visibility icon" />
                                 </NavLink> */}
                        </InputGroup>
                        <br />
                        <FormGroup>
                          <FormGroup check>
                            <NavLink to="/forgot-password">Forgot Password?</NavLink>
                          </FormGroup>
                        </FormGroup>
                      </CardBody>
                      <CardFooter>
                        <Button
                          block
                          className="btn-round mb-3"
                          disabled={loading}
                          color="warning"
                          href="#pablo"
                          onClick={handleLogin}
                        >
                          {loading ? (
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          ) : (
                            "Login"
                          )}
                        </Button>
                      </CardFooter>
                      <div style={{ color: "red", margin: "0px 8px" }}>{errorMessages}</div>
                    </Card>
                  </Form>
                </Col>
              </Row>
            </Container>

            <div
              className="full-page-background"
              style={{
                backgroundImage: `url(${require("../../../assests/images/signup-banner.png")})`,
              }}
            />
            <Modal isOpen={modalOpen} toggle={toggleModalClassic}>
              <div className="modal-header justify-content-center">
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={toggleCloseModalClassic}
                >
                  <i className="nc-icon nc-simple-remove" />
                </button>
                <h4 className="title title-up welcome">Welcome</h4>
              </div>
              <div className="modal-body">
                <p>
                  RoadRadar is the global standard for road safety. To help you get started a free 30-day vehicle
                  licence has been credited to your account. In order to protect more vehicles, you may purchase
                  additional licences now or speak to your account manager: james@roadradar.io
                </p>
              </div>
              <div className="modal-footer">
                <div className="left-side">
                  <Button
                    className="btn-link"
                    color="default"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleCloseModalClassic}
                  >
                    Later
                  </Button>
                </div>
                <div className="divider" />
                <div className="right-side">
                  <Button className="btn-link" color="danger" type="button">
                    Buy Licences
                  </Button>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RoadRadarLogin;
