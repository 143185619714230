import React, { Component } from "react";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { Modal } from "react-bootstrap";
import CheckoutForm from "../../services/checkoutForm";
import Helper from "../../services/helper";
import { Button, InputGroup } from "reactstrap";
import { Modal as StrapModal } from "reactstrap";
import "./index.scss";
import loader from "../../assests/images/loading_dots.gif";
import styles from "./styles.module.scss";
import QRCode from 'qrcode.react';
import * as mutations from "../../graphql/mutations";
import Select from "react-select";
import logo from "../../assests/images/logo.png";
import { withRouter } from "react-router-dom";

import { getFleetOwnerPurchasesByFleetOwnerId, getFleetOwners, getLicenseByFleetOwnerId } from "../../graphql/queries";

var randomstring = require("randomstring");

const roundTo = require("round-to");

const customStyles = {
    option: (provided) => ({
        ...provided,
        textAlign: "left",
    }),
    placeholder: (defaultStyles) => ({
        ...defaultStyles,
        textAlign: "left",
    }),
};

let totalLicences = [];

class BuyLicensesPopBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileName:
                "Licenses_" + new Date().toLocaleDateString().split(" ").join("") + "-" + new Date().getTime().toString(),
            code: "",
            checkoutId: this.props.match.params["cid"] === undefined ? "-" : this.props.match.params["cid"],
            pdfFileName:
                localStorage.getItem("fleetOwnerId") +
                "-" +
                new Date().getDate().toString().replace(" ", "-") +
                "-" +
                new Date().getTime().toString() +
                ".pdf",
            enableInvoicePayment: "",
            licenseCodes: [],
            fleetOwnerId:
                localStorage.getItem("fleetOwnerId") === null
                    ? this.props.location.state
                        ? this.props.location.state.fleetOwnerId
                        : ""
                    : localStorage.getItem("fleetOwnerId"),
            licenses: [],
            loading: true,
            searchText: "",
            purchaseDateSortOrder: "DESC",
            statusSortOrder: "ASC",
            codeSortOrder: "ASC",
            nextToken: undefined,
            currentPageNo: 1,
            showCurrentPageNo: 1,
            pageSize: 30,
            hasMoreData: true,
            isLoading: true,
            nextNextToken: null,
            previousTokens: [],
            hasPrev: false,
            hasNext: true,
            licenseCost: 0,
            profilePicURL: logo,
            isSortedByPurchaseDate: false,
            isSortedByCode: false,
            isSortedByStatus: false,
            paymentOption: [
                { value: "card", label: "Card" },
                { value: "invoice", label: "Invoice" },
            ],
            paymentOption2: [{ value: "card", label: "Card" }],
            inProgress: false,
            showProgress: false,
            noOfLicense: "",
            perLicenseRate: 0,
            errorMessage: "",
            paymentDescription: "",
            paymentTypeSelect: { value: "card", label: "Card" },
            paymentInProgress: false,
            paymentValue: "card",
            VATRate: 0,
            VATValue: 0,
            totalValue: 0,
            currency: "",
            StripeDomesticRate: 0,
            StripeInternationalRate: 0,
            StripeAdditionalCharge: 0,
            StripeSecretKey: "",
            selectedCountry: { value: "", label: "" },
            selectedNoOfVehicle: { value: "", label: "" },
            profile: {
                Email: "",
                FirstName: "",
                LastName: "",
                CompanyName: "",
                CompanyNo: "",
                PhoneNo: "",
                NoOfVehicles: "",
                Country: "",
                TotalLicenses:0,
                UnassignedLicenses: 0,
                PdfFile: "",
                ProfilePicture: logo,
                PdfFileUrl: "",
                AddressLine1: "",
                AddressLine2: "",
                City: "",
                Postcode: "",
                qrCode: "",
            },
            licenseCode: "",
            isOpen: false

        };
   }
    componentDidMount() {
        this.loadConfig();
        this.getFleetOwnerLicense();
    }

    getFleetOwnerLicense = async () => {
        var hasMoreRec = true;
        let licenses = [];
        let totalLicences = licenses;
        this.setState({ rNextToken: undefined });

        while (hasMoreRec) {
            var variables = {
                fleetOwnerId: this.state.fleetOwnerId,
                nextToken: this.state.rNextToken,
                filter: {
                    isDeleted: { eq: false }
                },
                limit: 500,
                sortDirection: "DESC",
            };
            await API.graphql(graphqlOperation(getLicenseByFleetOwnerId, variables)).then(result => {
                this.setState({ rNextToken: result.data.getLicenseByFleetOwnerId.nextToken });
                result.data.getLicenseByFleetOwnerId.items.forEach(function (itm) {
                    licenses.push({
                        id: itm.id,
                        code: itm.code,
                        fleetOwnerPurchaseId: itm.fleetOwnerPurchaseId,
                        assignedLicense: itm.assignedLicense,
                    });
                });

                hasMoreRec = result.data.getLicenseByFleetOwnerId.nextToken !== null;
            });
        }
        await API.graphql(graphqlOperation(getFleetOwners, { id: this.state.fleetOwnerId })).then(async (result) => {

            if (result.data.getFleetOwners.enableInvoicePayment === 'Yes') {
                this.setState({
                    enableInvoicePayment : result.data.getFleetOwners.enableInvoicePayment
                })
            } else {
                this.setState({
                    enableInvoicePayment: 'No'
                })
            }
            var objFleetOwner = {
                id: this.state.fleetOwnerId,
                totalLicenses: totalLicences.length,
                updatedAt: new Date(),
                _version: result.data.getFleetOwners._version
            };

            await API.graphql(graphqlOperation(mutations.updateFleetOwners, { input: objFleetOwner }))
                .then(async (data) => {
                    console.log("updated status", data)
                })
                .catch((error) => {
                    console.log("Error updating fleet owner total licenses: " + error);
                });
        });

        this.setState({
            licenseCode: licenses.filter(
                (l) => l.fleetOwnerPurchaseId === "Fleet Owner License"
            )[0].code
        }, function () {
            this.getFleetOwnerDetails();
        });
    }

    selectPaymentType = (e) => {
        this.setState({ paymentValue: e.value, paymentTypeSelect: e });
    };

    dataURItoFile(dataURI, contentType) {
        var binary = atob(dataURI.split(",")[1]);
        var array = [];

        for (var i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        return new File([new Uint8Array(array)], {
            type: contentType,
        });
    }

    invoiceLicense = async () => {
        const {
            fleetOwnerId,
            VATRate,
            pdfFileName,

            perLicenseRate,
            totalValue,
            noOfLicense,
            licenseCost
        } = this.state;

        this.props.handleCloseLicencePopup();
        this.setState({ paymentInProgress: true, showProgress: true });
        var paymentDetails = {
            fleetOwnerId: fleetOwnerId,
            numberOfLicense: parseInt(noOfLicense),
            cost: licenseCost,
            vat: VATRate,
            total: totalValue,
            pdfFilePath: pdfFileName,

            createdAt: new Date(),
        };
        await API.graphql(graphqlOperation(mutations.createFleetOwnerPurchases, { input: paymentDetails }))
            .then(async (data) => {
                var purchaseId = "";

                await API.graphql(
                    graphqlOperation(getFleetOwnerPurchasesByFleetOwnerId, {
                        fleetOwnerId: fleetOwnerId,
                        sortDirection: "DESC",
                    })
                ).then(async (result) => {
                    purchaseId = result.data.getFleetOwnerPurchasesByFleetOwnerId.items[0].id;
                });

                let listCode = [];
                let vCode = "";
                var i = 0;

                for (i = 0; i < noOfLicense; i++) {
                    vCode = randomstring.generate({
                        length: 10,
                        charset: "alphanumeric",
                    });

                    this.setState({ code: vCode });

                    const qrCodeCanvas = document.querySelector("canvas");
                    const qrCodeDataUri = qrCodeCanvas.toDataURL("image/png", 0.3);

                    var qrFile = this.dataURItoFile(qrCodeDataUri, "image/png");
                    var qrImageName = vCode.toUpperCase() + ".png";

                    await Storage.put(qrImageName, qrFile, {
                        contentType: "image/png",
                    })
                        .then((data) => {
                            console.log("Qr Image : " + JSON.stringify(data.key));
                        })
                        .catch((err) => console.log("Err : " + JSON.stringify(err)));

                    listCode.push(vCode.trim().toUpperCase());

                    var objLicense = {
                        fleetOwnerId: fleetOwnerId,
                        fleetUserId: " ",
                        fleetOwnerPurchaseId: purchaseId,
                        code: vCode.trim().toUpperCase(),
                        price: parseFloat(perLicenseRate),
                        qrImage: qrImageName,
                        isAssigned: false,
                        isActive: true,
                        isDeleted: false,
                        assignedLicense: "false",
                        deletedLicense: "false",
                        createdAt: new Date(),
                        paymentType: "Invoice",
                        expiryDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
                    };

                    await API.graphql(graphqlOperation(mutations.createLicenses, { input: objLicense }))
                        .then(async (data) => { })
                        .catch((error) => {
                            console.log("Error creating license: " + error);
                        });
                }

            
                await API.graphql(graphqlOperation(getFleetOwners, { id: this.state.fleetOwnerId })).then(async (result) => {
                    var totalLicense = result.data.getFleetOwners.totalLicenses;
                    var unAssignedLicense = result.data.getFleetOwners.unassignedLicenses;
                    var objFleetOwner = {
                        id: fleetOwnerId,
                        totalLicenses: totalLicense + parseInt(noOfLicense),
                        unassignedLicenses: unAssignedLicense + parseInt(noOfLicense),
                        updatedAt: new Date(),
                        _version: result.data.getFleetOwners._version
                    };

                    await API.graphql(graphqlOperation(mutations.updateFleetOwners, { input: objFleetOwner }))
                        .then(async (data) => {
                            localStorage.removeItem("licenseDetails");
                            alert("Purchase successful");
                            this.setState({ showProgress: false, noOfLicense: "", paymentTypeSelect: { value: "card", label: "Card" } });
                            this.setState({ paymentInProgress: false, showProgress: false });
                            this.props.handleCloseLicencePopup();
                            if (this.props.onSuccess) {
                                this.props.onSuccess();
                            }
                        })
                        .catch((error) => {
                            this.setState({ showProgress: false });
                            localStorage.removeItem("licenseDetails");
                            alert("Something went wrong. Please try again later");
                            console.log("Error updating fleet owner total licenses: " + JSON.stringify(error));
                        });

                });

            })
            .catch((error) => {
                localStorage.removeItem("licenseDetails");
                alert("Something went wrong. Please try again later");
                console.log("Error creating fleet owner purchase: " + error);
            });
    };
 
    generateLicenses = async () => {
        if (localStorage.getItem("licenseDetails") !== null) {
            this.setState({ paymentInProgress: true, showProgress: true });

            const {
                currency,
                StripeDomesticRate,
                StripeInternationalRate,
                StripeAdditionalCharge,
                fleetOwnerId,
                VATRate,
                pdfFileName,
                checkoutId,
                perLicenseRate,
                profile,
            } = this.state;
            
            var licenseDetails = JSON.parse(localStorage.getItem("licenseDetails"));

            const { totalValue, noOfLicense, licenseCost, totalLicenses, unassignedLicenses } = licenseDetails;

            var paidAmount = parseFloat(totalValue);
            var region = currency === "gbp" ? "domestic" : "international";

            var stripeRate = region === "domestic" ? StripeDomesticRate : StripeInternationalRate;

            var stripeAmount = roundTo((paidAmount * stripeRate) / 100 + StripeAdditionalCharge, 2);
            var busbyAmount = paidAmount - stripeAmount;

            var paymentDetails = {
                fleetOwnerId: fleetOwnerId,
                numberOfLicense: parseInt(noOfLicense),
                cost: licenseCost,
                vat: VATRate,
                total: totalValue,
                pdfFilePath: pdfFileName,
                stripeToken: "",
                stripeRate: stripeRate,
                stripeAmount: stripeAmount,
                busbyAmount: busbyAmount,
                stripeChargeId: checkoutId,
                isPaymentSuccessful: true,
                createdAt: new Date(),
            };

            await API.graphql(graphqlOperation(mutations.createFleetOwnerPurchases, { input: paymentDetails }))
                .then(async (data) => {
                    var purchaseId = "";

                    await API.graphql(
                        graphqlOperation(getFleetOwnerPurchasesByFleetOwnerId, {
                            fleetOwnerId: fleetOwnerId,
                            sortDirection: "DESC",
                        })
                    ).then(async (result) => {
                        purchaseId = result.data.getFleetOwnerPurchasesByFleetOwnerId.items[0].id;
                    });

                    let listCode = [];
                    let vCode = "";
                    var i = 0;

                    for (i = 0; i < noOfLicense; i++) {
                        vCode = randomstring.generate({
                            length: 10,
                            charset: "alphanumeric",
                        });

                        this.setState({ code: vCode });

                        const qrCodeCanvas = document.querySelector("canvas");
                        const qrCodeDataUri = qrCodeCanvas.toDataURL("image/png", 0.3);

                        var qrFile = this.dataURItoFile(qrCodeDataUri, "image/png");
                        var qrImageName = vCode.toUpperCase() + ".png";

                        await Storage.put(qrImageName, qrFile, {
                            contentType: "image/png",
                        })
                            .then((data) => {
                                console.log("Qr Image : " + JSON.stringify(data.key));
                            })
                            .catch((err) => console.log("Err : " + JSON.stringify(err)));

                        listCode.push(vCode.trim().toUpperCase());

                        var objLicense = {
                            fleetOwnerId: fleetOwnerId,
                            fleetUserId: " ",
                            fleetOwnerPurchaseId: purchaseId,
                            code: vCode.trim().toUpperCase(),
                            price: parseFloat(perLicenseRate),
                            qrImage: qrImageName,
                            isAssigned: false,
                            isActive: true,
                            isDeleted: false,
                            assignedLicense: "false",
                            deletedLicense: "false",
                            createdAt: new Date(),
                            paymentType: "Card",
                            expiryDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
                        };

                        await API.graphql(graphqlOperation(mutations.createLicenses, { input: objLicense }))
                            .then(async (data) => { })
                            .catch((error) => {
                                console.log("Error creating license: " + error);
                            });
                    }
                    var objFleetOwner = {
                        id: fleetOwnerId,
                        totalLicenses: profile.TotalLicenses + parseInt(noOfLicense),
                        unassignedLicenses: unassignedLicenses + parseInt(noOfLicense),
                        updatedAt: new Date(),
                        _version: this.state.version
                    };
                    
                    await API.graphql(graphqlOperation(mutations.updateFleetOwners, { input: objFleetOwner }))
                        .then(async (data) => {
                            this.setState({ paymentInProgress: false, showProgress: false }, function () {
                                localStorage.removeItem("licenseDetails");
                                alert("Purchase successful");
                                this.props.history.push("/manage-licenses");
                                if (this.props.onSuccess) {
                                    this.props.onSuccess();
                                }
                            });
                        })
                        .catch((error) => {
                            this.setState({ paymentInProgress: false, showProgress: false });
                            localStorage.removeItem("licenseDetails");
                            alert("Something went wrong. Please try again later");
                            console.log("Error updating fleet owner total licenses: " + error);
                        });
                })
                .catch((error) => {
                    localStorage.removeItem("licenseDetails");
                    alert("Something went wrong. Please try again later");
                    console.log("Error creating fleet owner purchase: " + error);
                });
        } else {
            alert("Something went wrong in licence. Please try again later");
        }
    };

    getFleetOwnerDetails = async () => {
        let fId =
            localStorage.getItem("fleetOwnerId") === null
                ? this.props.location.state
                    ? this.props.location.state.fleetOwnerId
                    : ""
                : localStorage.getItem("fleetOwnerId");


        if (fId !== null && fId !== undefined) {
            await API.graphql(graphqlOperation(getFleetOwners, { id: fId })).then(async (result) => {
                let profileData = this.state.profile;
                let countryOption = this.state.selectedCountry;
                let noOfVehicleOption = this.state.selectedNoOfVehicle;

                let profileImg = "";
                let pdfFile = "";

                if (result.data.getFleetOwners !== null) {
                    if (result.data.getFleetOwners.profilePicture.trim() !== "") {
                        await Storage.get(result.data.getFleetOwners.profilePicture, { level: "public" })
                            .then((url) => {
                                profileImg = url;
                                this.setState({ imageButtonText: "Change Image" });
                                localStorage.removeItem("profileNotSet");
                            })
                            .catch((err) => console.log("error = " + err));
                    } else {
                        profileImg = "";
                        this.setState({ profilePicture: "", profilePicURL: logo, imageButtonText: "Choose Image" });
                        localStorage.setItem("profileNotSet", "true");
                    }

                    if (result.data.getFleetOwners.pdfFilePath.trim() !== "") {
                        await Storage.get(result.data.getFleetOwners.pdfFilePath, { level: "public" })
                            .then((url) => {
                                pdfFile = url;
                            })
                            .catch((err) => console.log("error = " + err));
                    } else pdfFile = "";

                    profileData.CompanyName = result.data.getFleetOwners.companyName;
                    profileData.CompanyNo =
                        result.data.getFleetOwners.companyNo === "-" ? "" : result.data.getFleetOwners.companyNo;
                    profileData.Email = result.data.getFleetOwners.email;
                    profileData.FirstName = result.data.getFleetOwners.firstName;
                    profileData.LastName = result.data.getFleetOwners.lastName;
                    profileData.PhoneNo = result.data.getFleetOwners.phone;
                    profileData.NoOfVehicles = result.data.getFleetOwners.noOfVehicles;
                    profileData.Country = result.data.getFleetOwners.country;
                    profileData.TotalLicenses = result.data.getFleetOwners.totalLicenses
                        ? result.data.getFleetOwners.totalLicenses
                        : 0;
                    profileData.UnassignedLicenses = result.data.getFleetOwners.unassignedLicenses
                        ? result.data.getFleetOwners.unassignedLicenses
                        : 0;
                    profileData.PdfFile = result.data.getFleetOwners.pdfFilePath;
                    profileData.PdfFileUrl = pdfFile;
                    profileData.ProfilePicture = profileImg;
                    profileData.AddressLine1 =
                        result.data.getFleetOwners.addressLine1 !== undefined ? result.data.getFleetOwners.addressLine1 : "";
                    profileData.AddressLine2 =
                        result.data.getFleetOwners.addressLine2 !== undefined ? result.data.getFleetOwners.addressLine2 : "";
                    profileData.City = result.data.getFleetOwners.city !== undefined ? result.data.getFleetOwners.city : "";
                    profileData.Postcode =
                        result.data.getFleetOwners.postcode !== undefined ? result.data.getFleetOwners.postcode : "";
                    profileData.qrCode = this.state.licenseCode;

                    countryOption.label = profileData.Country;
                    countryOption.value = profileData.Country;

                    noOfVehicleOption.label = profileData.NoOfVehicles;
                    noOfVehicleOption.value = profileData.NoOfVehicles;
                    localStorage.setItem("profileImg", profileImg);
                    this.setState({
                        profile: profileData,
                        selectedCountry: countryOption,
                        selectedNoOfVehicle: noOfVehicleOption,
                        companyName: result.data.getFleetOwners.companyName,
                        companyNo: result.data.getFleetOwners.companyNo === "-" ? "" : result.data.getFleetOwners.companyNo,
                        email: result.data.getFleetOwners.email,
                        firstName: result.data.getFleetOwners.firstName,
                        lastName: result.data.getFleetOwners.lastName,
                        phoneNo: result.data.getFleetOwners.phone,
                        noOfVehicles: result.data.getFleetOwners.noOfVehicles,
                        country: result.data.getFleetOwners.country,
                        profilePicture: profileImg,
                        addressLine1:
                            result.data.getFleetOwners.addressLine1 !== undefined ? result.data.getFleetOwners.addressLine1 : "",
                        addressLine2:
                            result.data.getFleetOwners.addressLine2 !== undefined ? result.data.getFleetOwners.addressLine2 : "",
                        city: result.data.getFleetOwners.city !== undefined ? result.data.getFleetOwners.city : "",
                        postcode: result.data.getFleetOwners.postcode !== undefined ? result.data.getFleetOwners.postcode : "",
                        paymentDescription:
                            "Payment received from Road Radar Enterprise: " + result.data.getFleetOwners.companyName,
                        totalLicenses: totalLicences.length,
                    });
                }
            });
        }
    };

    handleBuyLicense = () => {
        const { noOfLicense, paymentTypeSelect } = this.state;

        if (parseInt(noOfLicense === "" ? "0" : parseInt(noOfLicense)) > 0) {
            this.props.handleCloseLicencePopup();
        } else {
            this.setState({ errorMessage: "Quantity should be greater than zero" });
            return;
        }
    };
    handleCloseLicencePopup = (e) => {
        this.setState({
            showLicencePopup: false,
        });
    };
    handleNoOfLicenseChange = (e) => {
        var regexp = /^[0-9\b]+$/;
        let license = e.target.value;

        if (license === "" || regexp.test(license)) {
            this.setState({ [e.target.name]: license }, function () {
                var rate = this.state.perLicenseRate;
                var VAT = this.state.VATRate;
                var cost = parseInt(license === "" ? "0" : license) * rate;
                var VATValue = (cost * VAT) / 100;
                var totalValue = cost + VATValue;

                this.setState({ licenseCost: cost, VATValue: VATValue, totalValue: totalValue });
            });
        }
    };
    numberFormate2Decimal = (x) => {
        return Number.parseFloat(x).toFixed(2);
    };
    handleCloseProgressPopup = (e) => {
        this.setState({
            inProgress: false,
        });
    };
    loadConfig = () => {
        fetch(window.location.origin + `/config.json`)
            .then((r) => r.json())
            .then(async (data) => {
                const StripeSecretKey = await Helper.getStripeSecretKey();

                this.setState(
                    {
                        perLicenseRate: parseFloat(data.perLicenseCost),
                        VATRate: parseFloat(data.VAT),
                        StripeDomesticRate: parseFloat(data.StripeDomesticRate),
                        StripeInternationalRate: parseFloat(data.StripeInternationalRate),
                        StripeAdditionalCharge: parseFloat(data.StripeAdditionalCharge),
                        currency: data.currency,
                        StripeSecretKey: StripeSecretKey,
                        noOfLicense:""
                    },
                    function () {
                        //payment succeeded
                        if (this.state.checkoutId !== "-" && this.state.checkoutId !== "0") this.generateLicenses();
                    }
                );
            });
    };
    render() {
        const {
            StripeSecretKey,
            paymentInProgress,
            showProgress,
            searchText,
            paymentOption,
            inProgress,
            currency,
            profile,
            licenseCodes,
            profilePicURL,
            noOfLicense,
            errorMessage,
            licenseCost,
            paymentDescription,
            perLicenseRate,
            VATRate,
            VATValue,
            totalValue,
            paymentTypeSelect,
            showLicencePopup,
            paymentValue,
            enableInvoicePayment,
            paymentOption2,
            version
        } = this.state;

        return (
            <>
                {
                    profile.qrCode !== '' && profile.qrCode !== null ?
                        <div style={{ display: "none" }}>
                            <QRCode value={profile.qrCode} margin="true" size="300" />
                        </div>
                        : null
                }
                <div>
                    <StrapModal
                        isOpen={this.props.showLicencePopup}
                        className="modalBody"
                        onHide={this.props.handleCloseLicencePopup}
                    >
                        <div>

                            <div className="title buyLicense">Buy Licenses</div>
                            <div className="licenseDetails">
                                <div className="detail">
                                    <div className="title" style={{ padding: "0px" }}>Quantity</div>
                                    <div className="formGroup">
                                        <input
                                            type="text"
                                            className="formControl quatityField"
                                            placeholder="Quantity"
                                            name="noOfLicense"
                                            value={noOfLicense}
                                            onChange={this.handleNoOfLicenseChange}
                                        />
                                    </div>
                                </div>
                                <div className="detail">
                                    <div className="title" style={{ padding: "0px" }}>Cost per License</div>
                                    <div className="value">&#163;{this.numberFormate2Decimal(perLicenseRate)}</div>
                                </div>
                                <div className="detail">
                                    <div className="title" style={{ padding: "0px" }}>Total Cost</div>
                                    <div className="value">&#163;{this.numberFormate2Decimal(licenseCost)}</div>
                                </div>
                                <div className="detail">
                                    <div className="title" style={{ padding: "0px" }}>VAT @ {VATRate} %</div>
                                    <div className="value">&#163;{this.numberFormate2Decimal(VATValue)}</div>
                                </div>
                                <div className="detail">
                                    <div className="title" style={{ padding: "0px" }}>Net Amount</div>
                                    <div className="value">&#163;{this.numberFormate2Decimal(totalValue)}</div>
                                </div>
                                <div className="detail">
                                    <div className="title" style={{ padding: "0px" }}>Payment Type</div>
                                    <div className="value">
                                        <InputGroup>
                                            <Select
                                                className="react-select primary paymentField"
                                                classNamePrefix="react-select"
                                                styles={customStyles}
                                                options={enableInvoicePayment === "No" ? paymentOption2 : paymentOption}
                                                name="Select"
                                                placeholder="Select"
                                                value={paymentTypeSelect}
                                                onChange={this.selectPaymentType}
                                            ></Select>
                                        </InputGroup>
                                    </div>
                                </div>
                                <div className="modalActions">
                                    <Button className="cancel" onClick={this.props.handleCloseLicencePopup}>
                                        Cancel
                                    </Button>

                                    {parseInt(noOfLicense === "" ? "0" : parseInt(noOfLicense)) > 0 ? (
                                        paymentValue === "card" ? (
                                            <CheckoutForm
                                                stripePublicKey={StripeSecretKey}
                                                apiName="stripePayment"
                                                apiEndpoint="/checkout"
                                                name="Road Radar Enterprise"
                                                email={profile.Email}
                                                // email="usertestgroup2@gmail.com"
                                                images={[profilePicURL]}
                                                amount={(parseFloat(totalValue).toFixed(2) * 1000) / 10}
                                                currency={currency}
                                                quantity={1}
                                                description={paymentDescription}
                                                // success_url={window.location.origin + "/dashboard/{CHECKOUT_SESSION_ID}"}
                                                success_url={window.location.origin + "/manage-licenses/{CHECKOUT_SESSION_ID}"} // Where to redirect if success
                                                // Where to redirect if success
                                                cancel_url={window.location.origin + "/payment-failed"} // Where to go if payment canceled
                                                noOfLicense={parseInt(noOfLicense)}
                                                totalAmount={parseFloat(totalValue)}
                                                licenseCost={parseFloat(licenseCost)}
                                                totalLicenses={parseInt(profile.TotalLicenses)}
                                                unassignedLicenses={parseInt(profile.UnassignedLicenses)}

                                            />
                                        ) : (
                                            <Button className="buy" onClick={this.invoiceLicense}>
                                                {showProgress ? (
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                ) : (
                                                    "Buy"
                                                )}
                                            </Button>
                                        )
                                    ) : (
                                        <Button onClick={this.handleBuyLicense} className="buy">
                                            Buy
                                        </Button>
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12" style={{ marginTop: "8px" }}>
                                    <p style={{ color: "red", marginBottom: "5px" }}>{errorMessage}</p>
                                </div>
                            </div>
                            {/* <Button className="crossButton" to="#" onClick={this.handleCloseLicencePopup}>
                x
              </Button> */}
                        </div>
                    </StrapModal>
                    <Modal show={paymentInProgress} onHide={this.handleClosePaymentPopup}>
                        <Modal.Body className={styles.modalBody}>
                            <div className="row">
                                <div className="col-md-12" style={{ textAlign: "center" }}>
                                    <label>Do not close the browser or click back button.</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12" style={{ textAlign: "center" }}>
                                    <img src={loader} alt="loading" style={{ width: "50px", height: "50px" }} />
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Modal show={inProgress} onHide={this.handleCloseProgressPopup}>
                        <Modal.Body className={styles.modalBody}>
                            <div className="row">
                                <div className="col-md-12" style={{ textAlign: "center" }}>
                                    <label>Please wait...</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12" style={{ textAlign: "center" }}>
                                    <img src={loader} alt="loading" style={{ width: "50px", height: "50px" }} />
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </>
        );
    }
}
export default withRouter(BuyLicensesPopBox);
