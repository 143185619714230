import React, { Component } from "react";
import moment from "moment";
// import { Modal } from "react-bootstrap";
import { Button, Modal } from "reactstrap";
import classNames from "classnames";
import "../homepage/index.scss";
import styles from "./styles.module.scss";
import paginationStyles from "../../components/pagination/styles.module.scss";
import logo from "../../assests/images/logo.png";
import upArrow from "../../assests/images/arrow-up.png";
import downArrow from "../../assests/images/arrow-down.png";
import loader from "../../assests/images/loading_dots.gif";
// import more from '../../assests/images/more.svg';
// import search from '../../assests/images/search.svg';
import arrowLeft from "../../assests/images/arrow-left.png";
import arrowRight from "../../assests/images/arrow-right.png";
import Helper from "../../services/helper";
import ViewMore from "../../components/tooltip/viewMore";
import QRCode from "qrcode.react";
import { jsPDF } from "jspdf";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { getFleetOwners } from "../../graphql/queries";
import {
    getFleetUsersSortedByCreatedAt,
    getFleetUsersSortedByFirstName,
    getFleetUsersSortedByLastName,
    getFleetUsersSortedByLastLoginDate,
    getFleetUsersSortedByDeviceType,
    getFleetUsersByFleetOwnerId,
    getFleetUsersSortedByDeviceId, getLicenseByFleetOwnerId
} from "../../graphql/queries";
import DashboardMetrics from "./DashboardMetrics";

let fId = ''

var fleetUserData = [];

const customStyles = {
    option: (provided) => ({
        ...provided,
        font: '500 14px/19px "Open Sans", san-serif',
    }),
    placeholder: (defaultStyles) => ({
        ...defaultStyles,
        color: "#999",
    }),
};

class RoadRadarHomepage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pdfFileName:
                localStorage.getItem("fleetOwnerId") +
                "-" +
                new Date().getDate().toString().replace(" ", "-") +
                "-" +
                new Date().getTime().toString() +
                ".pdf",
            loading: true,
            searchText: "",
            fleetUsers: [],
            fleetOwnerId:
                localStorage.getItem("fleetOwnerId") === null
                    ? this.props.location.state
                        ? this.props.location.state.fleetOwnerId
                        : ""
                    : localStorage.getItem("fleetOwnerId"),
            checkoutId: this.props.match.params["cid"] === undefined ? "-" : this.props.match.params["cid"],
            createdAtSortOrder: "DESC",
            lastLoginDateSortOrder: "ASC",
            firstNameSortOrder: "ASC",
            lastNameSortOrder: "ASC",
            deviceTypeSortOrder: "ASC",
            deviceIdSortOrder: "ASC",
            totalRecords: 0,
            currentPageNo: 1,
            showCurrentPageNo: 1,
            inProgress: false,
            nextToken: undefined,
            pageSize: 500,
            hasMoreData: true,
            isLoading: true,
            nextNextToken: null,
            previousTokens: [],
            hasPrev: false,
            hasNext: true,
            isSortedByCreatedAt: false,
            isSortedByLastLoginDate: false,
            isSortedByFirstName: false,
            isSortedByLastName: false,
            isSortedByDeviceType: false,
            isSortedByDeviceId: false,
            show: false,
            showLicencePopup: false,
            isImageChanged: false,
            paymentInProgress: false,
            errorMessage: "",
            riderAlerts: 0,

            noOfVehicles: "",
            country: "",
            uploadedProfilePicture: "",
            profilePicture: "",
            profilePicURL: logo,
            imageButtonText: "Choose Image",
            selectedCountry: { value: "", label: "" },
            selectedNoOfVehicle: { value: "", label: "" },
            code: "",
            noOfLicense: "",
            perLicenseRate: 0,
            licenseCost: 0,
            VATRate: 0,
            VATValue: 0,
            totalValue: 0,
            currency: "",
            StripeDomesticRate: 0,
            StripeInternationalRate: 0,
            StripeAdditionalCharge: 0,
            uploadedPdfFileName: "",
            chartData: [],

            profile: {
                NoOfVehicles: "",
                Country: "",
                TotalLicenses: 0,
                UnassignedLicenses: 0,
                PdfFile: "",
                ProfilePicture: logo,
                PdfFileUrl: "",
                Postcode: "",
                qrCode: "",
                licenseCode: "",
                totalLicenses: 0
            },
            paymentDescription: "",
        };
    }

    componentDidMount() {
        // this.loadConfig();
        this.getFleetOwnerLicense();
        //this.getFleetOwnerDetails();
        this.getFleetUsers();
    }

    getFleetOwnerLicense = async () => {
        var hasMoreRec = true;
        let licenses = [];

        this.setState({ rNextToken: undefined });

        while (hasMoreRec) {
            var variables = {
                fleetOwnerId: this.state.fleetOwnerId,
                nextToken: this.state.rNextToken,
                filter: {
                    isDeleted: { eq: false }
                },
                limit: 500,
                sortDirection: "DESC",
            };

            await API.graphql(graphqlOperation(getLicenseByFleetOwnerId, variables)).then(result => {
                this.setState({ rNextToken: result.data.getLicenseByFleetOwnerId.nextToken });

                result.data.getLicenseByFleetOwnerId.items.forEach(function (itm) {
                    licenses.push({
                        id: itm.id,
                        code: itm.code,
                        fleetOwnerPurchaseId: itm.fleetOwnerPurchaseId
                    });
                });

                hasMoreRec = result.data.getLicenseByFleetOwnerId.nextToken !== null;
            });
        }

        this.setState({
            licenseCode: licenses.filter(
                (l) => l.fleetOwnerPurchaseId === "Fleet Owner License"
            )[0].code,
            totalLicenses: licenses.length - 1
        }, function () {
            this.getFleetOwnerDetails();
        });
    }

    getFleetOwnerDetails = async () => {
        let fId =
            localStorage.getItem("fleetOwnerId") === null
                ? this.props.location.state
                    ? this.props.location.state.fleetOwnerId
                    : ""
                : localStorage.getItem("fleetOwnerId");

        if (fId !== null && fId !== undefined) {
            await API.graphql(graphqlOperation(getFleetOwners, { id: fId })).then(async (result) => {

                let profileData = this.state.profile;
                let countryOption = this.state.selectedCountry;
                let noOfVehicleOption = this.state.selectedNoOfVehicle;

                let profileImg = "";
                let pdfFile = "";

                if (result.data.getFleetOwners !== null) {
                    if (result.data.getFleetOwners.profilePicture.trim() !== "") {
                        await Storage.get(result.data.getFleetOwners.profilePicture, { level: "public" })
                            .then((url) => {
                                profileImg = url;
                                this.setState({ imageButtonText: "Change Image" });
                            })
                            .catch((err) => console.log("error = " + err));
                    } else {
                        profileImg = logo;
                        this.setState({ profilePicture: "", profilePicURL: logo, imageButtonText: "Choose Image" });
                    }

                    if (result.data.getFleetOwners.pdfFilePath.trim() !== "") {
                        await Storage.get(result.data.getFleetOwners.pdfFilePath, { level: "public" })
                            .then((url) => {
                                pdfFile = url;
                            })
                            .catch((err) => console.log("error = " + err));
                    } else pdfFile = "";

                    profileData.CompanyName = result.data.getFleetOwners.companyName;
                    profileData.CompanyNo =
                        result.data.getFleetOwners.companyNo === "-" ? "" : result.data.getFleetOwners.companyNo;
                    profileData.Email = result.data.getFleetOwners.email;
                    profileData.FirstName = result.data.getFleetOwners.firstName;
                    profileData.LastName = result.data.getFleetOwners.lastName;
                    profileData.PhoneNo = result.data.getFleetOwners.phone;
                    profileData.NoOfVehicles = result.data.getFleetOwners.noOfVehicles;
                    profileData.Country = result.data.getFleetOwners.country;
                    profileData.TotalLicenses = this.state.totalLicenses;
                    profileData.UnassignedLicenses = result.data.getFleetOwners.unassignedLicenses
                        ? result.data.getFleetOwners.unassignedLicenses
                        : 0;
                    profileData.PdfFile = result.data.getFleetOwners.pdfFilePath;
                    profileData.PdfFileUrl = pdfFile;
                    profileData.ProfilePicture = profileImg;
                    profileData.AddressLine1 =
                        result.data.getFleetOwners.addressLine1 !== undefined ? result.data.getFleetOwners.addressLine1 : "";
                    profileData.AddressLine2 =
                        result.data.getFleetOwners.addressLine2 !== undefined ? result.data.getFleetOwners.addressLine2 : "";
                    profileData.City = result.data.getFleetOwners.city !== undefined ? result.data.getFleetOwners.city : "";
                    profileData.Postcode =
                        result.data.getFleetOwners.postcode !== undefined ? result.data.getFleetOwners.postcode : "";
                    profileData.qrCode = this.state.licenseCode;

                    countryOption.label = profileData.Country;
                    countryOption.value = profileData.Country;

                    noOfVehicleOption.label = profileData.NoOfVehicles;
                    noOfVehicleOption.value = profileData.NoOfVehicles;
                    localStorage.setItem("profileImg", profileImg);

                    this.setState({
                        profile: profileData,
                        selectedCountry: countryOption,
                        selectedNoOfVehicle: noOfVehicleOption,
                        companyName: result.data.getFleetOwners.companyName,
                        companyNo: result.data.getFleetOwners.companyNo === "-" ? "" : result.data.getFleetOwners.companyNo,
                        email: result.data.getFleetOwners.email,
                        firstName: result.data.getFleetOwners.firstName,
                        lastName: result.data.getFleetOwners.lastName,
                        phoneNo: result.data.getFleetOwners.phone,
                        noOfVehicles: result.data.getFleetOwners.noOfVehicles,
                        country: result.data.getFleetOwners.country,
                        profilePicture: profileImg,
                        addressLine1:
                            result.data.getFleetOwners.addressLine1 !== undefined ? result.data.getFleetOwners.addressLine1 : "",
                        addressLine2:
                            result.data.getFleetOwners.addressLine2 !== undefined ? result.data.getFleetOwners.addressLine2 : "",
                        city: result.data.getFleetOwners.city !== undefined ? result.data.getFleetOwners.city : "",
                        postcode: result.data.getFleetOwners.postcode !== undefined ? result.data.getFleetOwners.postcode : "",
                        paymentDescription:
                            "Payment received from Road Radar Enterprise: " + result.data.getFleetOwners.companyName,
                    });
                }
            });
        }
    };

    handleCloseProgressPopup = (e) => {
        this.setState({
            inProgress: false,
        });
    };

    getFleetUsers = async () => {
        let fId =
            localStorage.getItem("fleetOwnerId") === null
                ? this.props.location.state
                    ? this.props.location.state.fleetOwnerId
                    : ""
                : localStorage.getItem("fleetOwnerId");

        var variables = {
            fleetOwnerId: fId,
            nextToken: this.state.nextToken,
            filter: {
                isDeleted: { eq: false },
                deletedFleetUser: { eq: "false" },
            },
            limit: this.state.pageSize,
            sortDirection: "DESC",
        };

        fleetUserData = await API.graphql(graphqlOperation(getFleetUsersByFleetOwnerId, variables));

        this.setState({ nextNextToken: fleetUserData.data.getFleetUsersByFleetOwnerId.nextToken });

        let objFleetUser = fleetUserData.data.getFleetUsersByFleetOwnerId.items;

        var counts = 0;
        if (objFleetUser !== null && objFleetUser.length > 0) {
            counts = objFleetUser.map((x) => x.riderAlerts).reduce((alert, x) => x + alert);
            const res = this.groupByDayandMonth(objFleetUser, "createdAt", moment("01/01/2021"), moment(), true, true);
            this.setState({ chartData: res });
        }

        this.setState({
            fleetUsers: objFleetUser,
            loading: false,
            hasNext: fleetUserData.data.getFleetUsersByFleetOwnerId.nextToken !== null,
            isLoading: false,
            riderAlerts: counts === null ? 0 : counts,
            showCurrentPageNo: this.state.currentPageNo,
            inProgress: false,
        });
    };

    countData = (filterData) => {
        let totalFleets = filterData.length;
        let activeFleets = 0;
        let totalriderAlerts = 0;
        console.log(activeFleets)
        //console.log("totalriderAlerts", totalriderAlerts)

        filterData.forEach((item) => {
            if (item.isActive) {
                activeFleets++;
            }
            if (item.riderAlerts) {
                totalriderAlerts = totalriderAlerts + item.riderAlerts;
            }
        });
        return {
            activeFleets,
            totalriderAlerts,
        };
    };

    groupByDayandMonth = (arrayData, key, startDate, endDate, IsCount, IsMonth) => {
        const groups = arrayData.reduce(function (r, o) {
            if (IsMonth) {
                var m = moment(o[key]).add(-1, "M").format("MM-YYYY");
                r[m] ? r[m].data.push(o) : (r[m] = { date: m, data: [o], month: moment(o[key]).add(-1, "M").format("MM") });
            } else {
                const date = new Date(o[key]);
                var m = moment(o[key]).format("MM-DD-YYYY");
                if (date >= startDate && date <= endDate) {
                    r[m] ? r[m].data.push(o) : (r[m] = { date: String(m), data: [o] });
                }
            }
            return r;
        }, {});
        const result = Object.keys(groups).map(function (k) {
            return groups[k];
        });
        return result.map((item) => ({
            data: IsCount ? this.countData(item.data) : item.data.length,
            date: item.date,
            month: item.month,
        }));
    };

    setNextToken = (tToken) => {
        this.setState({ nextToken: tToken });
    };

    setNextNextToken = (nextToken) => {
        this.setState({ nextNextToken: nextToken }, function () {
            if (this.state.isSortedByCreatedAt) this.sortByCreatedAt("");
            else if (this.state.isSortedByFirstName) this.sortByFirstName("");
            else if (this.state.isSortedByLastName) this.sortByLastName("");
            else if (this.state.isSortedByLastLoginDate) this.sortByLastLoginDate("");
            else if (this.state.isSortedByDeviceType) this.sortByDeviceType("");
            else if (this.state.isSortedByDeviceId) this.sortByDeviceId("");
            else this.getFleetUsers();
        });
    };

    setPreviousTokens = (pToken) => {
        var arPToken = this.state.previousTokens;
        arPToken.push(pToken);

        this.setState({ previousTokens: arPToken, hasPrev: arPToken.length });
    };

    setPreviousTokensArray = (pToken) => {
        this.setState({ previousTokens: pToken, hasPrev: pToken.length });
    };

    next = () => {
        this.setState({ currentPageNo: this.state.currentPageNo + 1, inProgress: true }, function () {
            this.setPreviousTokens(this.state.nextToken);
            this.setNextToken(this.state.nextNextToken);
            this.setNextNextToken(null);
        });
    };

    prev = () => {
        this.setState({ currentPageNo: this.state.currentPageNo - 1, inProgress: true }, function () {
            this.setNextToken(this.state.previousTokens.pop());
            this.setPreviousTokensArray(this.state.previousTokens);
            this.setNextNextToken(null);
        });
    };

    setSortIcon(sortDirection) {
        var arrow = "";

        if (sortDirection === "ASC") {
            arrow = upArrow;
        } else arrow = downArrow;

        return <img src={arrow} alt="downArrow" />;
    }

    sortByCreatedAt = async (key) => {
        var sortOrder = key;

        if (key === "") {
            sortOrder = this.state.createdAtSortOrder;
        } else {
            if (sortOrder === "ASC") sortOrder = "DESC";
            else sortOrder = "ASC";
        }

        this.setState({
            isSortedByCreatedAt: true,
            isSortedByFirstName: false,
            isSortedByLastName: false,
            isSortedByLastLoginDate: false,
            isSortedByDeviceType: false,
            isSortedByDeviceId: false,
            createdAtSortOrder: sortOrder,
        });

        var variables = {
            nextToken: this.state.nextToken,
            filter: {
                isDeleted: { eq: false },
                fleetOwnerId: { eq: this.state.fleetOwnerId },
            },
            limit: this.state.pageSize,
            deletedFleetUser: "false",
            sortDirection: sortOrder,
        };

        fleetUserData = await API.graphql(graphqlOperation(getFleetUsersSortedByCreatedAt, variables));

        this.setState({ nextNextToken: fleetUserData.data.getFleetUsersSortedByCreatedAt.nextToken });

        let objFleetUser = fleetUserData.data.getFleetUsersSortedByCreatedAt.items;

        this.setState({
            fleetUsers: objFleetUser,
            hasNext: fleetUserData.data.getFleetUsersSortedByCreatedAt.nextToken !== null,
            isLoading: false,
            showCurrentPageNo: this.state.currentPageNo,
            inProgress: false,
        });
    };

    sortByFirstName = async (key) => {
        var sortOrder = key;

        if (key === "") {
            sortOrder = this.state.firstNameSortOrder;
        } else {
            if (sortOrder === "ASC") sortOrder = "DESC";
            else sortOrder = "ASC";
        }

        this.setState({
            isSortedByCreatedAt: false,
            isSortedByFirstName: true,
            isSortedByLastName: false,
            isSortedByLastLoginDate: false,
            isSortedByDeviceType: false,
            isSortedByDeviceId: false,
            firstNameSortOrder: sortOrder,
        });

        var variables = {
            nextToken: this.state.nextToken,
            filter: {
                isDeleted: { eq: false },
                fleetOwnerId: { eq: this.state.fleetOwnerId },
            },
            limit: this.state.pageSize,
            deletedFleetUser: "false",
            sortDirection: sortOrder,
        };

        fleetUserData = await API.graphql(graphqlOperation(getFleetUsersSortedByFirstName, variables));

        this.setState({ nextNextToken: fleetUserData.data.getFleetUsersSortedByFirstName.nextToken });

        let objFleetUser = fleetUserData.data.getFleetUsersSortedByFirstName.items;

        this.setState({
            fleetUsers: objFleetUser,
            hasNext: fleetUserData.data.getFleetUsersSortedByFirstName.nextToken !== null,
            isLoading: false,
            showCurrentPageNo: this.state.currentPageNo,
            inProgress: false,
        });
    };

    sortByLastName = async (key) => {
        var sortOrder = key;

        if (key === "") {
            sortOrder = this.state.lastNameSortOrder;
        } else {
            if (sortOrder === "ASC") sortOrder = "DESC";
            else sortOrder = "ASC";
        }

        this.setState({
            isSortedByCreatedAt: false,
            isSortedByFirstName: false,
            isSortedByLastName: true,
            isSortedByLastLoginDate: false,
            isSortedByDeviceType: false,
            isSortedByDeviceId: false,
            lastNameSortOrder: sortOrder,
        });

        var variables = {
            nextToken: this.state.nextToken,
            filter: {
                isDeleted: { eq: false },
                fleetOwnerId: { eq: this.state.fleetOwnerId },
            },
            limit: this.state.pageSize,
            deletedFleetUser: "false",
            sortDirection: sortOrder,
        };

        fleetUserData = await API.graphql(graphqlOperation(getFleetUsersSortedByLastName, variables));

        this.setState({ nextNextToken: fleetUserData.data.getFleetUsersSortedByLastName.nextToken });

        let objFleetUser = fleetUserData.data.getFleetUsersSortedByLastName.items;

        this.setState({
            fleetUsers: objFleetUser,
            hasNext: fleetUserData.data.getFleetUsersSortedByLastName.nextToken !== null,
            isLoading: false,
            showCurrentPageNo: this.state.currentPageNo,
            inProgress: false,
        });
    };

    sortByLastLoginDate = async (key) => {
        var sortOrder = key;

        if (key === "") {
            sortOrder = this.state.lastLoginDateSortOrder;
        } else {
            if (sortOrder === "ASC") sortOrder = "DESC";
            else sortOrder = "ASC";
        }

        this.setState({
            isSortedByCreatedAt: false,
            isSortedByFirstName: false,
            isSortedByLastName: false,
            isSortedByLastLoginDate: true,
            isSortedByDeviceType: false,
            isSortedByDeviceId: false,
            lastLoginDateSortOrder: sortOrder,
        });

        var variables = {
            nextToken: this.state.nextToken,
            filter: {
                isDeleted: { eq: false },
                fleetOwnerId: { eq: this.state.fleetOwnerId },
            },
            limit: this.state.pageSize,
            deletedFleetUser: "false",
            sortDirection: sortOrder,
        };

        fleetUserData = await API.graphql(graphqlOperation(getFleetUsersSortedByLastLoginDate, variables));

        this.setState({ nextNextToken: fleetUserData.data.getFleetUsersSortedByLastLoginDate.nextToken });

        let objFleetUser = fleetUserData.data.getFleetUsersSortedByLastLoginDate.items;

        this.setState({
            fleetUsers: objFleetUser,
            hasNext: fleetUserData.data.getFleetUsersSortedByLastLoginDate.nextToken !== null,
            isLoading: false,
            showCurrentPageNo: this.state.currentPageNo,
            inProgress: false,
        });
    };

    sortByDeviceType = async (key) => {
        var sortOrder = key;

        if (key === "") {
            sortOrder = this.state.deviceTypeSortOrder;
        } else {
            if (sortOrder === "ASC") sortOrder = "DESC";
            else sortOrder = "ASC";
        }

        this.setState({
            isSortedByCreatedAt: false,
            isSortedByFirstName: false,
            isSortedByLastName: false,
            isSortedByLastLoginDate: false,
            isSortedByDeviceType: true,
            isSortedByDeviceId: false,
            deviceTypeSortOrder: sortOrder,
        });

        var variables = {
            nextToken: this.state.nextToken,
            filter: {
                isDeleted: { eq: false },
                fleetOwnerId: { eq: this.state.fleetOwnerId },
            },
            limit: this.state.pageSize,
            deletedFleetUser: "false",
            sortDirection: sortOrder,
        };

        fleetUserData = await API.graphql(graphqlOperation(getFleetUsersSortedByDeviceType, variables));

        this.setState({ nextNextToken: fleetUserData.data.getFleetUsersSortedByDeviceType.nextToken });

        let objFleetUser = fleetUserData.data.getFleetUsersSortedByDeviceType.items;

        this.setState({
            fleetUsers: objFleetUser,
            hasNext: fleetUserData.data.getFleetUsersSortedByDeviceType.nextToken !== null,
            isLoading: false,
            showCurrentPageNo: this.state.currentPageNo,
            inProgress: false,
        });
    };

    sortByDeviceId = async (key) => {
        var sortOrder = key;

        if (key === "") {
            sortOrder = this.state.deviceIdSortOrder;
        } else {
            if (sortOrder === "ASC") sortOrder = "DESC";
            else sortOrder = "ASC";
        }

        this.setState({
            isSortedByCreatedAt: false,
            isSortedByFirstName: false,
            isSortedByLastName: false,
            isSortedByLastLoginDate: false,
            isSortedByDeviceType: false,
            isSortedByDeviceId: true,
            deviceIdSortOrder: sortOrder,
        });

        var variables = {
            nextToken: this.state.nextToken,
            filter: {
                isDeleted: { eq: false },
                fleetOwnerId: { eq: this.state.fleetOwnerId },
            },
            limit: this.state.pageSize,
            deletedFleetUser: "false",
            sortDirection: sortOrder,
        };

        fleetUserData = await API.graphql(graphqlOperation(getFleetUsersSortedByDeviceId, variables));

        this.setState({ nextNextToken: fleetUserData.data.getFleetUsersSortedByDeviceId.nextToken });

        let objFleetUser = fleetUserData.data.getFleetUsersSortedByDeviceId.items;

        this.setState({
            fleetUsers: objFleetUser,
            hasNext: fleetUserData.data.getFleetUsersSortedByDeviceId.nextToken !== null,
            isLoading: false,
            showCurrentPageNo: this.state.currentPageNo,
            inProgress: false,
        });
    };

    render() {
        const { paymentInProgress, modalClassic, riderAlerts, chartData, profile } = this.state;
        const { isLoading, hasNext, hasPrev } = this.state;
        const { inProgress, showCurrentPageNo } = this.state;

        const disabledPrev = !hasPrev || isLoading;
        const disabledNext = !hasNext || isLoading;

        return (
            <>
                <div className={styles.homepageContent}>
                    <div className={styles.topLine}>
                        <div className={styles.titlefont}>DASHBOARD</div>
                        <div>
                            <DashboardMetrics
                                riderAlerts={riderAlerts}
                                NoOfVehicles={profile.NoOfVehicles}
                                TotalLicenses={profile.TotalLicenses}
                                chartData={chartData}
                            />
                        </div>
                    </div>
                    {disabledPrev && disabledNext ? null : (
                        <div className={paginationStyles.pagination}>
                            <button
                                disabled={disabledPrev}
                                onClick={this.prev}
                                style={{
                                    backgroundColor: disabledPrev ? "#D3D3D3" : "#fff",
                                    cursor: disabledPrev ? "text" : "pointer",
                                }}
                                className={paginationStyles.item}
                            >
                                <img src={arrowLeft} alt="arrow left" />
                            </button>
                            <div className={classNames(paginationStyles.active, paginationStyles.item)}>{showCurrentPageNo}</div>
                            <button
                                disabled={disabledNext}
                                onClick={this.next}
                                style={{
                                    backgroundColor: disabledNext ? "#D3D3D3" : "#fff",
                                    cursor: disabledNext ? "text" : "pointer",
                                }}
                                className={paginationStyles.item}
                            >
                                <img src={arrowRight} alt="arrow right" />
                            </button>
                        </div>
                    )}
                </div>
            </>
        );
    }
}

export default RoadRadarHomepage;