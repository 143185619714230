/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const syncActivityHistories = /* GraphQL */ `
  query SyncActivityHistories(
    $filter: ModelActivityHistoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncActivityHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        partnerId
        nanoid
        activity
        date
        distance
        time
        timeInSeconds
        totalActiveTime
        coinsEarned
        groupName
        groupCode
        groupDuration
        groupCreated
        totalParticipants
        coordinates
        isSweatCoinUpdated
        isSharedToStrava
        activityStatus
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getActivityHistory = /* GraphQL */ `
  query GetActivityHistory($id: ID!) {
    getActivityHistory(id: $id) {
      id
      userId
      partnerId
      nanoid
      activity
      date
      distance
      time
      timeInSeconds
      totalActiveTime
      coinsEarned
      groupName
      groupCode
      groupDuration
      groupCreated
      totalParticipants
      coordinates
      isSweatCoinUpdated
      isSharedToStrava
      activityStatus
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const listActivityHistorys = /* GraphQL */ `
  query ListActivityHistorys(
    $filter: ModelActivityHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivityHistorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        partnerId
        nanoid
        activity
        date
        distance
        time
        timeInSeconds
        totalActiveTime
        coinsEarned
        groupName
        groupCode
        groupDuration
        groupCreated
        totalParticipants
        coordinates
        isSweatCoinUpdated
        isSharedToStrava
        activityStatus
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTrackingMetrics = /* GraphQL */ `
  query SyncTrackingMetrics(
    $filter: ModelTrackingMetricsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTrackingMetrics(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        type
        nanoid
        uuid
        geohash
        status
        trackingLocations {
          timestamp
          geohash
          activity
          latitude
          longitude
          confidence
          speed
          speedAccuracy
          accuracy
          altitude
          heading
          batteryLevel
          isBatteryCharging
          odometer
          sample
          isMoving
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTrackingMetrics = /* GraphQL */ `
  query GetTrackingMetrics($id: ID!) {
    getTrackingMetrics(id: $id) {
      id
      userId
      type
      nanoid
      uuid
      geohash
      status
      trackingLocations {
        timestamp
        geohash
        activity
        latitude
        longitude
        confidence
        speed
        speedAccuracy
        accuracy
        altitude
        heading
        batteryLevel
        isBatteryCharging
        odometer
        sample
        isMoving
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTrackingMetricss = /* GraphQL */ `
  query ListTrackingMetricss(
    $filter: ModelTrackingMetricsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTrackingMetricss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        type
        nanoid
        uuid
        geohash
        status
        trackingLocations {
          timestamp
          geohash
          activity
          latitude
          longitude
          confidence
          speed
          speedAccuracy
          accuracy
          altitude
          heading
          batteryLevel
          isBatteryCharging
          odometer
          sample
          isMoving
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFlareStatistics = /* GraphQL */ `
  query SyncFlareStatistics(
    $filter: ModelFlareStatisticsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFlareStatistics(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        partnerId
        totalUsers
        protectedDistanceInMeters
        protectedJourneys
        rideTimeInSeconds
        incidents
        incidentAlerts
        sos
        sosAlerts
        hazards
        hazardAlerts
        flaresReceived
        flaresResponded
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFlareStatistics = /* GraphQL */ `
  query GetFlareStatistics($id: ID!) {
    getFlareStatistics(id: $id) {
      id
      partnerId
      totalUsers
      protectedDistanceInMeters
      protectedJourneys
      rideTimeInSeconds
      incidents
      incidentAlerts
      sos
      sosAlerts
      hazards
      hazardAlerts
      flaresReceived
      flaresResponded
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listFlareStatisticss = /* GraphQL */ `
  query ListFlareStatisticss(
    $filter: ModelFlareStatisticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFlareStatisticss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        totalUsers
        protectedDistanceInMeters
        protectedJourneys
        rideTimeInSeconds
        incidents
        incidentAlerts
        sos
        sosAlerts
        hazards
        hazardAlerts
        flaresReceived
        flaresResponded
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFleetOwners = /* GraphQL */ `
  query SyncFleetOwners(
    $filter: ModelFleetOwnersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFleetOwners(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        companyName
        companyNameLower
        companyNo
        email
        emailLower
        password
        phone
        noOfVehicles
        addressLine1
        addressLine2
        city
        country
        postcode
        profilePicture
        totalLicenses
        unassignedLicenses
        pdfFilePath
        accountManagerEmail
        laterOpted
        enableInvoicePayment
        disableSnooze
        companyType
        sandboxApiKey
        sandboxApiKeyUsage
        productionApiKey
        productionApiKeyUsage
        isVerified
        isActive
        isDeleted
        deletedFleetOwner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFleetOwners = /* GraphQL */ `
  query GetFleetOwners($id: ID!) {
    getFleetOwners(id: $id) {
      id
      firstName
      firstNameLower
      lastName
      lastNameLower
      userName
      userNameLower
      companyName
      companyNameLower
      companyNo
      email
      emailLower
      password
      phone
      noOfVehicles
      addressLine1
      addressLine2
      city
      country
      postcode
      profilePicture
      totalLicenses
      unassignedLicenses
      pdfFilePath
      accountManagerEmail
      laterOpted
      enableInvoicePayment
      disableSnooze
      companyType
      sandboxApiKey
      sandboxApiKeyUsage
      productionApiKey
      productionApiKeyUsage
      isVerified
      isActive
      isDeleted
      deletedFleetOwner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listFleetOwnerss = /* GraphQL */ `
  query ListFleetOwnerss(
    $filter: ModelFleetOwnersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFleetOwnerss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        companyName
        companyNameLower
        companyNo
        email
        emailLower
        password
        phone
        noOfVehicles
        addressLine1
        addressLine2
        city
        country
        postcode
        profilePicture
        totalLicenses
        unassignedLicenses
        pdfFilePath
        accountManagerEmail
        laterOpted
        enableInvoicePayment
        disableSnooze
        companyType
        sandboxApiKey
        sandboxApiKeyUsage
        productionApiKey
        productionApiKeyUsage
        isVerified
        isActive
        isDeleted
        deletedFleetOwner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFleetOwnerPurchases = /* GraphQL */ `
  query SyncFleetOwnerPurchases(
    $filter: ModelFleetOwnerPurchasesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFleetOwnerPurchases(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        fleetOwnerId
        numberOfLicense
        cost
        vat
        total
        pdfFilePath
        stripeToken
        stripeRate
        stripeAmount
        busbyAmount
        stripeChargeId
        isPaymentSuccessful
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFleetOwnerPurchases = /* GraphQL */ `
  query GetFleetOwnerPurchases($id: ID!) {
    getFleetOwnerPurchases(id: $id) {
      id
      fleetOwnerId
      numberOfLicense
      cost
      vat
      total
      pdfFilePath
      stripeToken
      stripeRate
      stripeAmount
      busbyAmount
      stripeChargeId
      isPaymentSuccessful
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listFleetOwnerPurchasess = /* GraphQL */ `
  query ListFleetOwnerPurchasess(
    $filter: ModelFleetOwnerPurchasesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFleetOwnerPurchasess(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fleetOwnerId
        numberOfLicense
        cost
        vat
        total
        pdfFilePath
        stripeToken
        stripeRate
        stripeAmount
        busbyAmount
        stripeChargeId
        isPaymentSuccessful
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFleetUsers = /* GraphQL */ `
  query SyncFleetUsers(
    $filter: ModelFleetUsersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFleetUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        fleetOwnerId
        licenseId
        firstName
        firstNameLower
        lastName
        lastNameLower
        deviceType
        deviceTypeLower
        deviceId
        appVersion
        notificationRequired
        riderAlerts
        protectedDistanceInMeters
        isActive
        isDeleted
        deletedFleetUser
        lastLoginDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFleetUsers = /* GraphQL */ `
  query GetFleetUsers($id: ID!) {
    getFleetUsers(id: $id) {
      id
      fleetOwnerId
      licenseId
      firstName
      firstNameLower
      lastName
      lastNameLower
      deviceType
      deviceTypeLower
      deviceId
      appVersion
      notificationRequired
      riderAlerts
      protectedDistanceInMeters
      isActive
      isDeleted
      deletedFleetUser
      lastLoginDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listFleetUserss = /* GraphQL */ `
  query ListFleetUserss(
    $filter: ModelFleetUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFleetUserss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fleetOwnerId
        licenseId
        firstName
        firstNameLower
        lastName
        lastNameLower
        deviceType
        deviceTypeLower
        deviceId
        appVersion
        notificationRequired
        riderAlerts
        protectedDistanceInMeters
        isActive
        isDeleted
        deletedFleetUser
        lastLoginDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLicenses = /* GraphQL */ `
  query SyncLicenses(
    $filter: ModelLicensesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLicenses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        fleetOwnerId
        fleetUserId
        fleetOwnerPurchaseId
        code
        qrImage
        price
        isAssigned
        assignedLicense
        paymentType
        expiryDate
        isActive
        isDeleted
        deletedLicense
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getLicenses = /* GraphQL */ `
  query GetLicenses($id: ID!) {
    getLicenses(id: $id) {
      id
      fleetOwnerId
      fleetUserId
      fleetOwnerPurchaseId
      code
      qrImage
      price
      isAssigned
      assignedLicense
      paymentType
      expiryDate
      isActive
      isDeleted
      deletedLicense
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listLicensess = /* GraphQL */ `
  query ListLicensess(
    $filter: ModelLicensesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLicensess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fleetOwnerId
        fleetUserId
        fleetOwnerPurchaseId
        code
        qrImage
        price
        isAssigned
        assignedLicense
        paymentType
        expiryDate
        isActive
        isDeleted
        deletedLicense
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFlares = /* GraphQL */ `
  query SyncFlares(
    $filter: ModelFlaresFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFlares(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        partnerId
        flareRequestorID
        flareResponderID
        nanoid
        geohash
        flareTime
        flareType
        flareStatus
        flareRequestorLatitude
        flareRequestorLongitude
        radius
        flareRequestorName
        flareRequestorProfilePic
        flareResponderName
        flareResponderProfilePic
        distance
        isConnected
        endChat
        isChatEnded
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFlares = /* GraphQL */ `
  query GetFlares($id: ID!) {
    getFlares(id: $id) {
      id
      partnerId
      flareRequestorID
      flareResponderID
      nanoid
      geohash
      flareTime
      flareType
      flareStatus
      flareRequestorLatitude
      flareRequestorLongitude
      radius
      flareRequestorName
      flareRequestorProfilePic
      flareResponderName
      flareResponderProfilePic
      distance
      isConnected
      endChat
      isChatEnded
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listFlaress = /* GraphQL */ `
  query ListFlaress(
    $filter: ModelFlaresFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFlaress(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        partnerId
        flareRequestorID
        flareResponderID
        nanoid
        geohash
        flareTime
        flareType
        flareStatus
        flareRequestorLatitude
        flareRequestorLongitude
        radius
        flareRequestorName
        flareRequestorProfilePic
        flareResponderName
        flareResponderProfilePic
        distance
        isConnected
        endChat
        isChatEnded
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFlareMessages = /* GraphQL */ `
  query SyncFlareMessages(
    $filter: ModelFlareMessagesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFlareMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        flareId
        partnerId
        flareRequestorID
        nanoid
        sent
        time
        content
        messageId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFlareMessages = /* GraphQL */ `
  query GetFlareMessages($id: ID!) {
    getFlareMessages(id: $id) {
      id
      flareId
      partnerId
      flareRequestorID
      nanoid
      sent
      time
      content
      messageId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listFlareMessagess = /* GraphQL */ `
  query ListFlareMessagess(
    $filter: ModelFlareMessagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFlareMessagess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        flareId
        partnerId
        flareRequestorID
        nanoid
        sent
        time
        content
        messageId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncIncidents = /* GraphQL */ `
  query SyncIncidents(
    $filter: ModelIncidentsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncIncidents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        partnerId
        userId
        userName
        userNameLower
        userEmail
        userEmailLower
        userProfilePic
        userReferralCode
        userRetailCodeUsed
        topicId
        expiresTimestamp
        incidentTime
        incidentLatitude
        incidentLongitude
        countryIsoCode
        city
        speed
        acceleration
        heading
        altitude
        distance
        isConnected
        endChat
        isChatEnded
        isVerified
        status
        statusLower
        userResponse
        userResponseLower
        totalAlerts
        deviceType
        deviceId
        battery
        coordinates
        incidentRaisedFrom
        incidentRaisedFromKey
        incidentType
        flareApiPayload
        activityTimeline {
          createdAt
          type
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getIncidents = /* GraphQL */ `
  query GetIncidents($id: ID!) {
    getIncidents(id: $id) {
      id
      partnerId
      userId
      userName
      userNameLower
      userEmail
      userEmailLower
      userProfilePic
      userReferralCode
      userRetailCodeUsed
      topicId
      expiresTimestamp
      incidentTime
      incidentLatitude
      incidentLongitude
      countryIsoCode
      city
      speed
      acceleration
      heading
      altitude
      distance
      isConnected
      endChat
      isChatEnded
      isVerified
      status
      statusLower
      userResponse
      userResponseLower
      totalAlerts
      deviceType
      deviceId
      battery
      coordinates
      incidentRaisedFrom
      incidentRaisedFromKey
      incidentType
      flareApiPayload
      activityTimeline {
        createdAt
        type
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listIncidentss = /* GraphQL */ `
  query ListIncidentss(
    $filter: ModelIncidentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIncidentss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        partnerId
        userId
        userName
        userNameLower
        userEmail
        userEmailLower
        userProfilePic
        userReferralCode
        userRetailCodeUsed
        topicId
        expiresTimestamp
        incidentTime
        incidentLatitude
        incidentLongitude
        countryIsoCode
        city
        speed
        acceleration
        heading
        altitude
        distance
        isConnected
        endChat
        isChatEnded
        isVerified
        status
        statusLower
        userResponse
        userResponseLower
        totalAlerts
        deviceType
        deviceId
        battery
        coordinates
        incidentRaisedFrom
        incidentRaisedFromKey
        incidentType
        flareApiPayload
        activityTimeline {
          createdAt
          type
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncIncidentsPlaygrounds = /* GraphQL */ `
  query SyncIncidentsPlaygrounds(
    $filter: ModelIncidentsPlaygroundFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncIncidentsPlaygrounds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        partnerId
        userId
        userName
        userNameLower
        userEmail
        userEmailLower
        userProfilePic
        userReferralCode
        userRetailCodeUsed
        topicId
        expiresTimestamp
        incidentTime
        incidentLatitude
        incidentLongitude
        countryIsoCode
        city
        speed
        acceleration
        heading
        altitude
        distance
        isConnected
        endChat
        isChatEnded
        isVerified
        status
        statusLower
        userResponse
        userResponseLower
        totalAlerts
        deviceType
        deviceId
        battery
        coordinates
        incidentRaisedFrom
        incidentRaisedFromKey
        incidentType
        flareApiPayload
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getIncidentsPlayground = /* GraphQL */ `
  query GetIncidentsPlayground($id: ID!) {
    getIncidentsPlayground(id: $id) {
      id
      partnerId
      userId
      userName
      userNameLower
      userEmail
      userEmailLower
      userProfilePic
      userReferralCode
      userRetailCodeUsed
      topicId
      expiresTimestamp
      incidentTime
      incidentLatitude
      incidentLongitude
      countryIsoCode
      city
      speed
      acceleration
      heading
      altitude
      distance
      isConnected
      endChat
      isChatEnded
      isVerified
      status
      statusLower
      userResponse
      userResponseLower
      totalAlerts
      deviceType
      deviceId
      battery
      coordinates
      incidentRaisedFrom
      incidentRaisedFromKey
      incidentType
      flareApiPayload
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listIncidentsPlaygrounds = /* GraphQL */ `
  query ListIncidentsPlaygrounds(
    $filter: ModelIncidentsPlaygroundFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIncidentsPlaygrounds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        userId
        userName
        userNameLower
        userEmail
        userEmailLower
        userProfilePic
        userReferralCode
        userRetailCodeUsed
        topicId
        expiresTimestamp
        incidentTime
        incidentLatitude
        incidentLongitude
        countryIsoCode
        city
        speed
        acceleration
        heading
        altitude
        distance
        isConnected
        endChat
        isChatEnded
        isVerified
        status
        statusLower
        userResponse
        userResponseLower
        totalAlerts
        deviceType
        deviceId
        battery
        coordinates
        incidentRaisedFrom
        incidentRaisedFromKey
        incidentType
        flareApiPayload
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncIncidentMessages = /* GraphQL */ `
  query SyncIncidentMessages(
    $filter: ModelIncidentMessagesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncIncidentMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        incidentId
        topicId
        partnerId
        userId
        sent
        time
        content
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getIncidentMessages = /* GraphQL */ `
  query GetIncidentMessages($id: ID!) {
    getIncidentMessages(id: $id) {
      id
      incidentId
      topicId
      partnerId
      userId
      sent
      time
      content
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listIncidentMessagess = /* GraphQL */ `
  query ListIncidentMessagess(
    $filter: ModelIncidentMessagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIncidentMessagess(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        incidentId
        topicId
        partnerId
        userId
        sent
        time
        content
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncEmergencySos = /* GraphQL */ `
  query SyncEmergencySos(
    $filter: ModelEmergencySOSFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEmergencySOS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        partnerId
        userId
        nanoid
        userName
        userNameLower
        userEmail
        userEmailLower
        userProfilePic
        userReferralCode
        reportedAt
        latitude
        longitude
        distance
        journeyEnded
        isJourneyEnded
        totalAlerts
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getEmergencySos = /* GraphQL */ `
  query GetEmergencySos($id: ID!) {
    getEmergencySOS(id: $id) {
      id
      partnerId
      userId
      nanoid
      userName
      userNameLower
      userEmail
      userEmailLower
      userProfilePic
      userReferralCode
      reportedAt
      latitude
      longitude
      distance
      journeyEnded
      isJourneyEnded
      totalAlerts
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listEmergencySoSs = /* GraphQL */ `
  query ListEmergencySoSs(
    $filter: ModelEmergencySOSFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmergencySOSs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        partnerId
        userId
        nanoid
        userName
        userNameLower
        userEmail
        userEmailLower
        userProfilePic
        userReferralCode
        reportedAt
        latitude
        longitude
        distance
        journeyEnded
        isJourneyEnded
        totalAlerts
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHazards = /* GraphQL */ `
  query SyncHazards(
    $filter: ModelHazardsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHazards(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        partnerId
        timeStamp
        latitude
        longitude
        type
        typeLower
        crowdinTitleKey
        userName
        userNameLower
        userEmail
        userEmailLower
        geohash
        expireAt
        isExpired
        notificationsGenerated
        notified
        accuracyScoreUp
        accuracyScoreDown
        countryCode
        totalAlerts
        isDeleted
        deletedHazard
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHazards = /* GraphQL */ `
  query GetHazards($id: ID!) {
    getHazards(id: $id) {
      id
      userId
      partnerId
      timeStamp
      latitude
      longitude
      type
      typeLower
      crowdinTitleKey
      userName
      userNameLower
      userEmail
      userEmailLower
      geohash
      expireAt
      isExpired
      notificationsGenerated
      notified
      accuracyScoreUp
      accuracyScoreDown
      countryCode
      totalAlerts
      isDeleted
      deletedHazard
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHazardss = /* GraphQL */ `
  query ListHazardss(
    $filter: ModelHazardsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHazardss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        partnerId
        timeStamp
        latitude
        longitude
        type
        typeLower
        crowdinTitleKey
        userName
        userNameLower
        userEmail
        userEmailLower
        geohash
        expireAt
        isExpired
        notificationsGenerated
        notified
        accuracyScoreUp
        accuracyScoreDown
        countryCode
        totalAlerts
        isDeleted
        deletedHazard
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUserHazards = /* GraphQL */ `
  query SyncUserHazards(
    $filter: ModelUserHazardsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserHazards(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        hazardId
        userId
        isNotified
        isVoted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserHazards = /* GraphQL */ `
  query GetUserHazards($id: ID!) {
    getUserHazards(id: $id) {
      id
      hazardId
      userId
      isNotified
      isVoted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUserHazardss = /* GraphQL */ `
  query ListUserHazardss(
    $filter: ModelUserHazardsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserHazardss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        hazardId
        userId
        isNotified
        isVoted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDataMetrics = /* GraphQL */ `
  query SyncDataMetrics(
    $filter: ModelDataMetricsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDataMetrics(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        email
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDataMetrics = /* GraphQL */ `
  query GetDataMetrics($id: ID!) {
    getDataMetrics(id: $id) {
      id
      email
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listDataMetricss = /* GraphQL */ `
  query ListDataMetricss(
    $filter: ModelDataMetricsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDataMetricss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncExternalUsers = /* GraphQL */ `
  query SyncExternalUsers(
    $filter: ModelExternalUsersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncExternalUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        partnerId
        type
        licenseKey
        totalUsage
        deviceType
        deviceId
        osVersion
        createdAtEpoch
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getExternalUsers = /* GraphQL */ `
  query GetExternalUsers($id: ID!) {
    getExternalUsers(id: $id) {
      id
      partnerId
      type
      licenseKey
      totalUsage
      deviceType
      deviceId
      osVersion
      createdAtEpoch
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listExternalUserss = /* GraphQL */ `
  query ListExternalUserss(
    $filter: ModelExternalUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExternalUserss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        partnerId
        type
        licenseKey
        totalUsage
        deviceType
        deviceId
        osVersion
        createdAtEpoch
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncExternalUsersPlaygrounds = /* GraphQL */ `
  query SyncExternalUsersPlaygrounds(
    $filter: ModelExternalUsersPlaygroundFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncExternalUsersPlaygrounds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        partnerId
        type
        licenseKey
        totalUsage
        deviceType
        deviceId
        osVersion
        createdAtEpoch
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getExternalUsersPlayground = /* GraphQL */ `
  query GetExternalUsersPlayground($id: ID!) {
    getExternalUsersPlayground(id: $id) {
      id
      partnerId
      type
      licenseKey
      totalUsage
      deviceType
      deviceId
      osVersion
      createdAtEpoch
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listExternalUsersPlaygrounds = /* GraphQL */ `
  query ListExternalUsersPlaygrounds(
    $filter: ModelExternalUsersPlaygroundFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExternalUsersPlaygrounds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        type
        licenseKey
        totalUsage
        deviceType
        deviceId
        osVersion
        createdAtEpoch
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncReferralHistories = /* GraphQL */ `
  query SyncReferralHistories(
    $filter: ModelReferralHistoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncReferralHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        referralUserId
        referralCode
        createdAt
        updateAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        user {
          id
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          referralCode
          referralName
          isReferralRedeem
          email
          emailLower
          profilePicture
          phoneNumber
          countDownDuration
          subscription
          subscriptionLower
          subscriptionDate
          subscriptionExpiryDate
          keepScreenOn
          isFlareOn
          automaticStartOn
          totalBalance
          totalReward
          totalDriversAlerted
          totalDistanceCovered
          totalProtectedJourneys
          totalRideTimeInSeconds
          totalNearMissesReported
          country
          deviceId
          fcmToken
          deviceType
          isEmailVerified
          latitude
          longitude
          partnerId
          retailCodeUsed
          retailCodeUsedLower
          riderActivities
          riderType
          ridingPeriod
          rewardAccessType
          rewardAccessTypeLower
          rewardAccessStartDate
          rewardAccessEndDate
          rewardAccessSubTotal
          rewardAccessVAT
          rewardAccessVATAmount
          rewardAccessTotalAmount
          rewardAccessStripeToken
          rewardAccessStripeReceipt
          isRewardAccessPaymentSuccessful
          rewardAccessMode
          isPaidToAffiliate
          isSmartStartOn
          referralId
          registrationProvider
          activityTimeInSeconds
          totalRewardsRedeemed
          reputationScore
          appVersion
          sweatCoinAuthenticationToken
          lastAccessed
          isSmartStartNotificationEnable
          isMarketPlaceNotificationEnable
          agreedConsents {
            description
            type
            accepted
          }
          isActive
          isDeleted
          deletedUser
          createdAt
          updateAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          owner
          partner {
            id
            invitedByPartnerId
            partnerCode
            dynamicLink
            desktopURL
            companyName
            companyNameLower
            firstName
            firstNameLower
            lastName
            lastNameLower
            userName
            userNameLower
            email
            emailLower
            password
            profilePicture
            companyEmblem
            phone
            addressLine1
            addressLine2
            city
            country
            countryIsoCode
            postcode
            deviceType
            fcmToken
            totalFlaresReceived
            totalFlaresRespondedTo
            totalIncidentsReceived
            totalIncidentsActioned
            latitude
            longitude
            retailCodePrice
            resalePrice
            invoicePayment
            rewardAvailabilityCountries
            referralURL
            referralIntroText
            referralBackgroundImage
            referralRetailPrice
            incidentNotificationSettings
            notificationTitle
            notificationMessage
            notificationPeriod
            hasRiderConfirmed
            iotEndpoint
            enableRewards
            enableReferrals
            enableAppDiscountsHub
            enableFlares
            enableRiderManagement
            enableRetail
            enableAutoStart
            enableEmergencySOS
            enableSdk
            enforceEmergencyContact
            enforceSafeZone
            enforcePhoneNumber
            youtubeLink
            enforceRiderId
            termsAndConditionLink
            privacyPolicyLink
            landingPagePoints {
              text
            }
            sandboxFlareApiKey
            sandboxFlareApiKeyUsage
            productionFlareApiKey
            productionFlareApiKeyUsage
            licenseKeys {
              licenseKey
              keyName
              environment
              totalUsage
              totalIncidentDetected
              isActive
              isDeleted
              deletedKey
              lastUsed
              createdAt
              updatedAt
            }
            incidentNotifications {
              notificationSettings
              notificationEmail
              notificationCountry
              notificationCountryCallingCode
              notificationMobileNo
              notificationWebhookURL
              includeRiderEmail
              riderID
              riderName
              riderCity
              riderEmail
              incidentDateAndTime
              ISOCountryCode
              incidentLocation
              incidentTelemetry
            }
            sosNotificationSettings
            sosNotifications {
              notificationSettings
              notificationEmail
              notificationCountry
              notificationCountryCallingCode
              notificationMobileNo
              notificationWebhookURL
              includeRiderEmail
              riderID
              riderName
              riderCity
              riderEmail
              incidentDateAndTime
              ISOCountryCode
              incidentLocation
              incidentTelemetry
            }
            consentCheckbox {
              title
              fileType
              link
              description
              type
            }
            enabledTwoStepVerification
            elasticIndexName
            isAccountVerified
            isActive
            isDeleted
            deletedPartner
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getReferralHistory = /* GraphQL */ `
  query GetReferralHistory($id: ID!) {
    getReferralHistory(id: $id) {
      id
      userId
      referralUserId
      referralCode
      createdAt
      updateAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      user {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        referralCode
        referralName
        isReferralRedeem
        email
        emailLower
        profilePicture
        phoneNumber
        countDownDuration
        subscription
        subscriptionLower
        subscriptionDate
        subscriptionExpiryDate
        keepScreenOn
        isFlareOn
        automaticStartOn
        totalBalance
        totalReward
        totalDriversAlerted
        totalDistanceCovered
        totalProtectedJourneys
        totalRideTimeInSeconds
        totalNearMissesReported
        country
        deviceId
        fcmToken
        deviceType
        isEmailVerified
        latitude
        longitude
        partnerId
        retailCodeUsed
        retailCodeUsedLower
        riderActivities
        riderType
        ridingPeriod
        rewardAccessType
        rewardAccessTypeLower
        rewardAccessStartDate
        rewardAccessEndDate
        rewardAccessSubTotal
        rewardAccessVAT
        rewardAccessVATAmount
        rewardAccessTotalAmount
        rewardAccessStripeToken
        rewardAccessStripeReceipt
        isRewardAccessPaymentSuccessful
        rewardAccessMode
        isPaidToAffiliate
        isSmartStartOn
        referralId
        registrationProvider
        activityTimeInSeconds
        totalRewardsRedeemed
        reputationScore
        appVersion
        sweatCoinAuthenticationToken
        lastAccessed
        isSmartStartNotificationEnable
        isMarketPlaceNotificationEnable
        agreedConsents {
          description
          type
          accepted
        }
        isActive
        isDeleted
        deletedUser
        createdAt
        updateAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        owner
        partner {
          id
          invitedByPartnerId
          partnerCode
          dynamicLink
          desktopURL
          companyName
          companyNameLower
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          email
          emailLower
          password
          profilePicture
          companyEmblem
          phone
          addressLine1
          addressLine2
          city
          country
          countryIsoCode
          postcode
          deviceType
          fcmToken
          totalFlaresReceived
          totalFlaresRespondedTo
          totalIncidentsReceived
          totalIncidentsActioned
          latitude
          longitude
          retailCodePrice
          resalePrice
          invoicePayment
          rewardAvailabilityCountries
          referralURL
          referralIntroText
          referralBackgroundImage
          referralRetailPrice
          incidentNotificationSettings
          notificationTitle
          notificationMessage
          notificationPeriod
          hasRiderConfirmed
          iotEndpoint
          enableRewards
          enableReferrals
          enableAppDiscountsHub
          enableFlares
          enableRiderManagement
          enableRetail
          enableAutoStart
          enableEmergencySOS
          enableSdk
          enforceEmergencyContact
          enforceSafeZone
          enforcePhoneNumber
          youtubeLink
          enforceRiderId
          termsAndConditionLink
          privacyPolicyLink
          landingPagePoints {
            text
          }
          sandboxFlareApiKey
          sandboxFlareApiKeyUsage
          productionFlareApiKey
          productionFlareApiKeyUsage
          licenseKeys {
            licenseKey
            keyName
            environment
            totalUsage
            totalIncidentDetected
            isActive
            isDeleted
            deletedKey
            lastUsed
            createdAt
            updatedAt
          }
          incidentNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          sosNotificationSettings
          sosNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          consentCheckbox {
            title
            fileType
            link
            description
            type
          }
          enabledTwoStepVerification
          elasticIndexName
          isAccountVerified
          isActive
          isDeleted
          deletedPartner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
      }
    }
  }
`;
export const listReferralHistorys = /* GraphQL */ `
  query ListReferralHistorys(
    $filter: ModelReferralHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReferralHistorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        referralUserId
        referralCode
        createdAt
        updateAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        user {
          id
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          referralCode
          referralName
          isReferralRedeem
          email
          emailLower
          profilePicture
          phoneNumber
          countDownDuration
          subscription
          subscriptionLower
          subscriptionDate
          subscriptionExpiryDate
          keepScreenOn
          isFlareOn
          automaticStartOn
          totalBalance
          totalReward
          totalDriversAlerted
          totalDistanceCovered
          totalProtectedJourneys
          totalRideTimeInSeconds
          totalNearMissesReported
          country
          deviceId
          fcmToken
          deviceType
          isEmailVerified
          latitude
          longitude
          partnerId
          retailCodeUsed
          retailCodeUsedLower
          riderActivities
          riderType
          ridingPeriod
          rewardAccessType
          rewardAccessTypeLower
          rewardAccessStartDate
          rewardAccessEndDate
          rewardAccessSubTotal
          rewardAccessVAT
          rewardAccessVATAmount
          rewardAccessTotalAmount
          rewardAccessStripeToken
          rewardAccessStripeReceipt
          isRewardAccessPaymentSuccessful
          rewardAccessMode
          isPaidToAffiliate
          isSmartStartOn
          referralId
          registrationProvider
          activityTimeInSeconds
          totalRewardsRedeemed
          reputationScore
          appVersion
          sweatCoinAuthenticationToken
          lastAccessed
          isSmartStartNotificationEnable
          isMarketPlaceNotificationEnable
          agreedConsents {
            description
            type
            accepted
          }
          isActive
          isDeleted
          deletedUser
          createdAt
          updateAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          owner
          partner {
            id
            invitedByPartnerId
            partnerCode
            dynamicLink
            desktopURL
            companyName
            companyNameLower
            firstName
            firstNameLower
            lastName
            lastNameLower
            userName
            userNameLower
            email
            emailLower
            password
            profilePicture
            companyEmblem
            phone
            addressLine1
            addressLine2
            city
            country
            countryIsoCode
            postcode
            deviceType
            fcmToken
            totalFlaresReceived
            totalFlaresRespondedTo
            totalIncidentsReceived
            totalIncidentsActioned
            latitude
            longitude
            retailCodePrice
            resalePrice
            invoicePayment
            rewardAvailabilityCountries
            referralURL
            referralIntroText
            referralBackgroundImage
            referralRetailPrice
            incidentNotificationSettings
            notificationTitle
            notificationMessage
            notificationPeriod
            hasRiderConfirmed
            iotEndpoint
            enableRewards
            enableReferrals
            enableAppDiscountsHub
            enableFlares
            enableRiderManagement
            enableRetail
            enableAutoStart
            enableEmergencySOS
            enableSdk
            enforceEmergencyContact
            enforceSafeZone
            enforcePhoneNumber
            youtubeLink
            enforceRiderId
            termsAndConditionLink
            privacyPolicyLink
            landingPagePoints {
              text
            }
            sandboxFlareApiKey
            sandboxFlareApiKeyUsage
            productionFlareApiKey
            productionFlareApiKeyUsage
            licenseKeys {
              licenseKey
              keyName
              environment
              totalUsage
              totalIncidentDetected
              isActive
              isDeleted
              deletedKey
              lastUsed
              createdAt
              updatedAt
            }
            incidentNotifications {
              notificationSettings
              notificationEmail
              notificationCountry
              notificationCountryCallingCode
              notificationMobileNo
              notificationWebhookURL
              includeRiderEmail
              riderID
              riderName
              riderCity
              riderEmail
              incidentDateAndTime
              ISOCountryCode
              incidentLocation
              incidentTelemetry
            }
            sosNotificationSettings
            sosNotifications {
              notificationSettings
              notificationEmail
              notificationCountry
              notificationCountryCallingCode
              notificationMobileNo
              notificationWebhookURL
              includeRiderEmail
              riderID
              riderName
              riderCity
              riderEmail
              incidentDateAndTime
              ISOCountryCode
              incidentLocation
              incidentTelemetry
            }
            consentCheckbox {
              title
              fileType
              link
              description
              type
            }
            enabledTwoStepVerification
            elasticIndexName
            isAccountVerified
            isActive
            isDeleted
            deletedPartner
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getHistoryByUserId = /* GraphQL */ `
  query GetHistoryByUserId(
    $userId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActivityHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getHistoryByUserId(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        partnerId
        nanoid
        activity
        date
        distance
        time
        timeInSeconds
        totalActiveTime
        coinsEarned
        groupName
        groupCode
        groupDuration
        groupCreated
        totalParticipants
        coordinates
        isSweatCoinUpdated
        isSharedToStrava
        activityStatus
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHistoryByPartnerId = /* GraphQL */ `
  query GetHistoryByPartnerId(
    $partnerId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActivityHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getHistoryByPartnerId(
      partnerId: $partnerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        partnerId
        nanoid
        activity
        date
        distance
        time
        timeInSeconds
        totalActiveTime
        coinsEarned
        groupName
        groupCode
        groupDuration
        groupCreated
        totalParticipants
        coordinates
        isSweatCoinUpdated
        isSharedToStrava
        activityStatus
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTrackingMetricsByNanoid = /* GraphQL */ `
  query GetTrackingMetricsByNanoid(
    $nanoid: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTrackingMetricsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTrackingMetricsByNanoid(
      nanoid: $nanoid
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        type
        nanoid
        uuid
        geohash
        status
        trackingLocations {
          timestamp
          geohash
          activity
          latitude
          longitude
          confidence
          speed
          speedAccuracy
          accuracy
          altitude
          heading
          batteryLevel
          isBatteryCharging
          odometer
          sample
          isMoving
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFlareStatisticsByPartnerId = /* GraphQL */ `
  query GetFlareStatisticsByPartnerId(
    $partnerId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFlareStatisticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFlareStatisticsByPartnerId(
      partnerId: $partnerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        totalUsers
        protectedDistanceInMeters
        protectedJourneys
        rideTimeInSeconds
        incidents
        incidentAlerts
        sos
        sosAlerts
        hazards
        hazardAlerts
        flaresReceived
        flaresResponded
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFleetOwnersSortedByCreatedAt = /* GraphQL */ `
  query GetFleetOwnersSortedByCreatedAt(
    $deletedFleetOwner: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFleetOwnersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFleetOwnersSortedByCreatedAt(
      deletedFleetOwner: $deletedFleetOwner
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        companyName
        companyNameLower
        companyNo
        email
        emailLower
        password
        phone
        noOfVehicles
        addressLine1
        addressLine2
        city
        country
        postcode
        profilePicture
        totalLicenses
        unassignedLicenses
        pdfFilePath
        accountManagerEmail
        laterOpted
        enableInvoicePayment
        disableSnooze
        companyType
        sandboxApiKey
        sandboxApiKeyUsage
        productionApiKey
        productionApiKeyUsage
        isVerified
        isActive
        isDeleted
        deletedFleetOwner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFleetOwnersSortedByFirstName = /* GraphQL */ `
  query GetFleetOwnersSortedByFirstName(
    $deletedFleetOwner: String
    $firstName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFleetOwnersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFleetOwnersSortedByFirstName(
      deletedFleetOwner: $deletedFleetOwner
      firstName: $firstName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        companyName
        companyNameLower
        companyNo
        email
        emailLower
        password
        phone
        noOfVehicles
        addressLine1
        addressLine2
        city
        country
        postcode
        profilePicture
        totalLicenses
        unassignedLicenses
        pdfFilePath
        accountManagerEmail
        laterOpted
        enableInvoicePayment
        disableSnooze
        companyType
        sandboxApiKey
        sandboxApiKeyUsage
        productionApiKey
        productionApiKeyUsage
        isVerified
        isActive
        isDeleted
        deletedFleetOwner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFleetOwnersSortedByLastName = /* GraphQL */ `
  query GetFleetOwnersSortedByLastName(
    $deletedFleetOwner: String
    $lastName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFleetOwnersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFleetOwnersSortedByLastName(
      deletedFleetOwner: $deletedFleetOwner
      lastName: $lastName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        companyName
        companyNameLower
        companyNo
        email
        emailLower
        password
        phone
        noOfVehicles
        addressLine1
        addressLine2
        city
        country
        postcode
        profilePicture
        totalLicenses
        unassignedLicenses
        pdfFilePath
        accountManagerEmail
        laterOpted
        enableInvoicePayment
        disableSnooze
        companyType
        sandboxApiKey
        sandboxApiKeyUsage
        productionApiKey
        productionApiKeyUsage
        isVerified
        isActive
        isDeleted
        deletedFleetOwner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFleetOwnersSortedByCompanyName = /* GraphQL */ `
  query GetFleetOwnersSortedByCompanyName(
    $deletedFleetOwner: String
    $companyName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFleetOwnersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFleetOwnersSortedByCompanyName(
      deletedFleetOwner: $deletedFleetOwner
      companyName: $companyName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        companyName
        companyNameLower
        companyNo
        email
        emailLower
        password
        phone
        noOfVehicles
        addressLine1
        addressLine2
        city
        country
        postcode
        profilePicture
        totalLicenses
        unassignedLicenses
        pdfFilePath
        accountManagerEmail
        laterOpted
        enableInvoicePayment
        disableSnooze
        companyType
        sandboxApiKey
        sandboxApiKeyUsage
        productionApiKey
        productionApiKeyUsage
        isVerified
        isActive
        isDeleted
        deletedFleetOwner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFleetOwnersSortedByEmail = /* GraphQL */ `
  query GetFleetOwnersSortedByEmail(
    $deletedFleetOwner: String
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFleetOwnersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFleetOwnersSortedByEmail(
      deletedFleetOwner: $deletedFleetOwner
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        companyName
        companyNameLower
        companyNo
        email
        emailLower
        password
        phone
        noOfVehicles
        addressLine1
        addressLine2
        city
        country
        postcode
        profilePicture
        totalLicenses
        unassignedLicenses
        pdfFilePath
        accountManagerEmail
        laterOpted
        enableInvoicePayment
        disableSnooze
        companyType
        sandboxApiKey
        sandboxApiKeyUsage
        productionApiKey
        productionApiKeyUsage
        isVerified
        isActive
        isDeleted
        deletedFleetOwner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFleetOwnersBySandboxApiKey = /* GraphQL */ `
  query GetFleetOwnersBySandboxApiKey(
    $sandboxApiKey: String
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFleetOwnersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFleetOwnersBySandboxApiKey(
      sandboxApiKey: $sandboxApiKey
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        companyName
        companyNameLower
        companyNo
        email
        emailLower
        password
        phone
        noOfVehicles
        addressLine1
        addressLine2
        city
        country
        postcode
        profilePicture
        totalLicenses
        unassignedLicenses
        pdfFilePath
        accountManagerEmail
        laterOpted
        enableInvoicePayment
        disableSnooze
        companyType
        sandboxApiKey
        sandboxApiKeyUsage
        productionApiKey
        productionApiKeyUsage
        isVerified
        isActive
        isDeleted
        deletedFleetOwner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFleetOwnersByProductionApiKey = /* GraphQL */ `
  query GetFleetOwnersByProductionApiKey(
    $productionApiKey: String
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFleetOwnersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFleetOwnersByProductionApiKey(
      productionApiKey: $productionApiKey
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        companyName
        companyNameLower
        companyNo
        email
        emailLower
        password
        phone
        noOfVehicles
        addressLine1
        addressLine2
        city
        country
        postcode
        profilePicture
        totalLicenses
        unassignedLicenses
        pdfFilePath
        accountManagerEmail
        laterOpted
        enableInvoicePayment
        disableSnooze
        companyType
        sandboxApiKey
        sandboxApiKeyUsage
        productionApiKey
        productionApiKeyUsage
        isVerified
        isActive
        isDeleted
        deletedFleetOwner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFleetOwnersByEmail = /* GraphQL */ `
  query GetFleetOwnersByEmail(
    $email: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFleetOwnersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFleetOwnersByEmail(
      email: $email
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        companyName
        companyNameLower
        companyNo
        email
        emailLower
        password
        phone
        noOfVehicles
        addressLine1
        addressLine2
        city
        country
        postcode
        profilePicture
        totalLicenses
        unassignedLicenses
        pdfFilePath
        accountManagerEmail
        laterOpted
        enableInvoicePayment
        disableSnooze
        companyType
        sandboxApiKey
        sandboxApiKeyUsage
        productionApiKey
        productionApiKeyUsage
        isVerified
        isActive
        isDeleted
        deletedFleetOwner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFleetOwnerPurchasesByFleetOwnerId = /* GraphQL */ `
  query GetFleetOwnerPurchasesByFleetOwnerId(
    $fleetOwnerId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFleetOwnerPurchasesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFleetOwnerPurchasesByFleetOwnerId(
      fleetOwnerId: $fleetOwnerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fleetOwnerId
        numberOfLicense
        cost
        vat
        total
        pdfFilePath
        stripeToken
        stripeRate
        stripeAmount
        busbyAmount
        stripeChargeId
        isPaymentSuccessful
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFleetUsersSortedByCreatedAt = /* GraphQL */ `
  query GetFleetUsersSortedByCreatedAt(
    $deletedFleetUser: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFleetUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFleetUsersSortedByCreatedAt(
      deletedFleetUser: $deletedFleetUser
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fleetOwnerId
        licenseId
        firstName
        firstNameLower
        lastName
        lastNameLower
        deviceType
        deviceTypeLower
        deviceId
        appVersion
        notificationRequired
        riderAlerts
        protectedDistanceInMeters
        isActive
        isDeleted
        deletedFleetUser
        lastLoginDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFleetUsersSortedByFirstName = /* GraphQL */ `
  query GetFleetUsersSortedByFirstName(
    $deletedFleetUser: String
    $firstName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFleetUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFleetUsersSortedByFirstName(
      deletedFleetUser: $deletedFleetUser
      firstName: $firstName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fleetOwnerId
        licenseId
        firstName
        firstNameLower
        lastName
        lastNameLower
        deviceType
        deviceTypeLower
        deviceId
        appVersion
        notificationRequired
        riderAlerts
        protectedDistanceInMeters
        isActive
        isDeleted
        deletedFleetUser
        lastLoginDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFleetUsersSortedByLastName = /* GraphQL */ `
  query GetFleetUsersSortedByLastName(
    $deletedFleetUser: String
    $lastName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFleetUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFleetUsersSortedByLastName(
      deletedFleetUser: $deletedFleetUser
      lastName: $lastName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fleetOwnerId
        licenseId
        firstName
        firstNameLower
        lastName
        lastNameLower
        deviceType
        deviceTypeLower
        deviceId
        appVersion
        notificationRequired
        riderAlerts
        protectedDistanceInMeters
        isActive
        isDeleted
        deletedFleetUser
        lastLoginDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFleetUsersSortedByLastLoginDate = /* GraphQL */ `
  query GetFleetUsersSortedByLastLoginDate(
    $deletedFleetUser: String
    $lastLoginDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFleetUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFleetUsersSortedByLastLoginDate(
      deletedFleetUser: $deletedFleetUser
      lastLoginDate: $lastLoginDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fleetOwnerId
        licenseId
        firstName
        firstNameLower
        lastName
        lastNameLower
        deviceType
        deviceTypeLower
        deviceId
        appVersion
        notificationRequired
        riderAlerts
        protectedDistanceInMeters
        isActive
        isDeleted
        deletedFleetUser
        lastLoginDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFleetUsersSortedByDeviceType = /* GraphQL */ `
  query GetFleetUsersSortedByDeviceType(
    $deletedFleetUser: String
    $deviceType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFleetUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFleetUsersSortedByDeviceType(
      deletedFleetUser: $deletedFleetUser
      deviceType: $deviceType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fleetOwnerId
        licenseId
        firstName
        firstNameLower
        lastName
        lastNameLower
        deviceType
        deviceTypeLower
        deviceId
        appVersion
        notificationRequired
        riderAlerts
        protectedDistanceInMeters
        isActive
        isDeleted
        deletedFleetUser
        lastLoginDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFleetUsersSortedByDeviceId = /* GraphQL */ `
  query GetFleetUsersSortedByDeviceId(
    $deletedFleetUser: String
    $deviceId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFleetUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFleetUsersSortedByDeviceId(
      deletedFleetUser: $deletedFleetUser
      deviceId: $deviceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fleetOwnerId
        licenseId
        firstName
        firstNameLower
        lastName
        lastNameLower
        deviceType
        deviceTypeLower
        deviceId
        appVersion
        notificationRequired
        riderAlerts
        protectedDistanceInMeters
        isActive
        isDeleted
        deletedFleetUser
        lastLoginDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFleetUsersByFleetOwnerId = /* GraphQL */ `
  query GetFleetUsersByFleetOwnerId(
    $fleetOwnerId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFleetUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFleetUsersByFleetOwnerId(
      fleetOwnerId: $fleetOwnerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fleetOwnerId
        licenseId
        firstName
        firstNameLower
        lastName
        lastNameLower
        deviceType
        deviceTypeLower
        deviceId
        appVersion
        notificationRequired
        riderAlerts
        protectedDistanceInMeters
        isActive
        isDeleted
        deletedFleetUser
        lastLoginDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFleetUsersByLicenseId = /* GraphQL */ `
  query GetFleetUsersByLicenseId(
    $licenseId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFleetUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFleetUsersByLicenseId(
      licenseId: $licenseId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fleetOwnerId
        licenseId
        firstName
        firstNameLower
        lastName
        lastNameLower
        deviceType
        deviceTypeLower
        deviceId
        appVersion
        notificationRequired
        riderAlerts
        protectedDistanceInMeters
        isActive
        isDeleted
        deletedFleetUser
        lastLoginDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getLicensesSortedByCreatedAt = /* GraphQL */ `
  query GetLicensesSortedByCreatedAt(
    $deletedLicense: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLicensesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLicensesSortedByCreatedAt(
      deletedLicense: $deletedLicense
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fleetOwnerId
        fleetUserId
        fleetOwnerPurchaseId
        code
        qrImage
        price
        isAssigned
        assignedLicense
        paymentType
        expiryDate
        isActive
        isDeleted
        deletedLicense
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getLicensesSortedByAssignedLicense = /* GraphQL */ `
  query GetLicensesSortedByAssignedLicense(
    $deletedLicense: String
    $assignedLicense: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLicensesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLicensesSortedByAssignedLicense(
      deletedLicense: $deletedLicense
      assignedLicense: $assignedLicense
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fleetOwnerId
        fleetUserId
        fleetOwnerPurchaseId
        code
        qrImage
        price
        isAssigned
        assignedLicense
        paymentType
        expiryDate
        isActive
        isDeleted
        deletedLicense
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getLicensesSortedByCode = /* GraphQL */ `
  query GetLicensesSortedByCode(
    $deletedLicense: String
    $code: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLicensesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLicensesSortedByCode(
      deletedLicense: $deletedLicense
      code: $code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fleetOwnerId
        fleetUserId
        fleetOwnerPurchaseId
        code
        qrImage
        price
        isAssigned
        assignedLicense
        paymentType
        expiryDate
        isActive
        isDeleted
        deletedLicense
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getLicenseByCode = /* GraphQL */ `
  query GetLicenseByCode(
    $code: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLicensesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLicenseByCode(
      code: $code
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fleetOwnerId
        fleetUserId
        fleetOwnerPurchaseId
        code
        qrImage
        price
        isAssigned
        assignedLicense
        paymentType
        expiryDate
        isActive
        isDeleted
        deletedLicense
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getLicenseByFleetOwnerId = /* GraphQL */ `
  query GetLicenseByFleetOwnerId(
    $fleetOwnerId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLicensesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLicenseByFleetOwnerId(
      fleetOwnerId: $fleetOwnerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fleetOwnerId
        fleetUserId
        fleetOwnerPurchaseId
        code
        qrImage
        price
        isAssigned
        assignedLicense
        paymentType
        expiryDate
        isActive
        isDeleted
        deletedLicense
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getLicenseByFleetOwnerPurchaseId = /* GraphQL */ `
  query GetLicenseByFleetOwnerPurchaseId(
    $fleetOwnerPurchaseId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLicensesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLicenseByFleetOwnerPurchaseId(
      fleetOwnerPurchaseId: $fleetOwnerPurchaseId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fleetOwnerId
        fleetUserId
        fleetOwnerPurchaseId
        code
        qrImage
        price
        isAssigned
        assignedLicense
        paymentType
        expiryDate
        isActive
        isDeleted
        deletedLicense
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFlaresByPartner = /* GraphQL */ `
  query GetFlaresByPartner(
    $partnerId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFlaresFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFlaresByPartner(
      partnerId: $partnerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        flareRequestorID
        flareResponderID
        nanoid
        geohash
        flareTime
        flareType
        flareStatus
        flareRequestorLatitude
        flareRequestorLongitude
        radius
        flareRequestorName
        flareRequestorProfilePic
        flareResponderName
        flareResponderProfilePic
        distance
        isConnected
        endChat
        isChatEnded
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFlaresByFlareRequestor = /* GraphQL */ `
  query GetFlaresByFlareRequestor(
    $flareRequestorID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFlaresFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFlaresByFlareRequestor(
      flareRequestorID: $flareRequestorID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        flareRequestorID
        flareResponderID
        nanoid
        geohash
        flareTime
        flareType
        flareStatus
        flareRequestorLatitude
        flareRequestorLongitude
        radius
        flareRequestorName
        flareRequestorProfilePic
        flareResponderName
        flareResponderProfilePic
        distance
        isConnected
        endChat
        isChatEnded
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFlareMessagesByFlareId = /* GraphQL */ `
  query GetFlareMessagesByFlareId(
    $flareId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFlareMessagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFlareMessagesByFlareId(
      flareId: $flareId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        flareId
        partnerId
        flareRequestorID
        nanoid
        sent
        time
        content
        messageId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getIncidentsByPartner = /* GraphQL */ `
  query GetIncidentsByPartner(
    $partnerId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIncidentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getIncidentsByPartner(
      partnerId: $partnerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        userId
        userName
        userNameLower
        userEmail
        userEmailLower
        userProfilePic
        userReferralCode
        userRetailCodeUsed
        topicId
        expiresTimestamp
        incidentTime
        incidentLatitude
        incidentLongitude
        countryIsoCode
        city
        speed
        acceleration
        heading
        altitude
        distance
        isConnected
        endChat
        isChatEnded
        isVerified
        status
        statusLower
        userResponse
        userResponseLower
        totalAlerts
        deviceType
        deviceId
        battery
        coordinates
        incidentRaisedFrom
        incidentRaisedFromKey
        incidentType
        flareApiPayload
        activityTimeline {
          createdAt
          type
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getIncidentsByTopicId = /* GraphQL */ `
  query GetIncidentsByTopicId(
    $topicId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIncidentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getIncidentsByTopicId(
      topicId: $topicId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        userId
        userName
        userNameLower
        userEmail
        userEmailLower
        userProfilePic
        userReferralCode
        userRetailCodeUsed
        topicId
        expiresTimestamp
        incidentTime
        incidentLatitude
        incidentLongitude
        countryIsoCode
        city
        speed
        acceleration
        heading
        altitude
        distance
        isConnected
        endChat
        isChatEnded
        isVerified
        status
        statusLower
        userResponse
        userResponseLower
        totalAlerts
        deviceType
        deviceId
        battery
        coordinates
        incidentRaisedFrom
        incidentRaisedFromKey
        incidentType
        flareApiPayload
        activityTimeline {
          createdAt
          type
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getIncidentsSortedByCreatedAt = /* GraphQL */ `
  query GetIncidentsSortedByCreatedAt(
    $partnerId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIncidentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getIncidentsSortedByCreatedAt(
      partnerId: $partnerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        userId
        userName
        userNameLower
        userEmail
        userEmailLower
        userProfilePic
        userReferralCode
        userRetailCodeUsed
        topicId
        expiresTimestamp
        incidentTime
        incidentLatitude
        incidentLongitude
        countryIsoCode
        city
        speed
        acceleration
        heading
        altitude
        distance
        isConnected
        endChat
        isChatEnded
        isVerified
        status
        statusLower
        userResponse
        userResponseLower
        totalAlerts
        deviceType
        deviceId
        battery
        coordinates
        incidentRaisedFrom
        incidentRaisedFromKey
        incidentType
        flareApiPayload
        activityTimeline {
          createdAt
          type
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getIncidentsByUserId = /* GraphQL */ `
  query GetIncidentsByUserId(
    $userId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIncidentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getIncidentsByUserId(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        userId
        userName
        userNameLower
        userEmail
        userEmailLower
        userProfilePic
        userReferralCode
        userRetailCodeUsed
        topicId
        expiresTimestamp
        incidentTime
        incidentLatitude
        incidentLongitude
        countryIsoCode
        city
        speed
        acceleration
        heading
        altitude
        distance
        isConnected
        endChat
        isChatEnded
        isVerified
        status
        statusLower
        userResponse
        userResponseLower
        totalAlerts
        deviceType
        deviceId
        battery
        coordinates
        incidentRaisedFrom
        incidentRaisedFromKey
        incidentType
        flareApiPayload
        activityTimeline {
          createdAt
          type
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPlaygroundIncidentsByPartner = /* GraphQL */ `
  query GetPlaygroundIncidentsByPartner(
    $partnerId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIncidentsPlaygroundFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPlaygroundIncidentsByPartner(
      partnerId: $partnerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        userId
        userName
        userNameLower
        userEmail
        userEmailLower
        userProfilePic
        userReferralCode
        userRetailCodeUsed
        topicId
        expiresTimestamp
        incidentTime
        incidentLatitude
        incidentLongitude
        countryIsoCode
        city
        speed
        acceleration
        heading
        altitude
        distance
        isConnected
        endChat
        isChatEnded
        isVerified
        status
        statusLower
        userResponse
        userResponseLower
        totalAlerts
        deviceType
        deviceId
        battery
        coordinates
        incidentRaisedFrom
        incidentRaisedFromKey
        incidentType
        flareApiPayload
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPlaygroundIncidentsSortedByCreatedAt = /* GraphQL */ `
  query GetPlaygroundIncidentsSortedByCreatedAt(
    $partnerId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIncidentsPlaygroundFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPlaygroundIncidentsSortedByCreatedAt(
      partnerId: $partnerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        userId
        userName
        userNameLower
        userEmail
        userEmailLower
        userProfilePic
        userReferralCode
        userRetailCodeUsed
        topicId
        expiresTimestamp
        incidentTime
        incidentLatitude
        incidentLongitude
        countryIsoCode
        city
        speed
        acceleration
        heading
        altitude
        distance
        isConnected
        endChat
        isChatEnded
        isVerified
        status
        statusLower
        userResponse
        userResponseLower
        totalAlerts
        deviceType
        deviceId
        battery
        coordinates
        incidentRaisedFrom
        incidentRaisedFromKey
        incidentType
        flareApiPayload
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPlaygroundIncidentsByUserId = /* GraphQL */ `
  query GetPlaygroundIncidentsByUserId(
    $userId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIncidentsPlaygroundFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPlaygroundIncidentsByUserId(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        userId
        userName
        userNameLower
        userEmail
        userEmailLower
        userProfilePic
        userReferralCode
        userRetailCodeUsed
        topicId
        expiresTimestamp
        incidentTime
        incidentLatitude
        incidentLongitude
        countryIsoCode
        city
        speed
        acceleration
        heading
        altitude
        distance
        isConnected
        endChat
        isChatEnded
        isVerified
        status
        statusLower
        userResponse
        userResponseLower
        totalAlerts
        deviceType
        deviceId
        battery
        coordinates
        incidentRaisedFrom
        incidentRaisedFromKey
        incidentType
        flareApiPayload
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getIncidentMessagesByIncidentId = /* GraphQL */ `
  query GetIncidentMessagesByIncidentId(
    $incidentId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIncidentMessagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getIncidentMessagesByIncidentId(
      incidentId: $incidentId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        incidentId
        topicId
        partnerId
        userId
        sent
        time
        content
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getEmergencySosByPartner = /* GraphQL */ `
  query GetEmergencySosByPartner(
    $partnerId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEmergencySOSFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getEmergencySOSByPartner(
      partnerId: $partnerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        userId
        nanoid
        userName
        userNameLower
        userEmail
        userEmailLower
        userProfilePic
        userReferralCode
        reportedAt
        latitude
        longitude
        distance
        journeyEnded
        isJourneyEnded
        totalAlerts
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getEmergencySosSortedByCreatedAt = /* GraphQL */ `
  query GetEmergencySosSortedByCreatedAt(
    $partnerId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEmergencySOSFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getEmergencySOSSortedByCreatedAt(
      partnerId: $partnerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        userId
        nanoid
        userName
        userNameLower
        userEmail
        userEmailLower
        userProfilePic
        userReferralCode
        reportedAt
        latitude
        longitude
        distance
        journeyEnded
        isJourneyEnded
        totalAlerts
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getEmergencySosByUserId = /* GraphQL */ `
  query GetEmergencySosByUserId(
    $userId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEmergencySOSFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getEmergencySosByUserId(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        userId
        nanoid
        userName
        userNameLower
        userEmail
        userEmailLower
        userProfilePic
        userReferralCode
        reportedAt
        latitude
        longitude
        distance
        journeyEnded
        isJourneyEnded
        totalAlerts
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHazardsSortedByCreatedAt = /* GraphQL */ `
  query GetHazardsSortedByCreatedAt(
    $deletedHazard: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelHazardsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getHazardsSortedByCreatedAt(
      deletedHazard: $deletedHazard
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        partnerId
        timeStamp
        latitude
        longitude
        type
        typeLower
        crowdinTitleKey
        userName
        userNameLower
        userEmail
        userEmailLower
        geohash
        expireAt
        isExpired
        notificationsGenerated
        notified
        accuracyScoreUp
        accuracyScoreDown
        countryCode
        totalAlerts
        isDeleted
        deletedHazard
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHazardsSortedByType = /* GraphQL */ `
  query GetHazardsSortedByType(
    $deletedHazard: String
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelHazardsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getHazardsSortedByType(
      deletedHazard: $deletedHazard
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        partnerId
        timeStamp
        latitude
        longitude
        type
        typeLower
        crowdinTitleKey
        userName
        userNameLower
        userEmail
        userEmailLower
        geohash
        expireAt
        isExpired
        notificationsGenerated
        notified
        accuracyScoreUp
        accuracyScoreDown
        countryCode
        totalAlerts
        isDeleted
        deletedHazard
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHazardsSortedByAccuracyScoreUp = /* GraphQL */ `
  query GetHazardsSortedByAccuracyScoreUp(
    $deletedHazard: String
    $accuracyScoreUp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelHazardsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getHazardsSortedByAccuracyScoreUp(
      deletedHazard: $deletedHazard
      accuracyScoreUp: $accuracyScoreUp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        partnerId
        timeStamp
        latitude
        longitude
        type
        typeLower
        crowdinTitleKey
        userName
        userNameLower
        userEmail
        userEmailLower
        geohash
        expireAt
        isExpired
        notificationsGenerated
        notified
        accuracyScoreUp
        accuracyScoreDown
        countryCode
        totalAlerts
        isDeleted
        deletedHazard
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHazardsByPartnerId = /* GraphQL */ `
  query GetHazardsByPartnerId(
    $partnerId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelHazardsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getHazardsByPartnerId(
      partnerId: $partnerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        partnerId
        timeStamp
        latitude
        longitude
        type
        typeLower
        crowdinTitleKey
        userName
        userNameLower
        userEmail
        userEmailLower
        geohash
        expireAt
        isExpired
        notificationsGenerated
        notified
        accuracyScoreUp
        accuracyScoreDown
        countryCode
        totalAlerts
        isDeleted
        deletedHazard
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHazardsByUserId = /* GraphQL */ `
  query GetHazardsByUserId(
    $userId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelHazardsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getHazardsByUserId(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        partnerId
        timeStamp
        latitude
        longitude
        type
        typeLower
        crowdinTitleKey
        userName
        userNameLower
        userEmail
        userEmailLower
        geohash
        expireAt
        isExpired
        notificationsGenerated
        notified
        accuracyScoreUp
        accuracyScoreDown
        countryCode
        totalAlerts
        isDeleted
        deletedHazard
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHazardsByGeohash = /* GraphQL */ `
  query GetHazardsByGeohash(
    $geohash: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelHazardsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getHazardsByGeohash(
      geohash: $geohash
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        partnerId
        timeStamp
        latitude
        longitude
        type
        typeLower
        crowdinTitleKey
        userName
        userNameLower
        userEmail
        userEmailLower
        geohash
        expireAt
        isExpired
        notificationsGenerated
        notified
        accuracyScoreUp
        accuracyScoreDown
        countryCode
        totalAlerts
        isDeleted
        deletedHazard
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHazardByUserId = /* GraphQL */ `
  query GetHazardByUserId(
    $userId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserHazardsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getHazardByUserId(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        hazardId
        userId
        isNotified
        isVoted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getExternalUsersByLicenseKey = /* GraphQL */ `
  query GetExternalUsersByLicenseKey(
    $licenseKey: String
    $deviceId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExternalUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getExternalUsersByLicenseKey(
      licenseKey: $licenseKey
      deviceId: $deviceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        type
        licenseKey
        totalUsage
        deviceType
        deviceId
        osVersion
        createdAtEpoch
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getExternalUsersByPartnerId = /* GraphQL */ `
  query GetExternalUsersByPartnerId(
    $partnerId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExternalUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getExternalUsersByPartnerId(
      partnerId: $partnerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        type
        licenseKey
        totalUsage
        deviceType
        deviceId
        osVersion
        createdAtEpoch
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getExternalUsersByType = /* GraphQL */ `
  query GetExternalUsersByType(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExternalUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getExternalUsersByType(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        type
        licenseKey
        totalUsage
        deviceType
        deviceId
        osVersion
        createdAtEpoch
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPlaygroundExternalUsersByLicenseKey = /* GraphQL */ `
  query GetPlaygroundExternalUsersByLicenseKey(
    $licenseKey: String
    $deviceId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExternalUsersPlaygroundFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPlaygroundExternalUsersByLicenseKey(
      licenseKey: $licenseKey
      deviceId: $deviceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        type
        licenseKey
        totalUsage
        deviceType
        deviceId
        osVersion
        createdAtEpoch
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPlaygroundExternalUsersByPartnerId = /* GraphQL */ `
  query GetPlaygroundExternalUsersByPartnerId(
    $partnerId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExternalUsersPlaygroundFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPlaygroundExternalUsersByPartnerId(
      partnerId: $partnerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        type
        licenseKey
        totalUsage
        deviceType
        deviceId
        osVersion
        createdAtEpoch
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPlaygroundExternalUsersByType = /* GraphQL */ `
  query GetPlaygroundExternalUsersByType(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExternalUsersPlaygroundFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPlaygroundExternalUsersByType(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        type
        licenseKey
        totalUsage
        deviceType
        deviceId
        osVersion
        createdAtEpoch
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getReferralUserHistoryByUserId = /* GraphQL */ `
  query GetReferralUserHistoryByUserId(
    $referralUserId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReferralHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getReferralUserHistoryByUserId(
      referralUserId: $referralUserId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        referralUserId
        referralCode
        createdAt
        updateAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        user {
          id
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          referralCode
          referralName
          isReferralRedeem
          email
          emailLower
          profilePicture
          phoneNumber
          countDownDuration
          subscription
          subscriptionLower
          subscriptionDate
          subscriptionExpiryDate
          keepScreenOn
          isFlareOn
          automaticStartOn
          totalBalance
          totalReward
          totalDriversAlerted
          totalDistanceCovered
          totalProtectedJourneys
          totalRideTimeInSeconds
          totalNearMissesReported
          country
          deviceId
          fcmToken
          deviceType
          isEmailVerified
          latitude
          longitude
          partnerId
          retailCodeUsed
          retailCodeUsedLower
          riderActivities
          riderType
          ridingPeriod
          rewardAccessType
          rewardAccessTypeLower
          rewardAccessStartDate
          rewardAccessEndDate
          rewardAccessSubTotal
          rewardAccessVAT
          rewardAccessVATAmount
          rewardAccessTotalAmount
          rewardAccessStripeToken
          rewardAccessStripeReceipt
          isRewardAccessPaymentSuccessful
          rewardAccessMode
          isPaidToAffiliate
          isSmartStartOn
          referralId
          registrationProvider
          activityTimeInSeconds
          totalRewardsRedeemed
          reputationScore
          appVersion
          sweatCoinAuthenticationToken
          lastAccessed
          isSmartStartNotificationEnable
          isMarketPlaceNotificationEnable
          agreedConsents {
            description
            type
            accepted
          }
          isActive
          isDeleted
          deletedUser
          createdAt
          updateAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          owner
          partner {
            id
            invitedByPartnerId
            partnerCode
            dynamicLink
            desktopURL
            companyName
            companyNameLower
            firstName
            firstNameLower
            lastName
            lastNameLower
            userName
            userNameLower
            email
            emailLower
            password
            profilePicture
            companyEmblem
            phone
            addressLine1
            addressLine2
            city
            country
            countryIsoCode
            postcode
            deviceType
            fcmToken
            totalFlaresReceived
            totalFlaresRespondedTo
            totalIncidentsReceived
            totalIncidentsActioned
            latitude
            longitude
            retailCodePrice
            resalePrice
            invoicePayment
            rewardAvailabilityCountries
            referralURL
            referralIntroText
            referralBackgroundImage
            referralRetailPrice
            incidentNotificationSettings
            notificationTitle
            notificationMessage
            notificationPeriod
            hasRiderConfirmed
            iotEndpoint
            enableRewards
            enableReferrals
            enableAppDiscountsHub
            enableFlares
            enableRiderManagement
            enableRetail
            enableAutoStart
            enableEmergencySOS
            enableSdk
            enforceEmergencyContact
            enforceSafeZone
            enforcePhoneNumber
            youtubeLink
            enforceRiderId
            termsAndConditionLink
            privacyPolicyLink
            landingPagePoints {
              text
            }
            sandboxFlareApiKey
            sandboxFlareApiKeyUsage
            productionFlareApiKey
            productionFlareApiKeyUsage
            licenseKeys {
              licenseKey
              keyName
              environment
              totalUsage
              totalIncidentDetected
              isActive
              isDeleted
              deletedKey
              lastUsed
              createdAt
              updatedAt
            }
            incidentNotifications {
              notificationSettings
              notificationEmail
              notificationCountry
              notificationCountryCallingCode
              notificationMobileNo
              notificationWebhookURL
              includeRiderEmail
              riderID
              riderName
              riderCity
              riderEmail
              incidentDateAndTime
              ISOCountryCode
              incidentLocation
              incidentTelemetry
            }
            sosNotificationSettings
            sosNotifications {
              notificationSettings
              notificationEmail
              notificationCountry
              notificationCountryCallingCode
              notificationMobileNo
              notificationWebhookURL
              includeRiderEmail
              riderID
              riderName
              riderCity
              riderEmail
              incidentDateAndTime
              ISOCountryCode
              incidentLocation
              incidentTelemetry
            }
            consentCheckbox {
              title
              fileType
              link
              description
              type
            }
            enabledTwoStepVerification
            elasticIndexName
            isAccountVerified
            isActive
            isDeleted
            deletedPartner
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getAdminUsers = /* GraphQL */ `
  query GetAdminUsers($id: ID!) {
    getAdminUsers(id: $id) {
      id
      firstName
      firstNameLower
      lastName
      lastNameLower
      email
      emailLower
      password
      userName
      userNameLower
      deviceType
      fcmToken
      isAccountVerified
      isActive
      isDeleted
      deletedAdminUser
      lastAccessed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listAdminUserss = /* GraphQL */ `
  query ListAdminUserss(
    $filter: ModelAdminUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminUserss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        email
        emailLower
        password
        userName
        userNameLower
        deviceType
        fcmToken
        isAccountVerified
        isActive
        isDeleted
        deletedAdminUser
        lastAccessed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getAdminUserByEmail = /* GraphQL */ `
  query GetAdminUserByEmail(
    $email: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdminUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAdminUserByEmail(
      email: $email
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        email
        emailLower
        password
        userName
        userNameLower
        deviceType
        fcmToken
        isAccountVerified
        isActive
        isDeleted
        deletedAdminUser
        lastAccessed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getAdminUsersSortedByCreatedAt = /* GraphQL */ `
  query GetAdminUsersSortedByCreatedAt(
    $deletedAdminUser: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdminUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAdminUsersSortedByCreatedAt(
      deletedAdminUser: $deletedAdminUser
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        email
        emailLower
        password
        userName
        userNameLower
        deviceType
        fcmToken
        isAccountVerified
        isActive
        isDeleted
        deletedAdminUser
        lastAccessed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getAdminUsersSortedByFirstName = /* GraphQL */ `
  query GetAdminUsersSortedByFirstName(
    $deletedAdminUser: String
    $firstName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdminUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAdminUsersSortedByFirstName(
      deletedAdminUser: $deletedAdminUser
      firstName: $firstName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        email
        emailLower
        password
        userName
        userNameLower
        deviceType
        fcmToken
        isAccountVerified
        isActive
        isDeleted
        deletedAdminUser
        lastAccessed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getAdminUsersSortedByLastName = /* GraphQL */ `
  query GetAdminUsersSortedByLastName(
    $deletedAdminUser: String
    $lastName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdminUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAdminUsersSortedByLastName(
      deletedAdminUser: $deletedAdminUser
      lastName: $lastName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        email
        emailLower
        password
        userName
        userNameLower
        deviceType
        fcmToken
        isAccountVerified
        isActive
        isDeleted
        deletedAdminUser
        lastAccessed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getAdminUsersSortedByEmail = /* GraphQL */ `
  query GetAdminUsersSortedByEmail(
    $deletedAdminUser: String
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdminUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAdminUsersSortedByEmail(
      deletedAdminUser: $deletedAdminUser
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        email
        emailLower
        password
        userName
        userNameLower
        deviceType
        fcmToken
        isAccountVerified
        isActive
        isDeleted
        deletedAdminUser
        lastAccessed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAdminUsers = /* GraphQL */ `
  query SyncAdminUsers(
    $filter: ModelAdminUsersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        email
        emailLower
        password
        userName
        userNameLower
        deviceType
        fcmToken
        isAccountVerified
        isActive
        isDeleted
        deletedAdminUser
        lastAccessed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getCountry = /* GraphQL */ `
  query GetCountry($id: ID!) {
    getCountry(id: $id) {
      id
      countryName
      callingCode
      isoCode
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCountrys = /* GraphQL */ `
  query ListCountrys(
    $filter: ModelCountryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCountrys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        countryName
        callingCode
        isoCode
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCountries = /* GraphQL */ `
  query SyncCountries(
    $filter: ModelCountryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCountries(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        countryName
        callingCode
        isoCode
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getAppConfig = /* GraphQL */ `
  query GetAppConfig($id: ID!) {
    getAppConfig(id: $id) {
      id
      androidVersion
      iOSVersion
      isForceUpdateAndroid
      isForceUpdateiOS
      message
      releaseNotes {
        title
        isLive
        version
        notes
        createdAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listAppConfigs = /* GraphQL */ `
  query ListAppConfigs(
    $filter: ModelAppConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppConfigs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        androidVersion
        iOSVersion
        isForceUpdateAndroid
        isForceUpdateiOS
        message
        releaseNotes {
          title
          isLive
          version
          notes
          createdAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAppConfigs = /* GraphQL */ `
  query SyncAppConfigs(
    $filter: ModelAppConfigFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAppConfigs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        androidVersion
        iOSVersion
        isForceUpdateAndroid
        isForceUpdateiOS
        message
        releaseNotes {
          title
          isLive
          version
          notes
          createdAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getUsers = /* GraphQL */ `
  query GetUsers($id: ID!) {
    getUsers(id: $id) {
      id
      firstName
      firstNameLower
      lastName
      lastNameLower
      userName
      userNameLower
      referralCode
      referralName
      isReferralRedeem
      email
      emailLower
      profilePicture
      phoneNumber
      countDownDuration
      subscription
      subscriptionLower
      subscriptionDate
      subscriptionExpiryDate
      keepScreenOn
      isFlareOn
      automaticStartOn
      totalBalance
      totalReward
      totalDriversAlerted
      totalDistanceCovered
      totalProtectedJourneys
      totalRideTimeInSeconds
      totalNearMissesReported
      country
      deviceId
      fcmToken
      deviceType
      isEmailVerified
      latitude
      longitude
      partnerId
      retailCodeUsed
      retailCodeUsedLower
      riderActivities
      riderType
      ridingPeriod
      rewardAccessType
      rewardAccessTypeLower
      rewardAccessStartDate
      rewardAccessEndDate
      rewardAccessSubTotal
      rewardAccessVAT
      rewardAccessVATAmount
      rewardAccessTotalAmount
      rewardAccessStripeToken
      rewardAccessStripeReceipt
      isRewardAccessPaymentSuccessful
      rewardAccessMode
      isPaidToAffiliate
      isSmartStartOn
      referralId
      registrationProvider
      activityTimeInSeconds
      totalRewardsRedeemed
      reputationScore
      appVersion
      sweatCoinAuthenticationToken
      lastAccessed
      isSmartStartNotificationEnable
      isMarketPlaceNotificationEnable
      agreedConsents {
        description
        type
        accepted
      }
      isActive
      isDeleted
      deletedUser
      createdAt
      updateAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      owner
      partner {
        id
        invitedByPartnerId
        partnerCode
        dynamicLink
        desktopURL
        companyName
        companyNameLower
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        email
        emailLower
        password
        profilePicture
        companyEmblem
        phone
        addressLine1
        addressLine2
        city
        country
        countryIsoCode
        postcode
        deviceType
        fcmToken
        totalFlaresReceived
        totalFlaresRespondedTo
        totalIncidentsReceived
        totalIncidentsActioned
        latitude
        longitude
        retailCodePrice
        resalePrice
        invoicePayment
        rewardAvailabilityCountries
        referralURL
        referralIntroText
        referralBackgroundImage
        referralRetailPrice
        incidentNotificationSettings
        notificationTitle
        notificationMessage
        notificationPeriod
        hasRiderConfirmed
        iotEndpoint
        enableRewards
        enableReferrals
        enableAppDiscountsHub
        enableFlares
        enableRiderManagement
        enableRetail
        enableAutoStart
        enableEmergencySOS
        enableSdk
        enforceEmergencyContact
        enforceSafeZone
        enforcePhoneNumber
        youtubeLink
        enforceRiderId
        termsAndConditionLink
        privacyPolicyLink
        landingPagePoints {
          text
        }
        sandboxFlareApiKey
        sandboxFlareApiKeyUsage
        productionFlareApiKey
        productionFlareApiKeyUsage
        licenseKeys {
          licenseKey
          keyName
          environment
          totalUsage
          totalIncidentDetected
          isActive
          isDeleted
          deletedKey
          lastUsed
          createdAt
          updatedAt
        }
        incidentNotifications {
          notificationSettings
          notificationEmail
          notificationCountry
          notificationCountryCallingCode
          notificationMobileNo
          notificationWebhookURL
          includeRiderEmail
          riderID
          riderName
          riderCity
          riderEmail
          incidentDateAndTime
          ISOCountryCode
          incidentLocation
          incidentTelemetry
        }
        sosNotificationSettings
        sosNotifications {
          notificationSettings
          notificationEmail
          notificationCountry
          notificationCountryCallingCode
          notificationMobileNo
          notificationWebhookURL
          includeRiderEmail
          riderID
          riderName
          riderCity
          riderEmail
          incidentDateAndTime
          ISOCountryCode
          incidentLocation
          incidentTelemetry
        }
        consentCheckbox {
          title
          fileType
          link
          description
          type
        }
        enabledTwoStepVerification
        elasticIndexName
        isAccountVerified
        isActive
        isDeleted
        deletedPartner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
    }
  }
`;
export const listUserss = /* GraphQL */ `
  query ListUserss(
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        referralCode
        referralName
        isReferralRedeem
        email
        emailLower
        profilePicture
        phoneNumber
        countDownDuration
        subscription
        subscriptionLower
        subscriptionDate
        subscriptionExpiryDate
        keepScreenOn
        isFlareOn
        automaticStartOn
        totalBalance
        totalReward
        totalDriversAlerted
        totalDistanceCovered
        totalProtectedJourneys
        totalRideTimeInSeconds
        totalNearMissesReported
        country
        deviceId
        fcmToken
        deviceType
        isEmailVerified
        latitude
        longitude
        partnerId
        retailCodeUsed
        retailCodeUsedLower
        riderActivities
        riderType
        ridingPeriod
        rewardAccessType
        rewardAccessTypeLower
        rewardAccessStartDate
        rewardAccessEndDate
        rewardAccessSubTotal
        rewardAccessVAT
        rewardAccessVATAmount
        rewardAccessTotalAmount
        rewardAccessStripeToken
        rewardAccessStripeReceipt
        isRewardAccessPaymentSuccessful
        rewardAccessMode
        isPaidToAffiliate
        isSmartStartOn
        referralId
        registrationProvider
        activityTimeInSeconds
        totalRewardsRedeemed
        reputationScore
        appVersion
        sweatCoinAuthenticationToken
        lastAccessed
        isSmartStartNotificationEnable
        isMarketPlaceNotificationEnable
        agreedConsents {
          description
          type
          accepted
        }
        isActive
        isDeleted
        deletedUser
        createdAt
        updateAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        owner
        partner {
          id
          invitedByPartnerId
          partnerCode
          dynamicLink
          desktopURL
          companyName
          companyNameLower
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          email
          emailLower
          password
          profilePicture
          companyEmblem
          phone
          addressLine1
          addressLine2
          city
          country
          countryIsoCode
          postcode
          deviceType
          fcmToken
          totalFlaresReceived
          totalFlaresRespondedTo
          totalIncidentsReceived
          totalIncidentsActioned
          latitude
          longitude
          retailCodePrice
          resalePrice
          invoicePayment
          rewardAvailabilityCountries
          referralURL
          referralIntroText
          referralBackgroundImage
          referralRetailPrice
          incidentNotificationSettings
          notificationTitle
          notificationMessage
          notificationPeriod
          hasRiderConfirmed
          iotEndpoint
          enableRewards
          enableReferrals
          enableAppDiscountsHub
          enableFlares
          enableRiderManagement
          enableRetail
          enableAutoStart
          enableEmergencySOS
          enableSdk
          enforceEmergencyContact
          enforceSafeZone
          enforcePhoneNumber
          youtubeLink
          enforceRiderId
          termsAndConditionLink
          privacyPolicyLink
          landingPagePoints {
            text
          }
          sandboxFlareApiKey
          sandboxFlareApiKeyUsage
          productionFlareApiKey
          productionFlareApiKeyUsage
          licenseKeys {
            licenseKey
            keyName
            environment
            totalUsage
            totalIncidentDetected
            isActive
            isDeleted
            deletedKey
            lastUsed
            createdAt
            updatedAt
          }
          incidentNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          sosNotificationSettings
          sosNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          consentCheckbox {
            title
            fileType
            link
            description
            type
          }
          enabledTwoStepVerification
          elasticIndexName
          isAccountVerified
          isActive
          isDeleted
          deletedPartner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserByEmail = /* GraphQL */ `
  query GetUserByEmail(
    $email: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserByEmail(
      email: $email
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        referralCode
        referralName
        isReferralRedeem
        email
        emailLower
        profilePicture
        phoneNumber
        countDownDuration
        subscription
        subscriptionLower
        subscriptionDate
        subscriptionExpiryDate
        keepScreenOn
        isFlareOn
        automaticStartOn
        totalBalance
        totalReward
        totalDriversAlerted
        totalDistanceCovered
        totalProtectedJourneys
        totalRideTimeInSeconds
        totalNearMissesReported
        country
        deviceId
        fcmToken
        deviceType
        isEmailVerified
        latitude
        longitude
        partnerId
        retailCodeUsed
        retailCodeUsedLower
        riderActivities
        riderType
        ridingPeriod
        rewardAccessType
        rewardAccessTypeLower
        rewardAccessStartDate
        rewardAccessEndDate
        rewardAccessSubTotal
        rewardAccessVAT
        rewardAccessVATAmount
        rewardAccessTotalAmount
        rewardAccessStripeToken
        rewardAccessStripeReceipt
        isRewardAccessPaymentSuccessful
        rewardAccessMode
        isPaidToAffiliate
        isSmartStartOn
        referralId
        registrationProvider
        activityTimeInSeconds
        totalRewardsRedeemed
        reputationScore
        appVersion
        sweatCoinAuthenticationToken
        lastAccessed
        isSmartStartNotificationEnable
        isMarketPlaceNotificationEnable
        agreedConsents {
          description
          type
          accepted
        }
        isActive
        isDeleted
        deletedUser
        createdAt
        updateAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        owner
        partner {
          id
          invitedByPartnerId
          partnerCode
          dynamicLink
          desktopURL
          companyName
          companyNameLower
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          email
          emailLower
          password
          profilePicture
          companyEmblem
          phone
          addressLine1
          addressLine2
          city
          country
          countryIsoCode
          postcode
          deviceType
          fcmToken
          totalFlaresReceived
          totalFlaresRespondedTo
          totalIncidentsReceived
          totalIncidentsActioned
          latitude
          longitude
          retailCodePrice
          resalePrice
          invoicePayment
          rewardAvailabilityCountries
          referralURL
          referralIntroText
          referralBackgroundImage
          referralRetailPrice
          incidentNotificationSettings
          notificationTitle
          notificationMessage
          notificationPeriod
          hasRiderConfirmed
          iotEndpoint
          enableRewards
          enableReferrals
          enableAppDiscountsHub
          enableFlares
          enableRiderManagement
          enableRetail
          enableAutoStart
          enableEmergencySOS
          enableSdk
          enforceEmergencyContact
          enforceSafeZone
          enforcePhoneNumber
          youtubeLink
          enforceRiderId
          termsAndConditionLink
          privacyPolicyLink
          landingPagePoints {
            text
          }
          sandboxFlareApiKey
          sandboxFlareApiKeyUsage
          productionFlareApiKey
          productionFlareApiKeyUsage
          licenseKeys {
            licenseKey
            keyName
            environment
            totalUsage
            totalIncidentDetected
            isActive
            isDeleted
            deletedKey
            lastUsed
            createdAt
            updatedAt
          }
          incidentNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          sosNotificationSettings
          sosNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          consentCheckbox {
            title
            fileType
            link
            description
            type
          }
          enabledTwoStepVerification
          elasticIndexName
          isAccountVerified
          isActive
          isDeleted
          deletedPartner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getUsersByReferral = /* GraphQL */ `
  query GetUsersByReferral(
    $referralId: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUsersByReferral(
      referralId: $referralId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        referralCode
        referralName
        isReferralRedeem
        email
        emailLower
        profilePicture
        phoneNumber
        countDownDuration
        subscription
        subscriptionLower
        subscriptionDate
        subscriptionExpiryDate
        keepScreenOn
        isFlareOn
        automaticStartOn
        totalBalance
        totalReward
        totalDriversAlerted
        totalDistanceCovered
        totalProtectedJourneys
        totalRideTimeInSeconds
        totalNearMissesReported
        country
        deviceId
        fcmToken
        deviceType
        isEmailVerified
        latitude
        longitude
        partnerId
        retailCodeUsed
        retailCodeUsedLower
        riderActivities
        riderType
        ridingPeriod
        rewardAccessType
        rewardAccessTypeLower
        rewardAccessStartDate
        rewardAccessEndDate
        rewardAccessSubTotal
        rewardAccessVAT
        rewardAccessVATAmount
        rewardAccessTotalAmount
        rewardAccessStripeToken
        rewardAccessStripeReceipt
        isRewardAccessPaymentSuccessful
        rewardAccessMode
        isPaidToAffiliate
        isSmartStartOn
        referralId
        registrationProvider
        activityTimeInSeconds
        totalRewardsRedeemed
        reputationScore
        appVersion
        sweatCoinAuthenticationToken
        lastAccessed
        isSmartStartNotificationEnable
        isMarketPlaceNotificationEnable
        agreedConsents {
          description
          type
          accepted
        }
        isActive
        isDeleted
        deletedUser
        createdAt
        updateAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        owner
        partner {
          id
          invitedByPartnerId
          partnerCode
          dynamicLink
          desktopURL
          companyName
          companyNameLower
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          email
          emailLower
          password
          profilePicture
          companyEmblem
          phone
          addressLine1
          addressLine2
          city
          country
          countryIsoCode
          postcode
          deviceType
          fcmToken
          totalFlaresReceived
          totalFlaresRespondedTo
          totalIncidentsReceived
          totalIncidentsActioned
          latitude
          longitude
          retailCodePrice
          resalePrice
          invoicePayment
          rewardAvailabilityCountries
          referralURL
          referralIntroText
          referralBackgroundImage
          referralRetailPrice
          incidentNotificationSettings
          notificationTitle
          notificationMessage
          notificationPeriod
          hasRiderConfirmed
          iotEndpoint
          enableRewards
          enableReferrals
          enableAppDiscountsHub
          enableFlares
          enableRiderManagement
          enableRetail
          enableAutoStart
          enableEmergencySOS
          enableSdk
          enforceEmergencyContact
          enforceSafeZone
          enforcePhoneNumber
          youtubeLink
          enforceRiderId
          termsAndConditionLink
          privacyPolicyLink
          landingPagePoints {
            text
          }
          sandboxFlareApiKey
          sandboxFlareApiKeyUsage
          productionFlareApiKey
          productionFlareApiKeyUsage
          licenseKeys {
            licenseKey
            keyName
            environment
            totalUsage
            totalIncidentDetected
            isActive
            isDeleted
            deletedKey
            lastUsed
            createdAt
            updatedAt
          }
          incidentNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          sosNotificationSettings
          sosNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          consentCheckbox {
            title
            fileType
            link
            description
            type
          }
          enabledTwoStepVerification
          elasticIndexName
          isAccountVerified
          isActive
          isDeleted
          deletedPartner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getUsersSortedByReferralCode = /* GraphQL */ `
  query GetUsersSortedByReferralCode(
    $deletedUser: String
    $referralCode: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUsersSortedByReferralCode(
      deletedUser: $deletedUser
      referralCode: $referralCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        referralCode
        referralName
        isReferralRedeem
        email
        emailLower
        profilePicture
        phoneNumber
        countDownDuration
        subscription
        subscriptionLower
        subscriptionDate
        subscriptionExpiryDate
        keepScreenOn
        isFlareOn
        automaticStartOn
        totalBalance
        totalReward
        totalDriversAlerted
        totalDistanceCovered
        totalProtectedJourneys
        totalRideTimeInSeconds
        totalNearMissesReported
        country
        deviceId
        fcmToken
        deviceType
        isEmailVerified
        latitude
        longitude
        partnerId
        retailCodeUsed
        retailCodeUsedLower
        riderActivities
        riderType
        ridingPeriod
        rewardAccessType
        rewardAccessTypeLower
        rewardAccessStartDate
        rewardAccessEndDate
        rewardAccessSubTotal
        rewardAccessVAT
        rewardAccessVATAmount
        rewardAccessTotalAmount
        rewardAccessStripeToken
        rewardAccessStripeReceipt
        isRewardAccessPaymentSuccessful
        rewardAccessMode
        isPaidToAffiliate
        isSmartStartOn
        referralId
        registrationProvider
        activityTimeInSeconds
        totalRewardsRedeemed
        reputationScore
        appVersion
        sweatCoinAuthenticationToken
        lastAccessed
        isSmartStartNotificationEnable
        isMarketPlaceNotificationEnable
        agreedConsents {
          description
          type
          accepted
        }
        isActive
        isDeleted
        deletedUser
        createdAt
        updateAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        owner
        partner {
          id
          invitedByPartnerId
          partnerCode
          dynamicLink
          desktopURL
          companyName
          companyNameLower
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          email
          emailLower
          password
          profilePicture
          companyEmblem
          phone
          addressLine1
          addressLine2
          city
          country
          countryIsoCode
          postcode
          deviceType
          fcmToken
          totalFlaresReceived
          totalFlaresRespondedTo
          totalIncidentsReceived
          totalIncidentsActioned
          latitude
          longitude
          retailCodePrice
          resalePrice
          invoicePayment
          rewardAvailabilityCountries
          referralURL
          referralIntroText
          referralBackgroundImage
          referralRetailPrice
          incidentNotificationSettings
          notificationTitle
          notificationMessage
          notificationPeriod
          hasRiderConfirmed
          iotEndpoint
          enableRewards
          enableReferrals
          enableAppDiscountsHub
          enableFlares
          enableRiderManagement
          enableRetail
          enableAutoStart
          enableEmergencySOS
          enableSdk
          enforceEmergencyContact
          enforceSafeZone
          enforcePhoneNumber
          youtubeLink
          enforceRiderId
          termsAndConditionLink
          privacyPolicyLink
          landingPagePoints {
            text
          }
          sandboxFlareApiKey
          sandboxFlareApiKeyUsage
          productionFlareApiKey
          productionFlareApiKeyUsage
          licenseKeys {
            licenseKey
            keyName
            environment
            totalUsage
            totalIncidentDetected
            isActive
            isDeleted
            deletedKey
            lastUsed
            createdAt
            updatedAt
          }
          incidentNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          sosNotificationSettings
          sosNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          consentCheckbox {
            title
            fileType
            link
            description
            type
          }
          enabledTwoStepVerification
          elasticIndexName
          isAccountVerified
          isActive
          isDeleted
          deletedPartner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getUsersSortedByCreatedAt = /* GraphQL */ `
  query GetUsersSortedByCreatedAt(
    $deletedUser: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUsersSortedByCreatedAt(
      deletedUser: $deletedUser
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        referralCode
        referralName
        isReferralRedeem
        email
        emailLower
        profilePicture
        phoneNumber
        countDownDuration
        subscription
        subscriptionLower
        subscriptionDate
        subscriptionExpiryDate
        keepScreenOn
        isFlareOn
        automaticStartOn
        totalBalance
        totalReward
        totalDriversAlerted
        totalDistanceCovered
        totalProtectedJourneys
        totalRideTimeInSeconds
        totalNearMissesReported
        country
        deviceId
        fcmToken
        deviceType
        isEmailVerified
        latitude
        longitude
        partnerId
        retailCodeUsed
        retailCodeUsedLower
        riderActivities
        riderType
        ridingPeriod
        rewardAccessType
        rewardAccessTypeLower
        rewardAccessStartDate
        rewardAccessEndDate
        rewardAccessSubTotal
        rewardAccessVAT
        rewardAccessVATAmount
        rewardAccessTotalAmount
        rewardAccessStripeToken
        rewardAccessStripeReceipt
        isRewardAccessPaymentSuccessful
        rewardAccessMode
        isPaidToAffiliate
        isSmartStartOn
        referralId
        registrationProvider
        activityTimeInSeconds
        totalRewardsRedeemed
        reputationScore
        appVersion
        sweatCoinAuthenticationToken
        lastAccessed
        isSmartStartNotificationEnable
        isMarketPlaceNotificationEnable
        agreedConsents {
          description
          type
          accepted
        }
        isActive
        isDeleted
        deletedUser
        createdAt
        updateAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        owner
        partner {
          id
          invitedByPartnerId
          partnerCode
          dynamicLink
          desktopURL
          companyName
          companyNameLower
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          email
          emailLower
          password
          profilePicture
          companyEmblem
          phone
          addressLine1
          addressLine2
          city
          country
          countryIsoCode
          postcode
          deviceType
          fcmToken
          totalFlaresReceived
          totalFlaresRespondedTo
          totalIncidentsReceived
          totalIncidentsActioned
          latitude
          longitude
          retailCodePrice
          resalePrice
          invoicePayment
          rewardAvailabilityCountries
          referralURL
          referralIntroText
          referralBackgroundImage
          referralRetailPrice
          incidentNotificationSettings
          notificationTitle
          notificationMessage
          notificationPeriod
          hasRiderConfirmed
          iotEndpoint
          enableRewards
          enableReferrals
          enableAppDiscountsHub
          enableFlares
          enableRiderManagement
          enableRetail
          enableAutoStart
          enableEmergencySOS
          enableSdk
          enforceEmergencyContact
          enforceSafeZone
          enforcePhoneNumber
          youtubeLink
          enforceRiderId
          termsAndConditionLink
          privacyPolicyLink
          landingPagePoints {
            text
          }
          sandboxFlareApiKey
          sandboxFlareApiKeyUsage
          productionFlareApiKey
          productionFlareApiKeyUsage
          licenseKeys {
            licenseKey
            keyName
            environment
            totalUsage
            totalIncidentDetected
            isActive
            isDeleted
            deletedKey
            lastUsed
            createdAt
            updatedAt
          }
          incidentNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          sosNotificationSettings
          sosNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          consentCheckbox {
            title
            fileType
            link
            description
            type
          }
          enabledTwoStepVerification
          elasticIndexName
          isAccountVerified
          isActive
          isDeleted
          deletedPartner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getUsersSortedByFirstName = /* GraphQL */ `
  query GetUsersSortedByFirstName(
    $deletedUser: String
    $firstName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUsersSortedByFirstName(
      deletedUser: $deletedUser
      firstName: $firstName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        referralCode
        referralName
        isReferralRedeem
        email
        emailLower
        profilePicture
        phoneNumber
        countDownDuration
        subscription
        subscriptionLower
        subscriptionDate
        subscriptionExpiryDate
        keepScreenOn
        isFlareOn
        automaticStartOn
        totalBalance
        totalReward
        totalDriversAlerted
        totalDistanceCovered
        totalProtectedJourneys
        totalRideTimeInSeconds
        totalNearMissesReported
        country
        deviceId
        fcmToken
        deviceType
        isEmailVerified
        latitude
        longitude
        partnerId
        retailCodeUsed
        retailCodeUsedLower
        riderActivities
        riderType
        ridingPeriod
        rewardAccessType
        rewardAccessTypeLower
        rewardAccessStartDate
        rewardAccessEndDate
        rewardAccessSubTotal
        rewardAccessVAT
        rewardAccessVATAmount
        rewardAccessTotalAmount
        rewardAccessStripeToken
        rewardAccessStripeReceipt
        isRewardAccessPaymentSuccessful
        rewardAccessMode
        isPaidToAffiliate
        isSmartStartOn
        referralId
        registrationProvider
        activityTimeInSeconds
        totalRewardsRedeemed
        reputationScore
        appVersion
        sweatCoinAuthenticationToken
        lastAccessed
        isSmartStartNotificationEnable
        isMarketPlaceNotificationEnable
        agreedConsents {
          description
          type
          accepted
        }
        isActive
        isDeleted
        deletedUser
        createdAt
        updateAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        owner
        partner {
          id
          invitedByPartnerId
          partnerCode
          dynamicLink
          desktopURL
          companyName
          companyNameLower
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          email
          emailLower
          password
          profilePicture
          companyEmblem
          phone
          addressLine1
          addressLine2
          city
          country
          countryIsoCode
          postcode
          deviceType
          fcmToken
          totalFlaresReceived
          totalFlaresRespondedTo
          totalIncidentsReceived
          totalIncidentsActioned
          latitude
          longitude
          retailCodePrice
          resalePrice
          invoicePayment
          rewardAvailabilityCountries
          referralURL
          referralIntroText
          referralBackgroundImage
          referralRetailPrice
          incidentNotificationSettings
          notificationTitle
          notificationMessage
          notificationPeriod
          hasRiderConfirmed
          iotEndpoint
          enableRewards
          enableReferrals
          enableAppDiscountsHub
          enableFlares
          enableRiderManagement
          enableRetail
          enableAutoStart
          enableEmergencySOS
          enableSdk
          enforceEmergencyContact
          enforceSafeZone
          enforcePhoneNumber
          youtubeLink
          enforceRiderId
          termsAndConditionLink
          privacyPolicyLink
          landingPagePoints {
            text
          }
          sandboxFlareApiKey
          sandboxFlareApiKeyUsage
          productionFlareApiKey
          productionFlareApiKeyUsage
          licenseKeys {
            licenseKey
            keyName
            environment
            totalUsage
            totalIncidentDetected
            isActive
            isDeleted
            deletedKey
            lastUsed
            createdAt
            updatedAt
          }
          incidentNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          sosNotificationSettings
          sosNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          consentCheckbox {
            title
            fileType
            link
            description
            type
          }
          enabledTwoStepVerification
          elasticIndexName
          isAccountVerified
          isActive
          isDeleted
          deletedPartner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getUsersSortedByLastName = /* GraphQL */ `
  query GetUsersSortedByLastName(
    $deletedUser: String
    $lastName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUsersSortedByLastName(
      deletedUser: $deletedUser
      lastName: $lastName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        referralCode
        referralName
        isReferralRedeem
        email
        emailLower
        profilePicture
        phoneNumber
        countDownDuration
        subscription
        subscriptionLower
        subscriptionDate
        subscriptionExpiryDate
        keepScreenOn
        isFlareOn
        automaticStartOn
        totalBalance
        totalReward
        totalDriversAlerted
        totalDistanceCovered
        totalProtectedJourneys
        totalRideTimeInSeconds
        totalNearMissesReported
        country
        deviceId
        fcmToken
        deviceType
        isEmailVerified
        latitude
        longitude
        partnerId
        retailCodeUsed
        retailCodeUsedLower
        riderActivities
        riderType
        ridingPeriod
        rewardAccessType
        rewardAccessTypeLower
        rewardAccessStartDate
        rewardAccessEndDate
        rewardAccessSubTotal
        rewardAccessVAT
        rewardAccessVATAmount
        rewardAccessTotalAmount
        rewardAccessStripeToken
        rewardAccessStripeReceipt
        isRewardAccessPaymentSuccessful
        rewardAccessMode
        isPaidToAffiliate
        isSmartStartOn
        referralId
        registrationProvider
        activityTimeInSeconds
        totalRewardsRedeemed
        reputationScore
        appVersion
        sweatCoinAuthenticationToken
        lastAccessed
        isSmartStartNotificationEnable
        isMarketPlaceNotificationEnable
        agreedConsents {
          description
          type
          accepted
        }
        isActive
        isDeleted
        deletedUser
        createdAt
        updateAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        owner
        partner {
          id
          invitedByPartnerId
          partnerCode
          dynamicLink
          desktopURL
          companyName
          companyNameLower
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          email
          emailLower
          password
          profilePicture
          companyEmblem
          phone
          addressLine1
          addressLine2
          city
          country
          countryIsoCode
          postcode
          deviceType
          fcmToken
          totalFlaresReceived
          totalFlaresRespondedTo
          totalIncidentsReceived
          totalIncidentsActioned
          latitude
          longitude
          retailCodePrice
          resalePrice
          invoicePayment
          rewardAvailabilityCountries
          referralURL
          referralIntroText
          referralBackgroundImage
          referralRetailPrice
          incidentNotificationSettings
          notificationTitle
          notificationMessage
          notificationPeriod
          hasRiderConfirmed
          iotEndpoint
          enableRewards
          enableReferrals
          enableAppDiscountsHub
          enableFlares
          enableRiderManagement
          enableRetail
          enableAutoStart
          enableEmergencySOS
          enableSdk
          enforceEmergencyContact
          enforceSafeZone
          enforcePhoneNumber
          youtubeLink
          enforceRiderId
          termsAndConditionLink
          privacyPolicyLink
          landingPagePoints {
            text
          }
          sandboxFlareApiKey
          sandboxFlareApiKeyUsage
          productionFlareApiKey
          productionFlareApiKeyUsage
          licenseKeys {
            licenseKey
            keyName
            environment
            totalUsage
            totalIncidentDetected
            isActive
            isDeleted
            deletedKey
            lastUsed
            createdAt
            updatedAt
          }
          incidentNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          sosNotificationSettings
          sosNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          consentCheckbox {
            title
            fileType
            link
            description
            type
          }
          enabledTwoStepVerification
          elasticIndexName
          isAccountVerified
          isActive
          isDeleted
          deletedPartner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getUsersSortedByUsername = /* GraphQL */ `
  query GetUsersSortedByUsername(
    $deletedUser: String
    $userName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUsersSortedByUsername(
      deletedUser: $deletedUser
      userName: $userName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        referralCode
        referralName
        isReferralRedeem
        email
        emailLower
        profilePicture
        phoneNumber
        countDownDuration
        subscription
        subscriptionLower
        subscriptionDate
        subscriptionExpiryDate
        keepScreenOn
        isFlareOn
        automaticStartOn
        totalBalance
        totalReward
        totalDriversAlerted
        totalDistanceCovered
        totalProtectedJourneys
        totalRideTimeInSeconds
        totalNearMissesReported
        country
        deviceId
        fcmToken
        deviceType
        isEmailVerified
        latitude
        longitude
        partnerId
        retailCodeUsed
        retailCodeUsedLower
        riderActivities
        riderType
        ridingPeriod
        rewardAccessType
        rewardAccessTypeLower
        rewardAccessStartDate
        rewardAccessEndDate
        rewardAccessSubTotal
        rewardAccessVAT
        rewardAccessVATAmount
        rewardAccessTotalAmount
        rewardAccessStripeToken
        rewardAccessStripeReceipt
        isRewardAccessPaymentSuccessful
        rewardAccessMode
        isPaidToAffiliate
        isSmartStartOn
        referralId
        registrationProvider
        activityTimeInSeconds
        totalRewardsRedeemed
        reputationScore
        appVersion
        sweatCoinAuthenticationToken
        lastAccessed
        isSmartStartNotificationEnable
        isMarketPlaceNotificationEnable
        agreedConsents {
          description
          type
          accepted
        }
        isActive
        isDeleted
        deletedUser
        createdAt
        updateAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        owner
        partner {
          id
          invitedByPartnerId
          partnerCode
          dynamicLink
          desktopURL
          companyName
          companyNameLower
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          email
          emailLower
          password
          profilePicture
          companyEmblem
          phone
          addressLine1
          addressLine2
          city
          country
          countryIsoCode
          postcode
          deviceType
          fcmToken
          totalFlaresReceived
          totalFlaresRespondedTo
          totalIncidentsReceived
          totalIncidentsActioned
          latitude
          longitude
          retailCodePrice
          resalePrice
          invoicePayment
          rewardAvailabilityCountries
          referralURL
          referralIntroText
          referralBackgroundImage
          referralRetailPrice
          incidentNotificationSettings
          notificationTitle
          notificationMessage
          notificationPeriod
          hasRiderConfirmed
          iotEndpoint
          enableRewards
          enableReferrals
          enableAppDiscountsHub
          enableFlares
          enableRiderManagement
          enableRetail
          enableAutoStart
          enableEmergencySOS
          enableSdk
          enforceEmergencyContact
          enforceSafeZone
          enforcePhoneNumber
          youtubeLink
          enforceRiderId
          termsAndConditionLink
          privacyPolicyLink
          landingPagePoints {
            text
          }
          sandboxFlareApiKey
          sandboxFlareApiKeyUsage
          productionFlareApiKey
          productionFlareApiKeyUsage
          licenseKeys {
            licenseKey
            keyName
            environment
            totalUsage
            totalIncidentDetected
            isActive
            isDeleted
            deletedKey
            lastUsed
            createdAt
            updatedAt
          }
          incidentNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          sosNotificationSettings
          sosNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          consentCheckbox {
            title
            fileType
            link
            description
            type
          }
          enabledTwoStepVerification
          elasticIndexName
          isAccountVerified
          isActive
          isDeleted
          deletedPartner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getUsersSortedBySubscription = /* GraphQL */ `
  query GetUsersSortedBySubscription(
    $deletedUser: String
    $subscription: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUsersSortedBySubscription(
      deletedUser: $deletedUser
      subscription: $subscription
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        referralCode
        referralName
        isReferralRedeem
        email
        emailLower
        profilePicture
        phoneNumber
        countDownDuration
        subscription
        subscriptionLower
        subscriptionDate
        subscriptionExpiryDate
        keepScreenOn
        isFlareOn
        automaticStartOn
        totalBalance
        totalReward
        totalDriversAlerted
        totalDistanceCovered
        totalProtectedJourneys
        totalRideTimeInSeconds
        totalNearMissesReported
        country
        deviceId
        fcmToken
        deviceType
        isEmailVerified
        latitude
        longitude
        partnerId
        retailCodeUsed
        retailCodeUsedLower
        riderActivities
        riderType
        ridingPeriod
        rewardAccessType
        rewardAccessTypeLower
        rewardAccessStartDate
        rewardAccessEndDate
        rewardAccessSubTotal
        rewardAccessVAT
        rewardAccessVATAmount
        rewardAccessTotalAmount
        rewardAccessStripeToken
        rewardAccessStripeReceipt
        isRewardAccessPaymentSuccessful
        rewardAccessMode
        isPaidToAffiliate
        isSmartStartOn
        referralId
        registrationProvider
        activityTimeInSeconds
        totalRewardsRedeemed
        reputationScore
        appVersion
        sweatCoinAuthenticationToken
        lastAccessed
        isSmartStartNotificationEnable
        isMarketPlaceNotificationEnable
        agreedConsents {
          description
          type
          accepted
        }
        isActive
        isDeleted
        deletedUser
        createdAt
        updateAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        owner
        partner {
          id
          invitedByPartnerId
          partnerCode
          dynamicLink
          desktopURL
          companyName
          companyNameLower
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          email
          emailLower
          password
          profilePicture
          companyEmblem
          phone
          addressLine1
          addressLine2
          city
          country
          countryIsoCode
          postcode
          deviceType
          fcmToken
          totalFlaresReceived
          totalFlaresRespondedTo
          totalIncidentsReceived
          totalIncidentsActioned
          latitude
          longitude
          retailCodePrice
          resalePrice
          invoicePayment
          rewardAvailabilityCountries
          referralURL
          referralIntroText
          referralBackgroundImage
          referralRetailPrice
          incidentNotificationSettings
          notificationTitle
          notificationMessage
          notificationPeriod
          hasRiderConfirmed
          iotEndpoint
          enableRewards
          enableReferrals
          enableAppDiscountsHub
          enableFlares
          enableRiderManagement
          enableRetail
          enableAutoStart
          enableEmergencySOS
          enableSdk
          enforceEmergencyContact
          enforceSafeZone
          enforcePhoneNumber
          youtubeLink
          enforceRiderId
          termsAndConditionLink
          privacyPolicyLink
          landingPagePoints {
            text
          }
          sandboxFlareApiKey
          sandboxFlareApiKeyUsage
          productionFlareApiKey
          productionFlareApiKeyUsage
          licenseKeys {
            licenseKey
            keyName
            environment
            totalUsage
            totalIncidentDetected
            isActive
            isDeleted
            deletedKey
            lastUsed
            createdAt
            updatedAt
          }
          incidentNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          sosNotificationSettings
          sosNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          consentCheckbox {
            title
            fileType
            link
            description
            type
          }
          enabledTwoStepVerification
          elasticIndexName
          isAccountVerified
          isActive
          isDeleted
          deletedPartner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getUsersSortedByEmail = /* GraphQL */ `
  query GetUsersSortedByEmail(
    $deletedUser: String
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUsersSortedByEmail(
      deletedUser: $deletedUser
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        referralCode
        referralName
        isReferralRedeem
        email
        emailLower
        profilePicture
        phoneNumber
        countDownDuration
        subscription
        subscriptionLower
        subscriptionDate
        subscriptionExpiryDate
        keepScreenOn
        isFlareOn
        automaticStartOn
        totalBalance
        totalReward
        totalDriversAlerted
        totalDistanceCovered
        totalProtectedJourneys
        totalRideTimeInSeconds
        totalNearMissesReported
        country
        deviceId
        fcmToken
        deviceType
        isEmailVerified
        latitude
        longitude
        partnerId
        retailCodeUsed
        retailCodeUsedLower
        riderActivities
        riderType
        ridingPeriod
        rewardAccessType
        rewardAccessTypeLower
        rewardAccessStartDate
        rewardAccessEndDate
        rewardAccessSubTotal
        rewardAccessVAT
        rewardAccessVATAmount
        rewardAccessTotalAmount
        rewardAccessStripeToken
        rewardAccessStripeReceipt
        isRewardAccessPaymentSuccessful
        rewardAccessMode
        isPaidToAffiliate
        isSmartStartOn
        referralId
        registrationProvider
        activityTimeInSeconds
        totalRewardsRedeemed
        reputationScore
        appVersion
        sweatCoinAuthenticationToken
        lastAccessed
        isSmartStartNotificationEnable
        isMarketPlaceNotificationEnable
        agreedConsents {
          description
          type
          accepted
        }
        isActive
        isDeleted
        deletedUser
        createdAt
        updateAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        owner
        partner {
          id
          invitedByPartnerId
          partnerCode
          dynamicLink
          desktopURL
          companyName
          companyNameLower
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          email
          emailLower
          password
          profilePicture
          companyEmblem
          phone
          addressLine1
          addressLine2
          city
          country
          countryIsoCode
          postcode
          deviceType
          fcmToken
          totalFlaresReceived
          totalFlaresRespondedTo
          totalIncidentsReceived
          totalIncidentsActioned
          latitude
          longitude
          retailCodePrice
          resalePrice
          invoicePayment
          rewardAvailabilityCountries
          referralURL
          referralIntroText
          referralBackgroundImage
          referralRetailPrice
          incidentNotificationSettings
          notificationTitle
          notificationMessage
          notificationPeriod
          hasRiderConfirmed
          iotEndpoint
          enableRewards
          enableReferrals
          enableAppDiscountsHub
          enableFlares
          enableRiderManagement
          enableRetail
          enableAutoStart
          enableEmergencySOS
          enableSdk
          enforceEmergencyContact
          enforceSafeZone
          enforcePhoneNumber
          youtubeLink
          enforceRiderId
          termsAndConditionLink
          privacyPolicyLink
          landingPagePoints {
            text
          }
          sandboxFlareApiKey
          sandboxFlareApiKeyUsage
          productionFlareApiKey
          productionFlareApiKeyUsage
          licenseKeys {
            licenseKey
            keyName
            environment
            totalUsage
            totalIncidentDetected
            isActive
            isDeleted
            deletedKey
            lastUsed
            createdAt
            updatedAt
          }
          incidentNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          sosNotificationSettings
          sosNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          consentCheckbox {
            title
            fileType
            link
            description
            type
          }
          enabledTwoStepVerification
          elasticIndexName
          isAccountVerified
          isActive
          isDeleted
          deletedPartner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getUsersSortedByActivityTimeInSeconds = /* GraphQL */ `
  query GetUsersSortedByActivityTimeInSeconds(
    $deletedUser: String
    $activityTimeInSeconds: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUsersSortedByActivityTimeInSeconds(
      deletedUser: $deletedUser
      activityTimeInSeconds: $activityTimeInSeconds
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        referralCode
        referralName
        isReferralRedeem
        email
        emailLower
        profilePicture
        phoneNumber
        countDownDuration
        subscription
        subscriptionLower
        subscriptionDate
        subscriptionExpiryDate
        keepScreenOn
        isFlareOn
        automaticStartOn
        totalBalance
        totalReward
        totalDriversAlerted
        totalDistanceCovered
        totalProtectedJourneys
        totalRideTimeInSeconds
        totalNearMissesReported
        country
        deviceId
        fcmToken
        deviceType
        isEmailVerified
        latitude
        longitude
        partnerId
        retailCodeUsed
        retailCodeUsedLower
        riderActivities
        riderType
        ridingPeriod
        rewardAccessType
        rewardAccessTypeLower
        rewardAccessStartDate
        rewardAccessEndDate
        rewardAccessSubTotal
        rewardAccessVAT
        rewardAccessVATAmount
        rewardAccessTotalAmount
        rewardAccessStripeToken
        rewardAccessStripeReceipt
        isRewardAccessPaymentSuccessful
        rewardAccessMode
        isPaidToAffiliate
        isSmartStartOn
        referralId
        registrationProvider
        activityTimeInSeconds
        totalRewardsRedeemed
        reputationScore
        appVersion
        sweatCoinAuthenticationToken
        lastAccessed
        isSmartStartNotificationEnable
        isMarketPlaceNotificationEnable
        agreedConsents {
          description
          type
          accepted
        }
        isActive
        isDeleted
        deletedUser
        createdAt
        updateAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        owner
        partner {
          id
          invitedByPartnerId
          partnerCode
          dynamicLink
          desktopURL
          companyName
          companyNameLower
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          email
          emailLower
          password
          profilePicture
          companyEmblem
          phone
          addressLine1
          addressLine2
          city
          country
          countryIsoCode
          postcode
          deviceType
          fcmToken
          totalFlaresReceived
          totalFlaresRespondedTo
          totalIncidentsReceived
          totalIncidentsActioned
          latitude
          longitude
          retailCodePrice
          resalePrice
          invoicePayment
          rewardAvailabilityCountries
          referralURL
          referralIntroText
          referralBackgroundImage
          referralRetailPrice
          incidentNotificationSettings
          notificationTitle
          notificationMessage
          notificationPeriod
          hasRiderConfirmed
          iotEndpoint
          enableRewards
          enableReferrals
          enableAppDiscountsHub
          enableFlares
          enableRiderManagement
          enableRetail
          enableAutoStart
          enableEmergencySOS
          enableSdk
          enforceEmergencyContact
          enforceSafeZone
          enforcePhoneNumber
          youtubeLink
          enforceRiderId
          termsAndConditionLink
          privacyPolicyLink
          landingPagePoints {
            text
          }
          sandboxFlareApiKey
          sandboxFlareApiKeyUsage
          productionFlareApiKey
          productionFlareApiKeyUsage
          licenseKeys {
            licenseKey
            keyName
            environment
            totalUsage
            totalIncidentDetected
            isActive
            isDeleted
            deletedKey
            lastUsed
            createdAt
            updatedAt
          }
          incidentNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          sosNotificationSettings
          sosNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          consentCheckbox {
            title
            fileType
            link
            description
            type
          }
          enabledTwoStepVerification
          elasticIndexName
          isAccountVerified
          isActive
          isDeleted
          deletedPartner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getUsersSortedByLastAccessed = /* GraphQL */ `
  query GetUsersSortedByLastAccessed(
    $deletedUser: String
    $lastAccessed: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUsersSortedByLastAccessed(
      deletedUser: $deletedUser
      lastAccessed: $lastAccessed
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        referralCode
        referralName
        isReferralRedeem
        email
        emailLower
        profilePicture
        phoneNumber
        countDownDuration
        subscription
        subscriptionLower
        subscriptionDate
        subscriptionExpiryDate
        keepScreenOn
        isFlareOn
        automaticStartOn
        totalBalance
        totalReward
        totalDriversAlerted
        totalDistanceCovered
        totalProtectedJourneys
        totalRideTimeInSeconds
        totalNearMissesReported
        country
        deviceId
        fcmToken
        deviceType
        isEmailVerified
        latitude
        longitude
        partnerId
        retailCodeUsed
        retailCodeUsedLower
        riderActivities
        riderType
        ridingPeriod
        rewardAccessType
        rewardAccessTypeLower
        rewardAccessStartDate
        rewardAccessEndDate
        rewardAccessSubTotal
        rewardAccessVAT
        rewardAccessVATAmount
        rewardAccessTotalAmount
        rewardAccessStripeToken
        rewardAccessStripeReceipt
        isRewardAccessPaymentSuccessful
        rewardAccessMode
        isPaidToAffiliate
        isSmartStartOn
        referralId
        registrationProvider
        activityTimeInSeconds
        totalRewardsRedeemed
        reputationScore
        appVersion
        sweatCoinAuthenticationToken
        lastAccessed
        isSmartStartNotificationEnable
        isMarketPlaceNotificationEnable
        agreedConsents {
          description
          type
          accepted
        }
        isActive
        isDeleted
        deletedUser
        createdAt
        updateAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        owner
        partner {
          id
          invitedByPartnerId
          partnerCode
          dynamicLink
          desktopURL
          companyName
          companyNameLower
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          email
          emailLower
          password
          profilePicture
          companyEmblem
          phone
          addressLine1
          addressLine2
          city
          country
          countryIsoCode
          postcode
          deviceType
          fcmToken
          totalFlaresReceived
          totalFlaresRespondedTo
          totalIncidentsReceived
          totalIncidentsActioned
          latitude
          longitude
          retailCodePrice
          resalePrice
          invoicePayment
          rewardAvailabilityCountries
          referralURL
          referralIntroText
          referralBackgroundImage
          referralRetailPrice
          incidentNotificationSettings
          notificationTitle
          notificationMessage
          notificationPeriod
          hasRiderConfirmed
          iotEndpoint
          enableRewards
          enableReferrals
          enableAppDiscountsHub
          enableFlares
          enableRiderManagement
          enableRetail
          enableAutoStart
          enableEmergencySOS
          enableSdk
          enforceEmergencyContact
          enforceSafeZone
          enforcePhoneNumber
          youtubeLink
          enforceRiderId
          termsAndConditionLink
          privacyPolicyLink
          landingPagePoints {
            text
          }
          sandboxFlareApiKey
          sandboxFlareApiKeyUsage
          productionFlareApiKey
          productionFlareApiKeyUsage
          licenseKeys {
            licenseKey
            keyName
            environment
            totalUsage
            totalIncidentDetected
            isActive
            isDeleted
            deletedKey
            lastUsed
            createdAt
            updatedAt
          }
          incidentNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          sosNotificationSettings
          sosNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          consentCheckbox {
            title
            fileType
            link
            description
            type
          }
          enabledTwoStepVerification
          elasticIndexName
          isAccountVerified
          isActive
          isDeleted
          deletedPartner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getUsersByPartnerIdCreatedAt = /* GraphQL */ `
  query GetUsersByPartnerIdCreatedAt(
    $partnerId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUsersByPartnerIdCreatedAt(
      partnerId: $partnerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        referralCode
        referralName
        isReferralRedeem
        email
        emailLower
        profilePicture
        phoneNumber
        countDownDuration
        subscription
        subscriptionLower
        subscriptionDate
        subscriptionExpiryDate
        keepScreenOn
        isFlareOn
        automaticStartOn
        totalBalance
        totalReward
        totalDriversAlerted
        totalDistanceCovered
        totalProtectedJourneys
        totalRideTimeInSeconds
        totalNearMissesReported
        country
        deviceId
        fcmToken
        deviceType
        isEmailVerified
        latitude
        longitude
        partnerId
        retailCodeUsed
        retailCodeUsedLower
        riderActivities
        riderType
        ridingPeriod
        rewardAccessType
        rewardAccessTypeLower
        rewardAccessStartDate
        rewardAccessEndDate
        rewardAccessSubTotal
        rewardAccessVAT
        rewardAccessVATAmount
        rewardAccessTotalAmount
        rewardAccessStripeToken
        rewardAccessStripeReceipt
        isRewardAccessPaymentSuccessful
        rewardAccessMode
        isPaidToAffiliate
        isSmartStartOn
        referralId
        registrationProvider
        activityTimeInSeconds
        totalRewardsRedeemed
        reputationScore
        appVersion
        sweatCoinAuthenticationToken
        lastAccessed
        isSmartStartNotificationEnable
        isMarketPlaceNotificationEnable
        agreedConsents {
          description
          type
          accepted
        }
        isActive
        isDeleted
        deletedUser
        createdAt
        updateAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        owner
        partner {
          id
          invitedByPartnerId
          partnerCode
          dynamicLink
          desktopURL
          companyName
          companyNameLower
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          email
          emailLower
          password
          profilePicture
          companyEmblem
          phone
          addressLine1
          addressLine2
          city
          country
          countryIsoCode
          postcode
          deviceType
          fcmToken
          totalFlaresReceived
          totalFlaresRespondedTo
          totalIncidentsReceived
          totalIncidentsActioned
          latitude
          longitude
          retailCodePrice
          resalePrice
          invoicePayment
          rewardAvailabilityCountries
          referralURL
          referralIntroText
          referralBackgroundImage
          referralRetailPrice
          incidentNotificationSettings
          notificationTitle
          notificationMessage
          notificationPeriod
          hasRiderConfirmed
          iotEndpoint
          enableRewards
          enableReferrals
          enableAppDiscountsHub
          enableFlares
          enableRiderManagement
          enableRetail
          enableAutoStart
          enableEmergencySOS
          enableSdk
          enforceEmergencyContact
          enforceSafeZone
          enforcePhoneNumber
          youtubeLink
          enforceRiderId
          termsAndConditionLink
          privacyPolicyLink
          landingPagePoints {
            text
          }
          sandboxFlareApiKey
          sandboxFlareApiKeyUsage
          productionFlareApiKey
          productionFlareApiKeyUsage
          licenseKeys {
            licenseKey
            keyName
            environment
            totalUsage
            totalIncidentDetected
            isActive
            isDeleted
            deletedKey
            lastUsed
            createdAt
            updatedAt
          }
          incidentNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          sosNotificationSettings
          sosNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          consentCheckbox {
            title
            fileType
            link
            description
            type
          }
          enabledTwoStepVerification
          elasticIndexName
          isAccountVerified
          isActive
          isDeleted
          deletedPartner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getUsersByPartnerIdFirstName = /* GraphQL */ `
  query GetUsersByPartnerIdFirstName(
    $partnerId: String
    $firstName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUsersByPartnerIdFirstName(
      partnerId: $partnerId
      firstName: $firstName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        referralCode
        referralName
        isReferralRedeem
        email
        emailLower
        profilePicture
        phoneNumber
        countDownDuration
        subscription
        subscriptionLower
        subscriptionDate
        subscriptionExpiryDate
        keepScreenOn
        isFlareOn
        automaticStartOn
        totalBalance
        totalReward
        totalDriversAlerted
        totalDistanceCovered
        totalProtectedJourneys
        totalRideTimeInSeconds
        totalNearMissesReported
        country
        deviceId
        fcmToken
        deviceType
        isEmailVerified
        latitude
        longitude
        partnerId
        retailCodeUsed
        retailCodeUsedLower
        riderActivities
        riderType
        ridingPeriod
        rewardAccessType
        rewardAccessTypeLower
        rewardAccessStartDate
        rewardAccessEndDate
        rewardAccessSubTotal
        rewardAccessVAT
        rewardAccessVATAmount
        rewardAccessTotalAmount
        rewardAccessStripeToken
        rewardAccessStripeReceipt
        isRewardAccessPaymentSuccessful
        rewardAccessMode
        isPaidToAffiliate
        isSmartStartOn
        referralId
        registrationProvider
        activityTimeInSeconds
        totalRewardsRedeemed
        reputationScore
        appVersion
        sweatCoinAuthenticationToken
        lastAccessed
        isSmartStartNotificationEnable
        isMarketPlaceNotificationEnable
        agreedConsents {
          description
          type
          accepted
        }
        isActive
        isDeleted
        deletedUser
        createdAt
        updateAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        owner
        partner {
          id
          invitedByPartnerId
          partnerCode
          dynamicLink
          desktopURL
          companyName
          companyNameLower
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          email
          emailLower
          password
          profilePicture
          companyEmblem
          phone
          addressLine1
          addressLine2
          city
          country
          countryIsoCode
          postcode
          deviceType
          fcmToken
          totalFlaresReceived
          totalFlaresRespondedTo
          totalIncidentsReceived
          totalIncidentsActioned
          latitude
          longitude
          retailCodePrice
          resalePrice
          invoicePayment
          rewardAvailabilityCountries
          referralURL
          referralIntroText
          referralBackgroundImage
          referralRetailPrice
          incidentNotificationSettings
          notificationTitle
          notificationMessage
          notificationPeriod
          hasRiderConfirmed
          iotEndpoint
          enableRewards
          enableReferrals
          enableAppDiscountsHub
          enableFlares
          enableRiderManagement
          enableRetail
          enableAutoStart
          enableEmergencySOS
          enableSdk
          enforceEmergencyContact
          enforceSafeZone
          enforcePhoneNumber
          youtubeLink
          enforceRiderId
          termsAndConditionLink
          privacyPolicyLink
          landingPagePoints {
            text
          }
          sandboxFlareApiKey
          sandboxFlareApiKeyUsage
          productionFlareApiKey
          productionFlareApiKeyUsage
          licenseKeys {
            licenseKey
            keyName
            environment
            totalUsage
            totalIncidentDetected
            isActive
            isDeleted
            deletedKey
            lastUsed
            createdAt
            updatedAt
          }
          incidentNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          sosNotificationSettings
          sosNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          consentCheckbox {
            title
            fileType
            link
            description
            type
          }
          enabledTwoStepVerification
          elasticIndexName
          isAccountVerified
          isActive
          isDeleted
          deletedPartner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getUsersByPartnerIdLastName = /* GraphQL */ `
  query GetUsersByPartnerIdLastName(
    $partnerId: String
    $lastName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUsersByPartnerIdLastName(
      partnerId: $partnerId
      lastName: $lastName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        referralCode
        referralName
        isReferralRedeem
        email
        emailLower
        profilePicture
        phoneNumber
        countDownDuration
        subscription
        subscriptionLower
        subscriptionDate
        subscriptionExpiryDate
        keepScreenOn
        isFlareOn
        automaticStartOn
        totalBalance
        totalReward
        totalDriversAlerted
        totalDistanceCovered
        totalProtectedJourneys
        totalRideTimeInSeconds
        totalNearMissesReported
        country
        deviceId
        fcmToken
        deviceType
        isEmailVerified
        latitude
        longitude
        partnerId
        retailCodeUsed
        retailCodeUsedLower
        riderActivities
        riderType
        ridingPeriod
        rewardAccessType
        rewardAccessTypeLower
        rewardAccessStartDate
        rewardAccessEndDate
        rewardAccessSubTotal
        rewardAccessVAT
        rewardAccessVATAmount
        rewardAccessTotalAmount
        rewardAccessStripeToken
        rewardAccessStripeReceipt
        isRewardAccessPaymentSuccessful
        rewardAccessMode
        isPaidToAffiliate
        isSmartStartOn
        referralId
        registrationProvider
        activityTimeInSeconds
        totalRewardsRedeemed
        reputationScore
        appVersion
        sweatCoinAuthenticationToken
        lastAccessed
        isSmartStartNotificationEnable
        isMarketPlaceNotificationEnable
        agreedConsents {
          description
          type
          accepted
        }
        isActive
        isDeleted
        deletedUser
        createdAt
        updateAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        owner
        partner {
          id
          invitedByPartnerId
          partnerCode
          dynamicLink
          desktopURL
          companyName
          companyNameLower
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          email
          emailLower
          password
          profilePicture
          companyEmblem
          phone
          addressLine1
          addressLine2
          city
          country
          countryIsoCode
          postcode
          deviceType
          fcmToken
          totalFlaresReceived
          totalFlaresRespondedTo
          totalIncidentsReceived
          totalIncidentsActioned
          latitude
          longitude
          retailCodePrice
          resalePrice
          invoicePayment
          rewardAvailabilityCountries
          referralURL
          referralIntroText
          referralBackgroundImage
          referralRetailPrice
          incidentNotificationSettings
          notificationTitle
          notificationMessage
          notificationPeriod
          hasRiderConfirmed
          iotEndpoint
          enableRewards
          enableReferrals
          enableAppDiscountsHub
          enableFlares
          enableRiderManagement
          enableRetail
          enableAutoStart
          enableEmergencySOS
          enableSdk
          enforceEmergencyContact
          enforceSafeZone
          enforcePhoneNumber
          youtubeLink
          enforceRiderId
          termsAndConditionLink
          privacyPolicyLink
          landingPagePoints {
            text
          }
          sandboxFlareApiKey
          sandboxFlareApiKeyUsage
          productionFlareApiKey
          productionFlareApiKeyUsage
          licenseKeys {
            licenseKey
            keyName
            environment
            totalUsage
            totalIncidentDetected
            isActive
            isDeleted
            deletedKey
            lastUsed
            createdAt
            updatedAt
          }
          incidentNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          sosNotificationSettings
          sosNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          consentCheckbox {
            title
            fileType
            link
            description
            type
          }
          enabledTwoStepVerification
          elasticIndexName
          isAccountVerified
          isActive
          isDeleted
          deletedPartner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getUsersByPartnerIdUsername = /* GraphQL */ `
  query GetUsersByPartnerIdUsername(
    $partnerId: String
    $userName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUsersByPartnerIdUsername(
      partnerId: $partnerId
      userName: $userName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        referralCode
        referralName
        isReferralRedeem
        email
        emailLower
        profilePicture
        phoneNumber
        countDownDuration
        subscription
        subscriptionLower
        subscriptionDate
        subscriptionExpiryDate
        keepScreenOn
        isFlareOn
        automaticStartOn
        totalBalance
        totalReward
        totalDriversAlerted
        totalDistanceCovered
        totalProtectedJourneys
        totalRideTimeInSeconds
        totalNearMissesReported
        country
        deviceId
        fcmToken
        deviceType
        isEmailVerified
        latitude
        longitude
        partnerId
        retailCodeUsed
        retailCodeUsedLower
        riderActivities
        riderType
        ridingPeriod
        rewardAccessType
        rewardAccessTypeLower
        rewardAccessStartDate
        rewardAccessEndDate
        rewardAccessSubTotal
        rewardAccessVAT
        rewardAccessVATAmount
        rewardAccessTotalAmount
        rewardAccessStripeToken
        rewardAccessStripeReceipt
        isRewardAccessPaymentSuccessful
        rewardAccessMode
        isPaidToAffiliate
        isSmartStartOn
        referralId
        registrationProvider
        activityTimeInSeconds
        totalRewardsRedeemed
        reputationScore
        appVersion
        sweatCoinAuthenticationToken
        lastAccessed
        isSmartStartNotificationEnable
        isMarketPlaceNotificationEnable
        agreedConsents {
          description
          type
          accepted
        }
        isActive
        isDeleted
        deletedUser
        createdAt
        updateAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        owner
        partner {
          id
          invitedByPartnerId
          partnerCode
          dynamicLink
          desktopURL
          companyName
          companyNameLower
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          email
          emailLower
          password
          profilePicture
          companyEmblem
          phone
          addressLine1
          addressLine2
          city
          country
          countryIsoCode
          postcode
          deviceType
          fcmToken
          totalFlaresReceived
          totalFlaresRespondedTo
          totalIncidentsReceived
          totalIncidentsActioned
          latitude
          longitude
          retailCodePrice
          resalePrice
          invoicePayment
          rewardAvailabilityCountries
          referralURL
          referralIntroText
          referralBackgroundImage
          referralRetailPrice
          incidentNotificationSettings
          notificationTitle
          notificationMessage
          notificationPeriod
          hasRiderConfirmed
          iotEndpoint
          enableRewards
          enableReferrals
          enableAppDiscountsHub
          enableFlares
          enableRiderManagement
          enableRetail
          enableAutoStart
          enableEmergencySOS
          enableSdk
          enforceEmergencyContact
          enforceSafeZone
          enforcePhoneNumber
          youtubeLink
          enforceRiderId
          termsAndConditionLink
          privacyPolicyLink
          landingPagePoints {
            text
          }
          sandboxFlareApiKey
          sandboxFlareApiKeyUsage
          productionFlareApiKey
          productionFlareApiKeyUsage
          licenseKeys {
            licenseKey
            keyName
            environment
            totalUsage
            totalIncidentDetected
            isActive
            isDeleted
            deletedKey
            lastUsed
            createdAt
            updatedAt
          }
          incidentNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          sosNotificationSettings
          sosNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          consentCheckbox {
            title
            fileType
            link
            description
            type
          }
          enabledTwoStepVerification
          elasticIndexName
          isAccountVerified
          isActive
          isDeleted
          deletedPartner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getUsersByPartnerIdSubscription = /* GraphQL */ `
  query GetUsersByPartnerIdSubscription(
    $partnerId: String
    $subscription: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUsersByPartnerIdSubscription(
      partnerId: $partnerId
      subscription: $subscription
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        referralCode
        referralName
        isReferralRedeem
        email
        emailLower
        profilePicture
        phoneNumber
        countDownDuration
        subscription
        subscriptionLower
        subscriptionDate
        subscriptionExpiryDate
        keepScreenOn
        isFlareOn
        automaticStartOn
        totalBalance
        totalReward
        totalDriversAlerted
        totalDistanceCovered
        totalProtectedJourneys
        totalRideTimeInSeconds
        totalNearMissesReported
        country
        deviceId
        fcmToken
        deviceType
        isEmailVerified
        latitude
        longitude
        partnerId
        retailCodeUsed
        retailCodeUsedLower
        riderActivities
        riderType
        ridingPeriod
        rewardAccessType
        rewardAccessTypeLower
        rewardAccessStartDate
        rewardAccessEndDate
        rewardAccessSubTotal
        rewardAccessVAT
        rewardAccessVATAmount
        rewardAccessTotalAmount
        rewardAccessStripeToken
        rewardAccessStripeReceipt
        isRewardAccessPaymentSuccessful
        rewardAccessMode
        isPaidToAffiliate
        isSmartStartOn
        referralId
        registrationProvider
        activityTimeInSeconds
        totalRewardsRedeemed
        reputationScore
        appVersion
        sweatCoinAuthenticationToken
        lastAccessed
        isSmartStartNotificationEnable
        isMarketPlaceNotificationEnable
        agreedConsents {
          description
          type
          accepted
        }
        isActive
        isDeleted
        deletedUser
        createdAt
        updateAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        owner
        partner {
          id
          invitedByPartnerId
          partnerCode
          dynamicLink
          desktopURL
          companyName
          companyNameLower
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          email
          emailLower
          password
          profilePicture
          companyEmblem
          phone
          addressLine1
          addressLine2
          city
          country
          countryIsoCode
          postcode
          deviceType
          fcmToken
          totalFlaresReceived
          totalFlaresRespondedTo
          totalIncidentsReceived
          totalIncidentsActioned
          latitude
          longitude
          retailCodePrice
          resalePrice
          invoicePayment
          rewardAvailabilityCountries
          referralURL
          referralIntroText
          referralBackgroundImage
          referralRetailPrice
          incidentNotificationSettings
          notificationTitle
          notificationMessage
          notificationPeriod
          hasRiderConfirmed
          iotEndpoint
          enableRewards
          enableReferrals
          enableAppDiscountsHub
          enableFlares
          enableRiderManagement
          enableRetail
          enableAutoStart
          enableEmergencySOS
          enableSdk
          enforceEmergencyContact
          enforceSafeZone
          enforcePhoneNumber
          youtubeLink
          enforceRiderId
          termsAndConditionLink
          privacyPolicyLink
          landingPagePoints {
            text
          }
          sandboxFlareApiKey
          sandboxFlareApiKeyUsage
          productionFlareApiKey
          productionFlareApiKeyUsage
          licenseKeys {
            licenseKey
            keyName
            environment
            totalUsage
            totalIncidentDetected
            isActive
            isDeleted
            deletedKey
            lastUsed
            createdAt
            updatedAt
          }
          incidentNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          sosNotificationSettings
          sosNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          consentCheckbox {
            title
            fileType
            link
            description
            type
          }
          enabledTwoStepVerification
          elasticIndexName
          isAccountVerified
          isActive
          isDeleted
          deletedPartner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getUsersByPartnerIdEmail = /* GraphQL */ `
  query GetUsersByPartnerIdEmail(
    $partnerId: String
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUsersByPartnerIdEmail(
      partnerId: $partnerId
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        referralCode
        referralName
        isReferralRedeem
        email
        emailLower
        profilePicture
        phoneNumber
        countDownDuration
        subscription
        subscriptionLower
        subscriptionDate
        subscriptionExpiryDate
        keepScreenOn
        isFlareOn
        automaticStartOn
        totalBalance
        totalReward
        totalDriversAlerted
        totalDistanceCovered
        totalProtectedJourneys
        totalRideTimeInSeconds
        totalNearMissesReported
        country
        deviceId
        fcmToken
        deviceType
        isEmailVerified
        latitude
        longitude
        partnerId
        retailCodeUsed
        retailCodeUsedLower
        riderActivities
        riderType
        ridingPeriod
        rewardAccessType
        rewardAccessTypeLower
        rewardAccessStartDate
        rewardAccessEndDate
        rewardAccessSubTotal
        rewardAccessVAT
        rewardAccessVATAmount
        rewardAccessTotalAmount
        rewardAccessStripeToken
        rewardAccessStripeReceipt
        isRewardAccessPaymentSuccessful
        rewardAccessMode
        isPaidToAffiliate
        isSmartStartOn
        referralId
        registrationProvider
        activityTimeInSeconds
        totalRewardsRedeemed
        reputationScore
        appVersion
        sweatCoinAuthenticationToken
        lastAccessed
        isSmartStartNotificationEnable
        isMarketPlaceNotificationEnable
        agreedConsents {
          description
          type
          accepted
        }
        isActive
        isDeleted
        deletedUser
        createdAt
        updateAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        owner
        partner {
          id
          invitedByPartnerId
          partnerCode
          dynamicLink
          desktopURL
          companyName
          companyNameLower
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          email
          emailLower
          password
          profilePicture
          companyEmblem
          phone
          addressLine1
          addressLine2
          city
          country
          countryIsoCode
          postcode
          deviceType
          fcmToken
          totalFlaresReceived
          totalFlaresRespondedTo
          totalIncidentsReceived
          totalIncidentsActioned
          latitude
          longitude
          retailCodePrice
          resalePrice
          invoicePayment
          rewardAvailabilityCountries
          referralURL
          referralIntroText
          referralBackgroundImage
          referralRetailPrice
          incidentNotificationSettings
          notificationTitle
          notificationMessage
          notificationPeriod
          hasRiderConfirmed
          iotEndpoint
          enableRewards
          enableReferrals
          enableAppDiscountsHub
          enableFlares
          enableRiderManagement
          enableRetail
          enableAutoStart
          enableEmergencySOS
          enableSdk
          enforceEmergencyContact
          enforceSafeZone
          enforcePhoneNumber
          youtubeLink
          enforceRiderId
          termsAndConditionLink
          privacyPolicyLink
          landingPagePoints {
            text
          }
          sandboxFlareApiKey
          sandboxFlareApiKeyUsage
          productionFlareApiKey
          productionFlareApiKeyUsage
          licenseKeys {
            licenseKey
            keyName
            environment
            totalUsage
            totalIncidentDetected
            isActive
            isDeleted
            deletedKey
            lastUsed
            createdAt
            updatedAt
          }
          incidentNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          sosNotificationSettings
          sosNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          consentCheckbox {
            title
            fileType
            link
            description
            type
          }
          enabledTwoStepVerification
          elasticIndexName
          isAccountVerified
          isActive
          isDeleted
          deletedPartner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getUsersByPartnerIdActivityTimeInSeconds = /* GraphQL */ `
  query GetUsersByPartnerIdActivityTimeInSeconds(
    $partnerId: String
    $activityTimeInSeconds: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUsersByPartnerIdActivityTimeInSeconds(
      partnerId: $partnerId
      activityTimeInSeconds: $activityTimeInSeconds
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        referralCode
        referralName
        isReferralRedeem
        email
        emailLower
        profilePicture
        phoneNumber
        countDownDuration
        subscription
        subscriptionLower
        subscriptionDate
        subscriptionExpiryDate
        keepScreenOn
        isFlareOn
        automaticStartOn
        totalBalance
        totalReward
        totalDriversAlerted
        totalDistanceCovered
        totalProtectedJourneys
        totalRideTimeInSeconds
        totalNearMissesReported
        country
        deviceId
        fcmToken
        deviceType
        isEmailVerified
        latitude
        longitude
        partnerId
        retailCodeUsed
        retailCodeUsedLower
        riderActivities
        riderType
        ridingPeriod
        rewardAccessType
        rewardAccessTypeLower
        rewardAccessStartDate
        rewardAccessEndDate
        rewardAccessSubTotal
        rewardAccessVAT
        rewardAccessVATAmount
        rewardAccessTotalAmount
        rewardAccessStripeToken
        rewardAccessStripeReceipt
        isRewardAccessPaymentSuccessful
        rewardAccessMode
        isPaidToAffiliate
        isSmartStartOn
        referralId
        registrationProvider
        activityTimeInSeconds
        totalRewardsRedeemed
        reputationScore
        appVersion
        sweatCoinAuthenticationToken
        lastAccessed
        isSmartStartNotificationEnable
        isMarketPlaceNotificationEnable
        agreedConsents {
          description
          type
          accepted
        }
        isActive
        isDeleted
        deletedUser
        createdAt
        updateAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        owner
        partner {
          id
          invitedByPartnerId
          partnerCode
          dynamicLink
          desktopURL
          companyName
          companyNameLower
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          email
          emailLower
          password
          profilePicture
          companyEmblem
          phone
          addressLine1
          addressLine2
          city
          country
          countryIsoCode
          postcode
          deviceType
          fcmToken
          totalFlaresReceived
          totalFlaresRespondedTo
          totalIncidentsReceived
          totalIncidentsActioned
          latitude
          longitude
          retailCodePrice
          resalePrice
          invoicePayment
          rewardAvailabilityCountries
          referralURL
          referralIntroText
          referralBackgroundImage
          referralRetailPrice
          incidentNotificationSettings
          notificationTitle
          notificationMessage
          notificationPeriod
          hasRiderConfirmed
          iotEndpoint
          enableRewards
          enableReferrals
          enableAppDiscountsHub
          enableFlares
          enableRiderManagement
          enableRetail
          enableAutoStart
          enableEmergencySOS
          enableSdk
          enforceEmergencyContact
          enforceSafeZone
          enforcePhoneNumber
          youtubeLink
          enforceRiderId
          termsAndConditionLink
          privacyPolicyLink
          landingPagePoints {
            text
          }
          sandboxFlareApiKey
          sandboxFlareApiKeyUsage
          productionFlareApiKey
          productionFlareApiKeyUsage
          licenseKeys {
            licenseKey
            keyName
            environment
            totalUsage
            totalIncidentDetected
            isActive
            isDeleted
            deletedKey
            lastUsed
            createdAt
            updatedAt
          }
          incidentNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          sosNotificationSettings
          sosNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          consentCheckbox {
            title
            fileType
            link
            description
            type
          }
          enabledTwoStepVerification
          elasticIndexName
          isAccountVerified
          isActive
          isDeleted
          deletedPartner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getUsersByPartnerIdLastAccessed = /* GraphQL */ `
  query GetUsersByPartnerIdLastAccessed(
    $partnerId: String
    $lastAccessed: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUsersByPartnerIdLastAccessed(
      partnerId: $partnerId
      lastAccessed: $lastAccessed
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        referralCode
        referralName
        isReferralRedeem
        email
        emailLower
        profilePicture
        phoneNumber
        countDownDuration
        subscription
        subscriptionLower
        subscriptionDate
        subscriptionExpiryDate
        keepScreenOn
        isFlareOn
        automaticStartOn
        totalBalance
        totalReward
        totalDriversAlerted
        totalDistanceCovered
        totalProtectedJourneys
        totalRideTimeInSeconds
        totalNearMissesReported
        country
        deviceId
        fcmToken
        deviceType
        isEmailVerified
        latitude
        longitude
        partnerId
        retailCodeUsed
        retailCodeUsedLower
        riderActivities
        riderType
        ridingPeriod
        rewardAccessType
        rewardAccessTypeLower
        rewardAccessStartDate
        rewardAccessEndDate
        rewardAccessSubTotal
        rewardAccessVAT
        rewardAccessVATAmount
        rewardAccessTotalAmount
        rewardAccessStripeToken
        rewardAccessStripeReceipt
        isRewardAccessPaymentSuccessful
        rewardAccessMode
        isPaidToAffiliate
        isSmartStartOn
        referralId
        registrationProvider
        activityTimeInSeconds
        totalRewardsRedeemed
        reputationScore
        appVersion
        sweatCoinAuthenticationToken
        lastAccessed
        isSmartStartNotificationEnable
        isMarketPlaceNotificationEnable
        agreedConsents {
          description
          type
          accepted
        }
        isActive
        isDeleted
        deletedUser
        createdAt
        updateAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        owner
        partner {
          id
          invitedByPartnerId
          partnerCode
          dynamicLink
          desktopURL
          companyName
          companyNameLower
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          email
          emailLower
          password
          profilePicture
          companyEmblem
          phone
          addressLine1
          addressLine2
          city
          country
          countryIsoCode
          postcode
          deviceType
          fcmToken
          totalFlaresReceived
          totalFlaresRespondedTo
          totalIncidentsReceived
          totalIncidentsActioned
          latitude
          longitude
          retailCodePrice
          resalePrice
          invoicePayment
          rewardAvailabilityCountries
          referralURL
          referralIntroText
          referralBackgroundImage
          referralRetailPrice
          incidentNotificationSettings
          notificationTitle
          notificationMessage
          notificationPeriod
          hasRiderConfirmed
          iotEndpoint
          enableRewards
          enableReferrals
          enableAppDiscountsHub
          enableFlares
          enableRiderManagement
          enableRetail
          enableAutoStart
          enableEmergencySOS
          enableSdk
          enforceEmergencyContact
          enforceSafeZone
          enforcePhoneNumber
          youtubeLink
          enforceRiderId
          termsAndConditionLink
          privacyPolicyLink
          landingPagePoints {
            text
          }
          sandboxFlareApiKey
          sandboxFlareApiKeyUsage
          productionFlareApiKey
          productionFlareApiKeyUsage
          licenseKeys {
            licenseKey
            keyName
            environment
            totalUsage
            totalIncidentDetected
            isActive
            isDeleted
            deletedKey
            lastUsed
            createdAt
            updatedAt
          }
          incidentNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          sosNotificationSettings
          sosNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          consentCheckbox {
            title
            fileType
            link
            description
            type
          }
          enabledTwoStepVerification
          elasticIndexName
          isAccountVerified
          isActive
          isDeleted
          deletedPartner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        referralCode
        referralName
        isReferralRedeem
        email
        emailLower
        profilePicture
        phoneNumber
        countDownDuration
        subscription
        subscriptionLower
        subscriptionDate
        subscriptionExpiryDate
        keepScreenOn
        isFlareOn
        automaticStartOn
        totalBalance
        totalReward
        totalDriversAlerted
        totalDistanceCovered
        totalProtectedJourneys
        totalRideTimeInSeconds
        totalNearMissesReported
        country
        deviceId
        fcmToken
        deviceType
        isEmailVerified
        latitude
        longitude
        partnerId
        retailCodeUsed
        retailCodeUsedLower
        riderActivities
        riderType
        ridingPeriod
        rewardAccessType
        rewardAccessTypeLower
        rewardAccessStartDate
        rewardAccessEndDate
        rewardAccessSubTotal
        rewardAccessVAT
        rewardAccessVATAmount
        rewardAccessTotalAmount
        rewardAccessStripeToken
        rewardAccessStripeReceipt
        isRewardAccessPaymentSuccessful
        rewardAccessMode
        isPaidToAffiliate
        isSmartStartOn
        referralId
        registrationProvider
        activityTimeInSeconds
        totalRewardsRedeemed
        reputationScore
        appVersion
        sweatCoinAuthenticationToken
        lastAccessed
        isSmartStartNotificationEnable
        isMarketPlaceNotificationEnable
        agreedConsents {
          description
          type
          accepted
        }
        isActive
        isDeleted
        deletedUser
        createdAt
        updateAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        owner
        partner {
          id
          invitedByPartnerId
          partnerCode
          dynamicLink
          desktopURL
          companyName
          companyNameLower
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          email
          emailLower
          password
          profilePicture
          companyEmblem
          phone
          addressLine1
          addressLine2
          city
          country
          countryIsoCode
          postcode
          deviceType
          fcmToken
          totalFlaresReceived
          totalFlaresRespondedTo
          totalIncidentsReceived
          totalIncidentsActioned
          latitude
          longitude
          retailCodePrice
          resalePrice
          invoicePayment
          rewardAvailabilityCountries
          referralURL
          referralIntroText
          referralBackgroundImage
          referralRetailPrice
          incidentNotificationSettings
          notificationTitle
          notificationMessage
          notificationPeriod
          hasRiderConfirmed
          iotEndpoint
          enableRewards
          enableReferrals
          enableAppDiscountsHub
          enableFlares
          enableRiderManagement
          enableRetail
          enableAutoStart
          enableEmergencySOS
          enableSdk
          enforceEmergencyContact
          enforceSafeZone
          enforcePhoneNumber
          youtubeLink
          enforceRiderId
          termsAndConditionLink
          privacyPolicyLink
          landingPagePoints {
            text
          }
          sandboxFlareApiKey
          sandboxFlareApiKeyUsage
          productionFlareApiKey
          productionFlareApiKeyUsage
          licenseKeys {
            licenseKey
            keyName
            environment
            totalUsage
            totalIncidentDetected
            isActive
            isDeleted
            deletedKey
            lastUsed
            createdAt
            updatedAt
          }
          incidentNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          sosNotificationSettings
          sosNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          consentCheckbox {
            title
            fileType
            link
            description
            type
          }
          enabledTwoStepVerification
          elasticIndexName
          isAccountVerified
          isActive
          isDeleted
          deletedPartner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getEmergencyContacts = /* GraphQL */ `
  query GetEmergencyContacts($id: ID!) {
    getEmergencyContacts(id: $id) {
      id
      userId
      partnerId
      name
      mobile
      country
      guestUserDeviceId
      isDeleted
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listEmergencyContactss = /* GraphQL */ `
  query ListEmergencyContactss(
    $filter: ModelEmergencyContactsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmergencyContactss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        partnerId
        name
        mobile
        country
        guestUserDeviceId
        isDeleted
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getEmergencyContactByUser = /* GraphQL */ `
  query GetEmergencyContactByUser(
    $userId: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEmergencyContactsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getEmergencyContactByUser(
      userId: $userId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        partnerId
        name
        mobile
        country
        guestUserDeviceId
        isDeleted
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncEmergencyContacts = /* GraphQL */ `
  query SyncEmergencyContacts(
    $filter: ModelEmergencyContactsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEmergencyContacts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        partnerId
        name
        mobile
        country
        guestUserDeviceId
        isDeleted
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getSubscriptions = /* GraphQL */ `
  query GetSubscriptions($id: ID!) {
    getSubscriptions(id: $id) {
      id
      userId
      subscription
      subscriptionAmount
      subscriptionDate
      subscriptionExpiryDate
      receipts
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listSubscriptionss = /* GraphQL */ `
  query ListSubscriptionss(
    $filter: ModelSubscriptionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscriptionss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        subscription
        subscriptionAmount
        subscriptionDate
        subscriptionExpiryDate
        receipts
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getSubscriptionsSortedBySubscriptionDate = /* GraphQL */ `
  query GetSubscriptionsSortedBySubscriptionDate(
    $userId: ID
    $subscriptionDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSubscriptionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSubscriptionsSortedBySubscriptionDate(
      userId: $userId
      subscriptionDate: $subscriptionDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        subscription
        subscriptionAmount
        subscriptionDate
        subscriptionExpiryDate
        receipts
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getSubscriptionsByUserId = /* GraphQL */ `
  query GetSubscriptionsByUserId(
    $userId: ID
    $subscriptionDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSubscriptionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSubscriptionsByUserId(
      userId: $userId
      subscriptionDate: $subscriptionDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        subscription
        subscriptionAmount
        subscriptionDate
        subscriptionExpiryDate
        receipts
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSubscriptions = /* GraphQL */ `
  query SyncSubscriptions(
    $filter: ModelSubscriptionsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        subscription
        subscriptionAmount
        subscriptionDate
        subscriptionExpiryDate
        receipts
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserSafeZone = /* GraphQL */ `
  query GetUserSafeZone($id: ID!) {
    getUserSafeZone(id: $id) {
      id
      userId
      type
      postalCode
      address
      latitude
      longitude
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      owner
    }
  }
`;
export const listUserSafeZones = /* GraphQL */ `
  query ListUserSafeZones(
    $filter: ModelUserSafeZoneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserSafeZones(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        type
        postalCode
        address
        latitude
        longitude
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getSafeZoneByUserId = /* GraphQL */ `
  query GetSafeZoneByUserId(
    $userId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserSafeZoneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSafeZoneByUserId(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        type
        postalCode
        address
        latitude
        longitude
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUserSafeZones = /* GraphQL */ `
  query SyncUserSafeZones(
    $filter: ModelUserSafeZoneFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserSafeZones(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        type
        postalCode
        address
        latitude
        longitude
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getGroups = /* GraphQL */ `
  query GetGroups($id: ID!) {
    getGroups(id: $id) {
      id
      createdByUserId
      nanoid
      name
      nameLower
      groupCode
      groupCodeLower
      qrImage
      rideRadius
      rideDuration
      latitude
      longitude
      isEnded
      isDeleted
      deletedGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        referralCode
        referralName
        isReferralRedeem
        email
        emailLower
        profilePicture
        phoneNumber
        countDownDuration
        subscription
        subscriptionLower
        subscriptionDate
        subscriptionExpiryDate
        keepScreenOn
        isFlareOn
        automaticStartOn
        totalBalance
        totalReward
        totalDriversAlerted
        totalDistanceCovered
        totalProtectedJourneys
        totalRideTimeInSeconds
        totalNearMissesReported
        country
        deviceId
        fcmToken
        deviceType
        isEmailVerified
        latitude
        longitude
        partnerId
        retailCodeUsed
        retailCodeUsedLower
        riderActivities
        riderType
        ridingPeriod
        rewardAccessType
        rewardAccessTypeLower
        rewardAccessStartDate
        rewardAccessEndDate
        rewardAccessSubTotal
        rewardAccessVAT
        rewardAccessVATAmount
        rewardAccessTotalAmount
        rewardAccessStripeToken
        rewardAccessStripeReceipt
        isRewardAccessPaymentSuccessful
        rewardAccessMode
        isPaidToAffiliate
        isSmartStartOn
        referralId
        registrationProvider
        activityTimeInSeconds
        totalRewardsRedeemed
        reputationScore
        appVersion
        sweatCoinAuthenticationToken
        lastAccessed
        isSmartStartNotificationEnable
        isMarketPlaceNotificationEnable
        agreedConsents {
          description
          type
          accepted
        }
        isActive
        isDeleted
        deletedUser
        createdAt
        updateAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        owner
        partner {
          id
          invitedByPartnerId
          partnerCode
          dynamicLink
          desktopURL
          companyName
          companyNameLower
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          email
          emailLower
          password
          profilePicture
          companyEmblem
          phone
          addressLine1
          addressLine2
          city
          country
          countryIsoCode
          postcode
          deviceType
          fcmToken
          totalFlaresReceived
          totalFlaresRespondedTo
          totalIncidentsReceived
          totalIncidentsActioned
          latitude
          longitude
          retailCodePrice
          resalePrice
          invoicePayment
          rewardAvailabilityCountries
          referralURL
          referralIntroText
          referralBackgroundImage
          referralRetailPrice
          incidentNotificationSettings
          notificationTitle
          notificationMessage
          notificationPeriod
          hasRiderConfirmed
          iotEndpoint
          enableRewards
          enableReferrals
          enableAppDiscountsHub
          enableFlares
          enableRiderManagement
          enableRetail
          enableAutoStart
          enableEmergencySOS
          enableSdk
          enforceEmergencyContact
          enforceSafeZone
          enforcePhoneNumber
          youtubeLink
          enforceRiderId
          termsAndConditionLink
          privacyPolicyLink
          landingPagePoints {
            text
          }
          sandboxFlareApiKey
          sandboxFlareApiKeyUsage
          productionFlareApiKey
          productionFlareApiKeyUsage
          licenseKeys {
            licenseKey
            keyName
            environment
            totalUsage
            totalIncidentDetected
            isActive
            isDeleted
            deletedKey
            lastUsed
            createdAt
            updatedAt
          }
          incidentNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          sosNotificationSettings
          sosNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          consentCheckbox {
            title
            fileType
            link
            description
            type
          }
          enabledTwoStepVerification
          elasticIndexName
          isAccountVerified
          isActive
          isDeleted
          deletedPartner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
      }
      owner
    }
  }
`;
export const listGroupss = /* GraphQL */ `
  query ListGroupss(
    $filter: ModelGroupsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdByUserId
        nanoid
        name
        nameLower
        groupCode
        groupCodeLower
        qrImage
        rideRadius
        rideDuration
        latitude
        longitude
        isEnded
        isDeleted
        deletedGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          referralCode
          referralName
          isReferralRedeem
          email
          emailLower
          profilePicture
          phoneNumber
          countDownDuration
          subscription
          subscriptionLower
          subscriptionDate
          subscriptionExpiryDate
          keepScreenOn
          isFlareOn
          automaticStartOn
          totalBalance
          totalReward
          totalDriversAlerted
          totalDistanceCovered
          totalProtectedJourneys
          totalRideTimeInSeconds
          totalNearMissesReported
          country
          deviceId
          fcmToken
          deviceType
          isEmailVerified
          latitude
          longitude
          partnerId
          retailCodeUsed
          retailCodeUsedLower
          riderActivities
          riderType
          ridingPeriod
          rewardAccessType
          rewardAccessTypeLower
          rewardAccessStartDate
          rewardAccessEndDate
          rewardAccessSubTotal
          rewardAccessVAT
          rewardAccessVATAmount
          rewardAccessTotalAmount
          rewardAccessStripeToken
          rewardAccessStripeReceipt
          isRewardAccessPaymentSuccessful
          rewardAccessMode
          isPaidToAffiliate
          isSmartStartOn
          referralId
          registrationProvider
          activityTimeInSeconds
          totalRewardsRedeemed
          reputationScore
          appVersion
          sweatCoinAuthenticationToken
          lastAccessed
          isSmartStartNotificationEnable
          isMarketPlaceNotificationEnable
          agreedConsents {
            description
            type
            accepted
          }
          isActive
          isDeleted
          deletedUser
          createdAt
          updateAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          owner
          partner {
            id
            invitedByPartnerId
            partnerCode
            dynamicLink
            desktopURL
            companyName
            companyNameLower
            firstName
            firstNameLower
            lastName
            lastNameLower
            userName
            userNameLower
            email
            emailLower
            password
            profilePicture
            companyEmblem
            phone
            addressLine1
            addressLine2
            city
            country
            countryIsoCode
            postcode
            deviceType
            fcmToken
            totalFlaresReceived
            totalFlaresRespondedTo
            totalIncidentsReceived
            totalIncidentsActioned
            latitude
            longitude
            retailCodePrice
            resalePrice
            invoicePayment
            rewardAvailabilityCountries
            referralURL
            referralIntroText
            referralBackgroundImage
            referralRetailPrice
            incidentNotificationSettings
            notificationTitle
            notificationMessage
            notificationPeriod
            hasRiderConfirmed
            iotEndpoint
            enableRewards
            enableReferrals
            enableAppDiscountsHub
            enableFlares
            enableRiderManagement
            enableRetail
            enableAutoStart
            enableEmergencySOS
            enableSdk
            enforceEmergencyContact
            enforceSafeZone
            enforcePhoneNumber
            youtubeLink
            enforceRiderId
            termsAndConditionLink
            privacyPolicyLink
            landingPagePoints {
              text
            }
            sandboxFlareApiKey
            sandboxFlareApiKeyUsage
            productionFlareApiKey
            productionFlareApiKeyUsage
            licenseKeys {
              licenseKey
              keyName
              environment
              totalUsage
              totalIncidentDetected
              isActive
              isDeleted
              deletedKey
              lastUsed
              createdAt
              updatedAt
            }
            incidentNotifications {
              notificationSettings
              notificationEmail
              notificationCountry
              notificationCountryCallingCode
              notificationMobileNo
              notificationWebhookURL
              includeRiderEmail
              riderID
              riderName
              riderCity
              riderEmail
              incidentDateAndTime
              ISOCountryCode
              incidentLocation
              incidentTelemetry
            }
            sosNotificationSettings
            sosNotifications {
              notificationSettings
              notificationEmail
              notificationCountry
              notificationCountryCallingCode
              notificationMobileNo
              notificationWebhookURL
              includeRiderEmail
              riderID
              riderName
              riderCity
              riderEmail
              incidentDateAndTime
              ISOCountryCode
              incidentLocation
              incidentTelemetry
            }
            consentCheckbox {
              title
              fileType
              link
              description
              type
            }
            enabledTwoStepVerification
            elasticIndexName
            isAccountVerified
            isActive
            isDeleted
            deletedPartner
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
          }
        }
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getGroupsSortedByCreatedAt = /* GraphQL */ `
  query GetGroupsSortedByCreatedAt(
    $deletedGroup: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getGroupsSortedByCreatedAt(
      deletedGroup: $deletedGroup
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdByUserId
        nanoid
        name
        nameLower
        groupCode
        groupCodeLower
        qrImage
        rideRadius
        rideDuration
        latitude
        longitude
        isEnded
        isDeleted
        deletedGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          referralCode
          referralName
          isReferralRedeem
          email
          emailLower
          profilePicture
          phoneNumber
          countDownDuration
          subscription
          subscriptionLower
          subscriptionDate
          subscriptionExpiryDate
          keepScreenOn
          isFlareOn
          automaticStartOn
          totalBalance
          totalReward
          totalDriversAlerted
          totalDistanceCovered
          totalProtectedJourneys
          totalRideTimeInSeconds
          totalNearMissesReported
          country
          deviceId
          fcmToken
          deviceType
          isEmailVerified
          latitude
          longitude
          partnerId
          retailCodeUsed
          retailCodeUsedLower
          riderActivities
          riderType
          ridingPeriod
          rewardAccessType
          rewardAccessTypeLower
          rewardAccessStartDate
          rewardAccessEndDate
          rewardAccessSubTotal
          rewardAccessVAT
          rewardAccessVATAmount
          rewardAccessTotalAmount
          rewardAccessStripeToken
          rewardAccessStripeReceipt
          isRewardAccessPaymentSuccessful
          rewardAccessMode
          isPaidToAffiliate
          isSmartStartOn
          referralId
          registrationProvider
          activityTimeInSeconds
          totalRewardsRedeemed
          reputationScore
          appVersion
          sweatCoinAuthenticationToken
          lastAccessed
          isSmartStartNotificationEnable
          isMarketPlaceNotificationEnable
          agreedConsents {
            description
            type
            accepted
          }
          isActive
          isDeleted
          deletedUser
          createdAt
          updateAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          owner
          partner {
            id
            invitedByPartnerId
            partnerCode
            dynamicLink
            desktopURL
            companyName
            companyNameLower
            firstName
            firstNameLower
            lastName
            lastNameLower
            userName
            userNameLower
            email
            emailLower
            password
            profilePicture
            companyEmblem
            phone
            addressLine1
            addressLine2
            city
            country
            countryIsoCode
            postcode
            deviceType
            fcmToken
            totalFlaresReceived
            totalFlaresRespondedTo
            totalIncidentsReceived
            totalIncidentsActioned
            latitude
            longitude
            retailCodePrice
            resalePrice
            invoicePayment
            rewardAvailabilityCountries
            referralURL
            referralIntroText
            referralBackgroundImage
            referralRetailPrice
            incidentNotificationSettings
            notificationTitle
            notificationMessage
            notificationPeriod
            hasRiderConfirmed
            iotEndpoint
            enableRewards
            enableReferrals
            enableAppDiscountsHub
            enableFlares
            enableRiderManagement
            enableRetail
            enableAutoStart
            enableEmergencySOS
            enableSdk
            enforceEmergencyContact
            enforceSafeZone
            enforcePhoneNumber
            youtubeLink
            enforceRiderId
            termsAndConditionLink
            privacyPolicyLink
            landingPagePoints {
              text
            }
            sandboxFlareApiKey
            sandboxFlareApiKeyUsage
            productionFlareApiKey
            productionFlareApiKeyUsage
            licenseKeys {
              licenseKey
              keyName
              environment
              totalUsage
              totalIncidentDetected
              isActive
              isDeleted
              deletedKey
              lastUsed
              createdAt
              updatedAt
            }
            incidentNotifications {
              notificationSettings
              notificationEmail
              notificationCountry
              notificationCountryCallingCode
              notificationMobileNo
              notificationWebhookURL
              includeRiderEmail
              riderID
              riderName
              riderCity
              riderEmail
              incidentDateAndTime
              ISOCountryCode
              incidentLocation
              incidentTelemetry
            }
            sosNotificationSettings
            sosNotifications {
              notificationSettings
              notificationEmail
              notificationCountry
              notificationCountryCallingCode
              notificationMobileNo
              notificationWebhookURL
              includeRiderEmail
              riderID
              riderName
              riderCity
              riderEmail
              incidentDateAndTime
              ISOCountryCode
              incidentLocation
              incidentTelemetry
            }
            consentCheckbox {
              title
              fileType
              link
              description
              type
            }
            enabledTwoStepVerification
            elasticIndexName
            isAccountVerified
            isActive
            isDeleted
            deletedPartner
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
          }
        }
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getGroupsByUserId = /* GraphQL */ `
  query GetGroupsByUserId(
    $createdByUserId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getGroupsByUserId(
      createdByUserId: $createdByUserId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdByUserId
        nanoid
        name
        nameLower
        groupCode
        groupCodeLower
        qrImage
        rideRadius
        rideDuration
        latitude
        longitude
        isEnded
        isDeleted
        deletedGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          referralCode
          referralName
          isReferralRedeem
          email
          emailLower
          profilePicture
          phoneNumber
          countDownDuration
          subscription
          subscriptionLower
          subscriptionDate
          subscriptionExpiryDate
          keepScreenOn
          isFlareOn
          automaticStartOn
          totalBalance
          totalReward
          totalDriversAlerted
          totalDistanceCovered
          totalProtectedJourneys
          totalRideTimeInSeconds
          totalNearMissesReported
          country
          deviceId
          fcmToken
          deviceType
          isEmailVerified
          latitude
          longitude
          partnerId
          retailCodeUsed
          retailCodeUsedLower
          riderActivities
          riderType
          ridingPeriod
          rewardAccessType
          rewardAccessTypeLower
          rewardAccessStartDate
          rewardAccessEndDate
          rewardAccessSubTotal
          rewardAccessVAT
          rewardAccessVATAmount
          rewardAccessTotalAmount
          rewardAccessStripeToken
          rewardAccessStripeReceipt
          isRewardAccessPaymentSuccessful
          rewardAccessMode
          isPaidToAffiliate
          isSmartStartOn
          referralId
          registrationProvider
          activityTimeInSeconds
          totalRewardsRedeemed
          reputationScore
          appVersion
          sweatCoinAuthenticationToken
          lastAccessed
          isSmartStartNotificationEnable
          isMarketPlaceNotificationEnable
          agreedConsents {
            description
            type
            accepted
          }
          isActive
          isDeleted
          deletedUser
          createdAt
          updateAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          owner
          partner {
            id
            invitedByPartnerId
            partnerCode
            dynamicLink
            desktopURL
            companyName
            companyNameLower
            firstName
            firstNameLower
            lastName
            lastNameLower
            userName
            userNameLower
            email
            emailLower
            password
            profilePicture
            companyEmblem
            phone
            addressLine1
            addressLine2
            city
            country
            countryIsoCode
            postcode
            deviceType
            fcmToken
            totalFlaresReceived
            totalFlaresRespondedTo
            totalIncidentsReceived
            totalIncidentsActioned
            latitude
            longitude
            retailCodePrice
            resalePrice
            invoicePayment
            rewardAvailabilityCountries
            referralURL
            referralIntroText
            referralBackgroundImage
            referralRetailPrice
            incidentNotificationSettings
            notificationTitle
            notificationMessage
            notificationPeriod
            hasRiderConfirmed
            iotEndpoint
            enableRewards
            enableReferrals
            enableAppDiscountsHub
            enableFlares
            enableRiderManagement
            enableRetail
            enableAutoStart
            enableEmergencySOS
            enableSdk
            enforceEmergencyContact
            enforceSafeZone
            enforcePhoneNumber
            youtubeLink
            enforceRiderId
            termsAndConditionLink
            privacyPolicyLink
            landingPagePoints {
              text
            }
            sandboxFlareApiKey
            sandboxFlareApiKeyUsage
            productionFlareApiKey
            productionFlareApiKeyUsage
            licenseKeys {
              licenseKey
              keyName
              environment
              totalUsage
              totalIncidentDetected
              isActive
              isDeleted
              deletedKey
              lastUsed
              createdAt
              updatedAt
            }
            incidentNotifications {
              notificationSettings
              notificationEmail
              notificationCountry
              notificationCountryCallingCode
              notificationMobileNo
              notificationWebhookURL
              includeRiderEmail
              riderID
              riderName
              riderCity
              riderEmail
              incidentDateAndTime
              ISOCountryCode
              incidentLocation
              incidentTelemetry
            }
            sosNotificationSettings
            sosNotifications {
              notificationSettings
              notificationEmail
              notificationCountry
              notificationCountryCallingCode
              notificationMobileNo
              notificationWebhookURL
              includeRiderEmail
              riderID
              riderName
              riderCity
              riderEmail
              incidentDateAndTime
              ISOCountryCode
              incidentLocation
              incidentTelemetry
            }
            consentCheckbox {
              title
              fileType
              link
              description
              type
            }
            enabledTwoStepVerification
            elasticIndexName
            isAccountVerified
            isActive
            isDeleted
            deletedPartner
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
          }
        }
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getGroupByGroupCode = /* GraphQL */ `
  query GetGroupByGroupCode(
    $groupCode: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getGroupByGroupCode(
      groupCode: $groupCode
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdByUserId
        nanoid
        name
        nameLower
        groupCode
        groupCodeLower
        qrImage
        rideRadius
        rideDuration
        latitude
        longitude
        isEnded
        isDeleted
        deletedGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          referralCode
          referralName
          isReferralRedeem
          email
          emailLower
          profilePicture
          phoneNumber
          countDownDuration
          subscription
          subscriptionLower
          subscriptionDate
          subscriptionExpiryDate
          keepScreenOn
          isFlareOn
          automaticStartOn
          totalBalance
          totalReward
          totalDriversAlerted
          totalDistanceCovered
          totalProtectedJourneys
          totalRideTimeInSeconds
          totalNearMissesReported
          country
          deviceId
          fcmToken
          deviceType
          isEmailVerified
          latitude
          longitude
          partnerId
          retailCodeUsed
          retailCodeUsedLower
          riderActivities
          riderType
          ridingPeriod
          rewardAccessType
          rewardAccessTypeLower
          rewardAccessStartDate
          rewardAccessEndDate
          rewardAccessSubTotal
          rewardAccessVAT
          rewardAccessVATAmount
          rewardAccessTotalAmount
          rewardAccessStripeToken
          rewardAccessStripeReceipt
          isRewardAccessPaymentSuccessful
          rewardAccessMode
          isPaidToAffiliate
          isSmartStartOn
          referralId
          registrationProvider
          activityTimeInSeconds
          totalRewardsRedeemed
          reputationScore
          appVersion
          sweatCoinAuthenticationToken
          lastAccessed
          isSmartStartNotificationEnable
          isMarketPlaceNotificationEnable
          agreedConsents {
            description
            type
            accepted
          }
          isActive
          isDeleted
          deletedUser
          createdAt
          updateAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          owner
          partner {
            id
            invitedByPartnerId
            partnerCode
            dynamicLink
            desktopURL
            companyName
            companyNameLower
            firstName
            firstNameLower
            lastName
            lastNameLower
            userName
            userNameLower
            email
            emailLower
            password
            profilePicture
            companyEmblem
            phone
            addressLine1
            addressLine2
            city
            country
            countryIsoCode
            postcode
            deviceType
            fcmToken
            totalFlaresReceived
            totalFlaresRespondedTo
            totalIncidentsReceived
            totalIncidentsActioned
            latitude
            longitude
            retailCodePrice
            resalePrice
            invoicePayment
            rewardAvailabilityCountries
            referralURL
            referralIntroText
            referralBackgroundImage
            referralRetailPrice
            incidentNotificationSettings
            notificationTitle
            notificationMessage
            notificationPeriod
            hasRiderConfirmed
            iotEndpoint
            enableRewards
            enableReferrals
            enableAppDiscountsHub
            enableFlares
            enableRiderManagement
            enableRetail
            enableAutoStart
            enableEmergencySOS
            enableSdk
            enforceEmergencyContact
            enforceSafeZone
            enforcePhoneNumber
            youtubeLink
            enforceRiderId
            termsAndConditionLink
            privacyPolicyLink
            landingPagePoints {
              text
            }
            sandboxFlareApiKey
            sandboxFlareApiKeyUsage
            productionFlareApiKey
            productionFlareApiKeyUsage
            licenseKeys {
              licenseKey
              keyName
              environment
              totalUsage
              totalIncidentDetected
              isActive
              isDeleted
              deletedKey
              lastUsed
              createdAt
              updatedAt
            }
            incidentNotifications {
              notificationSettings
              notificationEmail
              notificationCountry
              notificationCountryCallingCode
              notificationMobileNo
              notificationWebhookURL
              includeRiderEmail
              riderID
              riderName
              riderCity
              riderEmail
              incidentDateAndTime
              ISOCountryCode
              incidentLocation
              incidentTelemetry
            }
            sosNotificationSettings
            sosNotifications {
              notificationSettings
              notificationEmail
              notificationCountry
              notificationCountryCallingCode
              notificationMobileNo
              notificationWebhookURL
              includeRiderEmail
              riderID
              riderName
              riderCity
              riderEmail
              incidentDateAndTime
              ISOCountryCode
              incidentLocation
              incidentTelemetry
            }
            consentCheckbox {
              title
              fileType
              link
              description
              type
            }
            enabledTwoStepVerification
            elasticIndexName
            isAccountVerified
            isActive
            isDeleted
            deletedPartner
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
          }
        }
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncGroups = /* GraphQL */ `
  query SyncGroups(
    $filter: ModelGroupsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdByUserId
        nanoid
        name
        nameLower
        groupCode
        groupCodeLower
        qrImage
        rideRadius
        rideDuration
        latitude
        longitude
        isEnded
        isDeleted
        deletedGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          referralCode
          referralName
          isReferralRedeem
          email
          emailLower
          profilePicture
          phoneNumber
          countDownDuration
          subscription
          subscriptionLower
          subscriptionDate
          subscriptionExpiryDate
          keepScreenOn
          isFlareOn
          automaticStartOn
          totalBalance
          totalReward
          totalDriversAlerted
          totalDistanceCovered
          totalProtectedJourneys
          totalRideTimeInSeconds
          totalNearMissesReported
          country
          deviceId
          fcmToken
          deviceType
          isEmailVerified
          latitude
          longitude
          partnerId
          retailCodeUsed
          retailCodeUsedLower
          riderActivities
          riderType
          ridingPeriod
          rewardAccessType
          rewardAccessTypeLower
          rewardAccessStartDate
          rewardAccessEndDate
          rewardAccessSubTotal
          rewardAccessVAT
          rewardAccessVATAmount
          rewardAccessTotalAmount
          rewardAccessStripeToken
          rewardAccessStripeReceipt
          isRewardAccessPaymentSuccessful
          rewardAccessMode
          isPaidToAffiliate
          isSmartStartOn
          referralId
          registrationProvider
          activityTimeInSeconds
          totalRewardsRedeemed
          reputationScore
          appVersion
          sweatCoinAuthenticationToken
          lastAccessed
          isSmartStartNotificationEnable
          isMarketPlaceNotificationEnable
          agreedConsents {
            description
            type
            accepted
          }
          isActive
          isDeleted
          deletedUser
          createdAt
          updateAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          owner
          partner {
            id
            invitedByPartnerId
            partnerCode
            dynamicLink
            desktopURL
            companyName
            companyNameLower
            firstName
            firstNameLower
            lastName
            lastNameLower
            userName
            userNameLower
            email
            emailLower
            password
            profilePicture
            companyEmblem
            phone
            addressLine1
            addressLine2
            city
            country
            countryIsoCode
            postcode
            deviceType
            fcmToken
            totalFlaresReceived
            totalFlaresRespondedTo
            totalIncidentsReceived
            totalIncidentsActioned
            latitude
            longitude
            retailCodePrice
            resalePrice
            invoicePayment
            rewardAvailabilityCountries
            referralURL
            referralIntroText
            referralBackgroundImage
            referralRetailPrice
            incidentNotificationSettings
            notificationTitle
            notificationMessage
            notificationPeriod
            hasRiderConfirmed
            iotEndpoint
            enableRewards
            enableReferrals
            enableAppDiscountsHub
            enableFlares
            enableRiderManagement
            enableRetail
            enableAutoStart
            enableEmergencySOS
            enableSdk
            enforceEmergencyContact
            enforceSafeZone
            enforcePhoneNumber
            youtubeLink
            enforceRiderId
            termsAndConditionLink
            privacyPolicyLink
            landingPagePoints {
              text
            }
            sandboxFlareApiKey
            sandboxFlareApiKeyUsage
            productionFlareApiKey
            productionFlareApiKeyUsage
            licenseKeys {
              licenseKey
              keyName
              environment
              totalUsage
              totalIncidentDetected
              isActive
              isDeleted
              deletedKey
              lastUsed
              createdAt
              updatedAt
            }
            incidentNotifications {
              notificationSettings
              notificationEmail
              notificationCountry
              notificationCountryCallingCode
              notificationMobileNo
              notificationWebhookURL
              includeRiderEmail
              riderID
              riderName
              riderCity
              riderEmail
              incidentDateAndTime
              ISOCountryCode
              incidentLocation
              incidentTelemetry
            }
            sosNotificationSettings
            sosNotifications {
              notificationSettings
              notificationEmail
              notificationCountry
              notificationCountryCallingCode
              notificationMobileNo
              notificationWebhookURL
              includeRiderEmail
              riderID
              riderName
              riderCity
              riderEmail
              incidentDateAndTime
              ISOCountryCode
              incidentLocation
              incidentTelemetry
            }
            consentCheckbox {
              title
              fileType
              link
              description
              type
            }
            enabledTwoStepVerification
            elasticIndexName
            isAccountVerified
            isActive
            isDeleted
            deletedPartner
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
          }
        }
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getParticipants = /* GraphQL */ `
  query GetParticipants($id: ID!) {
    getParticipants(id: $id) {
      id
      groupId
      userId
      name
      latitude
      longitude
      isJoin
      isLeftGroup
      isRemove
      isIncident
      incidentProcessCompleted
      date
      distance
      time
      coinsEarned
      coordinates
      isDeleted
      deletedParticipant
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        referralCode
        referralName
        isReferralRedeem
        email
        emailLower
        profilePicture
        phoneNumber
        countDownDuration
        subscription
        subscriptionLower
        subscriptionDate
        subscriptionExpiryDate
        keepScreenOn
        isFlareOn
        automaticStartOn
        totalBalance
        totalReward
        totalDriversAlerted
        totalDistanceCovered
        totalProtectedJourneys
        totalRideTimeInSeconds
        totalNearMissesReported
        country
        deviceId
        fcmToken
        deviceType
        isEmailVerified
        latitude
        longitude
        partnerId
        retailCodeUsed
        retailCodeUsedLower
        riderActivities
        riderType
        ridingPeriod
        rewardAccessType
        rewardAccessTypeLower
        rewardAccessStartDate
        rewardAccessEndDate
        rewardAccessSubTotal
        rewardAccessVAT
        rewardAccessVATAmount
        rewardAccessTotalAmount
        rewardAccessStripeToken
        rewardAccessStripeReceipt
        isRewardAccessPaymentSuccessful
        rewardAccessMode
        isPaidToAffiliate
        isSmartStartOn
        referralId
        registrationProvider
        activityTimeInSeconds
        totalRewardsRedeemed
        reputationScore
        appVersion
        sweatCoinAuthenticationToken
        lastAccessed
        isSmartStartNotificationEnable
        isMarketPlaceNotificationEnable
        agreedConsents {
          description
          type
          accepted
        }
        isActive
        isDeleted
        deletedUser
        createdAt
        updateAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        owner
        partner {
          id
          invitedByPartnerId
          partnerCode
          dynamicLink
          desktopURL
          companyName
          companyNameLower
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          email
          emailLower
          password
          profilePicture
          companyEmblem
          phone
          addressLine1
          addressLine2
          city
          country
          countryIsoCode
          postcode
          deviceType
          fcmToken
          totalFlaresReceived
          totalFlaresRespondedTo
          totalIncidentsReceived
          totalIncidentsActioned
          latitude
          longitude
          retailCodePrice
          resalePrice
          invoicePayment
          rewardAvailabilityCountries
          referralURL
          referralIntroText
          referralBackgroundImage
          referralRetailPrice
          incidentNotificationSettings
          notificationTitle
          notificationMessage
          notificationPeriod
          hasRiderConfirmed
          iotEndpoint
          enableRewards
          enableReferrals
          enableAppDiscountsHub
          enableFlares
          enableRiderManagement
          enableRetail
          enableAutoStart
          enableEmergencySOS
          enableSdk
          enforceEmergencyContact
          enforceSafeZone
          enforcePhoneNumber
          youtubeLink
          enforceRiderId
          termsAndConditionLink
          privacyPolicyLink
          landingPagePoints {
            text
          }
          sandboxFlareApiKey
          sandboxFlareApiKeyUsage
          productionFlareApiKey
          productionFlareApiKeyUsage
          licenseKeys {
            licenseKey
            keyName
            environment
            totalUsage
            totalIncidentDetected
            isActive
            isDeleted
            deletedKey
            lastUsed
            createdAt
            updatedAt
          }
          incidentNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          sosNotificationSettings
          sosNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          consentCheckbox {
            title
            fileType
            link
            description
            type
          }
          enabledTwoStepVerification
          elasticIndexName
          isAccountVerified
          isActive
          isDeleted
          deletedPartner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
      }
      group {
        id
        createdByUserId
        nanoid
        name
        nameLower
        groupCode
        groupCodeLower
        qrImage
        rideRadius
        rideDuration
        latitude
        longitude
        isEnded
        isDeleted
        deletedGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          referralCode
          referralName
          isReferralRedeem
          email
          emailLower
          profilePicture
          phoneNumber
          countDownDuration
          subscription
          subscriptionLower
          subscriptionDate
          subscriptionExpiryDate
          keepScreenOn
          isFlareOn
          automaticStartOn
          totalBalance
          totalReward
          totalDriversAlerted
          totalDistanceCovered
          totalProtectedJourneys
          totalRideTimeInSeconds
          totalNearMissesReported
          country
          deviceId
          fcmToken
          deviceType
          isEmailVerified
          latitude
          longitude
          partnerId
          retailCodeUsed
          retailCodeUsedLower
          riderActivities
          riderType
          ridingPeriod
          rewardAccessType
          rewardAccessTypeLower
          rewardAccessStartDate
          rewardAccessEndDate
          rewardAccessSubTotal
          rewardAccessVAT
          rewardAccessVATAmount
          rewardAccessTotalAmount
          rewardAccessStripeToken
          rewardAccessStripeReceipt
          isRewardAccessPaymentSuccessful
          rewardAccessMode
          isPaidToAffiliate
          isSmartStartOn
          referralId
          registrationProvider
          activityTimeInSeconds
          totalRewardsRedeemed
          reputationScore
          appVersion
          sweatCoinAuthenticationToken
          lastAccessed
          isSmartStartNotificationEnable
          isMarketPlaceNotificationEnable
          agreedConsents {
            description
            type
            accepted
          }
          isActive
          isDeleted
          deletedUser
          createdAt
          updateAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          owner
          partner {
            id
            invitedByPartnerId
            partnerCode
            dynamicLink
            desktopURL
            companyName
            companyNameLower
            firstName
            firstNameLower
            lastName
            lastNameLower
            userName
            userNameLower
            email
            emailLower
            password
            profilePicture
            companyEmblem
            phone
            addressLine1
            addressLine2
            city
            country
            countryIsoCode
            postcode
            deviceType
            fcmToken
            totalFlaresReceived
            totalFlaresRespondedTo
            totalIncidentsReceived
            totalIncidentsActioned
            latitude
            longitude
            retailCodePrice
            resalePrice
            invoicePayment
            rewardAvailabilityCountries
            referralURL
            referralIntroText
            referralBackgroundImage
            referralRetailPrice
            incidentNotificationSettings
            notificationTitle
            notificationMessage
            notificationPeriod
            hasRiderConfirmed
            iotEndpoint
            enableRewards
            enableReferrals
            enableAppDiscountsHub
            enableFlares
            enableRiderManagement
            enableRetail
            enableAutoStart
            enableEmergencySOS
            enableSdk
            enforceEmergencyContact
            enforceSafeZone
            enforcePhoneNumber
            youtubeLink
            enforceRiderId
            termsAndConditionLink
            privacyPolicyLink
            landingPagePoints {
              text
            }
            sandboxFlareApiKey
            sandboxFlareApiKeyUsage
            productionFlareApiKey
            productionFlareApiKeyUsage
            licenseKeys {
              licenseKey
              keyName
              environment
              totalUsage
              totalIncidentDetected
              isActive
              isDeleted
              deletedKey
              lastUsed
              createdAt
              updatedAt
            }
            incidentNotifications {
              notificationSettings
              notificationEmail
              notificationCountry
              notificationCountryCallingCode
              notificationMobileNo
              notificationWebhookURL
              includeRiderEmail
              riderID
              riderName
              riderCity
              riderEmail
              incidentDateAndTime
              ISOCountryCode
              incidentLocation
              incidentTelemetry
            }
            sosNotificationSettings
            sosNotifications {
              notificationSettings
              notificationEmail
              notificationCountry
              notificationCountryCallingCode
              notificationMobileNo
              notificationWebhookURL
              includeRiderEmail
              riderID
              riderName
              riderCity
              riderEmail
              incidentDateAndTime
              ISOCountryCode
              incidentLocation
              incidentTelemetry
            }
            consentCheckbox {
              title
              fileType
              link
              description
              type
            }
            enabledTwoStepVerification
            elasticIndexName
            isAccountVerified
            isActive
            isDeleted
            deletedPartner
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
          }
        }
        owner
      }
      owner
    }
  }
`;
export const listParticipantss = /* GraphQL */ `
  query ListParticipantss(
    $filter: ModelParticipantsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listParticipantss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupId
        userId
        name
        latitude
        longitude
        isJoin
        isLeftGroup
        isRemove
        isIncident
        incidentProcessCompleted
        date
        distance
        time
        coinsEarned
        coordinates
        isDeleted
        deletedParticipant
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          referralCode
          referralName
          isReferralRedeem
          email
          emailLower
          profilePicture
          phoneNumber
          countDownDuration
          subscription
          subscriptionLower
          subscriptionDate
          subscriptionExpiryDate
          keepScreenOn
          isFlareOn
          automaticStartOn
          totalBalance
          totalReward
          totalDriversAlerted
          totalDistanceCovered
          totalProtectedJourneys
          totalRideTimeInSeconds
          totalNearMissesReported
          country
          deviceId
          fcmToken
          deviceType
          isEmailVerified
          latitude
          longitude
          partnerId
          retailCodeUsed
          retailCodeUsedLower
          riderActivities
          riderType
          ridingPeriod
          rewardAccessType
          rewardAccessTypeLower
          rewardAccessStartDate
          rewardAccessEndDate
          rewardAccessSubTotal
          rewardAccessVAT
          rewardAccessVATAmount
          rewardAccessTotalAmount
          rewardAccessStripeToken
          rewardAccessStripeReceipt
          isRewardAccessPaymentSuccessful
          rewardAccessMode
          isPaidToAffiliate
          isSmartStartOn
          referralId
          registrationProvider
          activityTimeInSeconds
          totalRewardsRedeemed
          reputationScore
          appVersion
          sweatCoinAuthenticationToken
          lastAccessed
          isSmartStartNotificationEnable
          isMarketPlaceNotificationEnable
          agreedConsents {
            description
            type
            accepted
          }
          isActive
          isDeleted
          deletedUser
          createdAt
          updateAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          owner
          partner {
            id
            invitedByPartnerId
            partnerCode
            dynamicLink
            desktopURL
            companyName
            companyNameLower
            firstName
            firstNameLower
            lastName
            lastNameLower
            userName
            userNameLower
            email
            emailLower
            password
            profilePicture
            companyEmblem
            phone
            addressLine1
            addressLine2
            city
            country
            countryIsoCode
            postcode
            deviceType
            fcmToken
            totalFlaresReceived
            totalFlaresRespondedTo
            totalIncidentsReceived
            totalIncidentsActioned
            latitude
            longitude
            retailCodePrice
            resalePrice
            invoicePayment
            rewardAvailabilityCountries
            referralURL
            referralIntroText
            referralBackgroundImage
            referralRetailPrice
            incidentNotificationSettings
            notificationTitle
            notificationMessage
            notificationPeriod
            hasRiderConfirmed
            iotEndpoint
            enableRewards
            enableReferrals
            enableAppDiscountsHub
            enableFlares
            enableRiderManagement
            enableRetail
            enableAutoStart
            enableEmergencySOS
            enableSdk
            enforceEmergencyContact
            enforceSafeZone
            enforcePhoneNumber
            youtubeLink
            enforceRiderId
            termsAndConditionLink
            privacyPolicyLink
            landingPagePoints {
              text
            }
            sandboxFlareApiKey
            sandboxFlareApiKeyUsage
            productionFlareApiKey
            productionFlareApiKeyUsage
            licenseKeys {
              licenseKey
              keyName
              environment
              totalUsage
              totalIncidentDetected
              isActive
              isDeleted
              deletedKey
              lastUsed
              createdAt
              updatedAt
            }
            incidentNotifications {
              notificationSettings
              notificationEmail
              notificationCountry
              notificationCountryCallingCode
              notificationMobileNo
              notificationWebhookURL
              includeRiderEmail
              riderID
              riderName
              riderCity
              riderEmail
              incidentDateAndTime
              ISOCountryCode
              incidentLocation
              incidentTelemetry
            }
            sosNotificationSettings
            sosNotifications {
              notificationSettings
              notificationEmail
              notificationCountry
              notificationCountryCallingCode
              notificationMobileNo
              notificationWebhookURL
              includeRiderEmail
              riderID
              riderName
              riderCity
              riderEmail
              incidentDateAndTime
              ISOCountryCode
              incidentLocation
              incidentTelemetry
            }
            consentCheckbox {
              title
              fileType
              link
              description
              type
            }
            enabledTwoStepVerification
            elasticIndexName
            isAccountVerified
            isActive
            isDeleted
            deletedPartner
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
          }
        }
        group {
          id
          createdByUserId
          nanoid
          name
          nameLower
          groupCode
          groupCodeLower
          qrImage
          rideRadius
          rideDuration
          latitude
          longitude
          isEnded
          isDeleted
          deletedGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          user {
            id
            firstName
            firstNameLower
            lastName
            lastNameLower
            userName
            userNameLower
            referralCode
            referralName
            isReferralRedeem
            email
            emailLower
            profilePicture
            phoneNumber
            countDownDuration
            subscription
            subscriptionLower
            subscriptionDate
            subscriptionExpiryDate
            keepScreenOn
            isFlareOn
            automaticStartOn
            totalBalance
            totalReward
            totalDriversAlerted
            totalDistanceCovered
            totalProtectedJourneys
            totalRideTimeInSeconds
            totalNearMissesReported
            country
            deviceId
            fcmToken
            deviceType
            isEmailVerified
            latitude
            longitude
            partnerId
            retailCodeUsed
            retailCodeUsedLower
            riderActivities
            riderType
            ridingPeriod
            rewardAccessType
            rewardAccessTypeLower
            rewardAccessStartDate
            rewardAccessEndDate
            rewardAccessSubTotal
            rewardAccessVAT
            rewardAccessVATAmount
            rewardAccessTotalAmount
            rewardAccessStripeToken
            rewardAccessStripeReceipt
            isRewardAccessPaymentSuccessful
            rewardAccessMode
            isPaidToAffiliate
            isSmartStartOn
            referralId
            registrationProvider
            activityTimeInSeconds
            totalRewardsRedeemed
            reputationScore
            appVersion
            sweatCoinAuthenticationToken
            lastAccessed
            isSmartStartNotificationEnable
            isMarketPlaceNotificationEnable
            agreedConsents {
              description
              type
              accepted
            }
            isActive
            isDeleted
            deletedUser
            createdAt
            updateAt
            _version
            _deleted
            _lastChangedAt
            updatedAt
            owner
            partner {
              id
              invitedByPartnerId
              partnerCode
              dynamicLink
              desktopURL
              companyName
              companyNameLower
              firstName
              firstNameLower
              lastName
              lastNameLower
              userName
              userNameLower
              email
              emailLower
              password
              profilePicture
              companyEmblem
              phone
              addressLine1
              addressLine2
              city
              country
              countryIsoCode
              postcode
              deviceType
              fcmToken
              totalFlaresReceived
              totalFlaresRespondedTo
              totalIncidentsReceived
              totalIncidentsActioned
              latitude
              longitude
              retailCodePrice
              resalePrice
              invoicePayment
              rewardAvailabilityCountries
              referralURL
              referralIntroText
              referralBackgroundImage
              referralRetailPrice
              incidentNotificationSettings
              notificationTitle
              notificationMessage
              notificationPeriod
              hasRiderConfirmed
              iotEndpoint
              enableRewards
              enableReferrals
              enableAppDiscountsHub
              enableFlares
              enableRiderManagement
              enableRetail
              enableAutoStart
              enableEmergencySOS
              enableSdk
              enforceEmergencyContact
              enforceSafeZone
              enforcePhoneNumber
              youtubeLink
              enforceRiderId
              termsAndConditionLink
              privacyPolicyLink
              sandboxFlareApiKey
              sandboxFlareApiKeyUsage
              productionFlareApiKey
              productionFlareApiKeyUsage
              sosNotificationSettings
              enabledTwoStepVerification
              elasticIndexName
              isAccountVerified
              isActive
              isDeleted
              deletedPartner
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
            }
          }
          owner
        }
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getParticipantsByGroupId = /* GraphQL */ `
  query GetParticipantsByGroupId(
    $groupId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelParticipantsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getParticipantsByGroupId(
      groupId: $groupId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        userId
        name
        latitude
        longitude
        isJoin
        isLeftGroup
        isRemove
        isIncident
        incidentProcessCompleted
        date
        distance
        time
        coinsEarned
        coordinates
        isDeleted
        deletedParticipant
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          referralCode
          referralName
          isReferralRedeem
          email
          emailLower
          profilePicture
          phoneNumber
          countDownDuration
          subscription
          subscriptionLower
          subscriptionDate
          subscriptionExpiryDate
          keepScreenOn
          isFlareOn
          automaticStartOn
          totalBalance
          totalReward
          totalDriversAlerted
          totalDistanceCovered
          totalProtectedJourneys
          totalRideTimeInSeconds
          totalNearMissesReported
          country
          deviceId
          fcmToken
          deviceType
          isEmailVerified
          latitude
          longitude
          partnerId
          retailCodeUsed
          retailCodeUsedLower
          riderActivities
          riderType
          ridingPeriod
          rewardAccessType
          rewardAccessTypeLower
          rewardAccessStartDate
          rewardAccessEndDate
          rewardAccessSubTotal
          rewardAccessVAT
          rewardAccessVATAmount
          rewardAccessTotalAmount
          rewardAccessStripeToken
          rewardAccessStripeReceipt
          isRewardAccessPaymentSuccessful
          rewardAccessMode
          isPaidToAffiliate
          isSmartStartOn
          referralId
          registrationProvider
          activityTimeInSeconds
          totalRewardsRedeemed
          reputationScore
          appVersion
          sweatCoinAuthenticationToken
          lastAccessed
          isSmartStartNotificationEnable
          isMarketPlaceNotificationEnable
          agreedConsents {
            description
            type
            accepted
          }
          isActive
          isDeleted
          deletedUser
          createdAt
          updateAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          owner
          partner {
            id
            invitedByPartnerId
            partnerCode
            dynamicLink
            desktopURL
            companyName
            companyNameLower
            firstName
            firstNameLower
            lastName
            lastNameLower
            userName
            userNameLower
            email
            emailLower
            password
            profilePicture
            companyEmblem
            phone
            addressLine1
            addressLine2
            city
            country
            countryIsoCode
            postcode
            deviceType
            fcmToken
            totalFlaresReceived
            totalFlaresRespondedTo
            totalIncidentsReceived
            totalIncidentsActioned
            latitude
            longitude
            retailCodePrice
            resalePrice
            invoicePayment
            rewardAvailabilityCountries
            referralURL
            referralIntroText
            referralBackgroundImage
            referralRetailPrice
            incidentNotificationSettings
            notificationTitle
            notificationMessage
            notificationPeriod
            hasRiderConfirmed
            iotEndpoint
            enableRewards
            enableReferrals
            enableAppDiscountsHub
            enableFlares
            enableRiderManagement
            enableRetail
            enableAutoStart
            enableEmergencySOS
            enableSdk
            enforceEmergencyContact
            enforceSafeZone
            enforcePhoneNumber
            youtubeLink
            enforceRiderId
            termsAndConditionLink
            privacyPolicyLink
            landingPagePoints {
              text
            }
            sandboxFlareApiKey
            sandboxFlareApiKeyUsage
            productionFlareApiKey
            productionFlareApiKeyUsage
            licenseKeys {
              licenseKey
              keyName
              environment
              totalUsage
              totalIncidentDetected
              isActive
              isDeleted
              deletedKey
              lastUsed
              createdAt
              updatedAt
            }
            incidentNotifications {
              notificationSettings
              notificationEmail
              notificationCountry
              notificationCountryCallingCode
              notificationMobileNo
              notificationWebhookURL
              includeRiderEmail
              riderID
              riderName
              riderCity
              riderEmail
              incidentDateAndTime
              ISOCountryCode
              incidentLocation
              incidentTelemetry
            }
            sosNotificationSettings
            sosNotifications {
              notificationSettings
              notificationEmail
              notificationCountry
              notificationCountryCallingCode
              notificationMobileNo
              notificationWebhookURL
              includeRiderEmail
              riderID
              riderName
              riderCity
              riderEmail
              incidentDateAndTime
              ISOCountryCode
              incidentLocation
              incidentTelemetry
            }
            consentCheckbox {
              title
              fileType
              link
              description
              type
            }
            enabledTwoStepVerification
            elasticIndexName
            isAccountVerified
            isActive
            isDeleted
            deletedPartner
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
          }
        }
        group {
          id
          createdByUserId
          nanoid
          name
          nameLower
          groupCode
          groupCodeLower
          qrImage
          rideRadius
          rideDuration
          latitude
          longitude
          isEnded
          isDeleted
          deletedGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          user {
            id
            firstName
            firstNameLower
            lastName
            lastNameLower
            userName
            userNameLower
            referralCode
            referralName
            isReferralRedeem
            email
            emailLower
            profilePicture
            phoneNumber
            countDownDuration
            subscription
            subscriptionLower
            subscriptionDate
            subscriptionExpiryDate
            keepScreenOn
            isFlareOn
            automaticStartOn
            totalBalance
            totalReward
            totalDriversAlerted
            totalDistanceCovered
            totalProtectedJourneys
            totalRideTimeInSeconds
            totalNearMissesReported
            country
            deviceId
            fcmToken
            deviceType
            isEmailVerified
            latitude
            longitude
            partnerId
            retailCodeUsed
            retailCodeUsedLower
            riderActivities
            riderType
            ridingPeriod
            rewardAccessType
            rewardAccessTypeLower
            rewardAccessStartDate
            rewardAccessEndDate
            rewardAccessSubTotal
            rewardAccessVAT
            rewardAccessVATAmount
            rewardAccessTotalAmount
            rewardAccessStripeToken
            rewardAccessStripeReceipt
            isRewardAccessPaymentSuccessful
            rewardAccessMode
            isPaidToAffiliate
            isSmartStartOn
            referralId
            registrationProvider
            activityTimeInSeconds
            totalRewardsRedeemed
            reputationScore
            appVersion
            sweatCoinAuthenticationToken
            lastAccessed
            isSmartStartNotificationEnable
            isMarketPlaceNotificationEnable
            agreedConsents {
              description
              type
              accepted
            }
            isActive
            isDeleted
            deletedUser
            createdAt
            updateAt
            _version
            _deleted
            _lastChangedAt
            updatedAt
            owner
            partner {
              id
              invitedByPartnerId
              partnerCode
              dynamicLink
              desktopURL
              companyName
              companyNameLower
              firstName
              firstNameLower
              lastName
              lastNameLower
              userName
              userNameLower
              email
              emailLower
              password
              profilePicture
              companyEmblem
              phone
              addressLine1
              addressLine2
              city
              country
              countryIsoCode
              postcode
              deviceType
              fcmToken
              totalFlaresReceived
              totalFlaresRespondedTo
              totalIncidentsReceived
              totalIncidentsActioned
              latitude
              longitude
              retailCodePrice
              resalePrice
              invoicePayment
              rewardAvailabilityCountries
              referralURL
              referralIntroText
              referralBackgroundImage
              referralRetailPrice
              incidentNotificationSettings
              notificationTitle
              notificationMessage
              notificationPeriod
              hasRiderConfirmed
              iotEndpoint
              enableRewards
              enableReferrals
              enableAppDiscountsHub
              enableFlares
              enableRiderManagement
              enableRetail
              enableAutoStart
              enableEmergencySOS
              enableSdk
              enforceEmergencyContact
              enforceSafeZone
              enforcePhoneNumber
              youtubeLink
              enforceRiderId
              termsAndConditionLink
              privacyPolicyLink
              sandboxFlareApiKey
              sandboxFlareApiKeyUsage
              productionFlareApiKey
              productionFlareApiKeyUsage
              sosNotificationSettings
              enabledTwoStepVerification
              elasticIndexName
              isAccountVerified
              isActive
              isDeleted
              deletedPartner
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
            }
          }
          owner
        }
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getGroupsByLoggedInUserId = /* GraphQL */ `
  query GetGroupsByLoggedInUserId(
    $userId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelParticipantsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getGroupsByLoggedInUserId(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        userId
        name
        latitude
        longitude
        isJoin
        isLeftGroup
        isRemove
        isIncident
        incidentProcessCompleted
        date
        distance
        time
        coinsEarned
        coordinates
        isDeleted
        deletedParticipant
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          referralCode
          referralName
          isReferralRedeem
          email
          emailLower
          profilePicture
          phoneNumber
          countDownDuration
          subscription
          subscriptionLower
          subscriptionDate
          subscriptionExpiryDate
          keepScreenOn
          isFlareOn
          automaticStartOn
          totalBalance
          totalReward
          totalDriversAlerted
          totalDistanceCovered
          totalProtectedJourneys
          totalRideTimeInSeconds
          totalNearMissesReported
          country
          deviceId
          fcmToken
          deviceType
          isEmailVerified
          latitude
          longitude
          partnerId
          retailCodeUsed
          retailCodeUsedLower
          riderActivities
          riderType
          ridingPeriod
          rewardAccessType
          rewardAccessTypeLower
          rewardAccessStartDate
          rewardAccessEndDate
          rewardAccessSubTotal
          rewardAccessVAT
          rewardAccessVATAmount
          rewardAccessTotalAmount
          rewardAccessStripeToken
          rewardAccessStripeReceipt
          isRewardAccessPaymentSuccessful
          rewardAccessMode
          isPaidToAffiliate
          isSmartStartOn
          referralId
          registrationProvider
          activityTimeInSeconds
          totalRewardsRedeemed
          reputationScore
          appVersion
          sweatCoinAuthenticationToken
          lastAccessed
          isSmartStartNotificationEnable
          isMarketPlaceNotificationEnable
          agreedConsents {
            description
            type
            accepted
          }
          isActive
          isDeleted
          deletedUser
          createdAt
          updateAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          owner
          partner {
            id
            invitedByPartnerId
            partnerCode
            dynamicLink
            desktopURL
            companyName
            companyNameLower
            firstName
            firstNameLower
            lastName
            lastNameLower
            userName
            userNameLower
            email
            emailLower
            password
            profilePicture
            companyEmblem
            phone
            addressLine1
            addressLine2
            city
            country
            countryIsoCode
            postcode
            deviceType
            fcmToken
            totalFlaresReceived
            totalFlaresRespondedTo
            totalIncidentsReceived
            totalIncidentsActioned
            latitude
            longitude
            retailCodePrice
            resalePrice
            invoicePayment
            rewardAvailabilityCountries
            referralURL
            referralIntroText
            referralBackgroundImage
            referralRetailPrice
            incidentNotificationSettings
            notificationTitle
            notificationMessage
            notificationPeriod
            hasRiderConfirmed
            iotEndpoint
            enableRewards
            enableReferrals
            enableAppDiscountsHub
            enableFlares
            enableRiderManagement
            enableRetail
            enableAutoStart
            enableEmergencySOS
            enableSdk
            enforceEmergencyContact
            enforceSafeZone
            enforcePhoneNumber
            youtubeLink
            enforceRiderId
            termsAndConditionLink
            privacyPolicyLink
            landingPagePoints {
              text
            }
            sandboxFlareApiKey
            sandboxFlareApiKeyUsage
            productionFlareApiKey
            productionFlareApiKeyUsage
            licenseKeys {
              licenseKey
              keyName
              environment
              totalUsage
              totalIncidentDetected
              isActive
              isDeleted
              deletedKey
              lastUsed
              createdAt
              updatedAt
            }
            incidentNotifications {
              notificationSettings
              notificationEmail
              notificationCountry
              notificationCountryCallingCode
              notificationMobileNo
              notificationWebhookURL
              includeRiderEmail
              riderID
              riderName
              riderCity
              riderEmail
              incidentDateAndTime
              ISOCountryCode
              incidentLocation
              incidentTelemetry
            }
            sosNotificationSettings
            sosNotifications {
              notificationSettings
              notificationEmail
              notificationCountry
              notificationCountryCallingCode
              notificationMobileNo
              notificationWebhookURL
              includeRiderEmail
              riderID
              riderName
              riderCity
              riderEmail
              incidentDateAndTime
              ISOCountryCode
              incidentLocation
              incidentTelemetry
            }
            consentCheckbox {
              title
              fileType
              link
              description
              type
            }
            enabledTwoStepVerification
            elasticIndexName
            isAccountVerified
            isActive
            isDeleted
            deletedPartner
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
          }
        }
        group {
          id
          createdByUserId
          nanoid
          name
          nameLower
          groupCode
          groupCodeLower
          qrImage
          rideRadius
          rideDuration
          latitude
          longitude
          isEnded
          isDeleted
          deletedGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          user {
            id
            firstName
            firstNameLower
            lastName
            lastNameLower
            userName
            userNameLower
            referralCode
            referralName
            isReferralRedeem
            email
            emailLower
            profilePicture
            phoneNumber
            countDownDuration
            subscription
            subscriptionLower
            subscriptionDate
            subscriptionExpiryDate
            keepScreenOn
            isFlareOn
            automaticStartOn
            totalBalance
            totalReward
            totalDriversAlerted
            totalDistanceCovered
            totalProtectedJourneys
            totalRideTimeInSeconds
            totalNearMissesReported
            country
            deviceId
            fcmToken
            deviceType
            isEmailVerified
            latitude
            longitude
            partnerId
            retailCodeUsed
            retailCodeUsedLower
            riderActivities
            riderType
            ridingPeriod
            rewardAccessType
            rewardAccessTypeLower
            rewardAccessStartDate
            rewardAccessEndDate
            rewardAccessSubTotal
            rewardAccessVAT
            rewardAccessVATAmount
            rewardAccessTotalAmount
            rewardAccessStripeToken
            rewardAccessStripeReceipt
            isRewardAccessPaymentSuccessful
            rewardAccessMode
            isPaidToAffiliate
            isSmartStartOn
            referralId
            registrationProvider
            activityTimeInSeconds
            totalRewardsRedeemed
            reputationScore
            appVersion
            sweatCoinAuthenticationToken
            lastAccessed
            isSmartStartNotificationEnable
            isMarketPlaceNotificationEnable
            agreedConsents {
              description
              type
              accepted
            }
            isActive
            isDeleted
            deletedUser
            createdAt
            updateAt
            _version
            _deleted
            _lastChangedAt
            updatedAt
            owner
            partner {
              id
              invitedByPartnerId
              partnerCode
              dynamicLink
              desktopURL
              companyName
              companyNameLower
              firstName
              firstNameLower
              lastName
              lastNameLower
              userName
              userNameLower
              email
              emailLower
              password
              profilePicture
              companyEmblem
              phone
              addressLine1
              addressLine2
              city
              country
              countryIsoCode
              postcode
              deviceType
              fcmToken
              totalFlaresReceived
              totalFlaresRespondedTo
              totalIncidentsReceived
              totalIncidentsActioned
              latitude
              longitude
              retailCodePrice
              resalePrice
              invoicePayment
              rewardAvailabilityCountries
              referralURL
              referralIntroText
              referralBackgroundImage
              referralRetailPrice
              incidentNotificationSettings
              notificationTitle
              notificationMessage
              notificationPeriod
              hasRiderConfirmed
              iotEndpoint
              enableRewards
              enableReferrals
              enableAppDiscountsHub
              enableFlares
              enableRiderManagement
              enableRetail
              enableAutoStart
              enableEmergencySOS
              enableSdk
              enforceEmergencyContact
              enforceSafeZone
              enforcePhoneNumber
              youtubeLink
              enforceRiderId
              termsAndConditionLink
              privacyPolicyLink
              sandboxFlareApiKey
              sandboxFlareApiKeyUsage
              productionFlareApiKey
              productionFlareApiKeyUsage
              sosNotificationSettings
              enabledTwoStepVerification
              elasticIndexName
              isAccountVerified
              isActive
              isDeleted
              deletedPartner
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
            }
          }
          owner
        }
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncParticipants = /* GraphQL */ `
  query SyncParticipants(
    $filter: ModelParticipantsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncParticipants(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        groupId
        userId
        name
        latitude
        longitude
        isJoin
        isLeftGroup
        isRemove
        isIncident
        incidentProcessCompleted
        date
        distance
        time
        coinsEarned
        coordinates
        isDeleted
        deletedParticipant
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          referralCode
          referralName
          isReferralRedeem
          email
          emailLower
          profilePicture
          phoneNumber
          countDownDuration
          subscription
          subscriptionLower
          subscriptionDate
          subscriptionExpiryDate
          keepScreenOn
          isFlareOn
          automaticStartOn
          totalBalance
          totalReward
          totalDriversAlerted
          totalDistanceCovered
          totalProtectedJourneys
          totalRideTimeInSeconds
          totalNearMissesReported
          country
          deviceId
          fcmToken
          deviceType
          isEmailVerified
          latitude
          longitude
          partnerId
          retailCodeUsed
          retailCodeUsedLower
          riderActivities
          riderType
          ridingPeriod
          rewardAccessType
          rewardAccessTypeLower
          rewardAccessStartDate
          rewardAccessEndDate
          rewardAccessSubTotal
          rewardAccessVAT
          rewardAccessVATAmount
          rewardAccessTotalAmount
          rewardAccessStripeToken
          rewardAccessStripeReceipt
          isRewardAccessPaymentSuccessful
          rewardAccessMode
          isPaidToAffiliate
          isSmartStartOn
          referralId
          registrationProvider
          activityTimeInSeconds
          totalRewardsRedeemed
          reputationScore
          appVersion
          sweatCoinAuthenticationToken
          lastAccessed
          isSmartStartNotificationEnable
          isMarketPlaceNotificationEnable
          agreedConsents {
            description
            type
            accepted
          }
          isActive
          isDeleted
          deletedUser
          createdAt
          updateAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          owner
          partner {
            id
            invitedByPartnerId
            partnerCode
            dynamicLink
            desktopURL
            companyName
            companyNameLower
            firstName
            firstNameLower
            lastName
            lastNameLower
            userName
            userNameLower
            email
            emailLower
            password
            profilePicture
            companyEmblem
            phone
            addressLine1
            addressLine2
            city
            country
            countryIsoCode
            postcode
            deviceType
            fcmToken
            totalFlaresReceived
            totalFlaresRespondedTo
            totalIncidentsReceived
            totalIncidentsActioned
            latitude
            longitude
            retailCodePrice
            resalePrice
            invoicePayment
            rewardAvailabilityCountries
            referralURL
            referralIntroText
            referralBackgroundImage
            referralRetailPrice
            incidentNotificationSettings
            notificationTitle
            notificationMessage
            notificationPeriod
            hasRiderConfirmed
            iotEndpoint
            enableRewards
            enableReferrals
            enableAppDiscountsHub
            enableFlares
            enableRiderManagement
            enableRetail
            enableAutoStart
            enableEmergencySOS
            enableSdk
            enforceEmergencyContact
            enforceSafeZone
            enforcePhoneNumber
            youtubeLink
            enforceRiderId
            termsAndConditionLink
            privacyPolicyLink
            landingPagePoints {
              text
            }
            sandboxFlareApiKey
            sandboxFlareApiKeyUsage
            productionFlareApiKey
            productionFlareApiKeyUsage
            licenseKeys {
              licenseKey
              keyName
              environment
              totalUsage
              totalIncidentDetected
              isActive
              isDeleted
              deletedKey
              lastUsed
              createdAt
              updatedAt
            }
            incidentNotifications {
              notificationSettings
              notificationEmail
              notificationCountry
              notificationCountryCallingCode
              notificationMobileNo
              notificationWebhookURL
              includeRiderEmail
              riderID
              riderName
              riderCity
              riderEmail
              incidentDateAndTime
              ISOCountryCode
              incidentLocation
              incidentTelemetry
            }
            sosNotificationSettings
            sosNotifications {
              notificationSettings
              notificationEmail
              notificationCountry
              notificationCountryCallingCode
              notificationMobileNo
              notificationWebhookURL
              includeRiderEmail
              riderID
              riderName
              riderCity
              riderEmail
              incidentDateAndTime
              ISOCountryCode
              incidentLocation
              incidentTelemetry
            }
            consentCheckbox {
              title
              fileType
              link
              description
              type
            }
            enabledTwoStepVerification
            elasticIndexName
            isAccountVerified
            isActive
            isDeleted
            deletedPartner
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
          }
        }
        group {
          id
          createdByUserId
          nanoid
          name
          nameLower
          groupCode
          groupCodeLower
          qrImage
          rideRadius
          rideDuration
          latitude
          longitude
          isEnded
          isDeleted
          deletedGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          user {
            id
            firstName
            firstNameLower
            lastName
            lastNameLower
            userName
            userNameLower
            referralCode
            referralName
            isReferralRedeem
            email
            emailLower
            profilePicture
            phoneNumber
            countDownDuration
            subscription
            subscriptionLower
            subscriptionDate
            subscriptionExpiryDate
            keepScreenOn
            isFlareOn
            automaticStartOn
            totalBalance
            totalReward
            totalDriversAlerted
            totalDistanceCovered
            totalProtectedJourneys
            totalRideTimeInSeconds
            totalNearMissesReported
            country
            deviceId
            fcmToken
            deviceType
            isEmailVerified
            latitude
            longitude
            partnerId
            retailCodeUsed
            retailCodeUsedLower
            riderActivities
            riderType
            ridingPeriod
            rewardAccessType
            rewardAccessTypeLower
            rewardAccessStartDate
            rewardAccessEndDate
            rewardAccessSubTotal
            rewardAccessVAT
            rewardAccessVATAmount
            rewardAccessTotalAmount
            rewardAccessStripeToken
            rewardAccessStripeReceipt
            isRewardAccessPaymentSuccessful
            rewardAccessMode
            isPaidToAffiliate
            isSmartStartOn
            referralId
            registrationProvider
            activityTimeInSeconds
            totalRewardsRedeemed
            reputationScore
            appVersion
            sweatCoinAuthenticationToken
            lastAccessed
            isSmartStartNotificationEnable
            isMarketPlaceNotificationEnable
            agreedConsents {
              description
              type
              accepted
            }
            isActive
            isDeleted
            deletedUser
            createdAt
            updateAt
            _version
            _deleted
            _lastChangedAt
            updatedAt
            owner
            partner {
              id
              invitedByPartnerId
              partnerCode
              dynamicLink
              desktopURL
              companyName
              companyNameLower
              firstName
              firstNameLower
              lastName
              lastNameLower
              userName
              userNameLower
              email
              emailLower
              password
              profilePicture
              companyEmblem
              phone
              addressLine1
              addressLine2
              city
              country
              countryIsoCode
              postcode
              deviceType
              fcmToken
              totalFlaresReceived
              totalFlaresRespondedTo
              totalIncidentsReceived
              totalIncidentsActioned
              latitude
              longitude
              retailCodePrice
              resalePrice
              invoicePayment
              rewardAvailabilityCountries
              referralURL
              referralIntroText
              referralBackgroundImage
              referralRetailPrice
              incidentNotificationSettings
              notificationTitle
              notificationMessage
              notificationPeriod
              hasRiderConfirmed
              iotEndpoint
              enableRewards
              enableReferrals
              enableAppDiscountsHub
              enableFlares
              enableRiderManagement
              enableRetail
              enableAutoStart
              enableEmergencySOS
              enableSdk
              enforceEmergencyContact
              enforceSafeZone
              enforcePhoneNumber
              youtubeLink
              enforceRiderId
              termsAndConditionLink
              privacyPolicyLink
              sandboxFlareApiKey
              sandboxFlareApiKeyUsage
              productionFlareApiKey
              productionFlareApiKeyUsage
              sosNotificationSettings
              enabledTwoStepVerification
              elasticIndexName
              isAccountVerified
              isActive
              isDeleted
              deletedPartner
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
            }
          }
          owner
        }
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getDeletedUsers = /* GraphQL */ `
  query GetDeletedUsers($id: ID!) {
    getDeletedUsers(id: $id) {
      id
      userId
      email
      sweatCoinAuthenticationToken
      deletedFromSweatCoin
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listDeletedUserss = /* GraphQL */ `
  query ListDeletedUserss(
    $filter: ModelDeletedUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeletedUserss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        email
        sweatCoinAuthenticationToken
        deletedFromSweatCoin
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getUsersDeletedFromSweatCoin = /* GraphQL */ `
  query GetUsersDeletedFromSweatCoin(
    $deletedFromSweatCoin: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeletedUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUsersDeletedFromSweatCoin(
      deletedFromSweatCoin: $deletedFromSweatCoin
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        email
        sweatCoinAuthenticationToken
        deletedFromSweatCoin
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDeletedUsers = /* GraphQL */ `
  query SyncDeletedUsers(
    $filter: ModelDeletedUsersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDeletedUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        email
        sweatCoinAuthenticationToken
        deletedFromSweatCoin
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getPartners = /* GraphQL */ `
  query GetPartners($id: ID!) {
    getPartners(id: $id) {
      id
      invitedByPartnerId
      partnerCode
      dynamicLink
      desktopURL
      companyName
      companyNameLower
      firstName
      firstNameLower
      lastName
      lastNameLower
      userName
      userNameLower
      email
      emailLower
      password
      profilePicture
      companyEmblem
      phone
      addressLine1
      addressLine2
      city
      country
      countryIsoCode
      postcode
      deviceType
      fcmToken
      totalFlaresReceived
      totalFlaresRespondedTo
      totalIncidentsReceived
      totalIncidentsActioned
      latitude
      longitude
      retailCodePrice
      resalePrice
      invoicePayment
      rewardAvailabilityCountries
      referralURL
      referralIntroText
      referralBackgroundImage
      referralRetailPrice
      incidentNotificationSettings
      notificationTitle
      notificationMessage
      notificationPeriod
      hasRiderConfirmed
      iotEndpoint
      enableRewards
      enableReferrals
      enableAppDiscountsHub
      enableFlares
      enableRiderManagement
      enableRetail
      enableAutoStart
      enableEmergencySOS
      enableSdk
      enforceEmergencyContact
      enforceSafeZone
      enforcePhoneNumber
      youtubeLink
      enforceRiderId
      termsAndConditionLink
      privacyPolicyLink
      landingPagePoints {
        text
      }
      sandboxFlareApiKey
      sandboxFlareApiKeyUsage
      productionFlareApiKey
      productionFlareApiKeyUsage
      licenseKeys {
        licenseKey
        keyName
        environment
        totalUsage
        totalIncidentDetected
        isActive
        isDeleted
        deletedKey
        lastUsed
        createdAt
        updatedAt
      }
      incidentNotifications {
        notificationSettings
        notificationEmail
        notificationCountry
        notificationCountryCallingCode
        notificationMobileNo
        notificationWebhookURL
        includeRiderEmail
        riderID
        riderName
        riderCity
        riderEmail
        incidentDateAndTime
        ISOCountryCode
        incidentLocation
        incidentTelemetry
      }
      sosNotificationSettings
      sosNotifications {
        notificationSettings
        notificationEmail
        notificationCountry
        notificationCountryCallingCode
        notificationMobileNo
        notificationWebhookURL
        includeRiderEmail
        riderID
        riderName
        riderCity
        riderEmail
        incidentDateAndTime
        ISOCountryCode
        incidentLocation
        incidentTelemetry
      }
      consentCheckbox {
        title
        fileType
        link
        description
        type
      }
      enabledTwoStepVerification
      elasticIndexName
      isAccountVerified
      isActive
      isDeleted
      deletedPartner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listPartnerss = /* GraphQL */ `
  query ListPartnerss(
    $filter: ModelPartnersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartnerss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        invitedByPartnerId
        partnerCode
        dynamicLink
        desktopURL
        companyName
        companyNameLower
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        email
        emailLower
        password
        profilePicture
        companyEmblem
        phone
        addressLine1
        addressLine2
        city
        country
        countryIsoCode
        postcode
        deviceType
        fcmToken
        totalFlaresReceived
        totalFlaresRespondedTo
        totalIncidentsReceived
        totalIncidentsActioned
        latitude
        longitude
        retailCodePrice
        resalePrice
        invoicePayment
        rewardAvailabilityCountries
        referralURL
        referralIntroText
        referralBackgroundImage
        referralRetailPrice
        incidentNotificationSettings
        notificationTitle
        notificationMessage
        notificationPeriod
        hasRiderConfirmed
        iotEndpoint
        enableRewards
        enableReferrals
        enableAppDiscountsHub
        enableFlares
        enableRiderManagement
        enableRetail
        enableAutoStart
        enableEmergencySOS
        enableSdk
        enforceEmergencyContact
        enforceSafeZone
        enforcePhoneNumber
        youtubeLink
        enforceRiderId
        termsAndConditionLink
        privacyPolicyLink
        landingPagePoints {
          text
        }
        sandboxFlareApiKey
        sandboxFlareApiKeyUsage
        productionFlareApiKey
        productionFlareApiKeyUsage
        licenseKeys {
          licenseKey
          keyName
          environment
          totalUsage
          totalIncidentDetected
          isActive
          isDeleted
          deletedKey
          lastUsed
          createdAt
          updatedAt
        }
        incidentNotifications {
          notificationSettings
          notificationEmail
          notificationCountry
          notificationCountryCallingCode
          notificationMobileNo
          notificationWebhookURL
          includeRiderEmail
          riderID
          riderName
          riderCity
          riderEmail
          incidentDateAndTime
          ISOCountryCode
          incidentLocation
          incidentTelemetry
        }
        sosNotificationSettings
        sosNotifications {
          notificationSettings
          notificationEmail
          notificationCountry
          notificationCountryCallingCode
          notificationMobileNo
          notificationWebhookURL
          includeRiderEmail
          riderID
          riderName
          riderCity
          riderEmail
          incidentDateAndTime
          ISOCountryCode
          incidentLocation
          incidentTelemetry
        }
        consentCheckbox {
          title
          fileType
          link
          description
          type
        }
        enabledTwoStepVerification
        elasticIndexName
        isAccountVerified
        isActive
        isDeleted
        deletedPartner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getPartnerByEmail = /* GraphQL */ `
  query GetPartnerByEmail(
    $email: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPartnersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPartnerByEmail(
      email: $email
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        invitedByPartnerId
        partnerCode
        dynamicLink
        desktopURL
        companyName
        companyNameLower
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        email
        emailLower
        password
        profilePicture
        companyEmblem
        phone
        addressLine1
        addressLine2
        city
        country
        countryIsoCode
        postcode
        deviceType
        fcmToken
        totalFlaresReceived
        totalFlaresRespondedTo
        totalIncidentsReceived
        totalIncidentsActioned
        latitude
        longitude
        retailCodePrice
        resalePrice
        invoicePayment
        rewardAvailabilityCountries
        referralURL
        referralIntroText
        referralBackgroundImage
        referralRetailPrice
        incidentNotificationSettings
        notificationTitle
        notificationMessage
        notificationPeriod
        hasRiderConfirmed
        iotEndpoint
        enableRewards
        enableReferrals
        enableAppDiscountsHub
        enableFlares
        enableRiderManagement
        enableRetail
        enableAutoStart
        enableEmergencySOS
        enableSdk
        enforceEmergencyContact
        enforceSafeZone
        enforcePhoneNumber
        youtubeLink
        enforceRiderId
        termsAndConditionLink
        privacyPolicyLink
        landingPagePoints {
          text
        }
        sandboxFlareApiKey
        sandboxFlareApiKeyUsage
        productionFlareApiKey
        productionFlareApiKeyUsage
        licenseKeys {
          licenseKey
          keyName
          environment
          totalUsage
          totalIncidentDetected
          isActive
          isDeleted
          deletedKey
          lastUsed
          createdAt
          updatedAt
        }
        incidentNotifications {
          notificationSettings
          notificationEmail
          notificationCountry
          notificationCountryCallingCode
          notificationMobileNo
          notificationWebhookURL
          includeRiderEmail
          riderID
          riderName
          riderCity
          riderEmail
          incidentDateAndTime
          ISOCountryCode
          incidentLocation
          incidentTelemetry
        }
        sosNotificationSettings
        sosNotifications {
          notificationSettings
          notificationEmail
          notificationCountry
          notificationCountryCallingCode
          notificationMobileNo
          notificationWebhookURL
          includeRiderEmail
          riderID
          riderName
          riderCity
          riderEmail
          incidentDateAndTime
          ISOCountryCode
          incidentLocation
          incidentTelemetry
        }
        consentCheckbox {
          title
          fileType
          link
          description
          type
        }
        enabledTwoStepVerification
        elasticIndexName
        isAccountVerified
        isActive
        isDeleted
        deletedPartner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getPartnersSortedByCreatedAt = /* GraphQL */ `
  query GetPartnersSortedByCreatedAt(
    $deletedPartner: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPartnersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPartnersSortedByCreatedAt(
      deletedPartner: $deletedPartner
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        invitedByPartnerId
        partnerCode
        dynamicLink
        desktopURL
        companyName
        companyNameLower
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        email
        emailLower
        password
        profilePicture
        companyEmblem
        phone
        addressLine1
        addressLine2
        city
        country
        countryIsoCode
        postcode
        deviceType
        fcmToken
        totalFlaresReceived
        totalFlaresRespondedTo
        totalIncidentsReceived
        totalIncidentsActioned
        latitude
        longitude
        retailCodePrice
        resalePrice
        invoicePayment
        rewardAvailabilityCountries
        referralURL
        referralIntroText
        referralBackgroundImage
        referralRetailPrice
        incidentNotificationSettings
        notificationTitle
        notificationMessage
        notificationPeriod
        hasRiderConfirmed
        iotEndpoint
        enableRewards
        enableReferrals
        enableAppDiscountsHub
        enableFlares
        enableRiderManagement
        enableRetail
        enableAutoStart
        enableEmergencySOS
        enableSdk
        enforceEmergencyContact
        enforceSafeZone
        enforcePhoneNumber
        youtubeLink
        enforceRiderId
        termsAndConditionLink
        privacyPolicyLink
        landingPagePoints {
          text
        }
        sandboxFlareApiKey
        sandboxFlareApiKeyUsage
        productionFlareApiKey
        productionFlareApiKeyUsage
        licenseKeys {
          licenseKey
          keyName
          environment
          totalUsage
          totalIncidentDetected
          isActive
          isDeleted
          deletedKey
          lastUsed
          createdAt
          updatedAt
        }
        incidentNotifications {
          notificationSettings
          notificationEmail
          notificationCountry
          notificationCountryCallingCode
          notificationMobileNo
          notificationWebhookURL
          includeRiderEmail
          riderID
          riderName
          riderCity
          riderEmail
          incidentDateAndTime
          ISOCountryCode
          incidentLocation
          incidentTelemetry
        }
        sosNotificationSettings
        sosNotifications {
          notificationSettings
          notificationEmail
          notificationCountry
          notificationCountryCallingCode
          notificationMobileNo
          notificationWebhookURL
          includeRiderEmail
          riderID
          riderName
          riderCity
          riderEmail
          incidentDateAndTime
          ISOCountryCode
          incidentLocation
          incidentTelemetry
        }
        consentCheckbox {
          title
          fileType
          link
          description
          type
        }
        enabledTwoStepVerification
        elasticIndexName
        isAccountVerified
        isActive
        isDeleted
        deletedPartner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getPartnersSortedByCompanyName = /* GraphQL */ `
  query GetPartnersSortedByCompanyName(
    $deletedPartner: String
    $companyName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPartnersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPartnersSortedByCompanyName(
      deletedPartner: $deletedPartner
      companyName: $companyName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        invitedByPartnerId
        partnerCode
        dynamicLink
        desktopURL
        companyName
        companyNameLower
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        email
        emailLower
        password
        profilePicture
        companyEmblem
        phone
        addressLine1
        addressLine2
        city
        country
        countryIsoCode
        postcode
        deviceType
        fcmToken
        totalFlaresReceived
        totalFlaresRespondedTo
        totalIncidentsReceived
        totalIncidentsActioned
        latitude
        longitude
        retailCodePrice
        resalePrice
        invoicePayment
        rewardAvailabilityCountries
        referralURL
        referralIntroText
        referralBackgroundImage
        referralRetailPrice
        incidentNotificationSettings
        notificationTitle
        notificationMessage
        notificationPeriod
        hasRiderConfirmed
        iotEndpoint
        enableRewards
        enableReferrals
        enableAppDiscountsHub
        enableFlares
        enableRiderManagement
        enableRetail
        enableAutoStart
        enableEmergencySOS
        enableSdk
        enforceEmergencyContact
        enforceSafeZone
        enforcePhoneNumber
        youtubeLink
        enforceRiderId
        termsAndConditionLink
        privacyPolicyLink
        landingPagePoints {
          text
        }
        sandboxFlareApiKey
        sandboxFlareApiKeyUsage
        productionFlareApiKey
        productionFlareApiKeyUsage
        licenseKeys {
          licenseKey
          keyName
          environment
          totalUsage
          totalIncidentDetected
          isActive
          isDeleted
          deletedKey
          lastUsed
          createdAt
          updatedAt
        }
        incidentNotifications {
          notificationSettings
          notificationEmail
          notificationCountry
          notificationCountryCallingCode
          notificationMobileNo
          notificationWebhookURL
          includeRiderEmail
          riderID
          riderName
          riderCity
          riderEmail
          incidentDateAndTime
          ISOCountryCode
          incidentLocation
          incidentTelemetry
        }
        sosNotificationSettings
        sosNotifications {
          notificationSettings
          notificationEmail
          notificationCountry
          notificationCountryCallingCode
          notificationMobileNo
          notificationWebhookURL
          includeRiderEmail
          riderID
          riderName
          riderCity
          riderEmail
          incidentDateAndTime
          ISOCountryCode
          incidentLocation
          incidentTelemetry
        }
        consentCheckbox {
          title
          fileType
          link
          description
          type
        }
        enabledTwoStepVerification
        elasticIndexName
        isAccountVerified
        isActive
        isDeleted
        deletedPartner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getPartnersSortedByFirstName = /* GraphQL */ `
  query GetPartnersSortedByFirstName(
    $deletedPartner: String
    $firstName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPartnersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPartnersSortedByFirstName(
      deletedPartner: $deletedPartner
      firstName: $firstName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        invitedByPartnerId
        partnerCode
        dynamicLink
        desktopURL
        companyName
        companyNameLower
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        email
        emailLower
        password
        profilePicture
        companyEmblem
        phone
        addressLine1
        addressLine2
        city
        country
        countryIsoCode
        postcode
        deviceType
        fcmToken
        totalFlaresReceived
        totalFlaresRespondedTo
        totalIncidentsReceived
        totalIncidentsActioned
        latitude
        longitude
        retailCodePrice
        resalePrice
        invoicePayment
        rewardAvailabilityCountries
        referralURL
        referralIntroText
        referralBackgroundImage
        referralRetailPrice
        incidentNotificationSettings
        notificationTitle
        notificationMessage
        notificationPeriod
        hasRiderConfirmed
        iotEndpoint
        enableRewards
        enableReferrals
        enableAppDiscountsHub
        enableFlares
        enableRiderManagement
        enableRetail
        enableAutoStart
        enableEmergencySOS
        enableSdk
        enforceEmergencyContact
        enforceSafeZone
        enforcePhoneNumber
        youtubeLink
        enforceRiderId
        termsAndConditionLink
        privacyPolicyLink
        landingPagePoints {
          text
        }
        sandboxFlareApiKey
        sandboxFlareApiKeyUsage
        productionFlareApiKey
        productionFlareApiKeyUsage
        licenseKeys {
          licenseKey
          keyName
          environment
          totalUsage
          totalIncidentDetected
          isActive
          isDeleted
          deletedKey
          lastUsed
          createdAt
          updatedAt
        }
        incidentNotifications {
          notificationSettings
          notificationEmail
          notificationCountry
          notificationCountryCallingCode
          notificationMobileNo
          notificationWebhookURL
          includeRiderEmail
          riderID
          riderName
          riderCity
          riderEmail
          incidentDateAndTime
          ISOCountryCode
          incidentLocation
          incidentTelemetry
        }
        sosNotificationSettings
        sosNotifications {
          notificationSettings
          notificationEmail
          notificationCountry
          notificationCountryCallingCode
          notificationMobileNo
          notificationWebhookURL
          includeRiderEmail
          riderID
          riderName
          riderCity
          riderEmail
          incidentDateAndTime
          ISOCountryCode
          incidentLocation
          incidentTelemetry
        }
        consentCheckbox {
          title
          fileType
          link
          description
          type
        }
        enabledTwoStepVerification
        elasticIndexName
        isAccountVerified
        isActive
        isDeleted
        deletedPartner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getPartnersSortedByLastName = /* GraphQL */ `
  query GetPartnersSortedByLastName(
    $deletedPartner: String
    $lastName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPartnersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPartnersSortedByLastName(
      deletedPartner: $deletedPartner
      lastName: $lastName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        invitedByPartnerId
        partnerCode
        dynamicLink
        desktopURL
        companyName
        companyNameLower
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        email
        emailLower
        password
        profilePicture
        companyEmblem
        phone
        addressLine1
        addressLine2
        city
        country
        countryIsoCode
        postcode
        deviceType
        fcmToken
        totalFlaresReceived
        totalFlaresRespondedTo
        totalIncidentsReceived
        totalIncidentsActioned
        latitude
        longitude
        retailCodePrice
        resalePrice
        invoicePayment
        rewardAvailabilityCountries
        referralURL
        referralIntroText
        referralBackgroundImage
        referralRetailPrice
        incidentNotificationSettings
        notificationTitle
        notificationMessage
        notificationPeriod
        hasRiderConfirmed
        iotEndpoint
        enableRewards
        enableReferrals
        enableAppDiscountsHub
        enableFlares
        enableRiderManagement
        enableRetail
        enableAutoStart
        enableEmergencySOS
        enableSdk
        enforceEmergencyContact
        enforceSafeZone
        enforcePhoneNumber
        youtubeLink
        enforceRiderId
        termsAndConditionLink
        privacyPolicyLink
        landingPagePoints {
          text
        }
        sandboxFlareApiKey
        sandboxFlareApiKeyUsage
        productionFlareApiKey
        productionFlareApiKeyUsage
        licenseKeys {
          licenseKey
          keyName
          environment
          totalUsage
          totalIncidentDetected
          isActive
          isDeleted
          deletedKey
          lastUsed
          createdAt
          updatedAt
        }
        incidentNotifications {
          notificationSettings
          notificationEmail
          notificationCountry
          notificationCountryCallingCode
          notificationMobileNo
          notificationWebhookURL
          includeRiderEmail
          riderID
          riderName
          riderCity
          riderEmail
          incidentDateAndTime
          ISOCountryCode
          incidentLocation
          incidentTelemetry
        }
        sosNotificationSettings
        sosNotifications {
          notificationSettings
          notificationEmail
          notificationCountry
          notificationCountryCallingCode
          notificationMobileNo
          notificationWebhookURL
          includeRiderEmail
          riderID
          riderName
          riderCity
          riderEmail
          incidentDateAndTime
          ISOCountryCode
          incidentLocation
          incidentTelemetry
        }
        consentCheckbox {
          title
          fileType
          link
          description
          type
        }
        enabledTwoStepVerification
        elasticIndexName
        isAccountVerified
        isActive
        isDeleted
        deletedPartner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getPartnersSortedByEmail = /* GraphQL */ `
  query GetPartnersSortedByEmail(
    $deletedPartner: String
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPartnersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPartnersSortedByEmail(
      deletedPartner: $deletedPartner
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        invitedByPartnerId
        partnerCode
        dynamicLink
        desktopURL
        companyName
        companyNameLower
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        email
        emailLower
        password
        profilePicture
        companyEmblem
        phone
        addressLine1
        addressLine2
        city
        country
        countryIsoCode
        postcode
        deviceType
        fcmToken
        totalFlaresReceived
        totalFlaresRespondedTo
        totalIncidentsReceived
        totalIncidentsActioned
        latitude
        longitude
        retailCodePrice
        resalePrice
        invoicePayment
        rewardAvailabilityCountries
        referralURL
        referralIntroText
        referralBackgroundImage
        referralRetailPrice
        incidentNotificationSettings
        notificationTitle
        notificationMessage
        notificationPeriod
        hasRiderConfirmed
        iotEndpoint
        enableRewards
        enableReferrals
        enableAppDiscountsHub
        enableFlares
        enableRiderManagement
        enableRetail
        enableAutoStart
        enableEmergencySOS
        enableSdk
        enforceEmergencyContact
        enforceSafeZone
        enforcePhoneNumber
        youtubeLink
        enforceRiderId
        termsAndConditionLink
        privacyPolicyLink
        landingPagePoints {
          text
        }
        sandboxFlareApiKey
        sandboxFlareApiKeyUsage
        productionFlareApiKey
        productionFlareApiKeyUsage
        licenseKeys {
          licenseKey
          keyName
          environment
          totalUsage
          totalIncidentDetected
          isActive
          isDeleted
          deletedKey
          lastUsed
          createdAt
          updatedAt
        }
        incidentNotifications {
          notificationSettings
          notificationEmail
          notificationCountry
          notificationCountryCallingCode
          notificationMobileNo
          notificationWebhookURL
          includeRiderEmail
          riderID
          riderName
          riderCity
          riderEmail
          incidentDateAndTime
          ISOCountryCode
          incidentLocation
          incidentTelemetry
        }
        sosNotificationSettings
        sosNotifications {
          notificationSettings
          notificationEmail
          notificationCountry
          notificationCountryCallingCode
          notificationMobileNo
          notificationWebhookURL
          includeRiderEmail
          riderID
          riderName
          riderCity
          riderEmail
          incidentDateAndTime
          ISOCountryCode
          incidentLocation
          incidentTelemetry
        }
        consentCheckbox {
          title
          fileType
          link
          description
          type
        }
        enabledTwoStepVerification
        elasticIndexName
        isAccountVerified
        isActive
        isDeleted
        deletedPartner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getInviteesByPartnerId = /* GraphQL */ `
  query GetInviteesByPartnerId(
    $invitedByPartnerId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPartnersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getInviteesByPartnerId(
      invitedByPartnerId: $invitedByPartnerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        invitedByPartnerId
        partnerCode
        dynamicLink
        desktopURL
        companyName
        companyNameLower
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        email
        emailLower
        password
        profilePicture
        companyEmblem
        phone
        addressLine1
        addressLine2
        city
        country
        countryIsoCode
        postcode
        deviceType
        fcmToken
        totalFlaresReceived
        totalFlaresRespondedTo
        totalIncidentsReceived
        totalIncidentsActioned
        latitude
        longitude
        retailCodePrice
        resalePrice
        invoicePayment
        rewardAvailabilityCountries
        referralURL
        referralIntroText
        referralBackgroundImage
        referralRetailPrice
        incidentNotificationSettings
        notificationTitle
        notificationMessage
        notificationPeriod
        hasRiderConfirmed
        iotEndpoint
        enableRewards
        enableReferrals
        enableAppDiscountsHub
        enableFlares
        enableRiderManagement
        enableRetail
        enableAutoStart
        enableEmergencySOS
        enableSdk
        enforceEmergencyContact
        enforceSafeZone
        enforcePhoneNumber
        youtubeLink
        enforceRiderId
        termsAndConditionLink
        privacyPolicyLink
        landingPagePoints {
          text
        }
        sandboxFlareApiKey
        sandboxFlareApiKeyUsage
        productionFlareApiKey
        productionFlareApiKeyUsage
        licenseKeys {
          licenseKey
          keyName
          environment
          totalUsage
          totalIncidentDetected
          isActive
          isDeleted
          deletedKey
          lastUsed
          createdAt
          updatedAt
        }
        incidentNotifications {
          notificationSettings
          notificationEmail
          notificationCountry
          notificationCountryCallingCode
          notificationMobileNo
          notificationWebhookURL
          includeRiderEmail
          riderID
          riderName
          riderCity
          riderEmail
          incidentDateAndTime
          ISOCountryCode
          incidentLocation
          incidentTelemetry
        }
        sosNotificationSettings
        sosNotifications {
          notificationSettings
          notificationEmail
          notificationCountry
          notificationCountryCallingCode
          notificationMobileNo
          notificationWebhookURL
          includeRiderEmail
          riderID
          riderName
          riderCity
          riderEmail
          incidentDateAndTime
          ISOCountryCode
          incidentLocation
          incidentTelemetry
        }
        consentCheckbox {
          title
          fileType
          link
          description
          type
        }
        enabledTwoStepVerification
        elasticIndexName
        isAccountVerified
        isActive
        isDeleted
        deletedPartner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getInviteesByPartnerIdSortedByFirstName = /* GraphQL */ `
  query GetInviteesByPartnerIdSortedByFirstName(
    $invitedByPartnerId: String
    $firstName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPartnersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getInviteesByPartnerIdSortedByFirstName(
      invitedByPartnerId: $invitedByPartnerId
      firstName: $firstName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        invitedByPartnerId
        partnerCode
        dynamicLink
        desktopURL
        companyName
        companyNameLower
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        email
        emailLower
        password
        profilePicture
        companyEmblem
        phone
        addressLine1
        addressLine2
        city
        country
        countryIsoCode
        postcode
        deviceType
        fcmToken
        totalFlaresReceived
        totalFlaresRespondedTo
        totalIncidentsReceived
        totalIncidentsActioned
        latitude
        longitude
        retailCodePrice
        resalePrice
        invoicePayment
        rewardAvailabilityCountries
        referralURL
        referralIntroText
        referralBackgroundImage
        referralRetailPrice
        incidentNotificationSettings
        notificationTitle
        notificationMessage
        notificationPeriod
        hasRiderConfirmed
        iotEndpoint
        enableRewards
        enableReferrals
        enableAppDiscountsHub
        enableFlares
        enableRiderManagement
        enableRetail
        enableAutoStart
        enableEmergencySOS
        enableSdk
        enforceEmergencyContact
        enforceSafeZone
        enforcePhoneNumber
        youtubeLink
        enforceRiderId
        termsAndConditionLink
        privacyPolicyLink
        landingPagePoints {
          text
        }
        sandboxFlareApiKey
        sandboxFlareApiKeyUsage
        productionFlareApiKey
        productionFlareApiKeyUsage
        licenseKeys {
          licenseKey
          keyName
          environment
          totalUsage
          totalIncidentDetected
          isActive
          isDeleted
          deletedKey
          lastUsed
          createdAt
          updatedAt
        }
        incidentNotifications {
          notificationSettings
          notificationEmail
          notificationCountry
          notificationCountryCallingCode
          notificationMobileNo
          notificationWebhookURL
          includeRiderEmail
          riderID
          riderName
          riderCity
          riderEmail
          incidentDateAndTime
          ISOCountryCode
          incidentLocation
          incidentTelemetry
        }
        sosNotificationSettings
        sosNotifications {
          notificationSettings
          notificationEmail
          notificationCountry
          notificationCountryCallingCode
          notificationMobileNo
          notificationWebhookURL
          includeRiderEmail
          riderID
          riderName
          riderCity
          riderEmail
          incidentDateAndTime
          ISOCountryCode
          incidentLocation
          incidentTelemetry
        }
        consentCheckbox {
          title
          fileType
          link
          description
          type
        }
        enabledTwoStepVerification
        elasticIndexName
        isAccountVerified
        isActive
        isDeleted
        deletedPartner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getInviteesByPartnerIdSortedByLastName = /* GraphQL */ `
  query GetInviteesByPartnerIdSortedByLastName(
    $invitedByPartnerId: String
    $lastName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPartnersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getInviteesByPartnerIdSortedByLastName(
      invitedByPartnerId: $invitedByPartnerId
      lastName: $lastName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        invitedByPartnerId
        partnerCode
        dynamicLink
        desktopURL
        companyName
        companyNameLower
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        email
        emailLower
        password
        profilePicture
        companyEmblem
        phone
        addressLine1
        addressLine2
        city
        country
        countryIsoCode
        postcode
        deviceType
        fcmToken
        totalFlaresReceived
        totalFlaresRespondedTo
        totalIncidentsReceived
        totalIncidentsActioned
        latitude
        longitude
        retailCodePrice
        resalePrice
        invoicePayment
        rewardAvailabilityCountries
        referralURL
        referralIntroText
        referralBackgroundImage
        referralRetailPrice
        incidentNotificationSettings
        notificationTitle
        notificationMessage
        notificationPeriod
        hasRiderConfirmed
        iotEndpoint
        enableRewards
        enableReferrals
        enableAppDiscountsHub
        enableFlares
        enableRiderManagement
        enableRetail
        enableAutoStart
        enableEmergencySOS
        enableSdk
        enforceEmergencyContact
        enforceSafeZone
        enforcePhoneNumber
        youtubeLink
        enforceRiderId
        termsAndConditionLink
        privacyPolicyLink
        landingPagePoints {
          text
        }
        sandboxFlareApiKey
        sandboxFlareApiKeyUsage
        productionFlareApiKey
        productionFlareApiKeyUsage
        licenseKeys {
          licenseKey
          keyName
          environment
          totalUsage
          totalIncidentDetected
          isActive
          isDeleted
          deletedKey
          lastUsed
          createdAt
          updatedAt
        }
        incidentNotifications {
          notificationSettings
          notificationEmail
          notificationCountry
          notificationCountryCallingCode
          notificationMobileNo
          notificationWebhookURL
          includeRiderEmail
          riderID
          riderName
          riderCity
          riderEmail
          incidentDateAndTime
          ISOCountryCode
          incidentLocation
          incidentTelemetry
        }
        sosNotificationSettings
        sosNotifications {
          notificationSettings
          notificationEmail
          notificationCountry
          notificationCountryCallingCode
          notificationMobileNo
          notificationWebhookURL
          includeRiderEmail
          riderID
          riderName
          riderCity
          riderEmail
          incidentDateAndTime
          ISOCountryCode
          incidentLocation
          incidentTelemetry
        }
        consentCheckbox {
          title
          fileType
          link
          description
          type
        }
        enabledTwoStepVerification
        elasticIndexName
        isAccountVerified
        isActive
        isDeleted
        deletedPartner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getInviteesByPartnerIdSortedByEmail = /* GraphQL */ `
  query GetInviteesByPartnerIdSortedByEmail(
    $invitedByPartnerId: String
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPartnersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getInviteesByPartnerIdSortedByEmail(
      invitedByPartnerId: $invitedByPartnerId
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        invitedByPartnerId
        partnerCode
        dynamicLink
        desktopURL
        companyName
        companyNameLower
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        email
        emailLower
        password
        profilePicture
        companyEmblem
        phone
        addressLine1
        addressLine2
        city
        country
        countryIsoCode
        postcode
        deviceType
        fcmToken
        totalFlaresReceived
        totalFlaresRespondedTo
        totalIncidentsReceived
        totalIncidentsActioned
        latitude
        longitude
        retailCodePrice
        resalePrice
        invoicePayment
        rewardAvailabilityCountries
        referralURL
        referralIntroText
        referralBackgroundImage
        referralRetailPrice
        incidentNotificationSettings
        notificationTitle
        notificationMessage
        notificationPeriod
        hasRiderConfirmed
        iotEndpoint
        enableRewards
        enableReferrals
        enableAppDiscountsHub
        enableFlares
        enableRiderManagement
        enableRetail
        enableAutoStart
        enableEmergencySOS
        enableSdk
        enforceEmergencyContact
        enforceSafeZone
        enforcePhoneNumber
        youtubeLink
        enforceRiderId
        termsAndConditionLink
        privacyPolicyLink
        landingPagePoints {
          text
        }
        sandboxFlareApiKey
        sandboxFlareApiKeyUsage
        productionFlareApiKey
        productionFlareApiKeyUsage
        licenseKeys {
          licenseKey
          keyName
          environment
          totalUsage
          totalIncidentDetected
          isActive
          isDeleted
          deletedKey
          lastUsed
          createdAt
          updatedAt
        }
        incidentNotifications {
          notificationSettings
          notificationEmail
          notificationCountry
          notificationCountryCallingCode
          notificationMobileNo
          notificationWebhookURL
          includeRiderEmail
          riderID
          riderName
          riderCity
          riderEmail
          incidentDateAndTime
          ISOCountryCode
          incidentLocation
          incidentTelemetry
        }
        sosNotificationSettings
        sosNotifications {
          notificationSettings
          notificationEmail
          notificationCountry
          notificationCountryCallingCode
          notificationMobileNo
          notificationWebhookURL
          includeRiderEmail
          riderID
          riderName
          riderCity
          riderEmail
          incidentDateAndTime
          ISOCountryCode
          incidentLocation
          incidentTelemetry
        }
        consentCheckbox {
          title
          fileType
          link
          description
          type
        }
        enabledTwoStepVerification
        elasticIndexName
        isAccountVerified
        isActive
        isDeleted
        deletedPartner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getPartnerBySandboxFlareApiKey = /* GraphQL */ `
  query GetPartnerBySandboxFlareApiKey(
    $sandboxFlareApiKey: String
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPartnersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPartnerBySandboxFlareApiKey(
      sandboxFlareApiKey: $sandboxFlareApiKey
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        invitedByPartnerId
        partnerCode
        dynamicLink
        desktopURL
        companyName
        companyNameLower
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        email
        emailLower
        password
        profilePicture
        companyEmblem
        phone
        addressLine1
        addressLine2
        city
        country
        countryIsoCode
        postcode
        deviceType
        fcmToken
        totalFlaresReceived
        totalFlaresRespondedTo
        totalIncidentsReceived
        totalIncidentsActioned
        latitude
        longitude
        retailCodePrice
        resalePrice
        invoicePayment
        rewardAvailabilityCountries
        referralURL
        referralIntroText
        referralBackgroundImage
        referralRetailPrice
        incidentNotificationSettings
        notificationTitle
        notificationMessage
        notificationPeriod
        hasRiderConfirmed
        iotEndpoint
        enableRewards
        enableReferrals
        enableAppDiscountsHub
        enableFlares
        enableRiderManagement
        enableRetail
        enableAutoStart
        enableEmergencySOS
        enableSdk
        enforceEmergencyContact
        enforceSafeZone
        enforcePhoneNumber
        youtubeLink
        enforceRiderId
        termsAndConditionLink
        privacyPolicyLink
        landingPagePoints {
          text
        }
        sandboxFlareApiKey
        sandboxFlareApiKeyUsage
        productionFlareApiKey
        productionFlareApiKeyUsage
        licenseKeys {
          licenseKey
          keyName
          environment
          totalUsage
          totalIncidentDetected
          isActive
          isDeleted
          deletedKey
          lastUsed
          createdAt
          updatedAt
        }
        incidentNotifications {
          notificationSettings
          notificationEmail
          notificationCountry
          notificationCountryCallingCode
          notificationMobileNo
          notificationWebhookURL
          includeRiderEmail
          riderID
          riderName
          riderCity
          riderEmail
          incidentDateAndTime
          ISOCountryCode
          incidentLocation
          incidentTelemetry
        }
        sosNotificationSettings
        sosNotifications {
          notificationSettings
          notificationEmail
          notificationCountry
          notificationCountryCallingCode
          notificationMobileNo
          notificationWebhookURL
          includeRiderEmail
          riderID
          riderName
          riderCity
          riderEmail
          incidentDateAndTime
          ISOCountryCode
          incidentLocation
          incidentTelemetry
        }
        consentCheckbox {
          title
          fileType
          link
          description
          type
        }
        enabledTwoStepVerification
        elasticIndexName
        isAccountVerified
        isActive
        isDeleted
        deletedPartner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getPartnerByProductionFlareApiKey = /* GraphQL */ `
  query GetPartnerByProductionFlareApiKey(
    $productionFlareApiKey: String
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPartnersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPartnerByProductionFlareApiKey(
      productionFlareApiKey: $productionFlareApiKey
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        invitedByPartnerId
        partnerCode
        dynamicLink
        desktopURL
        companyName
        companyNameLower
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        email
        emailLower
        password
        profilePicture
        companyEmblem
        phone
        addressLine1
        addressLine2
        city
        country
        countryIsoCode
        postcode
        deviceType
        fcmToken
        totalFlaresReceived
        totalFlaresRespondedTo
        totalIncidentsReceived
        totalIncidentsActioned
        latitude
        longitude
        retailCodePrice
        resalePrice
        invoicePayment
        rewardAvailabilityCountries
        referralURL
        referralIntroText
        referralBackgroundImage
        referralRetailPrice
        incidentNotificationSettings
        notificationTitle
        notificationMessage
        notificationPeriod
        hasRiderConfirmed
        iotEndpoint
        enableRewards
        enableReferrals
        enableAppDiscountsHub
        enableFlares
        enableRiderManagement
        enableRetail
        enableAutoStart
        enableEmergencySOS
        enableSdk
        enforceEmergencyContact
        enforceSafeZone
        enforcePhoneNumber
        youtubeLink
        enforceRiderId
        termsAndConditionLink
        privacyPolicyLink
        landingPagePoints {
          text
        }
        sandboxFlareApiKey
        sandboxFlareApiKeyUsage
        productionFlareApiKey
        productionFlareApiKeyUsage
        licenseKeys {
          licenseKey
          keyName
          environment
          totalUsage
          totalIncidentDetected
          isActive
          isDeleted
          deletedKey
          lastUsed
          createdAt
          updatedAt
        }
        incidentNotifications {
          notificationSettings
          notificationEmail
          notificationCountry
          notificationCountryCallingCode
          notificationMobileNo
          notificationWebhookURL
          includeRiderEmail
          riderID
          riderName
          riderCity
          riderEmail
          incidentDateAndTime
          ISOCountryCode
          incidentLocation
          incidentTelemetry
        }
        sosNotificationSettings
        sosNotifications {
          notificationSettings
          notificationEmail
          notificationCountry
          notificationCountryCallingCode
          notificationMobileNo
          notificationWebhookURL
          includeRiderEmail
          riderID
          riderName
          riderCity
          riderEmail
          incidentDateAndTime
          ISOCountryCode
          incidentLocation
          incidentTelemetry
        }
        consentCheckbox {
          title
          fileType
          link
          description
          type
        }
        enabledTwoStepVerification
        elasticIndexName
        isAccountVerified
        isActive
        isDeleted
        deletedPartner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getPartnerByPartnerCode = /* GraphQL */ `
  query GetPartnerByPartnerCode(
    $partnerCode: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPartnersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPartnerByPartnerCode(
      partnerCode: $partnerCode
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        invitedByPartnerId
        partnerCode
        dynamicLink
        desktopURL
        companyName
        companyNameLower
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        email
        emailLower
        password
        profilePicture
        companyEmblem
        phone
        addressLine1
        addressLine2
        city
        country
        countryIsoCode
        postcode
        deviceType
        fcmToken
        totalFlaresReceived
        totalFlaresRespondedTo
        totalIncidentsReceived
        totalIncidentsActioned
        latitude
        longitude
        retailCodePrice
        resalePrice
        invoicePayment
        rewardAvailabilityCountries
        referralURL
        referralIntroText
        referralBackgroundImage
        referralRetailPrice
        incidentNotificationSettings
        notificationTitle
        notificationMessage
        notificationPeriod
        hasRiderConfirmed
        iotEndpoint
        enableRewards
        enableReferrals
        enableAppDiscountsHub
        enableFlares
        enableRiderManagement
        enableRetail
        enableAutoStart
        enableEmergencySOS
        enableSdk
        enforceEmergencyContact
        enforceSafeZone
        enforcePhoneNumber
        youtubeLink
        enforceRiderId
        termsAndConditionLink
        privacyPolicyLink
        landingPagePoints {
          text
        }
        sandboxFlareApiKey
        sandboxFlareApiKeyUsage
        productionFlareApiKey
        productionFlareApiKeyUsage
        licenseKeys {
          licenseKey
          keyName
          environment
          totalUsage
          totalIncidentDetected
          isActive
          isDeleted
          deletedKey
          lastUsed
          createdAt
          updatedAt
        }
        incidentNotifications {
          notificationSettings
          notificationEmail
          notificationCountry
          notificationCountryCallingCode
          notificationMobileNo
          notificationWebhookURL
          includeRiderEmail
          riderID
          riderName
          riderCity
          riderEmail
          incidentDateAndTime
          ISOCountryCode
          incidentLocation
          incidentTelemetry
        }
        sosNotificationSettings
        sosNotifications {
          notificationSettings
          notificationEmail
          notificationCountry
          notificationCountryCallingCode
          notificationMobileNo
          notificationWebhookURL
          includeRiderEmail
          riderID
          riderName
          riderCity
          riderEmail
          incidentDateAndTime
          ISOCountryCode
          incidentLocation
          incidentTelemetry
        }
        consentCheckbox {
          title
          fileType
          link
          description
          type
        }
        enabledTwoStepVerification
        elasticIndexName
        isAccountVerified
        isActive
        isDeleted
        deletedPartner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPartners = /* GraphQL */ `
  query SyncPartners(
    $filter: ModelPartnersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPartners(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        invitedByPartnerId
        partnerCode
        dynamicLink
        desktopURL
        companyName
        companyNameLower
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        email
        emailLower
        password
        profilePicture
        companyEmblem
        phone
        addressLine1
        addressLine2
        city
        country
        countryIsoCode
        postcode
        deviceType
        fcmToken
        totalFlaresReceived
        totalFlaresRespondedTo
        totalIncidentsReceived
        totalIncidentsActioned
        latitude
        longitude
        retailCodePrice
        resalePrice
        invoicePayment
        rewardAvailabilityCountries
        referralURL
        referralIntroText
        referralBackgroundImage
        referralRetailPrice
        incidentNotificationSettings
        notificationTitle
        notificationMessage
        notificationPeriod
        hasRiderConfirmed
        iotEndpoint
        enableRewards
        enableReferrals
        enableAppDiscountsHub
        enableFlares
        enableRiderManagement
        enableRetail
        enableAutoStart
        enableEmergencySOS
        enableSdk
        enforceEmergencyContact
        enforceSafeZone
        enforcePhoneNumber
        youtubeLink
        enforceRiderId
        termsAndConditionLink
        privacyPolicyLink
        landingPagePoints {
          text
        }
        sandboxFlareApiKey
        sandboxFlareApiKeyUsage
        productionFlareApiKey
        productionFlareApiKeyUsage
        licenseKeys {
          licenseKey
          keyName
          environment
          totalUsage
          totalIncidentDetected
          isActive
          isDeleted
          deletedKey
          lastUsed
          createdAt
          updatedAt
        }
        incidentNotifications {
          notificationSettings
          notificationEmail
          notificationCountry
          notificationCountryCallingCode
          notificationMobileNo
          notificationWebhookURL
          includeRiderEmail
          riderID
          riderName
          riderCity
          riderEmail
          incidentDateAndTime
          ISOCountryCode
          incidentLocation
          incidentTelemetry
        }
        sosNotificationSettings
        sosNotifications {
          notificationSettings
          notificationEmail
          notificationCountry
          notificationCountryCallingCode
          notificationMobileNo
          notificationWebhookURL
          includeRiderEmail
          riderID
          riderName
          riderCity
          riderEmail
          incidentDateAndTime
          ISOCountryCode
          incidentLocation
          incidentTelemetry
        }
        consentCheckbox {
          title
          fileType
          link
          description
          type
        }
        enabledTwoStepVerification
        elasticIndexName
        isAccountVerified
        isActive
        isDeleted
        deletedPartner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getRetailCodePurchases = /* GraphQL */ `
  query GetRetailCodePurchases($id: ID!) {
    getRetailCodePurchases(id: $id) {
      id
      partnerId
      unitPrice
      quantity
      subTotal
      vat
      total
      paymentType
      stripeToken
      stripeRate
      stripeAmount
      busbyAmount
      stripeChargeId
      isPaymentSuccessful
      invoiceNumber
      isPaid
      retailCodesPaid
      isDeleted
      deletedRetailPurchase
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listRetailCodePurchasess = /* GraphQL */ `
  query ListRetailCodePurchasess(
    $filter: ModelRetailCodePurchasesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRetailCodePurchasess(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        unitPrice
        quantity
        subTotal
        vat
        total
        paymentType
        stripeToken
        stripeRate
        stripeAmount
        busbyAmount
        stripeChargeId
        isPaymentSuccessful
        invoiceNumber
        isPaid
        retailCodesPaid
        isDeleted
        deletedRetailPurchase
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getRetailCodePurchasesSortedByCreatedAt = /* GraphQL */ `
  query GetRetailCodePurchasesSortedByCreatedAt(
    $deletedRetailPurchase: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRetailCodePurchasesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRetailCodePurchasesSortedByCreatedAt(
      deletedRetailPurchase: $deletedRetailPurchase
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        unitPrice
        quantity
        subTotal
        vat
        total
        paymentType
        stripeToken
        stripeRate
        stripeAmount
        busbyAmount
        stripeChargeId
        isPaymentSuccessful
        invoiceNumber
        isPaid
        retailCodesPaid
        isDeleted
        deletedRetailPurchase
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getRetailCodePurchasesSortedByInvoiceNumber = /* GraphQL */ `
  query GetRetailCodePurchasesSortedByInvoiceNumber(
    $deletedRetailPurchase: String
    $invoiceNumber: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRetailCodePurchasesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRetailCodePurchasesSortedByInvoiceNumber(
      deletedRetailPurchase: $deletedRetailPurchase
      invoiceNumber: $invoiceNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        unitPrice
        quantity
        subTotal
        vat
        total
        paymentType
        stripeToken
        stripeRate
        stripeAmount
        busbyAmount
        stripeChargeId
        isPaymentSuccessful
        invoiceNumber
        isPaid
        retailCodesPaid
        isDeleted
        deletedRetailPurchase
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getRetailCodePurchasesSortedByTotal = /* GraphQL */ `
  query GetRetailCodePurchasesSortedByTotal(
    $deletedRetailPurchase: String
    $total: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRetailCodePurchasesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRetailCodePurchasesSortedByTotal(
      deletedRetailPurchase: $deletedRetailPurchase
      total: $total
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        unitPrice
        quantity
        subTotal
        vat
        total
        paymentType
        stripeToken
        stripeRate
        stripeAmount
        busbyAmount
        stripeChargeId
        isPaymentSuccessful
        invoiceNumber
        isPaid
        retailCodesPaid
        isDeleted
        deletedRetailPurchase
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getRetailCodePurchasesSortedByPaid = /* GraphQL */ `
  query GetRetailCodePurchasesSortedByPaid(
    $deletedRetailPurchase: String
    $retailCodesPaid: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRetailCodePurchasesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRetailCodePurchasesSortedByPaid(
      deletedRetailPurchase: $deletedRetailPurchase
      retailCodesPaid: $retailCodesPaid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        unitPrice
        quantity
        subTotal
        vat
        total
        paymentType
        stripeToken
        stripeRate
        stripeAmount
        busbyAmount
        stripeChargeId
        isPaymentSuccessful
        invoiceNumber
        isPaid
        retailCodesPaid
        isDeleted
        deletedRetailPurchase
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getRetailCodePurchasesByPartnerId = /* GraphQL */ `
  query GetRetailCodePurchasesByPartnerId(
    $partnerId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRetailCodePurchasesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRetailCodePurchasesByPartnerId(
      partnerId: $partnerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        unitPrice
        quantity
        subTotal
        vat
        total
        paymentType
        stripeToken
        stripeRate
        stripeAmount
        busbyAmount
        stripeChargeId
        isPaymentSuccessful
        invoiceNumber
        isPaid
        retailCodesPaid
        isDeleted
        deletedRetailPurchase
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRetailCodePurchases = /* GraphQL */ `
  query SyncRetailCodePurchases(
    $filter: ModelRetailCodePurchasesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRetailCodePurchases(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        partnerId
        unitPrice
        quantity
        subTotal
        vat
        total
        paymentType
        stripeToken
        stripeRate
        stripeAmount
        busbyAmount
        stripeChargeId
        isPaymentSuccessful
        invoiceNumber
        isPaid
        retailCodesPaid
        isDeleted
        deletedRetailPurchase
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getRetailCodes = /* GraphQL */ `
  query GetRetailCodes($id: ID!) {
    getRetailCodes(id: $id) {
      id
      partnerId
      retailCodePurchaseId
      referralId
      retailCode
      retailCodeLower
      paymentType
      paymentTypeLower
      status
      statusLower
      isDeleted
      deletedRetailCode
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listRetailCodess = /* GraphQL */ `
  query ListRetailCodess(
    $filter: ModelRetailCodesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRetailCodess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        partnerId
        retailCodePurchaseId
        referralId
        retailCode
        retailCodeLower
        paymentType
        paymentTypeLower
        status
        statusLower
        isDeleted
        deletedRetailCode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getRetailCodesSortedByCreatedAt = /* GraphQL */ `
  query GetRetailCodesSortedByCreatedAt(
    $partnerId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRetailCodesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRetailCodesSortedByCreatedAt(
      partnerId: $partnerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        retailCodePurchaseId
        referralId
        retailCode
        retailCodeLower
        paymentType
        paymentTypeLower
        status
        statusLower
        isDeleted
        deletedRetailCode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getRetailCodesSortedByPaymentType = /* GraphQL */ `
  query GetRetailCodesSortedByPaymentType(
    $partnerId: ID
    $paymentType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRetailCodesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRetailCodesSortedByPaymentType(
      partnerId: $partnerId
      paymentType: $paymentType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        retailCodePurchaseId
        referralId
        retailCode
        retailCodeLower
        paymentType
        paymentTypeLower
        status
        statusLower
        isDeleted
        deletedRetailCode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getRetailCodesSortedByRetailCode = /* GraphQL */ `
  query GetRetailCodesSortedByRetailCode(
    $partnerId: ID
    $retailCode: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRetailCodesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRetailCodesSortedByRetailCode(
      partnerId: $partnerId
      retailCode: $retailCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        retailCodePurchaseId
        referralId
        retailCode
        retailCodeLower
        paymentType
        paymentTypeLower
        status
        statusLower
        isDeleted
        deletedRetailCode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getRetailCodesSortedByStatus = /* GraphQL */ `
  query GetRetailCodesSortedByStatus(
    $partnerId: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRetailCodesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRetailCodesSortedByStatus(
      partnerId: $partnerId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        retailCodePurchaseId
        referralId
        retailCode
        retailCodeLower
        paymentType
        paymentTypeLower
        status
        statusLower
        isDeleted
        deletedRetailCode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getRetailCodesByPartnerId = /* GraphQL */ `
  query GetRetailCodesByPartnerId(
    $partnerId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRetailCodesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRetailCodesByPartnerId(
      partnerId: $partnerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        retailCodePurchaseId
        referralId
        retailCode
        retailCodeLower
        paymentType
        paymentTypeLower
        status
        statusLower
        isDeleted
        deletedRetailCode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getRetailCodesByCode = /* GraphQL */ `
  query GetRetailCodesByCode(
    $retailCode: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRetailCodesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRetailCodesByCode(
      retailCode: $retailCode
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        retailCodePurchaseId
        referralId
        retailCode
        retailCodeLower
        paymentType
        paymentTypeLower
        status
        statusLower
        isDeleted
        deletedRetailCode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getRetailCodesByPaymentType = /* GraphQL */ `
  query GetRetailCodesByPaymentType(
    $paymentType: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRetailCodesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRetailCodesByPaymentType(
      paymentType: $paymentType
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        retailCodePurchaseId
        referralId
        retailCode
        retailCodeLower
        paymentType
        paymentTypeLower
        status
        statusLower
        isDeleted
        deletedRetailCode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getRetailCodesByReferral = /* GraphQL */ `
  query GetRetailCodesByReferral(
    $referralId: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRetailCodesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRetailCodesByReferral(
      referralId: $referralId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        retailCodePurchaseId
        referralId
        retailCode
        retailCodeLower
        paymentType
        paymentTypeLower
        status
        statusLower
        isDeleted
        deletedRetailCode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRetailCodes = /* GraphQL */ `
  query SyncRetailCodes(
    $filter: ModelRetailCodesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRetailCodes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        partnerId
        retailCodePurchaseId
        referralId
        retailCode
        retailCodeLower
        paymentType
        paymentTypeLower
        status
        statusLower
        isDeleted
        deletedRetailCode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getReferrals = /* GraphQL */ `
  query GetReferrals($id: ID!) {
    getReferrals(id: $id) {
      id
      partnerId
      inviteLink
      inviteLinkLower
      premiumDiscount
      busbySubscription
      tag
      tagLower
      referralType
      usageLimit
      totalUsed
      retailPrice
      showFreeTile
      qrImage
      introduction
      banner
      logo
      showBusbyLogo
      youtubeURL
      desktopURL
      smTitle
      smDescription
      smImage
      isActive
      isDeleted
      deletedReferral
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listReferralss = /* GraphQL */ `
  query ListReferralss(
    $filter: ModelReferralsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReferralss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        partnerId
        inviteLink
        inviteLinkLower
        premiumDiscount
        busbySubscription
        tag
        tagLower
        referralType
        usageLimit
        totalUsed
        retailPrice
        showFreeTile
        qrImage
        introduction
        banner
        logo
        showBusbyLogo
        youtubeURL
        desktopURL
        smTitle
        smDescription
        smImage
        isActive
        isDeleted
        deletedReferral
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getReferralsByInviteLink = /* GraphQL */ `
  query GetReferralsByInviteLink(
    $inviteLink: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReferralsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getReferralsByInviteLink(
      inviteLink: $inviteLink
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        inviteLink
        inviteLinkLower
        premiumDiscount
        busbySubscription
        tag
        tagLower
        referralType
        usageLimit
        totalUsed
        retailPrice
        showFreeTile
        qrImage
        introduction
        banner
        logo
        showBusbyLogo
        youtubeURL
        desktopURL
        smTitle
        smDescription
        smImage
        isActive
        isDeleted
        deletedReferral
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getReferralsSortedByCreatedAt = /* GraphQL */ `
  query GetReferralsSortedByCreatedAt(
    $partnerId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReferralsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getReferralsSortedByCreatedAt(
      partnerId: $partnerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        inviteLink
        inviteLinkLower
        premiumDiscount
        busbySubscription
        tag
        tagLower
        referralType
        usageLimit
        totalUsed
        retailPrice
        showFreeTile
        qrImage
        introduction
        banner
        logo
        showBusbyLogo
        youtubeURL
        desktopURL
        smTitle
        smDescription
        smImage
        isActive
        isDeleted
        deletedReferral
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getAllReferralsSortedByCreatedAt = /* GraphQL */ `
  query GetAllReferralsSortedByCreatedAt(
    $deletedReferral: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReferralsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAllReferralsSortedByCreatedAt(
      deletedReferral: $deletedReferral
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        inviteLink
        inviteLinkLower
        premiumDiscount
        busbySubscription
        tag
        tagLower
        referralType
        usageLimit
        totalUsed
        retailPrice
        showFreeTile
        qrImage
        introduction
        banner
        logo
        showBusbyLogo
        youtubeURL
        desktopURL
        smTitle
        smDescription
        smImage
        isActive
        isDeleted
        deletedReferral
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncReferrals = /* GraphQL */ `
  query SyncReferrals(
    $filter: ModelReferralsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncReferrals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        partnerId
        inviteLink
        inviteLinkLower
        premiumDiscount
        busbySubscription
        tag
        tagLower
        referralType
        usageLimit
        totalUsed
        retailPrice
        showFreeTile
        qrImage
        introduction
        banner
        logo
        showBusbyLogo
        youtubeURL
        desktopURL
        smTitle
        smDescription
        smImage
        isActive
        isDeleted
        deletedReferral
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getMessageBoard = /* GraphQL */ `
  query GetMessageBoard($id: ID!) {
    getMessageBoard(id: $id) {
      id
      title
      titleLower
      details
      detailsLower
      messageImage
      isActive
      isDeleted
      deletedMessage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listMessageBoards = /* GraphQL */ `
  query ListMessageBoards(
    $filter: ModelMessageBoardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessageBoards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        titleLower
        details
        detailsLower
        messageImage
        isActive
        isDeleted
        deletedMessage
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getMessageBoardSortedByCreatedAt = /* GraphQL */ `
  query GetMessageBoardSortedByCreatedAt(
    $deletedMessage: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageBoardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMessageBoardSortedByCreatedAt(
      deletedMessage: $deletedMessage
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        titleLower
        details
        detailsLower
        messageImage
        isActive
        isDeleted
        deletedMessage
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getMessageBoardSortedByTitle = /* GraphQL */ `
  query GetMessageBoardSortedByTitle(
    $deletedMessage: String
    $title: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageBoardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMessageBoardSortedByTitle(
      deletedMessage: $deletedMessage
      title: $title
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        titleLower
        details
        detailsLower
        messageImage
        isActive
        isDeleted
        deletedMessage
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getMessageBoardSortedByDetails = /* GraphQL */ `
  query GetMessageBoardSortedByDetails(
    $deletedMessage: String
    $details: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageBoardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMessageBoardSortedByDetails(
      deletedMessage: $deletedMessage
      details: $details
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        titleLower
        details
        detailsLower
        messageImage
        isActive
        isDeleted
        deletedMessage
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMessageBoards = /* GraphQL */ `
  query SyncMessageBoards(
    $filter: ModelMessageBoardFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMessageBoards(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        titleLower
        details
        detailsLower
        messageImage
        isActive
        isDeleted
        deletedMessage
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserMessageBoard = /* GraphQL */ `
  query GetUserMessageBoard($id: ID!) {
    getUserMessageBoard(id: $id) {
      id
      messageBoardId
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listUserMessageBoards = /* GraphQL */ `
  query ListUserMessageBoards(
    $filter: ModelUserMessageBoardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserMessageBoards(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        messageBoardId
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getMessageBoardByLoggedInUserId = /* GraphQL */ `
  query GetMessageBoardByLoggedInUserId(
    $userId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserMessageBoardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMessageBoardByLoggedInUserId(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        messageBoardId
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUserMessageBoards = /* GraphQL */ `
  query SyncUserMessageBoards(
    $filter: ModelUserMessageBoardFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserMessageBoards(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        messageBoardId
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getHazardCategory = /* GraphQL */ `
  query GetHazardCategory($id: ID!) {
    getHazardCategory(id: $id) {
      id
      categoryId
      name
      description
      crowdinTitleKey
      crowdinDescriptionKey
      imageUrl
      expiresInMinutes
      isActive
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listHazardCategorys = /* GraphQL */ `
  query ListHazardCategorys(
    $filter: ModelHazardCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHazardCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        categoryId
        name
        description
        crowdinTitleKey
        crowdinDescriptionKey
        imageUrl
        expiresInMinutes
        isActive
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getHazardCategoryById = /* GraphQL */ `
  query GetHazardCategoryById(
    $categoryId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelHazardCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getHazardCategoryById(
      categoryId: $categoryId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        categoryId
        name
        description
        crowdinTitleKey
        crowdinDescriptionKey
        imageUrl
        expiresInMinutes
        isActive
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHazardCategories = /* GraphQL */ `
  query SyncHazardCategories(
    $filter: ModelHazardCategoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHazardCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        categoryId
        name
        description
        crowdinTitleKey
        crowdinDescriptionKey
        imageUrl
        expiresInMinutes
        isActive
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getSubscriptionRiderCodes = /* GraphQL */ `
  query GetSubscriptionRiderCodes($id: ID!) {
    getSubscriptionRiderCodes(id: $id) {
      id
      partnerId
      riderCode
      partnerName
      countryIsoCode
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listSubscriptionRiderCodess = /* GraphQL */ `
  query ListSubscriptionRiderCodess(
    $filter: ModelSubscriptionRiderCodesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscriptionRiderCodess(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        riderCode
        partnerName
        countryIsoCode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getByRiderCode = /* GraphQL */ `
  query GetByRiderCode(
    $riderCode: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSubscriptionRiderCodesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getByRiderCode(
      riderCode: $riderCode
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partnerId
        riderCode
        partnerName
        countryIsoCode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSubscriptionRiderCodes = /* GraphQL */ `
  query SyncSubscriptionRiderCodes(
    $filter: ModelSubscriptionRiderCodesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSubscriptionRiderCodes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        partnerId
        riderCode
        partnerName
        countryIsoCode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getLogs = /* GraphQL */ `
  query GetLogs($id: ID!) {
    getLogs(id: $id) {
      id
      userId
      type
      event
      payload
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listLogss = /* GraphQL */ `
  query ListLogss(
    $filter: ModelLogsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLogss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        type
        event
        payload
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getLogsByUserId = /* GraphQL */ `
  query GetLogsByUserId(
    $userId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLogsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLogsByUserId(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        type
        event
        payload
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLogs = /* GraphQL */ `
  query SyncLogs(
    $filter: ModelLogsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        type
        event
        payload
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
