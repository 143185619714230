// import React from 'react';
// import { API } from 'aws-amplify';
// import styles from '../views/homepage/styles.module.scss';
// import classNames from 'classnames';
// import '../styles/general.scss';
// import { CardElement } from '@stripe/react-stripe-js';
// import { Button, } from "reactstrap";

// class CheckoutForm extends React.Component {
//     handleSubmit = async (event) => {
//         event.preventDefault();

//         //// Use elements.getElement to get a reference to the mounted Element.
//         //const cardElement = elements.getElement(CardElement);

//         //// Pass the Element directly to other Stripe.js methods:
//         //// e.g. createToken - https://stripe.com/docs/js/tokens_sources/create_token?type=cardElement
//         //stripe.createToken(cardElement);

//         //// or createPaymentMethod - https://stripe.com/docs/js/payment_intents/create_payment_method
//         //stripe.createPaymentMethod({
//         //    type: 'card',
//         //    card: cardElement,
//         //});

//         //// or confirmCardPayment - https://stripe.com/docs/js/payment_intents/confirm_card_payment
//         //stripe.confirmCardPayment(paymentIntentClientSecret, {
//         //    payment_method: {
//         //        card: cardElement,
//         //    },
//         //});

//         const { totalAmount, noOfLicense, licenseCost, totalLicenses, unassignedLicenses } = this.props;

//         var objLicense = {
//             totalValue: totalAmount,
//             noOfLicense: noOfLicense,
//             licenseCost: licenseCost,
//             totalLicenses: totalLicenses,
//             unassignedLicenses: unassignedLicenses
//         }

//         localStorage.setItem('licenseDetails', JSON.stringify(objLicense));

//         const body = {
//             name: this.props.name,
//             email: this.props.email,
//             description: this.props.description,
//             images: this.props.images,
//             amount: this.props.amount,
//             currency: this.props.currency,
//             quantity: this.props.quantity,
//             success_url: this.props.success_url,
//             cancel_url: this.props.cancel_url,
//         }
//         const response = await API.post(this.props.apiName, this.props.apiEndpoint, { body })
//         console.log('response', response);
//         console.log(' this.props', JSON.stringify(this.props));

//         this.props.stripe.redirectToCheckout({
//             // Make the id field from the Checkout Session creation API response
//             // available to this file, so you can provide it as parameter here
//             // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
//             sessionId: response.session.id
//         }).then(function (result) {
//             // If `redirectToCheckout` fails due to a browser or network
//             // error, display the localized error message to your customer
//             // using `result.error.message`.
//         });
//     };

//     render() {
//         return (
//             <form onSubmit={this.handleSubmit}>
//                 <Button type="submit" className={classNames('buy', styles.saveBtn)}>
//                     Buy
//                 </Button>
//             </form>
//         );
//     }
// }

// export default CheckoutForm;
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Elements, StripeProvider } from 'react-stripe-elements';
import StripePayment from './stripePayment';

class CheckoutForm extends Component {
    componentDidUpdate() {
        const { totalAmount, noOfLicense, licenseCost, totalLicenses, unassignedLicenses } = this.props;
        var objLicense = {
            totalValue: totalAmount,
            noOfLicense: noOfLicense,
            licenseCost: licenseCost,
            totalLicenses: totalLicenses,
            unassignedLicenses: unassignedLicenses
        }

        localStorage.setItem('licenseDetails', JSON.stringify(objLicense));
    }

    render() {
        const {
            stripePublicKey,
            apiName,
            apiEndpoint,

            name,
            email,
            description,
            images,
            amount,
            currency,
            quantity,
            success_url,
            cancel_url,
        } = this.props;

        return (
            <StripeProvider apiKey={stripePublicKey}>
                <Elements>
                    <StripePayment
                        apiName={apiName}
                        apiEndpoint={apiEndpoint}
                        name={name}
                        email={email}
                        description={description}
                        images={images}
                        amount={amount}
                        currency={currency}
                        quantity={quantity}
                        success_url={success_url}
                        cancel_url={cancel_url}
                    />
                </Elements>
            </StripeProvider>
        );
    }
}

// CheckoutForm.propTypes = {
//     stripePublicKey: PropTypes.string.isRequired,
//     apiName: PropTypes.string.isRequired,
//     apiEndpoint: PropTypes.string.isRequired,

//     name: PropTypes.string.isRequired,
//     description: PropTypes.string.isRequired,
//     images: PropTypes.array.isRequired,
//     amount: PropTypes.number.isRequired,
//     currency: PropTypes.string.isRequired,
//     quantity: PropTypes.number.isRequired,

//     success_url: PropTypes.string.isRequired,
//     cancel_url: PropTypes.string.isRequired,
// };

export default CheckoutForm;