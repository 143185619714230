import React, { useState } from "react";

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
} from "reactstrap";
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import Select from "react-select";
import { API, graphqlOperation, Auth } from "aws-amplify";
import { listCountrys, getFleetOwnersByEmail } from "../../../graphql/queries";
import Helper from "../../../services/helper";
const customStyles = {
    option: (provided) => ({
        ...provided,
        textAlign: "left",
    }),
    placeholder: (defaultStyles) => ({
        ...defaultStyles,
        textAlign: "left",
    }),
};
const companyTypeList = [
    {
        value: "Enterprise Customer",
        label: "Enterprise Customer"
    },
    {
        value: "Standard Customer",
        label: "Standard Customer"
    }
];
function RoadRadarSignUp(props) {
    //const companyType = ["Single Licenced", "Multi Licenced"];
    const [countryList, setcountryList] = useState([]);
    const [formData, setformData] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
        companyName: "",
        companyType: "",
        selectedCompanyType: null,
        phoneNo: "",
        noOfVehicles: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        postcode: "",
        country: "",
        selectedCountry: null,
        isTNCAccepted: false,
        isLoading: false,
    });
    const [loading, setLoading] = useState(false);

    React.useEffect(() => {
        loadCountries();
        document.body.classList.toggle("register-page");
        return function cleanup() {
            document.body.classList.toggle("register-page");
        };
    }, []);

    const loadCountries = async () => {
        var variables = {
            nextToken: null,
            limit: 500,
        };
        await API.graphql(graphqlOperation(listCountrys, variables)).then(async (result) => {
            let data = [];

            data.push({ value: "GBR", label: "United Kingdom" });

            result.data.listCountrys.items
                .filter((c) => c.isoCode !== "GBR")
                .sort(Helper.sortJsonArray("countryName", "ASC"))
                .map((itm) => data.push({ value: itm.countryName, label: itm.countryName }));
            setcountryList(data);
        });
    };

    const handleNoOfVehicleChange = (e) => {
        var regexp = /^[0-9\b]+$/;
        let vehicles = e.target.value;

        if (vehicles === "" || regexp.test(vehicles)) {
            setformData({ ...formData, [e.target.name]: vehicles });
        }
    };

    const handlePhoneChange = (e) => {
        var regexp = /^[0-9\b]+$/;
        let phone = e.target.value;

        if (phone === "" || regexp.test(phone)) {
            setformData({ ...formData, [e.target.name]: phone });
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (e.target.name === "isTNCAccepted") {
            document.getElementById("isTNCAccepted").checked = e.target.checked;
            setformData({
                ...formData,
                isTNCAccepted: e.target.checked,
            });
        } else {
            setformData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleCountryChange = (e) => {
        setformData({
            ...formData,
            country: e.value,
            selectedCountry: e,
        });
    };

    const handleCompanyTypeChange = (e) => {

        setformData({
            ...formData,
            companyType: e.value,
            selectedCompanyType: e,

        });
    };
    const handleSignUp = async () => {
        const {
            email,
            password,
            confirmPassword,
            firstName,
            lastName,
            companyName,
            companyType,
            phoneNo,
            noOfVehicles,
            country,
            isTNCAccepted,
            addressLine1,
            addressLine2,
            city,
            postcode,
        } = formData;

        // this.setState({ isLoading: true });

        var isFleetOwnerExists = false;
        let isValid = true;
        const messages = [];
        setLoading(true);
        if (email === "") {
            isValid = false;
            messages.push("- Please enter an email\n");
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            isValid = false;
            messages.push("- Please enter valid email\n");
        }

        if (password === "") {
            isValid = false;
            messages.push("- Please enter password\n");
        }

        if (confirmPassword === "") {
            isValid = false;
            messages.push("- Please confirm password\n");
        }

        if (password.trim() !== confirmPassword.trim()) {
            isValid = false;
            messages.push("- Password & confirm password do not match\n");
        }

        if (companyName === "") {
            isValid = false;
            messages.push("- Please enter company name\n");
        }
        if (companyType === "") {
            isValid = false;
            messages.push("- Please enter company type\n");
        }
        if (firstName === "") {
            isValid = false;
            messages.push("- Please enter first name\n");
        }

        if (lastName === "") {
            isValid = false;
            messages.push("- Please enter last name\n");
        }

        if (addressLine1 === "") {
            isValid = false;
            messages.push("- Please enter address Line 1\n");
        }

        // if (addressLine2 === "") {
        //   isValid = false;
        //   messages.push("- Please enter address Line 2\n");
        // }

        if (city === "") {
            isValid = false;
            messages.push("- Please enter city/town\n");
        }

        if (postcode === "") {
            isValid = false;
            messages.push("- Please enter zip code/postcode\n");
        }

        if (phoneNo === "") {
            isValid = false;
            messages.push("- Please enter phone\n");
        }

        if (parseInt(noOfVehicles === "" ? "0" : noOfVehicles) === 0) {
            isValid = false;
            messages.push("- Please enter number of vehicles\n");
        }

        if (country === "") {
            isValid = false;
            messages.push("- Please select country\n");
        }

        if (!isTNCAccepted && isValid) {
            alert("You must accept terms and conditions");
            return;
        }

        if (!isValid) {
            setLoading(false);
            alert(Helper.arrayToString(messages));
            return;
        }

        await Auth.signIn(email, password)
            .then((user) => {
                Auth.signOut();
                isFleetOwnerExists = true;
            })
            .catch(async (err) => {
                if (err.code === "UserNotFoundException") {
                    isFleetOwnerExists = false;
                } else if (err.code === "NotAuthorizedException") {
                    if (err.message === "Incorrect username or password.") isFleetOwnerExists = true;
                }
            });

        if (isFleetOwnerExists) {
            alert("An account has already been setup with this email. Login instead.");
        } else {
            let userName = email.trim().split("@")[0] + Math.floor(1000000000 + Math.random() * 9000000000);

            await Auth.signUp({
                username: userName,
                password: password,
                attributes: {
                    email: email,
                },
            })
                .then(async (user) => {
                    console.log(user);

                    props.history.push({
                        pathname: "/verify-account",
                        state: {
                            partnerId: "",
                            userName: userName,
                            firstName: firstName,
                            lastName: lastName,
                            companyName: companyName,
                            companyType: companyType,
                            email: email,
                            password: password,
                            addressLine1: addressLine1,
                            addressLine2: addressLine2,
                            city: city,
                            postcode: postcode,
                            country: country,
                            phone: phoneNo,
                            noOfVehicles: noOfVehicles,
                        },
                    });
                })
                .catch((err) => {
                    setLoading(false);
                    console.log(JSON.stringify(err));
                    if (err.code === "UsernameExistsException") {
                        alert("This email is already registered. Try logging in with it");
                    } else if (err.code === "InvalidPasswordException" || err.code === "InvalidParameterException") {
                        alert("Password must be at least 8 characters");
                    } else {
                        alert("Something went wrong. Please try again later");
                    }
                });
        }
    };

    const handleKeypress = e => {
        //it triggers by pressing the enter key
        if (e.keyCode === 13) {
            handleSignUp(e);
        }
    };
    const {
        email,
        password,
        confirmPassword,
        firstName,
        lastName,
        companyName,
        phoneNo,
        noOfVehicles,
        selectedCountry,
        selectedCompanyType,
        isTNCAccepted,
        isLoading,
        addressLine1,
        addressLine2,
        city,
        postcode,
    } = formData;

    return (
        <div>
            <AuthNavbar />
            <div className="wrapper wrapper-full-page">
                <div className="full-page section-image">
                    <div
                        className="register-page"
                        style={{
                            justifyContent: "center",
                            display: "grid",
                        }}
                    >
                        <Container>
                            <Row>
                                {/* <Col className="ml-auto" lg="3" md="5" /> */}

                                {/* <Col lg="6" md="7"> */}
                                <Card className="card-signup text-center">
                                    <CardHeader>
                                        <CardTitle tag="h4">Register</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <Form action="" className="form">
                                            <Col lg="12">
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="nc-icon nc-email-85" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        placeholder="Email Address"
                                                        type="email"
                                                        value={email}
                                                        name="email"
                                                        onChange={handleChange}
                                                        onKeyUp={(e) => handleKeypress(e)}
                                                    />
                                                </InputGroup>
                                            </Col>

                                            <Col lg="12">
                                                <Row form>
                                                    <Col md={6}>
                                                        <InputGroup>
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="nc-icon nc-key-25" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="Password"
                                                                type="password"
                                                                autoComplete="off"
                                                                name="password"
                                                                value={password}
                                                                onChange={handleChange}
                                                                onKeyUp={(e) => handleKeypress(e)}
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <InputGroup>
                                                            <InputGroupAddon addonType="prepend">
                                                                {" "}
                                                                <InputGroupText>
                                                                    <i className="nc-icon nc-key-25" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="Confirm Password"
                                                                type="password"
                                                                autoComplete="off"
                                                                name="confirmPassword"
                                                                value={confirmPassword}
                                                                onChange={handleChange}
                                                                onKeyUp={(e) => handleKeypress(e)}
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col lg="12">
                                                <Row form>
                                                    <Col md={6}>
                                                        <InputGroup>
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="nc-icon nc-single-02" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="First Name"
                                                                type="text"
                                                                name="firstName"
                                                                value={firstName}
                                                                onChange={handleChange}
                                                                onKeyUp={(e) => handleKeypress(e)}
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <InputGroup>
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="nc-icon nc-circle-10" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="Last Name"
                                                                type="text"
                                                                name="lastName"
                                                                value={lastName}
                                                                onChange={handleChange}
                                                                onKeyUp={(e) => handleKeypress(e)}
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col lg="12">
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="nc-icon nc-badge" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        placeholder="Company Name"
                                                        type="text"
                                                        name="companyName"
                                                        value={companyName}
                                                        onChange={handleChange}
                                                        onKeyUp={(e) => handleKeypress(e)}
                                                    />
                                                </InputGroup>
                                            </Col>

                                            <Col lg="12">
                                                <Row form>
                                                    <Col md={6}>
                                                        <InputGroup>
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="nc-icon nc-single-02" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="Address Line 1"
                                                                type="text"
                                                                name="addressLine1"
                                                                value={addressLine1}
                                                                onChange={handleChange}
                                                                onKeyUp={(e) => handleKeypress(e)}
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <InputGroup>
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="nc-icon nc-circle-10" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="Address Line 2"
                                                                type="text"
                                                                name="addressLine2"
                                                                value={addressLine2}
                                                                onChange={handleChange}
                                                                onKeyUp={(e) => handleKeypress(e)}
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col lg="12">
                                                <Row form>
                                                    <Col md={6}>
                                                        <InputGroup>
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="nc-icon nc-single-02" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="City/Town"
                                                                type="text"
                                                                name="city"
                                                                value={city}
                                                                onChange={handleChange}
                                                                onKeyUp={(e) => handleKeypress(e)}
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <InputGroup>
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="nc-icon nc-circle-10" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="Zip Code/Postcode"
                                                                type="text"
                                                                name="postcode"
                                                                value={postcode}
                                                                onChange={handleChange}
                                                                onKeyUp={(e) => handleKeypress(e)}
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col lg="12">
                                                <Row form>
                                                    <Col md={6}>
                                                        <InputGroup>
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="nc-icon nc-mobile" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="Phone No"
                                                                type="text"
                                                                name="phoneNo"
                                                                value={phoneNo}
                                                                onChange={handlePhoneChange}
                                                                onKeyUp={(e) => handleKeypress(e)}
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <InputGroup>
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="nc-icon nc-circle-10" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="No of Vehicles"
                                                                type="text"
                                                                name="noOfVehicles"
                                                                value={noOfVehicles}
                                                                onChange={handleNoOfVehicleChange}
                                                                onKeyUp={(e) => handleKeypress(e)}
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col lg="12">
                                                <Row form>
                                                    <Col md={6}>
                                                        <InputGroup>
                                                            <Col lg="12" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                                                <Select
                                                                    className="react-select primary"
                                                                    classNamePrefix="react-select"
                                                                    styles={customStyles}
                                                                    name="country"
                                                                    options={countryList}
                                                                    placeholder="Country"
                                                                    value={selectedCountry}
                                                                    onChange={handleCountryChange}
                                                                    onKeyUp={(e) => handleKeypress(e)}
                                                                />
                                                            </Col>
                                                        </InputGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <InputGroup>
                                                            <Col lg="12" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                                                <Select
                                                                    className="react-select primary"
                                                                    classNamePrefix="react-select"
                                                                    styles={customStyles}
                                                                    name="Company Type"
                                                                    options={companyTypeList}
                                                                    placeholder="Customer Type"
                                                                    value={selectedCompanyType}
                                                                    onChange={handleCompanyTypeChange}
                                                                    onKeyUp={(e) => handleKeypress(e)}
                                                                />
                                                            </Col>
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                            </Col>


                                            <FormGroup check className="text-left">
                                                <Label check>
                                                    <Input
                                                        defaultChecked
                                                        type="checkbox"
                                                        id="isTNCAccepted"
                                                        name="isTNCAccepted"
                                                        checked={isTNCAccepted}
                                                        onChange={handleChange}
                                                        onKeyUp={(e) => handleKeypress(e)}
                                                    />
                                                    <span className="form-check-sign" />I agree to the{" "}
                                                    <a href="https://www.flaresafety.com/terms-of-service" target="_blank" style={{ cursor: "pointer" }}>
                                                        terms and conditions
                                                    </a>
                                                    .
                                                </Label>
                                            </FormGroup>
                                        </Form>
                                    </CardBody>
                                    <CardFooter>
                                        <Button className="btn-round" color="warning" onClick={handleSignUp}>

                                            {loading ? (
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            ) : (
                                                "Get Started"
                                            )}
                                        </Button>
                                    </CardFooter>
                                </Card>
                                {/* </Col> */}
                            </Row>
                        </Container>
                        <div
                            className="full-page-background"
                            style={{
                                backgroundImage: `url(${require("../../../assests/images/signup-banner.png")})`,
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RoadRadarSignUp;
