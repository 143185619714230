import { API } from "aws-amplify";

class Helper {
    static isAuthenticated() {
        let loggedInuser = localStorage.getItem("fleetOwnerName");

        console.log("loggedInuser: " + loggedInuser);

        if (loggedInuser) {
            return true;
        } else {
            return false;
        }
    }

    static arrayToString(arr) {
        let str = "";
        arr.forEach(function (i, index) {
            str += i;
            if (index !== arr.length - 1) {
                str += "";
            }
        });
        return str;
    }

    static formatDate(dt) {
        const dateTimeFormat = new Intl.DateTimeFormat("en", { year: "numeric", month: "short", day: "2-digit" });

        const date = new Date(dt);
        const [{ value: month }, , { value: day }, , { value: year }] = dateTimeFormat.formatToParts(date);
        var formattedDate = day + " " + month + ", " + year;

        return formattedDate;
    }

    static sortJsonArray(property, order) {
        var sort_order = 1;
        if (order === "DESC") {
            sort_order = -1;
        }
        return function (a, b) {
            if (a[property] < b[property]) {
                return -1 * sort_order;
            } else if (a[property] > b[property]) {
                return 1 * sort_order;
            } else {
                return 0 * sort_order;
            }
        };
    }

    static formatDateToSave(dt) {
        const dateTimeFormat = new Intl.DateTimeFormat("en", { year: "numeric", month: "2-digit", day: "2-digit" });

        const date = new Date(dt);
        const [{ value: month }, , { value: day }, , { value: year }] = dateTimeFormat.formatToParts(date);
        var formattedDate = year + "-" + month + "-" + day;

        return formattedDate;
    }

    static async getStripeSecretKey() {
        let apiName = "busbySecureKeys";
        let path = "/getValues"

        const secureKeys = await
            API.get(apiName, path, {});

        return secureKeys.response.strpiePublishableKey.toString();
    }
    static async getSecureKeys() {
        let apiName = "busbySecureKeys";
        let path = "/getValues";

        return await API.get(apiName, path, {});
    }

    static Logout() {
        localStorage.clear();
    }
}

export default Helper;
