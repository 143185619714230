import React, { Component, useEffect, useState } from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import styles from "../styles.module.scss";
import logo from "../../../assests/images/logo.png";
import Helper from "../../../services/helper";
import { Auth } from "aws-amplify";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
} from "reactstrap";
import AuthNavbar from "components/Navbars/AuthNavbar.js";

function ForgotPassword(props) {
  const [email, setemail] = useState("");
  const [loading, setloading] = useState(false);
    const [errorMessages, seterrorMessages] = useState("");


  useEffect(() => {
    document.body.classList.toggle("login-page");
    return function cleanup() {
      document.body.classList.toggle("login-page");
    };
  }, []);

  const handleForgotPassword = async () => {
    let isValid = true;

    const messages = [];

    if (email === "") {
      isValid = false;
      messages.push("- Please enter email\n");
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      isValid = false;
      messages.push("- Please enter valid email\n");
    }

    if (!isValid) {
      setloading(false);
      alert(Helper.arrayToString(messages));
      return;
    }

    await Auth.forgotPassword(email)
      .then((data) => {
        console.log("Success: " + JSON.stringify(data));
        props.history.push({
          pathname: "/email-sent",
          state: {
            email: email,
          },
        });
      })
      .catch((err) => {
        console.log("Error: " + JSON.stringify(err));
        seterrorMessages(JSON.stringify(err));
        props.history.push({
          pathname: "/email-sent",
          state: {
            email: email,
          },
        });
      });
    };

    const handleKeypress = e => {
        //it triggers by pressing the enter key
        if (e.keyCode === 13) {
            handleForgotPassword(e);
        }
    };

  return (
    <div>
      <AuthNavbar />
      <div className="wrapper wrapper-full-page">
        <div className="full-page section-image">
          <div className="login-page">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" lg="4" md="6">
                  <Form action="" className="form" method="">
                    <Card className="card-login">
                      <CardHeader>
                        <CardHeader>
                          <h3 className="header text-center mb-0">Forgot Password</h3>
                          <p className="header text-center">Please enter your registered email address.</p>
                        </CardHeader>
                      </CardHeader>
                      <CardBody>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="nc-icon nc-single-02" style={{ marginRight: "10px" }} />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setemail(e.target.value)}
                            onKeyUp={(e) => handleKeypress(e)}
                          />
                        </InputGroup>
                      </CardBody>
                      <CardFooter>
                        <Button
                          block
                          className="btn-round mb-3"
                          color="warning"
                          href="#pablo"
                          onClick={handleForgotPassword}
                        >
                          {loading ? (
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          ) : (
                            "Send"
                          )}
                          
                        </Button>
                      </CardFooter>
                      <div style={{ color: "red", margin: "0px 8px" }}>{errorMessages}</div>
                    </Card>
                  </Form>
                </Col>
              </Row>
            </Container>
            <div
              className="full-page-background"
              style={{
                backgroundImage: `url(${require("../../../assests/images/signup-banner.png")})`,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
