/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createActivityHistory = /* GraphQL */ `
  mutation CreateActivityHistory(
    $input: CreateActivityHistoryInput!
    $condition: ModelActivityHistoryConditionInput
  ) {
    createActivityHistory(input: $input, condition: $condition) {
      id
      userId
      partnerId
      nanoid
      activity
      date
      distance
      time
      timeInSeconds
      totalActiveTime
      coinsEarned
      groupName
      groupCode
      groupDuration
      groupCreated
      totalParticipants
      coordinates
      isSweatCoinUpdated
      isSharedToStrava
      activityStatus
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const updateActivityHistory = /* GraphQL */ `
  mutation UpdateActivityHistory(
    $input: UpdateActivityHistoryInput!
    $condition: ModelActivityHistoryConditionInput
  ) {
    updateActivityHistory(input: $input, condition: $condition) {
      id
      userId
      partnerId
      nanoid
      activity
      date
      distance
      time
      timeInSeconds
      totalActiveTime
      coinsEarned
      groupName
      groupCode
      groupDuration
      groupCreated
      totalParticipants
      coordinates
      isSweatCoinUpdated
      isSharedToStrava
      activityStatus
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const deleteActivityHistory = /* GraphQL */ `
  mutation DeleteActivityHistory(
    $input: DeleteActivityHistoryInput!
    $condition: ModelActivityHistoryConditionInput
  ) {
    deleteActivityHistory(input: $input, condition: $condition) {
      id
      userId
      partnerId
      nanoid
      activity
      date
      distance
      time
      timeInSeconds
      totalActiveTime
      coinsEarned
      groupName
      groupCode
      groupDuration
      groupCreated
      totalParticipants
      coordinates
      isSweatCoinUpdated
      isSharedToStrava
      activityStatus
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const createTrackingMetrics = /* GraphQL */ `
  mutation CreateTrackingMetrics(
    $input: CreateTrackingMetricsInput!
    $condition: ModelTrackingMetricsConditionInput
  ) {
    createTrackingMetrics(input: $input, condition: $condition) {
      id
      userId
      type
      nanoid
      uuid
      geohash
      status
      trackingLocations {
        timestamp
        geohash
        activity
        latitude
        longitude
        confidence
        speed
        speedAccuracy
        accuracy
        altitude
        heading
        batteryLevel
        isBatteryCharging
        odometer
        sample
        isMoving
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateTrackingMetrics = /* GraphQL */ `
  mutation UpdateTrackingMetrics(
    $input: UpdateTrackingMetricsInput!
    $condition: ModelTrackingMetricsConditionInput
  ) {
    updateTrackingMetrics(input: $input, condition: $condition) {
      id
      userId
      type
      nanoid
      uuid
      geohash
      status
      trackingLocations {
        timestamp
        geohash
        activity
        latitude
        longitude
        confidence
        speed
        speedAccuracy
        accuracy
        altitude
        heading
        batteryLevel
        isBatteryCharging
        odometer
        sample
        isMoving
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteTrackingMetrics = /* GraphQL */ `
  mutation DeleteTrackingMetrics(
    $input: DeleteTrackingMetricsInput!
    $condition: ModelTrackingMetricsConditionInput
  ) {
    deleteTrackingMetrics(input: $input, condition: $condition) {
      id
      userId
      type
      nanoid
      uuid
      geohash
      status
      trackingLocations {
        timestamp
        geohash
        activity
        latitude
        longitude
        confidence
        speed
        speedAccuracy
        accuracy
        altitude
        heading
        batteryLevel
        isBatteryCharging
        odometer
        sample
        isMoving
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createFlareStatistics = /* GraphQL */ `
  mutation CreateFlareStatistics(
    $input: CreateFlareStatisticsInput!
    $condition: ModelFlareStatisticsConditionInput
  ) {
    createFlareStatistics(input: $input, condition: $condition) {
      id
      partnerId
      totalUsers
      protectedDistanceInMeters
      protectedJourneys
      rideTimeInSeconds
      incidents
      incidentAlerts
      sos
      sosAlerts
      hazards
      hazardAlerts
      flaresReceived
      flaresResponded
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateFlareStatistics = /* GraphQL */ `
  mutation UpdateFlareStatistics(
    $input: UpdateFlareStatisticsInput!
    $condition: ModelFlareStatisticsConditionInput
  ) {
    updateFlareStatistics(input: $input, condition: $condition) {
      id
      partnerId
      totalUsers
      protectedDistanceInMeters
      protectedJourneys
      rideTimeInSeconds
      incidents
      incidentAlerts
      sos
      sosAlerts
      hazards
      hazardAlerts
      flaresReceived
      flaresResponded
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteFlareStatistics = /* GraphQL */ `
  mutation DeleteFlareStatistics(
    $input: DeleteFlareStatisticsInput!
    $condition: ModelFlareStatisticsConditionInput
  ) {
    deleteFlareStatistics(input: $input, condition: $condition) {
      id
      partnerId
      totalUsers
      protectedDistanceInMeters
      protectedJourneys
      rideTimeInSeconds
      incidents
      incidentAlerts
      sos
      sosAlerts
      hazards
      hazardAlerts
      flaresReceived
      flaresResponded
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createFleetOwners = /* GraphQL */ `
  mutation CreateFleetOwners(
    $input: CreateFleetOwnersInput!
    $condition: ModelFleetOwnersConditionInput
  ) {
    createFleetOwners(input: $input, condition: $condition) {
      id
      firstName
      firstNameLower
      lastName
      lastNameLower
      userName
      userNameLower
      companyName
      companyNameLower
      companyNo
      email
      emailLower
      password
      phone
      noOfVehicles
      addressLine1
      addressLine2
      city
      country
      postcode
      profilePicture
      totalLicenses
      unassignedLicenses
      pdfFilePath
      accountManagerEmail
      laterOpted
      enableInvoicePayment
      disableSnooze
      companyType
      sandboxApiKey
      sandboxApiKeyUsage
      productionApiKey
      productionApiKeyUsage
      isVerified
      isActive
      isDeleted
      deletedFleetOwner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateFleetOwners = /* GraphQL */ `
  mutation UpdateFleetOwners(
    $input: UpdateFleetOwnersInput!
    $condition: ModelFleetOwnersConditionInput
  ) {
    updateFleetOwners(input: $input, condition: $condition) {
      id
      firstName
      firstNameLower
      lastName
      lastNameLower
      userName
      userNameLower
      companyName
      companyNameLower
      companyNo
      email
      emailLower
      password
      phone
      noOfVehicles
      addressLine1
      addressLine2
      city
      country
      postcode
      profilePicture
      totalLicenses
      unassignedLicenses
      pdfFilePath
      accountManagerEmail
      laterOpted
      enableInvoicePayment
      disableSnooze
      companyType
      sandboxApiKey
      sandboxApiKeyUsage
      productionApiKey
      productionApiKeyUsage
      isVerified
      isActive
      isDeleted
      deletedFleetOwner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteFleetOwners = /* GraphQL */ `
  mutation DeleteFleetOwners(
    $input: DeleteFleetOwnersInput!
    $condition: ModelFleetOwnersConditionInput
  ) {
    deleteFleetOwners(input: $input, condition: $condition) {
      id
      firstName
      firstNameLower
      lastName
      lastNameLower
      userName
      userNameLower
      companyName
      companyNameLower
      companyNo
      email
      emailLower
      password
      phone
      noOfVehicles
      addressLine1
      addressLine2
      city
      country
      postcode
      profilePicture
      totalLicenses
      unassignedLicenses
      pdfFilePath
      accountManagerEmail
      laterOpted
      enableInvoicePayment
      disableSnooze
      companyType
      sandboxApiKey
      sandboxApiKeyUsage
      productionApiKey
      productionApiKeyUsage
      isVerified
      isActive
      isDeleted
      deletedFleetOwner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createFleetOwnerPurchases = /* GraphQL */ `
  mutation CreateFleetOwnerPurchases(
    $input: CreateFleetOwnerPurchasesInput!
    $condition: ModelFleetOwnerPurchasesConditionInput
  ) {
    createFleetOwnerPurchases(input: $input, condition: $condition) {
      id
      fleetOwnerId
      numberOfLicense
      cost
      vat
      total
      pdfFilePath
      stripeToken
      stripeRate
      stripeAmount
      busbyAmount
      stripeChargeId
      isPaymentSuccessful
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateFleetOwnerPurchases = /* GraphQL */ `
  mutation UpdateFleetOwnerPurchases(
    $input: UpdateFleetOwnerPurchasesInput!
    $condition: ModelFleetOwnerPurchasesConditionInput
  ) {
    updateFleetOwnerPurchases(input: $input, condition: $condition) {
      id
      fleetOwnerId
      numberOfLicense
      cost
      vat
      total
      pdfFilePath
      stripeToken
      stripeRate
      stripeAmount
      busbyAmount
      stripeChargeId
      isPaymentSuccessful
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteFleetOwnerPurchases = /* GraphQL */ `
  mutation DeleteFleetOwnerPurchases(
    $input: DeleteFleetOwnerPurchasesInput!
    $condition: ModelFleetOwnerPurchasesConditionInput
  ) {
    deleteFleetOwnerPurchases(input: $input, condition: $condition) {
      id
      fleetOwnerId
      numberOfLicense
      cost
      vat
      total
      pdfFilePath
      stripeToken
      stripeRate
      stripeAmount
      busbyAmount
      stripeChargeId
      isPaymentSuccessful
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createFleetUsers = /* GraphQL */ `
  mutation CreateFleetUsers(
    $input: CreateFleetUsersInput!
    $condition: ModelFleetUsersConditionInput
  ) {
    createFleetUsers(input: $input, condition: $condition) {
      id
      fleetOwnerId
      licenseId
      firstName
      firstNameLower
      lastName
      lastNameLower
      deviceType
      deviceTypeLower
      deviceId
      appVersion
      notificationRequired
      riderAlerts
      protectedDistanceInMeters
      isActive
      isDeleted
      deletedFleetUser
      lastLoginDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateFleetUsers = /* GraphQL */ `
  mutation UpdateFleetUsers(
    $input: UpdateFleetUsersInput!
    $condition: ModelFleetUsersConditionInput
  ) {
    updateFleetUsers(input: $input, condition: $condition) {
      id
      fleetOwnerId
      licenseId
      firstName
      firstNameLower
      lastName
      lastNameLower
      deviceType
      deviceTypeLower
      deviceId
      appVersion
      notificationRequired
      riderAlerts
      protectedDistanceInMeters
      isActive
      isDeleted
      deletedFleetUser
      lastLoginDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteFleetUsers = /* GraphQL */ `
  mutation DeleteFleetUsers(
    $input: DeleteFleetUsersInput!
    $condition: ModelFleetUsersConditionInput
  ) {
    deleteFleetUsers(input: $input, condition: $condition) {
      id
      fleetOwnerId
      licenseId
      firstName
      firstNameLower
      lastName
      lastNameLower
      deviceType
      deviceTypeLower
      deviceId
      appVersion
      notificationRequired
      riderAlerts
      protectedDistanceInMeters
      isActive
      isDeleted
      deletedFleetUser
      lastLoginDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createLicenses = /* GraphQL */ `
  mutation CreateLicenses(
    $input: CreateLicensesInput!
    $condition: ModelLicensesConditionInput
  ) {
    createLicenses(input: $input, condition: $condition) {
      id
      fleetOwnerId
      fleetUserId
      fleetOwnerPurchaseId
      code
      qrImage
      price
      isAssigned
      assignedLicense
      paymentType
      expiryDate
      isActive
      isDeleted
      deletedLicense
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateLicenses = /* GraphQL */ `
  mutation UpdateLicenses(
    $input: UpdateLicensesInput!
    $condition: ModelLicensesConditionInput
  ) {
    updateLicenses(input: $input, condition: $condition) {
      id
      fleetOwnerId
      fleetUserId
      fleetOwnerPurchaseId
      code
      qrImage
      price
      isAssigned
      assignedLicense
      paymentType
      expiryDate
      isActive
      isDeleted
      deletedLicense
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteLicenses = /* GraphQL */ `
  mutation DeleteLicenses(
    $input: DeleteLicensesInput!
    $condition: ModelLicensesConditionInput
  ) {
    deleteLicenses(input: $input, condition: $condition) {
      id
      fleetOwnerId
      fleetUserId
      fleetOwnerPurchaseId
      code
      qrImage
      price
      isAssigned
      assignedLicense
      paymentType
      expiryDate
      isActive
      isDeleted
      deletedLicense
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createFlares = /* GraphQL */ `
  mutation CreateFlares(
    $input: CreateFlaresInput!
    $condition: ModelFlaresConditionInput
  ) {
    createFlares(input: $input, condition: $condition) {
      id
      partnerId
      flareRequestorID
      flareResponderID
      nanoid
      geohash
      flareTime
      flareType
      flareStatus
      flareRequestorLatitude
      flareRequestorLongitude
      radius
      flareRequestorName
      flareRequestorProfilePic
      flareResponderName
      flareResponderProfilePic
      distance
      isConnected
      endChat
      isChatEnded
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateFlares = /* GraphQL */ `
  mutation UpdateFlares(
    $input: UpdateFlaresInput!
    $condition: ModelFlaresConditionInput
  ) {
    updateFlares(input: $input, condition: $condition) {
      id
      partnerId
      flareRequestorID
      flareResponderID
      nanoid
      geohash
      flareTime
      flareType
      flareStatus
      flareRequestorLatitude
      flareRequestorLongitude
      radius
      flareRequestorName
      flareRequestorProfilePic
      flareResponderName
      flareResponderProfilePic
      distance
      isConnected
      endChat
      isChatEnded
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteFlares = /* GraphQL */ `
  mutation DeleteFlares(
    $input: DeleteFlaresInput!
    $condition: ModelFlaresConditionInput
  ) {
    deleteFlares(input: $input, condition: $condition) {
      id
      partnerId
      flareRequestorID
      flareResponderID
      nanoid
      geohash
      flareTime
      flareType
      flareStatus
      flareRequestorLatitude
      flareRequestorLongitude
      radius
      flareRequestorName
      flareRequestorProfilePic
      flareResponderName
      flareResponderProfilePic
      distance
      isConnected
      endChat
      isChatEnded
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createFlareMessages = /* GraphQL */ `
  mutation CreateFlareMessages(
    $input: CreateFlareMessagesInput!
    $condition: ModelFlareMessagesConditionInput
  ) {
    createFlareMessages(input: $input, condition: $condition) {
      id
      flareId
      partnerId
      flareRequestorID
      nanoid
      sent
      time
      content
      messageId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateFlareMessages = /* GraphQL */ `
  mutation UpdateFlareMessages(
    $input: UpdateFlareMessagesInput!
    $condition: ModelFlareMessagesConditionInput
  ) {
    updateFlareMessages(input: $input, condition: $condition) {
      id
      flareId
      partnerId
      flareRequestorID
      nanoid
      sent
      time
      content
      messageId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteFlareMessages = /* GraphQL */ `
  mutation DeleteFlareMessages(
    $input: DeleteFlareMessagesInput!
    $condition: ModelFlareMessagesConditionInput
  ) {
    deleteFlareMessages(input: $input, condition: $condition) {
      id
      flareId
      partnerId
      flareRequestorID
      nanoid
      sent
      time
      content
      messageId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createIncidents = /* GraphQL */ `
  mutation CreateIncidents(
    $input: CreateIncidentsInput!
    $condition: ModelIncidentsConditionInput
  ) {
    createIncidents(input: $input, condition: $condition) {
      id
      partnerId
      userId
      userName
      userNameLower
      userEmail
      userEmailLower
      userProfilePic
      userReferralCode
      userRetailCodeUsed
      topicId
      expiresTimestamp
      incidentTime
      incidentLatitude
      incidentLongitude
      countryIsoCode
      city
      speed
      acceleration
      heading
      altitude
      distance
      isConnected
      endChat
      isChatEnded
      isVerified
      status
      statusLower
      userResponse
      userResponseLower
      totalAlerts
      deviceType
      deviceId
      battery
      coordinates
      incidentRaisedFrom
      incidentRaisedFromKey
      incidentType
      flareApiPayload
      activityTimeline {
        createdAt
        type
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateIncidents = /* GraphQL */ `
  mutation UpdateIncidents(
    $input: UpdateIncidentsInput!
    $condition: ModelIncidentsConditionInput
  ) {
    updateIncidents(input: $input, condition: $condition) {
      id
      partnerId
      userId
      userName
      userNameLower
      userEmail
      userEmailLower
      userProfilePic
      userReferralCode
      userRetailCodeUsed
      topicId
      expiresTimestamp
      incidentTime
      incidentLatitude
      incidentLongitude
      countryIsoCode
      city
      speed
      acceleration
      heading
      altitude
      distance
      isConnected
      endChat
      isChatEnded
      isVerified
      status
      statusLower
      userResponse
      userResponseLower
      totalAlerts
      deviceType
      deviceId
      battery
      coordinates
      incidentRaisedFrom
      incidentRaisedFromKey
      incidentType
      flareApiPayload
      activityTimeline {
        createdAt
        type
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteIncidents = /* GraphQL */ `
  mutation DeleteIncidents(
    $input: DeleteIncidentsInput!
    $condition: ModelIncidentsConditionInput
  ) {
    deleteIncidents(input: $input, condition: $condition) {
      id
      partnerId
      userId
      userName
      userNameLower
      userEmail
      userEmailLower
      userProfilePic
      userReferralCode
      userRetailCodeUsed
      topicId
      expiresTimestamp
      incidentTime
      incidentLatitude
      incidentLongitude
      countryIsoCode
      city
      speed
      acceleration
      heading
      altitude
      distance
      isConnected
      endChat
      isChatEnded
      isVerified
      status
      statusLower
      userResponse
      userResponseLower
      totalAlerts
      deviceType
      deviceId
      battery
      coordinates
      incidentRaisedFrom
      incidentRaisedFromKey
      incidentType
      flareApiPayload
      activityTimeline {
        createdAt
        type
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createIncidentsPlayground = /* GraphQL */ `
  mutation CreateIncidentsPlayground(
    $input: CreateIncidentsPlaygroundInput!
    $condition: ModelIncidentsPlaygroundConditionInput
  ) {
    createIncidentsPlayground(input: $input, condition: $condition) {
      id
      partnerId
      userId
      userName
      userNameLower
      userEmail
      userEmailLower
      userProfilePic
      userReferralCode
      userRetailCodeUsed
      topicId
      expiresTimestamp
      incidentTime
      incidentLatitude
      incidentLongitude
      countryIsoCode
      city
      speed
      acceleration
      heading
      altitude
      distance
      isConnected
      endChat
      isChatEnded
      isVerified
      status
      statusLower
      userResponse
      userResponseLower
      totalAlerts
      deviceType
      deviceId
      battery
      coordinates
      incidentRaisedFrom
      incidentRaisedFromKey
      incidentType
      flareApiPayload
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateIncidentsPlayground = /* GraphQL */ `
  mutation UpdateIncidentsPlayground(
    $input: UpdateIncidentsPlaygroundInput!
    $condition: ModelIncidentsPlaygroundConditionInput
  ) {
    updateIncidentsPlayground(input: $input, condition: $condition) {
      id
      partnerId
      userId
      userName
      userNameLower
      userEmail
      userEmailLower
      userProfilePic
      userReferralCode
      userRetailCodeUsed
      topicId
      expiresTimestamp
      incidentTime
      incidentLatitude
      incidentLongitude
      countryIsoCode
      city
      speed
      acceleration
      heading
      altitude
      distance
      isConnected
      endChat
      isChatEnded
      isVerified
      status
      statusLower
      userResponse
      userResponseLower
      totalAlerts
      deviceType
      deviceId
      battery
      coordinates
      incidentRaisedFrom
      incidentRaisedFromKey
      incidentType
      flareApiPayload
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteIncidentsPlayground = /* GraphQL */ `
  mutation DeleteIncidentsPlayground(
    $input: DeleteIncidentsPlaygroundInput!
    $condition: ModelIncidentsPlaygroundConditionInput
  ) {
    deleteIncidentsPlayground(input: $input, condition: $condition) {
      id
      partnerId
      userId
      userName
      userNameLower
      userEmail
      userEmailLower
      userProfilePic
      userReferralCode
      userRetailCodeUsed
      topicId
      expiresTimestamp
      incidentTime
      incidentLatitude
      incidentLongitude
      countryIsoCode
      city
      speed
      acceleration
      heading
      altitude
      distance
      isConnected
      endChat
      isChatEnded
      isVerified
      status
      statusLower
      userResponse
      userResponseLower
      totalAlerts
      deviceType
      deviceId
      battery
      coordinates
      incidentRaisedFrom
      incidentRaisedFromKey
      incidentType
      flareApiPayload
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createIncidentMessages = /* GraphQL */ `
  mutation CreateIncidentMessages(
    $input: CreateIncidentMessagesInput!
    $condition: ModelIncidentMessagesConditionInput
  ) {
    createIncidentMessages(input: $input, condition: $condition) {
      id
      incidentId
      topicId
      partnerId
      userId
      sent
      time
      content
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateIncidentMessages = /* GraphQL */ `
  mutation UpdateIncidentMessages(
    $input: UpdateIncidentMessagesInput!
    $condition: ModelIncidentMessagesConditionInput
  ) {
    updateIncidentMessages(input: $input, condition: $condition) {
      id
      incidentId
      topicId
      partnerId
      userId
      sent
      time
      content
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteIncidentMessages = /* GraphQL */ `
  mutation DeleteIncidentMessages(
    $input: DeleteIncidentMessagesInput!
    $condition: ModelIncidentMessagesConditionInput
  ) {
    deleteIncidentMessages(input: $input, condition: $condition) {
      id
      incidentId
      topicId
      partnerId
      userId
      sent
      time
      content
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createEmergencySos = /* GraphQL */ `
  mutation CreateEmergencySos(
    $input: CreateEmergencySOSInput!
    $condition: ModelEmergencySOSConditionInput
  ) {
    createEmergencySOS(input: $input, condition: $condition) {
      id
      partnerId
      userId
      nanoid
      userName
      userNameLower
      userEmail
      userEmailLower
      userProfilePic
      userReferralCode
      reportedAt
      latitude
      longitude
      distance
      journeyEnded
      isJourneyEnded
      totalAlerts
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateEmergencySos = /* GraphQL */ `
  mutation UpdateEmergencySos(
    $input: UpdateEmergencySOSInput!
    $condition: ModelEmergencySOSConditionInput
  ) {
    updateEmergencySOS(input: $input, condition: $condition) {
      id
      partnerId
      userId
      nanoid
      userName
      userNameLower
      userEmail
      userEmailLower
      userProfilePic
      userReferralCode
      reportedAt
      latitude
      longitude
      distance
      journeyEnded
      isJourneyEnded
      totalAlerts
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteEmergencySos = /* GraphQL */ `
  mutation DeleteEmergencySos(
    $input: DeleteEmergencySOSInput!
    $condition: ModelEmergencySOSConditionInput
  ) {
    deleteEmergencySOS(input: $input, condition: $condition) {
      id
      partnerId
      userId
      nanoid
      userName
      userNameLower
      userEmail
      userEmailLower
      userProfilePic
      userReferralCode
      reportedAt
      latitude
      longitude
      distance
      journeyEnded
      isJourneyEnded
      totalAlerts
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHazards = /* GraphQL */ `
  mutation CreateHazards(
    $input: CreateHazardsInput!
    $condition: ModelHazardsConditionInput
  ) {
    createHazards(input: $input, condition: $condition) {
      id
      userId
      partnerId
      timeStamp
      latitude
      longitude
      type
      typeLower
      crowdinTitleKey
      userName
      userNameLower
      userEmail
      userEmailLower
      geohash
      expireAt
      isExpired
      notificationsGenerated
      notified
      accuracyScoreUp
      accuracyScoreDown
      countryCode
      totalAlerts
      isDeleted
      deletedHazard
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHazards = /* GraphQL */ `
  mutation UpdateHazards(
    $input: UpdateHazardsInput!
    $condition: ModelHazardsConditionInput
  ) {
    updateHazards(input: $input, condition: $condition) {
      id
      userId
      partnerId
      timeStamp
      latitude
      longitude
      type
      typeLower
      crowdinTitleKey
      userName
      userNameLower
      userEmail
      userEmailLower
      geohash
      expireAt
      isExpired
      notificationsGenerated
      notified
      accuracyScoreUp
      accuracyScoreDown
      countryCode
      totalAlerts
      isDeleted
      deletedHazard
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHazards = /* GraphQL */ `
  mutation DeleteHazards(
    $input: DeleteHazardsInput!
    $condition: ModelHazardsConditionInput
  ) {
    deleteHazards(input: $input, condition: $condition) {
      id
      userId
      partnerId
      timeStamp
      latitude
      longitude
      type
      typeLower
      crowdinTitleKey
      userName
      userNameLower
      userEmail
      userEmailLower
      geohash
      expireAt
      isExpired
      notificationsGenerated
      notified
      accuracyScoreUp
      accuracyScoreDown
      countryCode
      totalAlerts
      isDeleted
      deletedHazard
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUserHazards = /* GraphQL */ `
  mutation CreateUserHazards(
    $input: CreateUserHazardsInput!
    $condition: ModelUserHazardsConditionInput
  ) {
    createUserHazards(input: $input, condition: $condition) {
      id
      hazardId
      userId
      isNotified
      isVoted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUserHazards = /* GraphQL */ `
  mutation UpdateUserHazards(
    $input: UpdateUserHazardsInput!
    $condition: ModelUserHazardsConditionInput
  ) {
    updateUserHazards(input: $input, condition: $condition) {
      id
      hazardId
      userId
      isNotified
      isVoted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteUserHazards = /* GraphQL */ `
  mutation DeleteUserHazards(
    $input: DeleteUserHazardsInput!
    $condition: ModelUserHazardsConditionInput
  ) {
    deleteUserHazards(input: $input, condition: $condition) {
      id
      hazardId
      userId
      isNotified
      isVoted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDataMetrics = /* GraphQL */ `
  mutation CreateDataMetrics(
    $input: CreateDataMetricsInput!
    $condition: ModelDataMetricsConditionInput
  ) {
    createDataMetrics(input: $input, condition: $condition) {
      id
      email
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateDataMetrics = /* GraphQL */ `
  mutation UpdateDataMetrics(
    $input: UpdateDataMetricsInput!
    $condition: ModelDataMetricsConditionInput
  ) {
    updateDataMetrics(input: $input, condition: $condition) {
      id
      email
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteDataMetrics = /* GraphQL */ `
  mutation DeleteDataMetrics(
    $input: DeleteDataMetricsInput!
    $condition: ModelDataMetricsConditionInput
  ) {
    deleteDataMetrics(input: $input, condition: $condition) {
      id
      email
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createExternalUsers = /* GraphQL */ `
  mutation CreateExternalUsers(
    $input: CreateExternalUsersInput!
    $condition: ModelExternalUsersConditionInput
  ) {
    createExternalUsers(input: $input, condition: $condition) {
      id
      partnerId
      type
      licenseKey
      totalUsage
      deviceType
      deviceId
      osVersion
      createdAtEpoch
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateExternalUsers = /* GraphQL */ `
  mutation UpdateExternalUsers(
    $input: UpdateExternalUsersInput!
    $condition: ModelExternalUsersConditionInput
  ) {
    updateExternalUsers(input: $input, condition: $condition) {
      id
      partnerId
      type
      licenseKey
      totalUsage
      deviceType
      deviceId
      osVersion
      createdAtEpoch
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteExternalUsers = /* GraphQL */ `
  mutation DeleteExternalUsers(
    $input: DeleteExternalUsersInput!
    $condition: ModelExternalUsersConditionInput
  ) {
    deleteExternalUsers(input: $input, condition: $condition) {
      id
      partnerId
      type
      licenseKey
      totalUsage
      deviceType
      deviceId
      osVersion
      createdAtEpoch
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createExternalUsersPlayground = /* GraphQL */ `
  mutation CreateExternalUsersPlayground(
    $input: CreateExternalUsersPlaygroundInput!
    $condition: ModelExternalUsersPlaygroundConditionInput
  ) {
    createExternalUsersPlayground(input: $input, condition: $condition) {
      id
      partnerId
      type
      licenseKey
      totalUsage
      deviceType
      deviceId
      osVersion
      createdAtEpoch
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateExternalUsersPlayground = /* GraphQL */ `
  mutation UpdateExternalUsersPlayground(
    $input: UpdateExternalUsersPlaygroundInput!
    $condition: ModelExternalUsersPlaygroundConditionInput
  ) {
    updateExternalUsersPlayground(input: $input, condition: $condition) {
      id
      partnerId
      type
      licenseKey
      totalUsage
      deviceType
      deviceId
      osVersion
      createdAtEpoch
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteExternalUsersPlayground = /* GraphQL */ `
  mutation DeleteExternalUsersPlayground(
    $input: DeleteExternalUsersPlaygroundInput!
    $condition: ModelExternalUsersPlaygroundConditionInput
  ) {
    deleteExternalUsersPlayground(input: $input, condition: $condition) {
      id
      partnerId
      type
      licenseKey
      totalUsage
      deviceType
      deviceId
      osVersion
      createdAtEpoch
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createReferralHistory = /* GraphQL */ `
  mutation CreateReferralHistory(
    $input: CreateReferralHistoryInput!
    $condition: ModelReferralHistoryConditionInput
  ) {
    createReferralHistory(input: $input, condition: $condition) {
      id
      userId
      referralUserId
      referralCode
      createdAt
      updateAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      user {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        referralCode
        referralName
        isReferralRedeem
        email
        emailLower
        profilePicture
        phoneNumber
        countDownDuration
        subscription
        subscriptionLower
        subscriptionDate
        subscriptionExpiryDate
        keepScreenOn
        isFlareOn
        automaticStartOn
        totalBalance
        totalReward
        totalDriversAlerted
        totalDistanceCovered
        totalProtectedJourneys
        totalRideTimeInSeconds
        totalNearMissesReported
        country
        deviceId
        fcmToken
        deviceType
        isEmailVerified
        latitude
        longitude
        partnerId
        retailCodeUsed
        retailCodeUsedLower
        riderActivities
        riderType
        ridingPeriod
        rewardAccessType
        rewardAccessTypeLower
        rewardAccessStartDate
        rewardAccessEndDate
        rewardAccessSubTotal
        rewardAccessVAT
        rewardAccessVATAmount
        rewardAccessTotalAmount
        rewardAccessStripeToken
        rewardAccessStripeReceipt
        isRewardAccessPaymentSuccessful
        rewardAccessMode
        isPaidToAffiliate
        isSmartStartOn
        referralId
        registrationProvider
        activityTimeInSeconds
        totalRewardsRedeemed
        reputationScore
        appVersion
        sweatCoinAuthenticationToken
        lastAccessed
        isSmartStartNotificationEnable
        isMarketPlaceNotificationEnable
        agreedConsents {
          description
          type
          accepted
        }
        isActive
        isDeleted
        deletedUser
        createdAt
        updateAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        owner
        partner {
          id
          invitedByPartnerId
          partnerCode
          dynamicLink
          desktopURL
          companyName
          companyNameLower
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          email
          emailLower
          password
          profilePicture
          companyEmblem
          phone
          addressLine1
          addressLine2
          city
          country
          countryIsoCode
          postcode
          deviceType
          fcmToken
          totalFlaresReceived
          totalFlaresRespondedTo
          totalIncidentsReceived
          totalIncidentsActioned
          latitude
          longitude
          retailCodePrice
          resalePrice
          invoicePayment
          rewardAvailabilityCountries
          referralURL
          referralIntroText
          referralBackgroundImage
          referralRetailPrice
          incidentNotificationSettings
          notificationTitle
          notificationMessage
          notificationPeriod
          hasRiderConfirmed
          iotEndpoint
          enableRewards
          enableReferrals
          enableAppDiscountsHub
          enableFlares
          enableRiderManagement
          enableRetail
          enableAutoStart
          enableEmergencySOS
          enableSdk
          enforceEmergencyContact
          enforceSafeZone
          enforcePhoneNumber
          youtubeLink
          enforceRiderId
          termsAndConditionLink
          privacyPolicyLink
          landingPagePoints {
            text
          }
          sandboxFlareApiKey
          sandboxFlareApiKeyUsage
          productionFlareApiKey
          productionFlareApiKeyUsage
          licenseKeys {
            licenseKey
            keyName
            environment
            totalUsage
            totalIncidentDetected
            isActive
            isDeleted
            deletedKey
            lastUsed
            createdAt
            updatedAt
          }
          incidentNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          sosNotificationSettings
          sosNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          consentCheckbox {
            title
            fileType
            link
            description
            type
          }
          enabledTwoStepVerification
          elasticIndexName
          isAccountVerified
          isActive
          isDeleted
          deletedPartner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
      }
    }
  }
`;
export const updateReferralHistory = /* GraphQL */ `
  mutation UpdateReferralHistory(
    $input: UpdateReferralHistoryInput!
    $condition: ModelReferralHistoryConditionInput
  ) {
    updateReferralHistory(input: $input, condition: $condition) {
      id
      userId
      referralUserId
      referralCode
      createdAt
      updateAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      user {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        referralCode
        referralName
        isReferralRedeem
        email
        emailLower
        profilePicture
        phoneNumber
        countDownDuration
        subscription
        subscriptionLower
        subscriptionDate
        subscriptionExpiryDate
        keepScreenOn
        isFlareOn
        automaticStartOn
        totalBalance
        totalReward
        totalDriversAlerted
        totalDistanceCovered
        totalProtectedJourneys
        totalRideTimeInSeconds
        totalNearMissesReported
        country
        deviceId
        fcmToken
        deviceType
        isEmailVerified
        latitude
        longitude
        partnerId
        retailCodeUsed
        retailCodeUsedLower
        riderActivities
        riderType
        ridingPeriod
        rewardAccessType
        rewardAccessTypeLower
        rewardAccessStartDate
        rewardAccessEndDate
        rewardAccessSubTotal
        rewardAccessVAT
        rewardAccessVATAmount
        rewardAccessTotalAmount
        rewardAccessStripeToken
        rewardAccessStripeReceipt
        isRewardAccessPaymentSuccessful
        rewardAccessMode
        isPaidToAffiliate
        isSmartStartOn
        referralId
        registrationProvider
        activityTimeInSeconds
        totalRewardsRedeemed
        reputationScore
        appVersion
        sweatCoinAuthenticationToken
        lastAccessed
        isSmartStartNotificationEnable
        isMarketPlaceNotificationEnable
        agreedConsents {
          description
          type
          accepted
        }
        isActive
        isDeleted
        deletedUser
        createdAt
        updateAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        owner
        partner {
          id
          invitedByPartnerId
          partnerCode
          dynamicLink
          desktopURL
          companyName
          companyNameLower
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          email
          emailLower
          password
          profilePicture
          companyEmblem
          phone
          addressLine1
          addressLine2
          city
          country
          countryIsoCode
          postcode
          deviceType
          fcmToken
          totalFlaresReceived
          totalFlaresRespondedTo
          totalIncidentsReceived
          totalIncidentsActioned
          latitude
          longitude
          retailCodePrice
          resalePrice
          invoicePayment
          rewardAvailabilityCountries
          referralURL
          referralIntroText
          referralBackgroundImage
          referralRetailPrice
          incidentNotificationSettings
          notificationTitle
          notificationMessage
          notificationPeriod
          hasRiderConfirmed
          iotEndpoint
          enableRewards
          enableReferrals
          enableAppDiscountsHub
          enableFlares
          enableRiderManagement
          enableRetail
          enableAutoStart
          enableEmergencySOS
          enableSdk
          enforceEmergencyContact
          enforceSafeZone
          enforcePhoneNumber
          youtubeLink
          enforceRiderId
          termsAndConditionLink
          privacyPolicyLink
          landingPagePoints {
            text
          }
          sandboxFlareApiKey
          sandboxFlareApiKeyUsage
          productionFlareApiKey
          productionFlareApiKeyUsage
          licenseKeys {
            licenseKey
            keyName
            environment
            totalUsage
            totalIncidentDetected
            isActive
            isDeleted
            deletedKey
            lastUsed
            createdAt
            updatedAt
          }
          incidentNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          sosNotificationSettings
          sosNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          consentCheckbox {
            title
            fileType
            link
            description
            type
          }
          enabledTwoStepVerification
          elasticIndexName
          isAccountVerified
          isActive
          isDeleted
          deletedPartner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
      }
    }
  }
`;
export const deleteReferralHistory = /* GraphQL */ `
  mutation DeleteReferralHistory(
    $input: DeleteReferralHistoryInput!
    $condition: ModelReferralHistoryConditionInput
  ) {
    deleteReferralHistory(input: $input, condition: $condition) {
      id
      userId
      referralUserId
      referralCode
      createdAt
      updateAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      user {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        referralCode
        referralName
        isReferralRedeem
        email
        emailLower
        profilePicture
        phoneNumber
        countDownDuration
        subscription
        subscriptionLower
        subscriptionDate
        subscriptionExpiryDate
        keepScreenOn
        isFlareOn
        automaticStartOn
        totalBalance
        totalReward
        totalDriversAlerted
        totalDistanceCovered
        totalProtectedJourneys
        totalRideTimeInSeconds
        totalNearMissesReported
        country
        deviceId
        fcmToken
        deviceType
        isEmailVerified
        latitude
        longitude
        partnerId
        retailCodeUsed
        retailCodeUsedLower
        riderActivities
        riderType
        ridingPeriod
        rewardAccessType
        rewardAccessTypeLower
        rewardAccessStartDate
        rewardAccessEndDate
        rewardAccessSubTotal
        rewardAccessVAT
        rewardAccessVATAmount
        rewardAccessTotalAmount
        rewardAccessStripeToken
        rewardAccessStripeReceipt
        isRewardAccessPaymentSuccessful
        rewardAccessMode
        isPaidToAffiliate
        isSmartStartOn
        referralId
        registrationProvider
        activityTimeInSeconds
        totalRewardsRedeemed
        reputationScore
        appVersion
        sweatCoinAuthenticationToken
        lastAccessed
        isSmartStartNotificationEnable
        isMarketPlaceNotificationEnable
        agreedConsents {
          description
          type
          accepted
        }
        isActive
        isDeleted
        deletedUser
        createdAt
        updateAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        owner
        partner {
          id
          invitedByPartnerId
          partnerCode
          dynamicLink
          desktopURL
          companyName
          companyNameLower
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          email
          emailLower
          password
          profilePicture
          companyEmblem
          phone
          addressLine1
          addressLine2
          city
          country
          countryIsoCode
          postcode
          deviceType
          fcmToken
          totalFlaresReceived
          totalFlaresRespondedTo
          totalIncidentsReceived
          totalIncidentsActioned
          latitude
          longitude
          retailCodePrice
          resalePrice
          invoicePayment
          rewardAvailabilityCountries
          referralURL
          referralIntroText
          referralBackgroundImage
          referralRetailPrice
          incidentNotificationSettings
          notificationTitle
          notificationMessage
          notificationPeriod
          hasRiderConfirmed
          iotEndpoint
          enableRewards
          enableReferrals
          enableAppDiscountsHub
          enableFlares
          enableRiderManagement
          enableRetail
          enableAutoStart
          enableEmergencySOS
          enableSdk
          enforceEmergencyContact
          enforceSafeZone
          enforcePhoneNumber
          youtubeLink
          enforceRiderId
          termsAndConditionLink
          privacyPolicyLink
          landingPagePoints {
            text
          }
          sandboxFlareApiKey
          sandboxFlareApiKeyUsage
          productionFlareApiKey
          productionFlareApiKeyUsage
          licenseKeys {
            licenseKey
            keyName
            environment
            totalUsage
            totalIncidentDetected
            isActive
            isDeleted
            deletedKey
            lastUsed
            createdAt
            updatedAt
          }
          incidentNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          sosNotificationSettings
          sosNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          consentCheckbox {
            title
            fileType
            link
            description
            type
          }
          enabledTwoStepVerification
          elasticIndexName
          isAccountVerified
          isActive
          isDeleted
          deletedPartner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
      }
    }
  }
`;
export const createAdminUsers = /* GraphQL */ `
  mutation CreateAdminUsers(
    $input: CreateAdminUsersInput!
    $condition: ModelAdminUsersConditionInput
  ) {
    createAdminUsers(input: $input, condition: $condition) {
      id
      firstName
      firstNameLower
      lastName
      lastNameLower
      email
      emailLower
      password
      userName
      userNameLower
      deviceType
      fcmToken
      isAccountVerified
      isActive
      isDeleted
      deletedAdminUser
      lastAccessed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateAdminUsers = /* GraphQL */ `
  mutation UpdateAdminUsers(
    $input: UpdateAdminUsersInput!
    $condition: ModelAdminUsersConditionInput
  ) {
    updateAdminUsers(input: $input, condition: $condition) {
      id
      firstName
      firstNameLower
      lastName
      lastNameLower
      email
      emailLower
      password
      userName
      userNameLower
      deviceType
      fcmToken
      isAccountVerified
      isActive
      isDeleted
      deletedAdminUser
      lastAccessed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteAdminUsers = /* GraphQL */ `
  mutation DeleteAdminUsers(
    $input: DeleteAdminUsersInput!
    $condition: ModelAdminUsersConditionInput
  ) {
    deleteAdminUsers(input: $input, condition: $condition) {
      id
      firstName
      firstNameLower
      lastName
      lastNameLower
      email
      emailLower
      password
      userName
      userNameLower
      deviceType
      fcmToken
      isAccountVerified
      isActive
      isDeleted
      deletedAdminUser
      lastAccessed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createCountry = /* GraphQL */ `
  mutation CreateCountry(
    $input: CreateCountryInput!
    $condition: ModelCountryConditionInput
  ) {
    createCountry(input: $input, condition: $condition) {
      id
      countryName
      callingCode
      isoCode
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCountry = /* GraphQL */ `
  mutation UpdateCountry(
    $input: UpdateCountryInput!
    $condition: ModelCountryConditionInput
  ) {
    updateCountry(input: $input, condition: $condition) {
      id
      countryName
      callingCode
      isoCode
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCountry = /* GraphQL */ `
  mutation DeleteCountry(
    $input: DeleteCountryInput!
    $condition: ModelCountryConditionInput
  ) {
    deleteCountry(input: $input, condition: $condition) {
      id
      countryName
      callingCode
      isoCode
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createAppConfig = /* GraphQL */ `
  mutation CreateAppConfig(
    $input: CreateAppConfigInput!
    $condition: ModelAppConfigConditionInput
  ) {
    createAppConfig(input: $input, condition: $condition) {
      id
      androidVersion
      iOSVersion
      isForceUpdateAndroid
      isForceUpdateiOS
      message
      releaseNotes {
        title
        isLive
        version
        notes
        createdAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateAppConfig = /* GraphQL */ `
  mutation UpdateAppConfig(
    $input: UpdateAppConfigInput!
    $condition: ModelAppConfigConditionInput
  ) {
    updateAppConfig(input: $input, condition: $condition) {
      id
      androidVersion
      iOSVersion
      isForceUpdateAndroid
      isForceUpdateiOS
      message
      releaseNotes {
        title
        isLive
        version
        notes
        createdAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteAppConfig = /* GraphQL */ `
  mutation DeleteAppConfig(
    $input: DeleteAppConfigInput!
    $condition: ModelAppConfigConditionInput
  ) {
    deleteAppConfig(input: $input, condition: $condition) {
      id
      androidVersion
      iOSVersion
      isForceUpdateAndroid
      isForceUpdateiOS
      message
      releaseNotes {
        title
        isLive
        version
        notes
        createdAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createUsers = /* GraphQL */ `
  mutation CreateUsers(
    $input: CreateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    createUsers(input: $input, condition: $condition) {
      id
      firstName
      firstNameLower
      lastName
      lastNameLower
      userName
      userNameLower
      referralCode
      referralName
      isReferralRedeem
      email
      emailLower
      profilePicture
      phoneNumber
      countDownDuration
      subscription
      subscriptionLower
      subscriptionDate
      subscriptionExpiryDate
      keepScreenOn
      isFlareOn
      automaticStartOn
      totalBalance
      totalReward
      totalDriversAlerted
      totalDistanceCovered
      totalProtectedJourneys
      totalRideTimeInSeconds
      totalNearMissesReported
      country
      deviceId
      fcmToken
      deviceType
      isEmailVerified
      latitude
      longitude
      partnerId
      retailCodeUsed
      retailCodeUsedLower
      riderActivities
      riderType
      ridingPeriod
      rewardAccessType
      rewardAccessTypeLower
      rewardAccessStartDate
      rewardAccessEndDate
      rewardAccessSubTotal
      rewardAccessVAT
      rewardAccessVATAmount
      rewardAccessTotalAmount
      rewardAccessStripeToken
      rewardAccessStripeReceipt
      isRewardAccessPaymentSuccessful
      rewardAccessMode
      isPaidToAffiliate
      isSmartStartOn
      referralId
      registrationProvider
      activityTimeInSeconds
      totalRewardsRedeemed
      reputationScore
      appVersion
      sweatCoinAuthenticationToken
      lastAccessed
      isSmartStartNotificationEnable
      isMarketPlaceNotificationEnable
      agreedConsents {
        description
        type
        accepted
      }
      isActive
      isDeleted
      deletedUser
      createdAt
      updateAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      owner
      partner {
        id
        invitedByPartnerId
        partnerCode
        dynamicLink
        desktopURL
        companyName
        companyNameLower
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        email
        emailLower
        password
        profilePicture
        companyEmblem
        phone
        addressLine1
        addressLine2
        city
        country
        countryIsoCode
        postcode
        deviceType
        fcmToken
        totalFlaresReceived
        totalFlaresRespondedTo
        totalIncidentsReceived
        totalIncidentsActioned
        latitude
        longitude
        retailCodePrice
        resalePrice
        invoicePayment
        rewardAvailabilityCountries
        referralURL
        referralIntroText
        referralBackgroundImage
        referralRetailPrice
        incidentNotificationSettings
        notificationTitle
        notificationMessage
        notificationPeriod
        hasRiderConfirmed
        iotEndpoint
        enableRewards
        enableReferrals
        enableAppDiscountsHub
        enableFlares
        enableRiderManagement
        enableRetail
        enableAutoStart
        enableEmergencySOS
        enableSdk
        enforceEmergencyContact
        enforceSafeZone
        enforcePhoneNumber
        youtubeLink
        enforceRiderId
        termsAndConditionLink
        privacyPolicyLink
        landingPagePoints {
          text
        }
        sandboxFlareApiKey
        sandboxFlareApiKeyUsage
        productionFlareApiKey
        productionFlareApiKeyUsage
        licenseKeys {
          licenseKey
          keyName
          environment
          totalUsage
          totalIncidentDetected
          isActive
          isDeleted
          deletedKey
          lastUsed
          createdAt
          updatedAt
        }
        incidentNotifications {
          notificationSettings
          notificationEmail
          notificationCountry
          notificationCountryCallingCode
          notificationMobileNo
          notificationWebhookURL
          includeRiderEmail
          riderID
          riderName
          riderCity
          riderEmail
          incidentDateAndTime
          ISOCountryCode
          incidentLocation
          incidentTelemetry
        }
        sosNotificationSettings
        sosNotifications {
          notificationSettings
          notificationEmail
          notificationCountry
          notificationCountryCallingCode
          notificationMobileNo
          notificationWebhookURL
          includeRiderEmail
          riderID
          riderName
          riderCity
          riderEmail
          incidentDateAndTime
          ISOCountryCode
          incidentLocation
          incidentTelemetry
        }
        consentCheckbox {
          title
          fileType
          link
          description
          type
        }
        enabledTwoStepVerification
        elasticIndexName
        isAccountVerified
        isActive
        isDeleted
        deletedPartner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
    }
  }
`;
export const updateUsers = /* GraphQL */ `
  mutation UpdateUsers(
    $input: UpdateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    updateUsers(input: $input, condition: $condition) {
      id
      firstName
      firstNameLower
      lastName
      lastNameLower
      userName
      userNameLower
      referralCode
      referralName
      isReferralRedeem
      email
      emailLower
      profilePicture
      phoneNumber
      countDownDuration
      subscription
      subscriptionLower
      subscriptionDate
      subscriptionExpiryDate
      keepScreenOn
      isFlareOn
      automaticStartOn
      totalBalance
      totalReward
      totalDriversAlerted
      totalDistanceCovered
      totalProtectedJourneys
      totalRideTimeInSeconds
      totalNearMissesReported
      country
      deviceId
      fcmToken
      deviceType
      isEmailVerified
      latitude
      longitude
      partnerId
      retailCodeUsed
      retailCodeUsedLower
      riderActivities
      riderType
      ridingPeriod
      rewardAccessType
      rewardAccessTypeLower
      rewardAccessStartDate
      rewardAccessEndDate
      rewardAccessSubTotal
      rewardAccessVAT
      rewardAccessVATAmount
      rewardAccessTotalAmount
      rewardAccessStripeToken
      rewardAccessStripeReceipt
      isRewardAccessPaymentSuccessful
      rewardAccessMode
      isPaidToAffiliate
      isSmartStartOn
      referralId
      registrationProvider
      activityTimeInSeconds
      totalRewardsRedeemed
      reputationScore
      appVersion
      sweatCoinAuthenticationToken
      lastAccessed
      isSmartStartNotificationEnable
      isMarketPlaceNotificationEnable
      agreedConsents {
        description
        type
        accepted
      }
      isActive
      isDeleted
      deletedUser
      createdAt
      updateAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      owner
      partner {
        id
        invitedByPartnerId
        partnerCode
        dynamicLink
        desktopURL
        companyName
        companyNameLower
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        email
        emailLower
        password
        profilePicture
        companyEmblem
        phone
        addressLine1
        addressLine2
        city
        country
        countryIsoCode
        postcode
        deviceType
        fcmToken
        totalFlaresReceived
        totalFlaresRespondedTo
        totalIncidentsReceived
        totalIncidentsActioned
        latitude
        longitude
        retailCodePrice
        resalePrice
        invoicePayment
        rewardAvailabilityCountries
        referralURL
        referralIntroText
        referralBackgroundImage
        referralRetailPrice
        incidentNotificationSettings
        notificationTitle
        notificationMessage
        notificationPeriod
        hasRiderConfirmed
        iotEndpoint
        enableRewards
        enableReferrals
        enableAppDiscountsHub
        enableFlares
        enableRiderManagement
        enableRetail
        enableAutoStart
        enableEmergencySOS
        enableSdk
        enforceEmergencyContact
        enforceSafeZone
        enforcePhoneNumber
        youtubeLink
        enforceRiderId
        termsAndConditionLink
        privacyPolicyLink
        landingPagePoints {
          text
        }
        sandboxFlareApiKey
        sandboxFlareApiKeyUsage
        productionFlareApiKey
        productionFlareApiKeyUsage
        licenseKeys {
          licenseKey
          keyName
          environment
          totalUsage
          totalIncidentDetected
          isActive
          isDeleted
          deletedKey
          lastUsed
          createdAt
          updatedAt
        }
        incidentNotifications {
          notificationSettings
          notificationEmail
          notificationCountry
          notificationCountryCallingCode
          notificationMobileNo
          notificationWebhookURL
          includeRiderEmail
          riderID
          riderName
          riderCity
          riderEmail
          incidentDateAndTime
          ISOCountryCode
          incidentLocation
          incidentTelemetry
        }
        sosNotificationSettings
        sosNotifications {
          notificationSettings
          notificationEmail
          notificationCountry
          notificationCountryCallingCode
          notificationMobileNo
          notificationWebhookURL
          includeRiderEmail
          riderID
          riderName
          riderCity
          riderEmail
          incidentDateAndTime
          ISOCountryCode
          incidentLocation
          incidentTelemetry
        }
        consentCheckbox {
          title
          fileType
          link
          description
          type
        }
        enabledTwoStepVerification
        elasticIndexName
        isAccountVerified
        isActive
        isDeleted
        deletedPartner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
    }
  }
`;
export const deleteUsers = /* GraphQL */ `
  mutation DeleteUsers(
    $input: DeleteUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    deleteUsers(input: $input, condition: $condition) {
      id
      firstName
      firstNameLower
      lastName
      lastNameLower
      userName
      userNameLower
      referralCode
      referralName
      isReferralRedeem
      email
      emailLower
      profilePicture
      phoneNumber
      countDownDuration
      subscription
      subscriptionLower
      subscriptionDate
      subscriptionExpiryDate
      keepScreenOn
      isFlareOn
      automaticStartOn
      totalBalance
      totalReward
      totalDriversAlerted
      totalDistanceCovered
      totalProtectedJourneys
      totalRideTimeInSeconds
      totalNearMissesReported
      country
      deviceId
      fcmToken
      deviceType
      isEmailVerified
      latitude
      longitude
      partnerId
      retailCodeUsed
      retailCodeUsedLower
      riderActivities
      riderType
      ridingPeriod
      rewardAccessType
      rewardAccessTypeLower
      rewardAccessStartDate
      rewardAccessEndDate
      rewardAccessSubTotal
      rewardAccessVAT
      rewardAccessVATAmount
      rewardAccessTotalAmount
      rewardAccessStripeToken
      rewardAccessStripeReceipt
      isRewardAccessPaymentSuccessful
      rewardAccessMode
      isPaidToAffiliate
      isSmartStartOn
      referralId
      registrationProvider
      activityTimeInSeconds
      totalRewardsRedeemed
      reputationScore
      appVersion
      sweatCoinAuthenticationToken
      lastAccessed
      isSmartStartNotificationEnable
      isMarketPlaceNotificationEnable
      agreedConsents {
        description
        type
        accepted
      }
      isActive
      isDeleted
      deletedUser
      createdAt
      updateAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      owner
      partner {
        id
        invitedByPartnerId
        partnerCode
        dynamicLink
        desktopURL
        companyName
        companyNameLower
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        email
        emailLower
        password
        profilePicture
        companyEmblem
        phone
        addressLine1
        addressLine2
        city
        country
        countryIsoCode
        postcode
        deviceType
        fcmToken
        totalFlaresReceived
        totalFlaresRespondedTo
        totalIncidentsReceived
        totalIncidentsActioned
        latitude
        longitude
        retailCodePrice
        resalePrice
        invoicePayment
        rewardAvailabilityCountries
        referralURL
        referralIntroText
        referralBackgroundImage
        referralRetailPrice
        incidentNotificationSettings
        notificationTitle
        notificationMessage
        notificationPeriod
        hasRiderConfirmed
        iotEndpoint
        enableRewards
        enableReferrals
        enableAppDiscountsHub
        enableFlares
        enableRiderManagement
        enableRetail
        enableAutoStart
        enableEmergencySOS
        enableSdk
        enforceEmergencyContact
        enforceSafeZone
        enforcePhoneNumber
        youtubeLink
        enforceRiderId
        termsAndConditionLink
        privacyPolicyLink
        landingPagePoints {
          text
        }
        sandboxFlareApiKey
        sandboxFlareApiKeyUsage
        productionFlareApiKey
        productionFlareApiKeyUsage
        licenseKeys {
          licenseKey
          keyName
          environment
          totalUsage
          totalIncidentDetected
          isActive
          isDeleted
          deletedKey
          lastUsed
          createdAt
          updatedAt
        }
        incidentNotifications {
          notificationSettings
          notificationEmail
          notificationCountry
          notificationCountryCallingCode
          notificationMobileNo
          notificationWebhookURL
          includeRiderEmail
          riderID
          riderName
          riderCity
          riderEmail
          incidentDateAndTime
          ISOCountryCode
          incidentLocation
          incidentTelemetry
        }
        sosNotificationSettings
        sosNotifications {
          notificationSettings
          notificationEmail
          notificationCountry
          notificationCountryCallingCode
          notificationMobileNo
          notificationWebhookURL
          includeRiderEmail
          riderID
          riderName
          riderCity
          riderEmail
          incidentDateAndTime
          ISOCountryCode
          incidentLocation
          incidentTelemetry
        }
        consentCheckbox {
          title
          fileType
          link
          description
          type
        }
        enabledTwoStepVerification
        elasticIndexName
        isAccountVerified
        isActive
        isDeleted
        deletedPartner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
    }
  }
`;
export const createEmergencyContacts = /* GraphQL */ `
  mutation CreateEmergencyContacts(
    $input: CreateEmergencyContactsInput!
    $condition: ModelEmergencyContactsConditionInput
  ) {
    createEmergencyContacts(input: $input, condition: $condition) {
      id
      userId
      partnerId
      name
      mobile
      country
      guestUserDeviceId
      isDeleted
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateEmergencyContacts = /* GraphQL */ `
  mutation UpdateEmergencyContacts(
    $input: UpdateEmergencyContactsInput!
    $condition: ModelEmergencyContactsConditionInput
  ) {
    updateEmergencyContacts(input: $input, condition: $condition) {
      id
      userId
      partnerId
      name
      mobile
      country
      guestUserDeviceId
      isDeleted
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteEmergencyContacts = /* GraphQL */ `
  mutation DeleteEmergencyContacts(
    $input: DeleteEmergencyContactsInput!
    $condition: ModelEmergencyContactsConditionInput
  ) {
    deleteEmergencyContacts(input: $input, condition: $condition) {
      id
      userId
      partnerId
      name
      mobile
      country
      guestUserDeviceId
      isDeleted
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createSubscriptions = /* GraphQL */ `
  mutation CreateSubscriptions(
    $input: CreateSubscriptionsInput!
    $condition: ModelSubscriptionsConditionInput
  ) {
    createSubscriptions(input: $input, condition: $condition) {
      id
      userId
      subscription
      subscriptionAmount
      subscriptionDate
      subscriptionExpiryDate
      receipts
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateSubscriptions = /* GraphQL */ `
  mutation UpdateSubscriptions(
    $input: UpdateSubscriptionsInput!
    $condition: ModelSubscriptionsConditionInput
  ) {
    updateSubscriptions(input: $input, condition: $condition) {
      id
      userId
      subscription
      subscriptionAmount
      subscriptionDate
      subscriptionExpiryDate
      receipts
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteSubscriptions = /* GraphQL */ `
  mutation DeleteSubscriptions(
    $input: DeleteSubscriptionsInput!
    $condition: ModelSubscriptionsConditionInput
  ) {
    deleteSubscriptions(input: $input, condition: $condition) {
      id
      userId
      subscription
      subscriptionAmount
      subscriptionDate
      subscriptionExpiryDate
      receipts
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createUserSafeZone = /* GraphQL */ `
  mutation CreateUserSafeZone(
    $input: CreateUserSafeZoneInput!
    $condition: ModelUserSafeZoneConditionInput
  ) {
    createUserSafeZone(input: $input, condition: $condition) {
      id
      userId
      type
      postalCode
      address
      latitude
      longitude
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      owner
    }
  }
`;
export const updateUserSafeZone = /* GraphQL */ `
  mutation UpdateUserSafeZone(
    $input: UpdateUserSafeZoneInput!
    $condition: ModelUserSafeZoneConditionInput
  ) {
    updateUserSafeZone(input: $input, condition: $condition) {
      id
      userId
      type
      postalCode
      address
      latitude
      longitude
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      owner
    }
  }
`;
export const deleteUserSafeZone = /* GraphQL */ `
  mutation DeleteUserSafeZone(
    $input: DeleteUserSafeZoneInput!
    $condition: ModelUserSafeZoneConditionInput
  ) {
    deleteUserSafeZone(input: $input, condition: $condition) {
      id
      userId
      type
      postalCode
      address
      latitude
      longitude
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      owner
    }
  }
`;
export const createGroups = /* GraphQL */ `
  mutation CreateGroups(
    $input: CreateGroupsInput!
    $condition: ModelGroupsConditionInput
  ) {
    createGroups(input: $input, condition: $condition) {
      id
      createdByUserId
      nanoid
      name
      nameLower
      groupCode
      groupCodeLower
      qrImage
      rideRadius
      rideDuration
      latitude
      longitude
      isEnded
      isDeleted
      deletedGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        referralCode
        referralName
        isReferralRedeem
        email
        emailLower
        profilePicture
        phoneNumber
        countDownDuration
        subscription
        subscriptionLower
        subscriptionDate
        subscriptionExpiryDate
        keepScreenOn
        isFlareOn
        automaticStartOn
        totalBalance
        totalReward
        totalDriversAlerted
        totalDistanceCovered
        totalProtectedJourneys
        totalRideTimeInSeconds
        totalNearMissesReported
        country
        deviceId
        fcmToken
        deviceType
        isEmailVerified
        latitude
        longitude
        partnerId
        retailCodeUsed
        retailCodeUsedLower
        riderActivities
        riderType
        ridingPeriod
        rewardAccessType
        rewardAccessTypeLower
        rewardAccessStartDate
        rewardAccessEndDate
        rewardAccessSubTotal
        rewardAccessVAT
        rewardAccessVATAmount
        rewardAccessTotalAmount
        rewardAccessStripeToken
        rewardAccessStripeReceipt
        isRewardAccessPaymentSuccessful
        rewardAccessMode
        isPaidToAffiliate
        isSmartStartOn
        referralId
        registrationProvider
        activityTimeInSeconds
        totalRewardsRedeemed
        reputationScore
        appVersion
        sweatCoinAuthenticationToken
        lastAccessed
        isSmartStartNotificationEnable
        isMarketPlaceNotificationEnable
        agreedConsents {
          description
          type
          accepted
        }
        isActive
        isDeleted
        deletedUser
        createdAt
        updateAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        owner
        partner {
          id
          invitedByPartnerId
          partnerCode
          dynamicLink
          desktopURL
          companyName
          companyNameLower
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          email
          emailLower
          password
          profilePicture
          companyEmblem
          phone
          addressLine1
          addressLine2
          city
          country
          countryIsoCode
          postcode
          deviceType
          fcmToken
          totalFlaresReceived
          totalFlaresRespondedTo
          totalIncidentsReceived
          totalIncidentsActioned
          latitude
          longitude
          retailCodePrice
          resalePrice
          invoicePayment
          rewardAvailabilityCountries
          referralURL
          referralIntroText
          referralBackgroundImage
          referralRetailPrice
          incidentNotificationSettings
          notificationTitle
          notificationMessage
          notificationPeriod
          hasRiderConfirmed
          iotEndpoint
          enableRewards
          enableReferrals
          enableAppDiscountsHub
          enableFlares
          enableRiderManagement
          enableRetail
          enableAutoStart
          enableEmergencySOS
          enableSdk
          enforceEmergencyContact
          enforceSafeZone
          enforcePhoneNumber
          youtubeLink
          enforceRiderId
          termsAndConditionLink
          privacyPolicyLink
          landingPagePoints {
            text
          }
          sandboxFlareApiKey
          sandboxFlareApiKeyUsage
          productionFlareApiKey
          productionFlareApiKeyUsage
          licenseKeys {
            licenseKey
            keyName
            environment
            totalUsage
            totalIncidentDetected
            isActive
            isDeleted
            deletedKey
            lastUsed
            createdAt
            updatedAt
          }
          incidentNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          sosNotificationSettings
          sosNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          consentCheckbox {
            title
            fileType
            link
            description
            type
          }
          enabledTwoStepVerification
          elasticIndexName
          isAccountVerified
          isActive
          isDeleted
          deletedPartner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
      }
      owner
    }
  }
`;
export const updateGroups = /* GraphQL */ `
  mutation UpdateGroups(
    $input: UpdateGroupsInput!
    $condition: ModelGroupsConditionInput
  ) {
    updateGroups(input: $input, condition: $condition) {
      id
      createdByUserId
      nanoid
      name
      nameLower
      groupCode
      groupCodeLower
      qrImage
      rideRadius
      rideDuration
      latitude
      longitude
      isEnded
      isDeleted
      deletedGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        referralCode
        referralName
        isReferralRedeem
        email
        emailLower
        profilePicture
        phoneNumber
        countDownDuration
        subscription
        subscriptionLower
        subscriptionDate
        subscriptionExpiryDate
        keepScreenOn
        isFlareOn
        automaticStartOn
        totalBalance
        totalReward
        totalDriversAlerted
        totalDistanceCovered
        totalProtectedJourneys
        totalRideTimeInSeconds
        totalNearMissesReported
        country
        deviceId
        fcmToken
        deviceType
        isEmailVerified
        latitude
        longitude
        partnerId
        retailCodeUsed
        retailCodeUsedLower
        riderActivities
        riderType
        ridingPeriod
        rewardAccessType
        rewardAccessTypeLower
        rewardAccessStartDate
        rewardAccessEndDate
        rewardAccessSubTotal
        rewardAccessVAT
        rewardAccessVATAmount
        rewardAccessTotalAmount
        rewardAccessStripeToken
        rewardAccessStripeReceipt
        isRewardAccessPaymentSuccessful
        rewardAccessMode
        isPaidToAffiliate
        isSmartStartOn
        referralId
        registrationProvider
        activityTimeInSeconds
        totalRewardsRedeemed
        reputationScore
        appVersion
        sweatCoinAuthenticationToken
        lastAccessed
        isSmartStartNotificationEnable
        isMarketPlaceNotificationEnable
        agreedConsents {
          description
          type
          accepted
        }
        isActive
        isDeleted
        deletedUser
        createdAt
        updateAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        owner
        partner {
          id
          invitedByPartnerId
          partnerCode
          dynamicLink
          desktopURL
          companyName
          companyNameLower
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          email
          emailLower
          password
          profilePicture
          companyEmblem
          phone
          addressLine1
          addressLine2
          city
          country
          countryIsoCode
          postcode
          deviceType
          fcmToken
          totalFlaresReceived
          totalFlaresRespondedTo
          totalIncidentsReceived
          totalIncidentsActioned
          latitude
          longitude
          retailCodePrice
          resalePrice
          invoicePayment
          rewardAvailabilityCountries
          referralURL
          referralIntroText
          referralBackgroundImage
          referralRetailPrice
          incidentNotificationSettings
          notificationTitle
          notificationMessage
          notificationPeriod
          hasRiderConfirmed
          iotEndpoint
          enableRewards
          enableReferrals
          enableAppDiscountsHub
          enableFlares
          enableRiderManagement
          enableRetail
          enableAutoStart
          enableEmergencySOS
          enableSdk
          enforceEmergencyContact
          enforceSafeZone
          enforcePhoneNumber
          youtubeLink
          enforceRiderId
          termsAndConditionLink
          privacyPolicyLink
          landingPagePoints {
            text
          }
          sandboxFlareApiKey
          sandboxFlareApiKeyUsage
          productionFlareApiKey
          productionFlareApiKeyUsage
          licenseKeys {
            licenseKey
            keyName
            environment
            totalUsage
            totalIncidentDetected
            isActive
            isDeleted
            deletedKey
            lastUsed
            createdAt
            updatedAt
          }
          incidentNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          sosNotificationSettings
          sosNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          consentCheckbox {
            title
            fileType
            link
            description
            type
          }
          enabledTwoStepVerification
          elasticIndexName
          isAccountVerified
          isActive
          isDeleted
          deletedPartner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
      }
      owner
    }
  }
`;
export const deleteGroups = /* GraphQL */ `
  mutation DeleteGroups(
    $input: DeleteGroupsInput!
    $condition: ModelGroupsConditionInput
  ) {
    deleteGroups(input: $input, condition: $condition) {
      id
      createdByUserId
      nanoid
      name
      nameLower
      groupCode
      groupCodeLower
      qrImage
      rideRadius
      rideDuration
      latitude
      longitude
      isEnded
      isDeleted
      deletedGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        referralCode
        referralName
        isReferralRedeem
        email
        emailLower
        profilePicture
        phoneNumber
        countDownDuration
        subscription
        subscriptionLower
        subscriptionDate
        subscriptionExpiryDate
        keepScreenOn
        isFlareOn
        automaticStartOn
        totalBalance
        totalReward
        totalDriversAlerted
        totalDistanceCovered
        totalProtectedJourneys
        totalRideTimeInSeconds
        totalNearMissesReported
        country
        deviceId
        fcmToken
        deviceType
        isEmailVerified
        latitude
        longitude
        partnerId
        retailCodeUsed
        retailCodeUsedLower
        riderActivities
        riderType
        ridingPeriod
        rewardAccessType
        rewardAccessTypeLower
        rewardAccessStartDate
        rewardAccessEndDate
        rewardAccessSubTotal
        rewardAccessVAT
        rewardAccessVATAmount
        rewardAccessTotalAmount
        rewardAccessStripeToken
        rewardAccessStripeReceipt
        isRewardAccessPaymentSuccessful
        rewardAccessMode
        isPaidToAffiliate
        isSmartStartOn
        referralId
        registrationProvider
        activityTimeInSeconds
        totalRewardsRedeemed
        reputationScore
        appVersion
        sweatCoinAuthenticationToken
        lastAccessed
        isSmartStartNotificationEnable
        isMarketPlaceNotificationEnable
        agreedConsents {
          description
          type
          accepted
        }
        isActive
        isDeleted
        deletedUser
        createdAt
        updateAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        owner
        partner {
          id
          invitedByPartnerId
          partnerCode
          dynamicLink
          desktopURL
          companyName
          companyNameLower
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          email
          emailLower
          password
          profilePicture
          companyEmblem
          phone
          addressLine1
          addressLine2
          city
          country
          countryIsoCode
          postcode
          deviceType
          fcmToken
          totalFlaresReceived
          totalFlaresRespondedTo
          totalIncidentsReceived
          totalIncidentsActioned
          latitude
          longitude
          retailCodePrice
          resalePrice
          invoicePayment
          rewardAvailabilityCountries
          referralURL
          referralIntroText
          referralBackgroundImage
          referralRetailPrice
          incidentNotificationSettings
          notificationTitle
          notificationMessage
          notificationPeriod
          hasRiderConfirmed
          iotEndpoint
          enableRewards
          enableReferrals
          enableAppDiscountsHub
          enableFlares
          enableRiderManagement
          enableRetail
          enableAutoStart
          enableEmergencySOS
          enableSdk
          enforceEmergencyContact
          enforceSafeZone
          enforcePhoneNumber
          youtubeLink
          enforceRiderId
          termsAndConditionLink
          privacyPolicyLink
          landingPagePoints {
            text
          }
          sandboxFlareApiKey
          sandboxFlareApiKeyUsage
          productionFlareApiKey
          productionFlareApiKeyUsage
          licenseKeys {
            licenseKey
            keyName
            environment
            totalUsage
            totalIncidentDetected
            isActive
            isDeleted
            deletedKey
            lastUsed
            createdAt
            updatedAt
          }
          incidentNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          sosNotificationSettings
          sosNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          consentCheckbox {
            title
            fileType
            link
            description
            type
          }
          enabledTwoStepVerification
          elasticIndexName
          isAccountVerified
          isActive
          isDeleted
          deletedPartner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
      }
      owner
    }
  }
`;
export const createParticipants = /* GraphQL */ `
  mutation CreateParticipants(
    $input: CreateParticipantsInput!
    $condition: ModelParticipantsConditionInput
  ) {
    createParticipants(input: $input, condition: $condition) {
      id
      groupId
      userId
      name
      latitude
      longitude
      isJoin
      isLeftGroup
      isRemove
      isIncident
      incidentProcessCompleted
      date
      distance
      time
      coinsEarned
      coordinates
      isDeleted
      deletedParticipant
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        referralCode
        referralName
        isReferralRedeem
        email
        emailLower
        profilePicture
        phoneNumber
        countDownDuration
        subscription
        subscriptionLower
        subscriptionDate
        subscriptionExpiryDate
        keepScreenOn
        isFlareOn
        automaticStartOn
        totalBalance
        totalReward
        totalDriversAlerted
        totalDistanceCovered
        totalProtectedJourneys
        totalRideTimeInSeconds
        totalNearMissesReported
        country
        deviceId
        fcmToken
        deviceType
        isEmailVerified
        latitude
        longitude
        partnerId
        retailCodeUsed
        retailCodeUsedLower
        riderActivities
        riderType
        ridingPeriod
        rewardAccessType
        rewardAccessTypeLower
        rewardAccessStartDate
        rewardAccessEndDate
        rewardAccessSubTotal
        rewardAccessVAT
        rewardAccessVATAmount
        rewardAccessTotalAmount
        rewardAccessStripeToken
        rewardAccessStripeReceipt
        isRewardAccessPaymentSuccessful
        rewardAccessMode
        isPaidToAffiliate
        isSmartStartOn
        referralId
        registrationProvider
        activityTimeInSeconds
        totalRewardsRedeemed
        reputationScore
        appVersion
        sweatCoinAuthenticationToken
        lastAccessed
        isSmartStartNotificationEnable
        isMarketPlaceNotificationEnable
        agreedConsents {
          description
          type
          accepted
        }
        isActive
        isDeleted
        deletedUser
        createdAt
        updateAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        owner
        partner {
          id
          invitedByPartnerId
          partnerCode
          dynamicLink
          desktopURL
          companyName
          companyNameLower
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          email
          emailLower
          password
          profilePicture
          companyEmblem
          phone
          addressLine1
          addressLine2
          city
          country
          countryIsoCode
          postcode
          deviceType
          fcmToken
          totalFlaresReceived
          totalFlaresRespondedTo
          totalIncidentsReceived
          totalIncidentsActioned
          latitude
          longitude
          retailCodePrice
          resalePrice
          invoicePayment
          rewardAvailabilityCountries
          referralURL
          referralIntroText
          referralBackgroundImage
          referralRetailPrice
          incidentNotificationSettings
          notificationTitle
          notificationMessage
          notificationPeriod
          hasRiderConfirmed
          iotEndpoint
          enableRewards
          enableReferrals
          enableAppDiscountsHub
          enableFlares
          enableRiderManagement
          enableRetail
          enableAutoStart
          enableEmergencySOS
          enableSdk
          enforceEmergencyContact
          enforceSafeZone
          enforcePhoneNumber
          youtubeLink
          enforceRiderId
          termsAndConditionLink
          privacyPolicyLink
          landingPagePoints {
            text
          }
          sandboxFlareApiKey
          sandboxFlareApiKeyUsage
          productionFlareApiKey
          productionFlareApiKeyUsage
          licenseKeys {
            licenseKey
            keyName
            environment
            totalUsage
            totalIncidentDetected
            isActive
            isDeleted
            deletedKey
            lastUsed
            createdAt
            updatedAt
          }
          incidentNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          sosNotificationSettings
          sosNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          consentCheckbox {
            title
            fileType
            link
            description
            type
          }
          enabledTwoStepVerification
          elasticIndexName
          isAccountVerified
          isActive
          isDeleted
          deletedPartner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
      }
      group {
        id
        createdByUserId
        nanoid
        name
        nameLower
        groupCode
        groupCodeLower
        qrImage
        rideRadius
        rideDuration
        latitude
        longitude
        isEnded
        isDeleted
        deletedGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          referralCode
          referralName
          isReferralRedeem
          email
          emailLower
          profilePicture
          phoneNumber
          countDownDuration
          subscription
          subscriptionLower
          subscriptionDate
          subscriptionExpiryDate
          keepScreenOn
          isFlareOn
          automaticStartOn
          totalBalance
          totalReward
          totalDriversAlerted
          totalDistanceCovered
          totalProtectedJourneys
          totalRideTimeInSeconds
          totalNearMissesReported
          country
          deviceId
          fcmToken
          deviceType
          isEmailVerified
          latitude
          longitude
          partnerId
          retailCodeUsed
          retailCodeUsedLower
          riderActivities
          riderType
          ridingPeriod
          rewardAccessType
          rewardAccessTypeLower
          rewardAccessStartDate
          rewardAccessEndDate
          rewardAccessSubTotal
          rewardAccessVAT
          rewardAccessVATAmount
          rewardAccessTotalAmount
          rewardAccessStripeToken
          rewardAccessStripeReceipt
          isRewardAccessPaymentSuccessful
          rewardAccessMode
          isPaidToAffiliate
          isSmartStartOn
          referralId
          registrationProvider
          activityTimeInSeconds
          totalRewardsRedeemed
          reputationScore
          appVersion
          sweatCoinAuthenticationToken
          lastAccessed
          isSmartStartNotificationEnable
          isMarketPlaceNotificationEnable
          agreedConsents {
            description
            type
            accepted
          }
          isActive
          isDeleted
          deletedUser
          createdAt
          updateAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          owner
          partner {
            id
            invitedByPartnerId
            partnerCode
            dynamicLink
            desktopURL
            companyName
            companyNameLower
            firstName
            firstNameLower
            lastName
            lastNameLower
            userName
            userNameLower
            email
            emailLower
            password
            profilePicture
            companyEmblem
            phone
            addressLine1
            addressLine2
            city
            country
            countryIsoCode
            postcode
            deviceType
            fcmToken
            totalFlaresReceived
            totalFlaresRespondedTo
            totalIncidentsReceived
            totalIncidentsActioned
            latitude
            longitude
            retailCodePrice
            resalePrice
            invoicePayment
            rewardAvailabilityCountries
            referralURL
            referralIntroText
            referralBackgroundImage
            referralRetailPrice
            incidentNotificationSettings
            notificationTitle
            notificationMessage
            notificationPeriod
            hasRiderConfirmed
            iotEndpoint
            enableRewards
            enableReferrals
            enableAppDiscountsHub
            enableFlares
            enableRiderManagement
            enableRetail
            enableAutoStart
            enableEmergencySOS
            enableSdk
            enforceEmergencyContact
            enforceSafeZone
            enforcePhoneNumber
            youtubeLink
            enforceRiderId
            termsAndConditionLink
            privacyPolicyLink
            landingPagePoints {
              text
            }
            sandboxFlareApiKey
            sandboxFlareApiKeyUsage
            productionFlareApiKey
            productionFlareApiKeyUsage
            licenseKeys {
              licenseKey
              keyName
              environment
              totalUsage
              totalIncidentDetected
              isActive
              isDeleted
              deletedKey
              lastUsed
              createdAt
              updatedAt
            }
            incidentNotifications {
              notificationSettings
              notificationEmail
              notificationCountry
              notificationCountryCallingCode
              notificationMobileNo
              notificationWebhookURL
              includeRiderEmail
              riderID
              riderName
              riderCity
              riderEmail
              incidentDateAndTime
              ISOCountryCode
              incidentLocation
              incidentTelemetry
            }
            sosNotificationSettings
            sosNotifications {
              notificationSettings
              notificationEmail
              notificationCountry
              notificationCountryCallingCode
              notificationMobileNo
              notificationWebhookURL
              includeRiderEmail
              riderID
              riderName
              riderCity
              riderEmail
              incidentDateAndTime
              ISOCountryCode
              incidentLocation
              incidentTelemetry
            }
            consentCheckbox {
              title
              fileType
              link
              description
              type
            }
            enabledTwoStepVerification
            elasticIndexName
            isAccountVerified
            isActive
            isDeleted
            deletedPartner
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
          }
        }
        owner
      }
      owner
    }
  }
`;
export const updateParticipants = /* GraphQL */ `
  mutation UpdateParticipants(
    $input: UpdateParticipantsInput!
    $condition: ModelParticipantsConditionInput
  ) {
    updateParticipants(input: $input, condition: $condition) {
      id
      groupId
      userId
      name
      latitude
      longitude
      isJoin
      isLeftGroup
      isRemove
      isIncident
      incidentProcessCompleted
      date
      distance
      time
      coinsEarned
      coordinates
      isDeleted
      deletedParticipant
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        referralCode
        referralName
        isReferralRedeem
        email
        emailLower
        profilePicture
        phoneNumber
        countDownDuration
        subscription
        subscriptionLower
        subscriptionDate
        subscriptionExpiryDate
        keepScreenOn
        isFlareOn
        automaticStartOn
        totalBalance
        totalReward
        totalDriversAlerted
        totalDistanceCovered
        totalProtectedJourneys
        totalRideTimeInSeconds
        totalNearMissesReported
        country
        deviceId
        fcmToken
        deviceType
        isEmailVerified
        latitude
        longitude
        partnerId
        retailCodeUsed
        retailCodeUsedLower
        riderActivities
        riderType
        ridingPeriod
        rewardAccessType
        rewardAccessTypeLower
        rewardAccessStartDate
        rewardAccessEndDate
        rewardAccessSubTotal
        rewardAccessVAT
        rewardAccessVATAmount
        rewardAccessTotalAmount
        rewardAccessStripeToken
        rewardAccessStripeReceipt
        isRewardAccessPaymentSuccessful
        rewardAccessMode
        isPaidToAffiliate
        isSmartStartOn
        referralId
        registrationProvider
        activityTimeInSeconds
        totalRewardsRedeemed
        reputationScore
        appVersion
        sweatCoinAuthenticationToken
        lastAccessed
        isSmartStartNotificationEnable
        isMarketPlaceNotificationEnable
        agreedConsents {
          description
          type
          accepted
        }
        isActive
        isDeleted
        deletedUser
        createdAt
        updateAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        owner
        partner {
          id
          invitedByPartnerId
          partnerCode
          dynamicLink
          desktopURL
          companyName
          companyNameLower
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          email
          emailLower
          password
          profilePicture
          companyEmblem
          phone
          addressLine1
          addressLine2
          city
          country
          countryIsoCode
          postcode
          deviceType
          fcmToken
          totalFlaresReceived
          totalFlaresRespondedTo
          totalIncidentsReceived
          totalIncidentsActioned
          latitude
          longitude
          retailCodePrice
          resalePrice
          invoicePayment
          rewardAvailabilityCountries
          referralURL
          referralIntroText
          referralBackgroundImage
          referralRetailPrice
          incidentNotificationSettings
          notificationTitle
          notificationMessage
          notificationPeriod
          hasRiderConfirmed
          iotEndpoint
          enableRewards
          enableReferrals
          enableAppDiscountsHub
          enableFlares
          enableRiderManagement
          enableRetail
          enableAutoStart
          enableEmergencySOS
          enableSdk
          enforceEmergencyContact
          enforceSafeZone
          enforcePhoneNumber
          youtubeLink
          enforceRiderId
          termsAndConditionLink
          privacyPolicyLink
          landingPagePoints {
            text
          }
          sandboxFlareApiKey
          sandboxFlareApiKeyUsage
          productionFlareApiKey
          productionFlareApiKeyUsage
          licenseKeys {
            licenseKey
            keyName
            environment
            totalUsage
            totalIncidentDetected
            isActive
            isDeleted
            deletedKey
            lastUsed
            createdAt
            updatedAt
          }
          incidentNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          sosNotificationSettings
          sosNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          consentCheckbox {
            title
            fileType
            link
            description
            type
          }
          enabledTwoStepVerification
          elasticIndexName
          isAccountVerified
          isActive
          isDeleted
          deletedPartner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
      }
      group {
        id
        createdByUserId
        nanoid
        name
        nameLower
        groupCode
        groupCodeLower
        qrImage
        rideRadius
        rideDuration
        latitude
        longitude
        isEnded
        isDeleted
        deletedGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          referralCode
          referralName
          isReferralRedeem
          email
          emailLower
          profilePicture
          phoneNumber
          countDownDuration
          subscription
          subscriptionLower
          subscriptionDate
          subscriptionExpiryDate
          keepScreenOn
          isFlareOn
          automaticStartOn
          totalBalance
          totalReward
          totalDriversAlerted
          totalDistanceCovered
          totalProtectedJourneys
          totalRideTimeInSeconds
          totalNearMissesReported
          country
          deviceId
          fcmToken
          deviceType
          isEmailVerified
          latitude
          longitude
          partnerId
          retailCodeUsed
          retailCodeUsedLower
          riderActivities
          riderType
          ridingPeriod
          rewardAccessType
          rewardAccessTypeLower
          rewardAccessStartDate
          rewardAccessEndDate
          rewardAccessSubTotal
          rewardAccessVAT
          rewardAccessVATAmount
          rewardAccessTotalAmount
          rewardAccessStripeToken
          rewardAccessStripeReceipt
          isRewardAccessPaymentSuccessful
          rewardAccessMode
          isPaidToAffiliate
          isSmartStartOn
          referralId
          registrationProvider
          activityTimeInSeconds
          totalRewardsRedeemed
          reputationScore
          appVersion
          sweatCoinAuthenticationToken
          lastAccessed
          isSmartStartNotificationEnable
          isMarketPlaceNotificationEnable
          agreedConsents {
            description
            type
            accepted
          }
          isActive
          isDeleted
          deletedUser
          createdAt
          updateAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          owner
          partner {
            id
            invitedByPartnerId
            partnerCode
            dynamicLink
            desktopURL
            companyName
            companyNameLower
            firstName
            firstNameLower
            lastName
            lastNameLower
            userName
            userNameLower
            email
            emailLower
            password
            profilePicture
            companyEmblem
            phone
            addressLine1
            addressLine2
            city
            country
            countryIsoCode
            postcode
            deviceType
            fcmToken
            totalFlaresReceived
            totalFlaresRespondedTo
            totalIncidentsReceived
            totalIncidentsActioned
            latitude
            longitude
            retailCodePrice
            resalePrice
            invoicePayment
            rewardAvailabilityCountries
            referralURL
            referralIntroText
            referralBackgroundImage
            referralRetailPrice
            incidentNotificationSettings
            notificationTitle
            notificationMessage
            notificationPeriod
            hasRiderConfirmed
            iotEndpoint
            enableRewards
            enableReferrals
            enableAppDiscountsHub
            enableFlares
            enableRiderManagement
            enableRetail
            enableAutoStart
            enableEmergencySOS
            enableSdk
            enforceEmergencyContact
            enforceSafeZone
            enforcePhoneNumber
            youtubeLink
            enforceRiderId
            termsAndConditionLink
            privacyPolicyLink
            landingPagePoints {
              text
            }
            sandboxFlareApiKey
            sandboxFlareApiKeyUsage
            productionFlareApiKey
            productionFlareApiKeyUsage
            licenseKeys {
              licenseKey
              keyName
              environment
              totalUsage
              totalIncidentDetected
              isActive
              isDeleted
              deletedKey
              lastUsed
              createdAt
              updatedAt
            }
            incidentNotifications {
              notificationSettings
              notificationEmail
              notificationCountry
              notificationCountryCallingCode
              notificationMobileNo
              notificationWebhookURL
              includeRiderEmail
              riderID
              riderName
              riderCity
              riderEmail
              incidentDateAndTime
              ISOCountryCode
              incidentLocation
              incidentTelemetry
            }
            sosNotificationSettings
            sosNotifications {
              notificationSettings
              notificationEmail
              notificationCountry
              notificationCountryCallingCode
              notificationMobileNo
              notificationWebhookURL
              includeRiderEmail
              riderID
              riderName
              riderCity
              riderEmail
              incidentDateAndTime
              ISOCountryCode
              incidentLocation
              incidentTelemetry
            }
            consentCheckbox {
              title
              fileType
              link
              description
              type
            }
            enabledTwoStepVerification
            elasticIndexName
            isAccountVerified
            isActive
            isDeleted
            deletedPartner
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
          }
        }
        owner
      }
      owner
    }
  }
`;
export const deleteParticipants = /* GraphQL */ `
  mutation DeleteParticipants(
    $input: DeleteParticipantsInput!
    $condition: ModelParticipantsConditionInput
  ) {
    deleteParticipants(input: $input, condition: $condition) {
      id
      groupId
      userId
      name
      latitude
      longitude
      isJoin
      isLeftGroup
      isRemove
      isIncident
      incidentProcessCompleted
      date
      distance
      time
      coinsEarned
      coordinates
      isDeleted
      deletedParticipant
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        firstName
        firstNameLower
        lastName
        lastNameLower
        userName
        userNameLower
        referralCode
        referralName
        isReferralRedeem
        email
        emailLower
        profilePicture
        phoneNumber
        countDownDuration
        subscription
        subscriptionLower
        subscriptionDate
        subscriptionExpiryDate
        keepScreenOn
        isFlareOn
        automaticStartOn
        totalBalance
        totalReward
        totalDriversAlerted
        totalDistanceCovered
        totalProtectedJourneys
        totalRideTimeInSeconds
        totalNearMissesReported
        country
        deviceId
        fcmToken
        deviceType
        isEmailVerified
        latitude
        longitude
        partnerId
        retailCodeUsed
        retailCodeUsedLower
        riderActivities
        riderType
        ridingPeriod
        rewardAccessType
        rewardAccessTypeLower
        rewardAccessStartDate
        rewardAccessEndDate
        rewardAccessSubTotal
        rewardAccessVAT
        rewardAccessVATAmount
        rewardAccessTotalAmount
        rewardAccessStripeToken
        rewardAccessStripeReceipt
        isRewardAccessPaymentSuccessful
        rewardAccessMode
        isPaidToAffiliate
        isSmartStartOn
        referralId
        registrationProvider
        activityTimeInSeconds
        totalRewardsRedeemed
        reputationScore
        appVersion
        sweatCoinAuthenticationToken
        lastAccessed
        isSmartStartNotificationEnable
        isMarketPlaceNotificationEnable
        agreedConsents {
          description
          type
          accepted
        }
        isActive
        isDeleted
        deletedUser
        createdAt
        updateAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        owner
        partner {
          id
          invitedByPartnerId
          partnerCode
          dynamicLink
          desktopURL
          companyName
          companyNameLower
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          email
          emailLower
          password
          profilePicture
          companyEmblem
          phone
          addressLine1
          addressLine2
          city
          country
          countryIsoCode
          postcode
          deviceType
          fcmToken
          totalFlaresReceived
          totalFlaresRespondedTo
          totalIncidentsReceived
          totalIncidentsActioned
          latitude
          longitude
          retailCodePrice
          resalePrice
          invoicePayment
          rewardAvailabilityCountries
          referralURL
          referralIntroText
          referralBackgroundImage
          referralRetailPrice
          incidentNotificationSettings
          notificationTitle
          notificationMessage
          notificationPeriod
          hasRiderConfirmed
          iotEndpoint
          enableRewards
          enableReferrals
          enableAppDiscountsHub
          enableFlares
          enableRiderManagement
          enableRetail
          enableAutoStart
          enableEmergencySOS
          enableSdk
          enforceEmergencyContact
          enforceSafeZone
          enforcePhoneNumber
          youtubeLink
          enforceRiderId
          termsAndConditionLink
          privacyPolicyLink
          landingPagePoints {
            text
          }
          sandboxFlareApiKey
          sandboxFlareApiKeyUsage
          productionFlareApiKey
          productionFlareApiKeyUsage
          licenseKeys {
            licenseKey
            keyName
            environment
            totalUsage
            totalIncidentDetected
            isActive
            isDeleted
            deletedKey
            lastUsed
            createdAt
            updatedAt
          }
          incidentNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          sosNotificationSettings
          sosNotifications {
            notificationSettings
            notificationEmail
            notificationCountry
            notificationCountryCallingCode
            notificationMobileNo
            notificationWebhookURL
            includeRiderEmail
            riderID
            riderName
            riderCity
            riderEmail
            incidentDateAndTime
            ISOCountryCode
            incidentLocation
            incidentTelemetry
          }
          consentCheckbox {
            title
            fileType
            link
            description
            type
          }
          enabledTwoStepVerification
          elasticIndexName
          isAccountVerified
          isActive
          isDeleted
          deletedPartner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
      }
      group {
        id
        createdByUserId
        nanoid
        name
        nameLower
        groupCode
        groupCodeLower
        qrImage
        rideRadius
        rideDuration
        latitude
        longitude
        isEnded
        isDeleted
        deletedGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          firstName
          firstNameLower
          lastName
          lastNameLower
          userName
          userNameLower
          referralCode
          referralName
          isReferralRedeem
          email
          emailLower
          profilePicture
          phoneNumber
          countDownDuration
          subscription
          subscriptionLower
          subscriptionDate
          subscriptionExpiryDate
          keepScreenOn
          isFlareOn
          automaticStartOn
          totalBalance
          totalReward
          totalDriversAlerted
          totalDistanceCovered
          totalProtectedJourneys
          totalRideTimeInSeconds
          totalNearMissesReported
          country
          deviceId
          fcmToken
          deviceType
          isEmailVerified
          latitude
          longitude
          partnerId
          retailCodeUsed
          retailCodeUsedLower
          riderActivities
          riderType
          ridingPeriod
          rewardAccessType
          rewardAccessTypeLower
          rewardAccessStartDate
          rewardAccessEndDate
          rewardAccessSubTotal
          rewardAccessVAT
          rewardAccessVATAmount
          rewardAccessTotalAmount
          rewardAccessStripeToken
          rewardAccessStripeReceipt
          isRewardAccessPaymentSuccessful
          rewardAccessMode
          isPaidToAffiliate
          isSmartStartOn
          referralId
          registrationProvider
          activityTimeInSeconds
          totalRewardsRedeemed
          reputationScore
          appVersion
          sweatCoinAuthenticationToken
          lastAccessed
          isSmartStartNotificationEnable
          isMarketPlaceNotificationEnable
          agreedConsents {
            description
            type
            accepted
          }
          isActive
          isDeleted
          deletedUser
          createdAt
          updateAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
          owner
          partner {
            id
            invitedByPartnerId
            partnerCode
            dynamicLink
            desktopURL
            companyName
            companyNameLower
            firstName
            firstNameLower
            lastName
            lastNameLower
            userName
            userNameLower
            email
            emailLower
            password
            profilePicture
            companyEmblem
            phone
            addressLine1
            addressLine2
            city
            country
            countryIsoCode
            postcode
            deviceType
            fcmToken
            totalFlaresReceived
            totalFlaresRespondedTo
            totalIncidentsReceived
            totalIncidentsActioned
            latitude
            longitude
            retailCodePrice
            resalePrice
            invoicePayment
            rewardAvailabilityCountries
            referralURL
            referralIntroText
            referralBackgroundImage
            referralRetailPrice
            incidentNotificationSettings
            notificationTitle
            notificationMessage
            notificationPeriod
            hasRiderConfirmed
            iotEndpoint
            enableRewards
            enableReferrals
            enableAppDiscountsHub
            enableFlares
            enableRiderManagement
            enableRetail
            enableAutoStart
            enableEmergencySOS
            enableSdk
            enforceEmergencyContact
            enforceSafeZone
            enforcePhoneNumber
            youtubeLink
            enforceRiderId
            termsAndConditionLink
            privacyPolicyLink
            landingPagePoints {
              text
            }
            sandboxFlareApiKey
            sandboxFlareApiKeyUsage
            productionFlareApiKey
            productionFlareApiKeyUsage
            licenseKeys {
              licenseKey
              keyName
              environment
              totalUsage
              totalIncidentDetected
              isActive
              isDeleted
              deletedKey
              lastUsed
              createdAt
              updatedAt
            }
            incidentNotifications {
              notificationSettings
              notificationEmail
              notificationCountry
              notificationCountryCallingCode
              notificationMobileNo
              notificationWebhookURL
              includeRiderEmail
              riderID
              riderName
              riderCity
              riderEmail
              incidentDateAndTime
              ISOCountryCode
              incidentLocation
              incidentTelemetry
            }
            sosNotificationSettings
            sosNotifications {
              notificationSettings
              notificationEmail
              notificationCountry
              notificationCountryCallingCode
              notificationMobileNo
              notificationWebhookURL
              includeRiderEmail
              riderID
              riderName
              riderCity
              riderEmail
              incidentDateAndTime
              ISOCountryCode
              incidentLocation
              incidentTelemetry
            }
            consentCheckbox {
              title
              fileType
              link
              description
              type
            }
            enabledTwoStepVerification
            elasticIndexName
            isAccountVerified
            isActive
            isDeleted
            deletedPartner
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
          }
        }
        owner
      }
      owner
    }
  }
`;
export const createDeletedUsers = /* GraphQL */ `
  mutation CreateDeletedUsers(
    $input: CreateDeletedUsersInput!
    $condition: ModelDeletedUsersConditionInput
  ) {
    createDeletedUsers(input: $input, condition: $condition) {
      id
      userId
      email
      sweatCoinAuthenticationToken
      deletedFromSweatCoin
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateDeletedUsers = /* GraphQL */ `
  mutation UpdateDeletedUsers(
    $input: UpdateDeletedUsersInput!
    $condition: ModelDeletedUsersConditionInput
  ) {
    updateDeletedUsers(input: $input, condition: $condition) {
      id
      userId
      email
      sweatCoinAuthenticationToken
      deletedFromSweatCoin
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteDeletedUsers = /* GraphQL */ `
  mutation DeleteDeletedUsers(
    $input: DeleteDeletedUsersInput!
    $condition: ModelDeletedUsersConditionInput
  ) {
    deleteDeletedUsers(input: $input, condition: $condition) {
      id
      userId
      email
      sweatCoinAuthenticationToken
      deletedFromSweatCoin
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createPartners = /* GraphQL */ `
  mutation CreatePartners(
    $input: CreatePartnersInput!
    $condition: ModelPartnersConditionInput
  ) {
    createPartners(input: $input, condition: $condition) {
      id
      invitedByPartnerId
      partnerCode
      dynamicLink
      desktopURL
      companyName
      companyNameLower
      firstName
      firstNameLower
      lastName
      lastNameLower
      userName
      userNameLower
      email
      emailLower
      password
      profilePicture
      companyEmblem
      phone
      addressLine1
      addressLine2
      city
      country
      countryIsoCode
      postcode
      deviceType
      fcmToken
      totalFlaresReceived
      totalFlaresRespondedTo
      totalIncidentsReceived
      totalIncidentsActioned
      latitude
      longitude
      retailCodePrice
      resalePrice
      invoicePayment
      rewardAvailabilityCountries
      referralURL
      referralIntroText
      referralBackgroundImage
      referralRetailPrice
      incidentNotificationSettings
      notificationTitle
      notificationMessage
      notificationPeriod
      hasRiderConfirmed
      iotEndpoint
      enableRewards
      enableReferrals
      enableAppDiscountsHub
      enableFlares
      enableRiderManagement
      enableRetail
      enableAutoStart
      enableEmergencySOS
      enableSdk
      enforceEmergencyContact
      enforceSafeZone
      enforcePhoneNumber
      youtubeLink
      enforceRiderId
      termsAndConditionLink
      privacyPolicyLink
      landingPagePoints {
        text
      }
      sandboxFlareApiKey
      sandboxFlareApiKeyUsage
      productionFlareApiKey
      productionFlareApiKeyUsage
      licenseKeys {
        licenseKey
        keyName
        environment
        totalUsage
        totalIncidentDetected
        isActive
        isDeleted
        deletedKey
        lastUsed
        createdAt
        updatedAt
      }
      incidentNotifications {
        notificationSettings
        notificationEmail
        notificationCountry
        notificationCountryCallingCode
        notificationMobileNo
        notificationWebhookURL
        includeRiderEmail
        riderID
        riderName
        riderCity
        riderEmail
        incidentDateAndTime
        ISOCountryCode
        incidentLocation
        incidentTelemetry
      }
      sosNotificationSettings
      sosNotifications {
        notificationSettings
        notificationEmail
        notificationCountry
        notificationCountryCallingCode
        notificationMobileNo
        notificationWebhookURL
        includeRiderEmail
        riderID
        riderName
        riderCity
        riderEmail
        incidentDateAndTime
        ISOCountryCode
        incidentLocation
        incidentTelemetry
      }
      consentCheckbox {
        title
        fileType
        link
        description
        type
      }
      enabledTwoStepVerification
      elasticIndexName
      isAccountVerified
      isActive
      isDeleted
      deletedPartner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updatePartners = /* GraphQL */ `
  mutation UpdatePartners(
    $input: UpdatePartnersInput!
    $condition: ModelPartnersConditionInput
  ) {
    updatePartners(input: $input, condition: $condition) {
      id
      invitedByPartnerId
      partnerCode
      dynamicLink
      desktopURL
      companyName
      companyNameLower
      firstName
      firstNameLower
      lastName
      lastNameLower
      userName
      userNameLower
      email
      emailLower
      password
      profilePicture
      companyEmblem
      phone
      addressLine1
      addressLine2
      city
      country
      countryIsoCode
      postcode
      deviceType
      fcmToken
      totalFlaresReceived
      totalFlaresRespondedTo
      totalIncidentsReceived
      totalIncidentsActioned
      latitude
      longitude
      retailCodePrice
      resalePrice
      invoicePayment
      rewardAvailabilityCountries
      referralURL
      referralIntroText
      referralBackgroundImage
      referralRetailPrice
      incidentNotificationSettings
      notificationTitle
      notificationMessage
      notificationPeriod
      hasRiderConfirmed
      iotEndpoint
      enableRewards
      enableReferrals
      enableAppDiscountsHub
      enableFlares
      enableRiderManagement
      enableRetail
      enableAutoStart
      enableEmergencySOS
      enableSdk
      enforceEmergencyContact
      enforceSafeZone
      enforcePhoneNumber
      youtubeLink
      enforceRiderId
      termsAndConditionLink
      privacyPolicyLink
      landingPagePoints {
        text
      }
      sandboxFlareApiKey
      sandboxFlareApiKeyUsage
      productionFlareApiKey
      productionFlareApiKeyUsage
      licenseKeys {
        licenseKey
        keyName
        environment
        totalUsage
        totalIncidentDetected
        isActive
        isDeleted
        deletedKey
        lastUsed
        createdAt
        updatedAt
      }
      incidentNotifications {
        notificationSettings
        notificationEmail
        notificationCountry
        notificationCountryCallingCode
        notificationMobileNo
        notificationWebhookURL
        includeRiderEmail
        riderID
        riderName
        riderCity
        riderEmail
        incidentDateAndTime
        ISOCountryCode
        incidentLocation
        incidentTelemetry
      }
      sosNotificationSettings
      sosNotifications {
        notificationSettings
        notificationEmail
        notificationCountry
        notificationCountryCallingCode
        notificationMobileNo
        notificationWebhookURL
        includeRiderEmail
        riderID
        riderName
        riderCity
        riderEmail
        incidentDateAndTime
        ISOCountryCode
        incidentLocation
        incidentTelemetry
      }
      consentCheckbox {
        title
        fileType
        link
        description
        type
      }
      enabledTwoStepVerification
      elasticIndexName
      isAccountVerified
      isActive
      isDeleted
      deletedPartner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deletePartners = /* GraphQL */ `
  mutation DeletePartners(
    $input: DeletePartnersInput!
    $condition: ModelPartnersConditionInput
  ) {
    deletePartners(input: $input, condition: $condition) {
      id
      invitedByPartnerId
      partnerCode
      dynamicLink
      desktopURL
      companyName
      companyNameLower
      firstName
      firstNameLower
      lastName
      lastNameLower
      userName
      userNameLower
      email
      emailLower
      password
      profilePicture
      companyEmblem
      phone
      addressLine1
      addressLine2
      city
      country
      countryIsoCode
      postcode
      deviceType
      fcmToken
      totalFlaresReceived
      totalFlaresRespondedTo
      totalIncidentsReceived
      totalIncidentsActioned
      latitude
      longitude
      retailCodePrice
      resalePrice
      invoicePayment
      rewardAvailabilityCountries
      referralURL
      referralIntroText
      referralBackgroundImage
      referralRetailPrice
      incidentNotificationSettings
      notificationTitle
      notificationMessage
      notificationPeriod
      hasRiderConfirmed
      iotEndpoint
      enableRewards
      enableReferrals
      enableAppDiscountsHub
      enableFlares
      enableRiderManagement
      enableRetail
      enableAutoStart
      enableEmergencySOS
      enableSdk
      enforceEmergencyContact
      enforceSafeZone
      enforcePhoneNumber
      youtubeLink
      enforceRiderId
      termsAndConditionLink
      privacyPolicyLink
      landingPagePoints {
        text
      }
      sandboxFlareApiKey
      sandboxFlareApiKeyUsage
      productionFlareApiKey
      productionFlareApiKeyUsage
      licenseKeys {
        licenseKey
        keyName
        environment
        totalUsage
        totalIncidentDetected
        isActive
        isDeleted
        deletedKey
        lastUsed
        createdAt
        updatedAt
      }
      incidentNotifications {
        notificationSettings
        notificationEmail
        notificationCountry
        notificationCountryCallingCode
        notificationMobileNo
        notificationWebhookURL
        includeRiderEmail
        riderID
        riderName
        riderCity
        riderEmail
        incidentDateAndTime
        ISOCountryCode
        incidentLocation
        incidentTelemetry
      }
      sosNotificationSettings
      sosNotifications {
        notificationSettings
        notificationEmail
        notificationCountry
        notificationCountryCallingCode
        notificationMobileNo
        notificationWebhookURL
        includeRiderEmail
        riderID
        riderName
        riderCity
        riderEmail
        incidentDateAndTime
        ISOCountryCode
        incidentLocation
        incidentTelemetry
      }
      consentCheckbox {
        title
        fileType
        link
        description
        type
      }
      enabledTwoStepVerification
      elasticIndexName
      isAccountVerified
      isActive
      isDeleted
      deletedPartner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createRetailCodePurchases = /* GraphQL */ `
  mutation CreateRetailCodePurchases(
    $input: CreateRetailCodePurchasesInput!
    $condition: ModelRetailCodePurchasesConditionInput
  ) {
    createRetailCodePurchases(input: $input, condition: $condition) {
      id
      partnerId
      unitPrice
      quantity
      subTotal
      vat
      total
      paymentType
      stripeToken
      stripeRate
      stripeAmount
      busbyAmount
      stripeChargeId
      isPaymentSuccessful
      invoiceNumber
      isPaid
      retailCodesPaid
      isDeleted
      deletedRetailPurchase
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateRetailCodePurchases = /* GraphQL */ `
  mutation UpdateRetailCodePurchases(
    $input: UpdateRetailCodePurchasesInput!
    $condition: ModelRetailCodePurchasesConditionInput
  ) {
    updateRetailCodePurchases(input: $input, condition: $condition) {
      id
      partnerId
      unitPrice
      quantity
      subTotal
      vat
      total
      paymentType
      stripeToken
      stripeRate
      stripeAmount
      busbyAmount
      stripeChargeId
      isPaymentSuccessful
      invoiceNumber
      isPaid
      retailCodesPaid
      isDeleted
      deletedRetailPurchase
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteRetailCodePurchases = /* GraphQL */ `
  mutation DeleteRetailCodePurchases(
    $input: DeleteRetailCodePurchasesInput!
    $condition: ModelRetailCodePurchasesConditionInput
  ) {
    deleteRetailCodePurchases(input: $input, condition: $condition) {
      id
      partnerId
      unitPrice
      quantity
      subTotal
      vat
      total
      paymentType
      stripeToken
      stripeRate
      stripeAmount
      busbyAmount
      stripeChargeId
      isPaymentSuccessful
      invoiceNumber
      isPaid
      retailCodesPaid
      isDeleted
      deletedRetailPurchase
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createRetailCodes = /* GraphQL */ `
  mutation CreateRetailCodes(
    $input: CreateRetailCodesInput!
    $condition: ModelRetailCodesConditionInput
  ) {
    createRetailCodes(input: $input, condition: $condition) {
      id
      partnerId
      retailCodePurchaseId
      referralId
      retailCode
      retailCodeLower
      paymentType
      paymentTypeLower
      status
      statusLower
      isDeleted
      deletedRetailCode
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateRetailCodes = /* GraphQL */ `
  mutation UpdateRetailCodes(
    $input: UpdateRetailCodesInput!
    $condition: ModelRetailCodesConditionInput
  ) {
    updateRetailCodes(input: $input, condition: $condition) {
      id
      partnerId
      retailCodePurchaseId
      referralId
      retailCode
      retailCodeLower
      paymentType
      paymentTypeLower
      status
      statusLower
      isDeleted
      deletedRetailCode
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteRetailCodes = /* GraphQL */ `
  mutation DeleteRetailCodes(
    $input: DeleteRetailCodesInput!
    $condition: ModelRetailCodesConditionInput
  ) {
    deleteRetailCodes(input: $input, condition: $condition) {
      id
      partnerId
      retailCodePurchaseId
      referralId
      retailCode
      retailCodeLower
      paymentType
      paymentTypeLower
      status
      statusLower
      isDeleted
      deletedRetailCode
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createReferrals = /* GraphQL */ `
  mutation CreateReferrals(
    $input: CreateReferralsInput!
    $condition: ModelReferralsConditionInput
  ) {
    createReferrals(input: $input, condition: $condition) {
      id
      partnerId
      inviteLink
      inviteLinkLower
      premiumDiscount
      busbySubscription
      tag
      tagLower
      referralType
      usageLimit
      totalUsed
      retailPrice
      showFreeTile
      qrImage
      introduction
      banner
      logo
      showBusbyLogo
      youtubeURL
      desktopURL
      smTitle
      smDescription
      smImage
      isActive
      isDeleted
      deletedReferral
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateReferrals = /* GraphQL */ `
  mutation UpdateReferrals(
    $input: UpdateReferralsInput!
    $condition: ModelReferralsConditionInput
  ) {
    updateReferrals(input: $input, condition: $condition) {
      id
      partnerId
      inviteLink
      inviteLinkLower
      premiumDiscount
      busbySubscription
      tag
      tagLower
      referralType
      usageLimit
      totalUsed
      retailPrice
      showFreeTile
      qrImage
      introduction
      banner
      logo
      showBusbyLogo
      youtubeURL
      desktopURL
      smTitle
      smDescription
      smImage
      isActive
      isDeleted
      deletedReferral
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteReferrals = /* GraphQL */ `
  mutation DeleteReferrals(
    $input: DeleteReferralsInput!
    $condition: ModelReferralsConditionInput
  ) {
    deleteReferrals(input: $input, condition: $condition) {
      id
      partnerId
      inviteLink
      inviteLinkLower
      premiumDiscount
      busbySubscription
      tag
      tagLower
      referralType
      usageLimit
      totalUsed
      retailPrice
      showFreeTile
      qrImage
      introduction
      banner
      logo
      showBusbyLogo
      youtubeURL
      desktopURL
      smTitle
      smDescription
      smImage
      isActive
      isDeleted
      deletedReferral
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createMessageBoard = /* GraphQL */ `
  mutation CreateMessageBoard(
    $input: CreateMessageBoardInput!
    $condition: ModelMessageBoardConditionInput
  ) {
    createMessageBoard(input: $input, condition: $condition) {
      id
      title
      titleLower
      details
      detailsLower
      messageImage
      isActive
      isDeleted
      deletedMessage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateMessageBoard = /* GraphQL */ `
  mutation UpdateMessageBoard(
    $input: UpdateMessageBoardInput!
    $condition: ModelMessageBoardConditionInput
  ) {
    updateMessageBoard(input: $input, condition: $condition) {
      id
      title
      titleLower
      details
      detailsLower
      messageImage
      isActive
      isDeleted
      deletedMessage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteMessageBoard = /* GraphQL */ `
  mutation DeleteMessageBoard(
    $input: DeleteMessageBoardInput!
    $condition: ModelMessageBoardConditionInput
  ) {
    deleteMessageBoard(input: $input, condition: $condition) {
      id
      title
      titleLower
      details
      detailsLower
      messageImage
      isActive
      isDeleted
      deletedMessage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createUserMessageBoard = /* GraphQL */ `
  mutation CreateUserMessageBoard(
    $input: CreateUserMessageBoardInput!
    $condition: ModelUserMessageBoardConditionInput
  ) {
    createUserMessageBoard(input: $input, condition: $condition) {
      id
      messageBoardId
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateUserMessageBoard = /* GraphQL */ `
  mutation UpdateUserMessageBoard(
    $input: UpdateUserMessageBoardInput!
    $condition: ModelUserMessageBoardConditionInput
  ) {
    updateUserMessageBoard(input: $input, condition: $condition) {
      id
      messageBoardId
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteUserMessageBoard = /* GraphQL */ `
  mutation DeleteUserMessageBoard(
    $input: DeleteUserMessageBoardInput!
    $condition: ModelUserMessageBoardConditionInput
  ) {
    deleteUserMessageBoard(input: $input, condition: $condition) {
      id
      messageBoardId
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createHazardCategory = /* GraphQL */ `
  mutation CreateHazardCategory(
    $input: CreateHazardCategoryInput!
    $condition: ModelHazardCategoryConditionInput
  ) {
    createHazardCategory(input: $input, condition: $condition) {
      id
      categoryId
      name
      description
      crowdinTitleKey
      crowdinDescriptionKey
      imageUrl
      expiresInMinutes
      isActive
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateHazardCategory = /* GraphQL */ `
  mutation UpdateHazardCategory(
    $input: UpdateHazardCategoryInput!
    $condition: ModelHazardCategoryConditionInput
  ) {
    updateHazardCategory(input: $input, condition: $condition) {
      id
      categoryId
      name
      description
      crowdinTitleKey
      crowdinDescriptionKey
      imageUrl
      expiresInMinutes
      isActive
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteHazardCategory = /* GraphQL */ `
  mutation DeleteHazardCategory(
    $input: DeleteHazardCategoryInput!
    $condition: ModelHazardCategoryConditionInput
  ) {
    deleteHazardCategory(input: $input, condition: $condition) {
      id
      categoryId
      name
      description
      crowdinTitleKey
      crowdinDescriptionKey
      imageUrl
      expiresInMinutes
      isActive
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createSubscriptionRiderCodes = /* GraphQL */ `
  mutation CreateSubscriptionRiderCodes(
    $input: CreateSubscriptionRiderCodesInput!
    $condition: ModelSubscriptionRiderCodesConditionInput
  ) {
    createSubscriptionRiderCodes(input: $input, condition: $condition) {
      id
      partnerId
      riderCode
      partnerName
      countryIsoCode
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateSubscriptionRiderCodes = /* GraphQL */ `
  mutation UpdateSubscriptionRiderCodes(
    $input: UpdateSubscriptionRiderCodesInput!
    $condition: ModelSubscriptionRiderCodesConditionInput
  ) {
    updateSubscriptionRiderCodes(input: $input, condition: $condition) {
      id
      partnerId
      riderCode
      partnerName
      countryIsoCode
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteSubscriptionRiderCodes = /* GraphQL */ `
  mutation DeleteSubscriptionRiderCodes(
    $input: DeleteSubscriptionRiderCodesInput!
    $condition: ModelSubscriptionRiderCodesConditionInput
  ) {
    deleteSubscriptionRiderCodes(input: $input, condition: $condition) {
      id
      partnerId
      riderCode
      partnerName
      countryIsoCode
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createLogs = /* GraphQL */ `
  mutation CreateLogs(
    $input: CreateLogsInput!
    $condition: ModelLogsConditionInput
  ) {
    createLogs(input: $input, condition: $condition) {
      id
      userId
      type
      event
      payload
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateLogs = /* GraphQL */ `
  mutation UpdateLogs(
    $input: UpdateLogsInput!
    $condition: ModelLogsConditionInput
  ) {
    updateLogs(input: $input, condition: $condition) {
      id
      userId
      type
      event
      payload
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteLogs = /* GraphQL */ `
  mutation DeleteLogs(
    $input: DeleteLogsInput!
    $condition: ModelLogsConditionInput
  ) {
    deleteLogs(input: $input, condition: $condition) {
      id
      userId
      type
      event
      payload
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
