import React, { Component } from "react";
import classNames from "classnames";
import styles from "../styles.module.scss";
import logo from "../../../assests/images/logo.png";
import { Button, Card, CardHeader, CardBody, CardFooter, Form, Container, Col, Row } from "reactstrap";
import AuthNavbar from "components/Navbars/AuthNavbar.js";

export default class EmailSent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: this.props.location.state ? this.props.location.state.email : "",
    };
  }

  handleNext = () => {
    const { email } = this.state;

    this.props.history.push({
      pathname: "/change-password",
      state: {
        email: email,
      },
    });
  };

  render() {
    const { email } = this.state;

    return (
      <div>
        <AuthNavbar />
        <div className="wrapper wrapper-full-page">
          <div className="full-page section-image">
            <div className="login-page">
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto" lg="4" md="6">
                    <Form action="" className="form" method="">
                      <Card className="card-login">
                        <CardHeader>
                          <CardHeader>
                            <h3 className="header text-center mb-3">Email Sent</h3>
                            <p className="header text-center">
                              {" "}
                              A confirmation code has been sent to your email address {email}. <br />
                              <br />
                              Please check your mail.
                            </p>
                          </CardHeader>
                        </CardHeader>

                        <CardFooter>
                          <Button
                            block
                            className="btn-round mb-3"
                            color="warning"
                            href="#pablo"
                            onClick={this.handleNext}
                          >
                            Next
                          </Button>
                        </CardFooter>
                      </Card>
                    </Form>
                  </Col>
                </Row>
              </Container>
              <div
                className="full-page-background"
                style={{
                  backgroundImage: `url(${require("../../../assests/images/signup-banner.png")})`,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
{
  /* <div className={styles.authContent}>
  <div className={styles.background} />
  <div className={styles.loginForm}>
    <div className={styles.logo}>
      <img src={logo} alt="logo" width="450px" />
    </div>
    <div className={styles.form}>
      <p className={styles.infoLeft}>
        A confirmation code has been sent to your email address {email}. <br />
        <br />
        Please check your mail.
      </p>
      <div>
        <div className={classNames("form-group", styles.formGroup)}>
          <button className={classNames("btn", styles.otherBtn)} onClick={this.handleNext}>
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</div>; */
}
