import React from 'react';
import PublicRoutes from './publicRoutes';
import PrivateRoutes from './privateRoutes';

function Routing() {
  return (
    <>
      {/* Render public routes */}
      <PublicRoutes />

      {/* Render private routes */}
      <PrivateRoutes />
    </>
  );
}

export default Routing;
