import React, { Component } from "react";
import { useContext } from "react";
import { Card, CardBody, CardFooter, CardTitle, Row, Col, CardHeader, Button, Modal } from "reactstrap";
import { Line } from "react-chartjs-2";
import { getLicenseByFleetOwnerId, getFleetOwners } from "../../graphql/queries";
import styles from "./styles.module.scss";
import { API, graphqlOperation, Auth } from "aws-amplify";
import { withRouter } from "react-router-dom";
import moment from "moment";
import * as mutations from "../../graphql/mutations";
import AWS from "aws-sdk";
import BuyLicensesPopBox from "./BuyLicensesPopBox";
import loader from "../../assests/images/loading_dots.gif";
import infoIcon from "../../assests/images/info-icon.png";
var licenseData = [];
var lstCodes = [];

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

class DashboardMetrics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            licenses: [],
            modalClassic: false,
            totalLicense: "-",
            laterOpted: false,
            pageSize: 30,
            accountManagerEmail: "",
            fleetOwnerId:
                localStorage.getItem("fleetOwnerId") === null
                    ? this.props.location.state
                        ? this.props.location.state.fleetOwnerId
                        : ""
                    : localStorage.getItem("fleetOwnerId"),
            appSyncApiId: '',
            version: 0,
            companyType: localStorage.getItem("companyType"),
        };
    }
    componentDidMount() {
        this.loadConfig();
    }

    loadConfig = () => {
        let _this = this;

        fetch(window.location.origin + `/config.json`)
            .then((r) => r.json())
            .then((data) => {
                this.setState({ appSyncApiId: data.appSyncApiId }, function () {
                    _this.getLicense();
                    _this.getFleetOwnerDetails();

                    setTimeout(() => {
                        if (_this.state.totalLicense <= 1 && _this.state.laterOpted != true) {
                            _this.toggleModalClassic();
                        }
                    }, 800);
                });
            });
    }

    toggleModalClassic = async () => {
        this.setState({
            modalClassic: true,
        });
    };
    toggleCloseModalClassic = async () => {
        this.setState({
            modalClassic: false,
        });
        var objFleetOwner = {
            id: this.state.fleetOwnerId,
            laterOpted: true,
            _version: this.state.version
        };

        await API.graphql(graphqlOperation(mutations.updateFleetOwners, { input: objFleetOwner })).then(async (data) => { });
    };

    getFleetOwnerDetails = async () => {
        let fId =
            localStorage.getItem("fleetOwnerId") === null
                ? this.props.location.state
                    ? this.props.location.state.fleetOwnerId
                    : ""
                : localStorage.getItem("fleetOwnerId");

        if (fId !== null && fId !== undefined) {
            await API.graphql(graphqlOperation(getFleetOwners, { id: fId })).then(async (result) => {
                this.setState({
                    accountManagerEmail: result.data.getFleetOwners.accountManagerEmail,
                    laterOpted: result.data.getFleetOwners.laterOpted,
                    version: result.data.getFleetOwners._version
                });
            });
        }
    };

    getLicense = async () => {
        var hasMoreRec = true;
        let licenses = [];

        this.setState({ rNextToken: undefined });

        while (hasMoreRec) {
            var variables = {
                fleetOwnerId: this.state.fleetOwnerId,
                nextToken: this.state.rNextToken,
                filter: {
                    isDeleted: { eq: false }
                },
                limit: 500,
                sortDirection: "DESC",
            };

            await API.graphql(graphqlOperation(getLicenseByFleetOwnerId, variables)).then(result => {
                this.setState({ rNextToken: result.data.getLicenseByFleetOwnerId.nextToken });

                result.data.getLicenseByFleetOwnerId.items.forEach(function (itm) {
                    licenses.push({
                        id: itm.id,
                        code: itm.code,
                        fleetOwnerPurchaseId: itm.fleetOwnerPurchaseId
                    });
                });

                hasMoreRec = result.data.getLicenseByFleetOwnerId.nextToken !== null;
            });
        }

        this.setState({
            totalLicense: licenses.length,
            licenses: licenses.filter(
                (l) => l.fleetOwnerPurchaseId !== "Fleet Owner License"
            )
        });


        // this.setState(
        //   {
        //     pageSize: 10000,
        //     nextToken: undefined,

        //     nextNextToken: null,
        //   },
        //   async function () {
        //     var variables = {
        //       fleetOwnerId: this.state.fleetOwnerId,
        //       nextToken: this.state.nextToken,

        //       limit: this.state.pageSize,
        //       filter: {
        //         isDeleted: { eq: false },
        //         deletedLicense: { eq: "false" },
        //       },
        //       limit: this.state.pageSize,
        //       sortDirection: "DESC",
        //     };

        //     licenseData = await API.graphql(graphqlOperation(getLicenseByFleetOwnerId, variables));
        //     let totLicenses = licenseData.data.getLicenseByFleetOwnerId.items;

        //     this.setState({
        //       nextNextToken: licenseData.data.getLicenseByFleetOwnerId.nextToken,
        //       totalLicense: totLicenses.length,
        //     });
        //     let objLicenses = licenseData.data.getLicenseByFleetOwnerId.items.filter(
        //       (l) => l.fleetOwnerPurchaseId !== "Fleet Owner License"
        //     );

        //     lstCodes = [];

        //     for (var i = 0; i < objLicenses.length; i++) {
        //       lstCodes.push(objLicenses[i].code);
        //     }

        //     this.setState({
        //       licenseCodes: lstCodes,
        //       licenses: objLicenses,
        //       loading: false,
        //       hasNext: licenseData.data.getLicenseByFleetOwnerId.nextToken !== null,
        //       isLoading: false,
        //       showCurrentPageNo: this.state.currentPageNo,
        //       inProgress: false,
        //     });
        //   }
        // );
    };

    get12MonthsLabel() {
        let newDate = new Date()
        let date = newDate.getDate();
        let last_30_days = [];
        const goBack_Days = date - 1;
        for (var i = goBack_Days; i > -1; i--) {
            last_30_days.push(moment().subtract(i, "days").format("D"));
        }
        return last_30_days;

        /*      let last_12_months = [];
              const currentMonth = parseInt(moment().format("MM"));
              for (let i = 0; i < currentMonth; i++) {
                  last_12_months.push(monthNames[i]);
              }
              return last_12_months;*/

    }

    getRiderAlertdata() {
        var res = [];
        for (let i = 0; i < this.get12MonthsLabel().length; i++) {
            let isMatched = false;
            this.props.chartData.map((item) => {
                if (parseInt(item.month) === i) {
                    isMatched = true;

                    res[i] = item.data.totalriderAlerts;
                }
            });
            if (!isMatched) {
                res[i] = 0;
            }
        }

        return res;
    }

    getActiveVehicles() {
        var res = [];
        for (let i = 0; i < this.get12MonthsLabel().length; i++) {
            let isMatched = false;
            this.props.chartData.map((item) => {
                if (parseInt(item.month) === i) {
                    isMatched = true;

                    res[i] = item.data.activeFleets;
                }
            });

            if (!isMatched) {
                res[i] = 0;
            }
        }

        return res;
    }
    handleShowLicencePopup = (e) => {
        this.setState({
            showLicencePopup: true,
            noOfLicense: "",
            licenseCost: 0,
            VATValue: 0,
            totalValue: 0,
        });
    };

    handleCloseLicencePopup = (e) => {
        this.setState({
            showLicencePopup: false,
        });
    };

    render() {
        const { showLicencePopup, modalClassic, accountManagerEmail, totalLicense } = this.state;
        const AlertChartOption = {
            data: {
                labels: this.get12MonthsLabel(),
                datasets: [
                    {
                        label: "Active Users",
                        borderColor: "#6bd098",
                        pointRadius: 0,
                        pointHoverRadius: 0,
                        fill: false,
                        borderWidth: 3,
                        barPercentage: 1.6,
                        tension: 0.4,
                        data: this.getRiderAlertdata(),
                    },
                ],
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                    },

                    tooltips: {
                        enabled: false,
                    },
                },

                scales: {
                    y: {
                        ticks: {
                            color: "#9f9f9f",
                            beginAtZero: true,
                            maxTicksLimit: 5,
                            precision: 0,
                        },

                        title: {
                            display: true,
                            text: "Alerts",
                        },

                        grid: {
                            drawBorder: false,
                            display: false,
                        },
                    },
                    x: {
                        grid: {
                            drawBorder: false,
                            display: false,
                        },
                        ticks: {
                            padding: 20,
                            color: "#9f9f9f",

                        },
                        title: {
                            display: true,
                            text: "Months",
                        },
                    },
                },
            },
        };
        //console.log(this.getActiveVehicles())

        const ActiveChartOption = {
            data: {
                labels: this.get12MonthsLabel(),
                datasets: [
                    {
                        label: "Active Users",
                        borderColor: "#6bd098",
                        pointRadius: 0,
                        pointHoverRadius: 0,
                        fill: false,
                        borderWidth: 3,
                        barPercentage: 1.6,
                        tension: 0.4,
                        data: this.getActiveVehicles(),
                    },
                ],
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                    },

                    tooltips: {
                        enabled: false,
                    },
                },

                scales: {
                    y: {
                        ticks: {
                            color: "#9f9f9f",
                            beginAtZero: false,
                            maxTicksLimit: 5,
                            //padding: 20
                        },
                        title: {
                            display: true,
                            text: "Vehicles",
                        },
                        grid: {
                            drawBorder: false,
                            display: false,
                        },
                    },
                    x: {
                        grid: {
                            drawBorder: false,
                            display: false,
                        },
                        title: {
                            display: true,
                            text: "Months",
                        },
                        ticks: {
                            padding: 20,
                            color: "#9f9f9f",
                        },
                    },
                },
            },
        };
        const { licenses } = this.state;

        const filterdata = licenses.filter((item) => new Date().getMonth() === new Date(item.createdAt).getUTCMonth());

        const findprice = filterdata.map((item) => item.price);
        const sum = findprice.reduce((price_sum, a) => price_sum + a, 0);
        return (
            <div>
                <div className="content">
                    <Row>
                        <Col lg="4" md="6" sm="6">
                            <Card
                                className={`card-stats ${styles.handCursor}`}
                                onClick={() => {
                                    this.props.history.push("/fleet");
                                }}
                            >
                                <CardBody>
                                    <Row>
                                        <Col md="4" xs="5">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-delivery-fast text-warning" />
                                            </div>
                                        </Col>
                                        <Col md="8" xs="7">
                                            <div className="numbers">
                                                <p className="card-category">Vehicles</p>
                                                <CardTitle tag="p">
                                                    {this.props.NoOfVehicles === "-" ? <img src={loader} alt="" /> : this.props.NoOfVehicles}
                                                </CardTitle>
                                                <p />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <hr />
                                    <div className="stats">
                                        <i className="fa fa-refresh" />
                                        Total Vehicles
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                        <Col lg="4" md="6" sm="6">
                            <Card className="card-stats">
                                <CardBody
                                    className={styles.handCursor}
                                    onClick={() => {
                                        this.props.history.push("/manage-licenses");
                                    }}
                                >
                                    <Row>
                                        <Col md="4" xs="5">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-single-copy-04 text-success" />
                                            </div>
                                        </Col>

                                        <Col md="8" xs="7">
                                            <div className="numbers">
                                                {localStorage.getItem("companyType") === "Enterprise Customer" ?
                                                    <p className="card-category">License</p>
                                                    :
                                                    <p className="card-category">Licenses</p>}
                                                <CardTitle tag="p">
                                                    {totalLicense === "-" ? <img src={loader} alt="" /> : totalLicense}
                                                </CardTitle>
                                                <p />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <hr />
                                    <div className="d-flex  justify-content-between">
                                        <div
                                            className="stats"
                                            onClick={() => {
                                                this.setState({ totalLicense: "-" });
                                                setTimeout(() => {
                                                    this.getLicense();
                                                }, 300);
                                            }}
                                        >
                                            <i className="fa fa-refresh" />
                                            Update now
                                        </div>
                                        {(localStorage.getItem("companyType") === "Standard Customer") ?
                                            <Button
                                                className="buynow btn-round "
                                                color="warning"
                                                style={{ marginTop: "-8px", marginBottom: "-10px", paddingInline: "15px" }}
                                                onClick={this.handleShowLicencePopup}
                                            >
                                                Buy Now
                                            </Button> : null}
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                        <Col lg="4" md="6" sm="6">
                            <Card className="card-stats">
                                <CardBody>
                                    <Row>
                                        <Col md="4" xs="5">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-bell-55 text-danger" />
                                            </div>
                                        </Col>
                                        <Col md="8" xs="7">
                                            <div className="numbers">
                                                <p className="card-category">Detections</p>
                                                <CardTitle tag="p">
                                                    {this.props.riderAlerts === "-" ? <img src={loader} alt="" /> : this.props.riderAlerts}</CardTitle>
                                                <p />
                                            </div>

                                        </Col>
                                    </Row>
                                </CardBody>

                                <CardFooter>
                                    <hr />
                                    <div className="d-flex  justify-content-between">
                                        <div
                                            className="stats"

                                        >
                                            <i className="fa fa-clock-o" />
                                            Total Detections
                                        </div>
                                        <div className={styles.tooltip} style={{ width: "10%" }}>
                                            <img src={infoIcon} width="16px" height="16px" style={{ float: "right" }} /><span className={styles.tooltiptext} style={{ marginLeft: "-135px" }}>For every 10 alerts we believe we have prevented at least 1 incident.</span>
                                        </div>
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                        {/*<Col lg="3" md="6" sm="6">
                            <Card className="card-stats">
                                <CardBody>
                                    <Row>
                                        <Col md="4" xs="5">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-money-coins text-primary" />
                                            </div>
                                        </Col>
                                        <Col md="8" xs="7">
                                            <div className="numbers">
                                                <p className="card-category">Monthly Cost </p>
                                                <CardTitle tag="p">
                                                    £{parseFloat(sum).toFixed(2) === "-" ? <img src={loader} alt="" /> : parseFloat(sum).toFixed(2)}
                                                </CardTitle>
                                                <p />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <hr />
                                    <div className="stats">
                                        <i className="fa fa-refresh" />
                                        Total Monthly Cost
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>*/}
                    </Row>
                    <Row>
                        <Col lg="6" sm="6">
                            <span style={{ margin: "10px" }}>Red alerts this year:</span>
                            <Card>
                                {/*<CardHeader>
                                    <Row>
                                        <Col sm="7">
                                            <div className="numbers pull-left">24 hour performance</div>
                                        </Col>
                                    </Row>
                                </CardHeader>*/}
                                <CardBody>
                                    <Line data={AlertChartOption.data} options={AlertChartOption.options} height={380} width={826} />
                                </CardBody>
                                {/* <CardFooter className={styles.chartLabel}>Months</CardFooter> */}
                            </Card>
                        </Col>
                        <Col lg="6" sm="6">
                            <span style={{ margin: "10px" }}>Active vehicles this year:</span>
                            <Card>
                                {/*<CardHeader>
                                    <Row>
                                        <Col sm="7">
                                            <div className="numbers pull-left">24 hour performance</div>
                                        </Col>
                                    </Row>
                                </CardHeader>*/}
                                <CardBody>
                                    <Line data={ActiveChartOption.data} options={ActiveChartOption.options} height={380} width={826} />
                                </CardBody>
                                {/* <CardFooter className={styles.chartLabel}>Months</CardFooter> */}
                            </Card>
                        </Col>
                    </Row>
                    <BuyLicensesPopBox
                        showLicencePopup={showLicencePopup}
                        handleCloseLicencePopup={this.handleCloseLicencePopup}
                        onSuccess={() => this.getLicense()}
                    />
                    <Modal isOpen={modalClassic} toggle={this.toggleModalClassic}>
                        <div className="modal-header justify-content-center">
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={this.toggleCloseModalClassic}
                            >
                                <i className="nc-icon nc-simple-remove" />
                            </button>
                            <h4 className="title title-up welcome">Welcome</h4>
                        </div>
                        <div className="modal-body">
                            <p>
                                RoadRadar is the global standard for road safety. To help you get started a free 30-day vehicle licence
                                has been credited to your account. In order to protect more vehicles, you may purchase additional
                                licences now or speak to your account manager:{" "}
                                <a href="mailto:accountManagerEmail">{accountManagerEmail}</a>
                            </p>
                        </div>
                        <div className="modal-footer">
                            <div className="left-side">
                                <Button
                                    className="btn-link"
                                    color="default"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={this.toggleCloseModalClassic}
                                >
                                    Later
                                </Button>
                            </div>
                            <div className="divider" />
                            <div className="right-side">
                                <Button className="btn-link" color="danger" type="button" onClick={this.handleShowLicencePopup}>
                                    Buy Licences
                                </Button>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        );
    }
}
export default withRouter(DashboardMetrics);
