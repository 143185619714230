import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Nav, Collapse } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import styles from "./styles.module.scss";
import logo from "../../assests/images/logo.png";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { getFleetOwners } from "../../graphql/queries";
import loader from "../../assests/images/loading_dots.gif";

var ps;

function Sidebar(props) {
    const [openAvatar, setOpenAvatar] = React.useState(false);
    const [collapseStates, setCollapseStates] = React.useState({});
    const sidebar = React.useRef();
    const companyType = localStorage.getItem("companyType");
    const getCollapseStates = (routes) => {
        let initialState = {};
        routes.map((prop, key) => {
            if (prop.collapse) {
                initialState = {
                    [prop.state]: getCollapseInitialState(prop.views),
                    ...getCollapseStates(prop.views),
                    ...initialState,
                };
            }
            return null;
        });
        return initialState;
    };
    const getCollapseInitialState = (routes) => {
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
                return true;
            } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
                return true;
            }
        }
        return false;
    };

    React.useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(sidebar.current, {
                suppressScrollX: true,
                suppressScrollY: false,
            });
        }
        return function cleanup() {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
            }
        };
    });

    React.useEffect(() => {
        setCollapseStates(getCollapseStates(props.routes));
    }, []);

    const createLinks = (routes) => {
        return routes.map((prop, key) => {
            if (prop.redirect) {
                return null;
            }
            if (prop.collapse) {
                var st = {};
                st[prop["state"]] = !collapseStates[prop.state];
                return (
                    <li className={getCollapseInitialState(prop.views) ? "active" : ""} key={key}>
                        <a
                            href="#pablo"
                            data-toggle="collapse"
                            aria-expanded={collapseStates[prop.state]}
                            onClick={(e) => {
                                e.preventDefault();
                                setCollapseStates(st);
                            }}
                        >
                            {prop.icon !== undefined ? (
                                <>
                                    <i className={prop.icon} />
                                    <p>
                                        {prop.name}
                                        <b className="caret" />
                                    </p>
                                </>
                            ) : (
                                <>
                                    <span className="sidebar-mini-icon">{prop.mini}</span>
                                    <span className="sidebar-normal">
                                        {prop.name}
                                        <b className="caret" />
                                    </span>
                                </>
                            )}
                        </a>
                        <Collapse isOpen={collapseStates[prop.state]}>
                            <ul className="nav">{createLinks(prop.views)}</ul>
                        </Collapse>
                    </li>
                );
            }
            return (
                <li className={activeRoute("/" + prop.path.split("/")[1])} key={key}>
                    <NavLink to={"/" + prop.path.split("/")[1]} activeClassName="">
                        {prop.icon !== undefined ? (
                            (localStorage.getItem("companyType") === "Enterprise Customer" && prop.name === "licenses") ?
                                <>
                                    <i className={prop.icon} />
                                    <p>License</p>
                                </>
                                :
                                <>
                                    <i className={prop.icon} />
                                    <p>{prop.name}</p>
                                </>
                        ) : (
                            <>
                                <span className="sidebar-mini-icon">{prop.mini}</span>
                                <span className="sidebar-normal">{prop.name}</span>
                            </>
                        )}
                    </NavLink>
                </li>
            );
        });
    };
    const activeRoute = (routeName) => {
        return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
    };

    const profileImg = localStorage.getItem("profileImg");

    const profileNotSet = localStorage.getItem("profileNotSet");

    return (
        <div>
            <div className="sidebar" data-color={props.bgColor} data-active-color={props.activeColor}>
                <div className="sidebar-wrapper" ref={sidebar}>
                    <div className="user">
                        <div className="photo">
                            <div className={styles.userContent}>
                                <div className={styles.avatar}>
                                    {profileImg === "" && profileImg === null ? (
                                        <img
                                            src={logo}
                                            className="avatar border-gray"
                                            style={{ objectFit: "contain" }}
                                            alt="company logo"
                                        />
                                    ) : (
                                        <img
                                            src={profileImg && profileImg.toString().startsWith("https") ? profileImg : logo}
                                            alt="userlogo"
                                            style={{ borderRadius: "50%", height: "35px" }}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="info">
                            <a data-toggle="collapse" aria-expanded={openAvatar} onClick={() => setOpenAvatar(!openAvatar)}>
                                <span>
                                    {localStorage.getItem("fleetOwnerName")}
                                    <b className="caret" />
                                </span>
                            </a>
                            <Collapse isOpen={openAvatar}>
                                <ul className="nav">
                                    <li>
                                        <NavLink to="/profile" activeClassName="">
                                            <span className="sidebar-mini-icon">MP</span>
                                            <span className="sidebar-normal">My Profile</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </Collapse>
                        </div>
                    </div>
                    <Nav>{createLinks(props.routes)}</Nav>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;
