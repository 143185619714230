import React from "react";
import classnames from "classnames";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import Logo from "assests/images/logo-white.png";

import { Collapse, NavbarBrand, Navbar, NavItem, Nav, Container } from "reactstrap";

function AuthNavbar() {
  const location = useLocation();
  const history = useHistory();
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [color, setColor] = React.useState("navbar-transparent");

  const toggleCollapse = () => {
    if (!collapseOpen) {
      setColor("bg-white");
    } else {
      setColor("navbar-transparent");
    }
    setCollapseOpen(!collapseOpen);
  };

  return (
    <Navbar className={classnames("navbar-absolute fixed-top", color)} expand="lg" style={{ borderBottom: "none" }}>
      <Container>
        <div className="navbar-wrapper">
          <NavbarBrand href="#" onClick={(e) => e.preventDefault()}>
            <div>
              <img src={Logo} alt="logo"
                onClick={() => history.push("/login")} style={{ maxWidth: "200px", maxHeight: "115px", verticalAlign: "baseline" }} />
              <br />
              <p style={{ display: "block" }}>Fleet Owners</p>
            </div>
          </NavbarBrand>
        </div>
        <button
          aria-controls="navigation-index"
          aria-expanded={false}
          aria-label="Toggle navigation"
          className="navbar-toggler"
          data-toggle="collapse"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
        </button>
        <Collapse isOpen={collapseOpen} className="justify-content-end" navbar>
          <Nav navbar>
            {(location.pathname === "/" || location.pathname === "/login") && (
              <NavItem>
                <NavLink to="/sign-up" className="nav-link">
                  <i className="nc-icon nc-book-bookmark" />
                  Register
                </NavLink>
              </NavItem>
            )}
            {(location.pathname === "/sign-up" || location.pathname === "/forgot-password") && (
              <NavItem>
                <NavLink to="/" className="nav-link">
                  <i className="nc-icon nc-tap-01" />
                  Login
                </NavLink>
              </NavItem>
            )}
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default AuthNavbar;
