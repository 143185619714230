import React, { Component } from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import styles from "../styles.module.scss";
import logo from "../../../assests/images/logo.png";
import Helper from "../../../services/helper";
import eye from "../../../assests/images/eye.svg";
import hide from "../../../assests/images/hide.svg";
import { Auth } from "aws-amplify";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Col,
    Row,
} from "reactstrap";
import AuthNavbar from "components/Navbars/AuthNavbar.js";

export default class ChangePassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: this.props.location.state ? this.props.location.state.email : "",
            isPasswordShow: false,
            isNewPasswordShow: false,
            confirmationCode: "",
            newPassword: "",
            confirmPassword: "",
            loading: false,
        };
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
        });
    };

    setIsPasswordShow = (e) => {
        this.setState({ isPasswordShow: e });
    };

    setIsNewPasswordShow = (e) => {
        this.setState({ isNewPasswordShow: e });
    };

    handleChangePassword = async () => {
        const { email, confirmationCode, newPassword, confirmPassword } = this.state;
        this.setState({ loading: true });
        let isValid = true;

        const messages = [];

        if (confirmationCode.trim() === "") {
            isValid = false;
            messages.push("- Please enter confirmation code\n");
        }

        if (newPassword.trim() === "") {
            isValid = false;
            messages.push("- Please enter new password\n");
        } else if (newPassword.trim().length < 8) {
            isValid = false;
            messages.push("- Password must be at least 8 characters long\n");
        }

        if (confirmPassword.trim() === "") {
            isValid = false;
            messages.push("- Please re-enter new password\n");
        }

        if (newPassword.trim() !== confirmPassword.trim()) {
            isValid = false;
            messages.push("- Both the passwords must match\n");
        }

        if (!isValid) {
            this.setState({ loading: false });
            alert(Helper.arrayToString(messages));
            return;
        }

        await Auth.forgotPasswordSubmit(email, confirmationCode, newPassword)
            .then(async (result) => {
                this.props.history.push("/password-changed");
            })
            .catch((err) => {
                console.log(err);
                if (err.code === "InvalidPasswordException" || err.code === "InvalidParameterException") {
                    alert("Password must be at least 8 characters long");
                } else if (err.code === "CodeMismatchException") {
                    alert("The code is invalid, please try again or resend a new code");
                } else alert("Something went wrong. Please try again later");
            });
    };

     handleKeypress = e => {
        //it triggers by pressing the enter key
        if (e.keyCode === 13) {
            console.log("handleKeypress 344");
            this.handleChangePassword(e);
        }
    };
    render() {
        const { loading, isPasswordShow, isNewPasswordShow, confirmationCode, newPassword, confirmPassword } = this.state;

        return (
            <div>
                <AuthNavbar />
                <div className="wrapper wrapper-full-page">
                    <div className="full-page section-image">
                        <div className="login-page">
                            <Container>
                                <Row>
                                    <Col className="ml-auto mr-auto" lg="4" md="6">
                                        <Form action="" className="form" method="">
                                            <Card className="card-login">
                                                <CardHeader>
                                                    <CardHeader>
                                                        <h3 className="header text-center mb-3">Change Password</h3>
                                                        <p className="header text-center">
                                                            {" "}
                                                            Enter the code sent on your email and set your new password.
                                                        </p>
                                                    </CardHeader>
                                                </CardHeader>
                                                <CardBody>
                                                    <InputGroup>
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                                <i className="nc-icon nc-single-02" style={{ marginRight: "10px" }} />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input
                                                            type="text"
                                                            id="confirmationCode"
                                                            name="confirmationCode"
                                                            autoComplete="off"
                                                            placeholder="Confirmation code"
                                                            value={confirmationCode}
                                                            onChange={this.handleChange}
                                                            onKeyUp={(e) => this.handleKeypress(e)}
                                                        />
                                                    </InputGroup>
                                                    <InputGroup>
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                                <i className="nc-icon nc-key-25" style={{ marginRight: "10px" }} />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input
                                                            type={isPasswordShow ? "text" : "password"}
                                                            placeholder="Enter new password"
                                                            id="newPassword"
                                                            name="newPassword"
                                                            value={newPassword}
                                                            onChange={this.handleChange}
                                                            onKeyUp={(e) => this.handleKeypress(e)}
                                                        />
                                                        <div className={styles.pointer} onClick={() => this.setIsPasswordShow(!isPasswordShow)}>
                                                            <img src={!isPasswordShow ? hide : eye} alt="password visibility icon" />
                                                        </div>
                                                    </InputGroup>
                                                    <InputGroup>
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                                <i className="nc-icon nc-key-25" style={{ marginRight: "10px" }} />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input
                                                            type={isNewPasswordShow ? "text" : "password"}
                                                            placeholder="Re-enter new password"
                                                            id="confirmPassword"
                                                            name="confirmPassword"
                                                            value={confirmPassword}
                                                            onChange={this.handleChange}
                                                            onKeyUp={(e) => this.handleKeypress(e)}
                                                        />
                                                        <div
                                                            className={styles.pointer}
                                                            onClick={() => this.setIsNewPasswordShow(!isNewPasswordShow)}
                                                        >
                                                            <img src={!isNewPasswordShow ? hide : eye} alt="password visibility icon" />
                                                        </div>
                                                    </InputGroup>
                                                </CardBody>
                                                <CardFooter>
                                                    <Button block className="btn-round mb-3" color="warning" onClick={this.handleChangePassword}>
                                                        {loading ? (
                                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        ) : (
                                                            "Submit"
                                                        )}
                                                        
                                                    </Button>
                                                </CardFooter>
                                            </Card>
                                        </Form>
                                    </Col>
                                </Row>
                            </Container>
                            <div
                                className="full-page-background"
                                style={{
                                    backgroundImage: `url(${require("../../../assests/images/signup-banner.png")})`,
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

{
    /* <div className={styles.authContent}>
    <div className={styles.background} />
    <div className={styles.loginForm}>
      <div className={styles.logo}>
        <img src={logo} alt="logo" width="450px" />
      </div>
      <div className={styles.form}>
        <p className={styles.infoLeft}>Enter the code sent on your email and set your new password.</p>
        <div>
          <div className={classNames("form-group", styles.formGroup)}>
            <input
              type="text"
              className={classNames("form-control", styles.formControl)}
              id="confirmationCode"
              name="confirmationCode"
              placeholder="Confirmation code"
              value={confirmationCode}
              onChange={this.handleChange}
            />
          </div>
          <div className={classNames("form-group", styles.formGroup, styles.password)}>
            <input
              type={isPasswordShow ? "text" : "password"}
              className={classNames("form-control", styles.formControl)}
              placeholder="Enter new password"
              id="newPassword"
              name="newPassword"
              value={newPassword}
              onChange={this.handleChange}
            />
            <div className={styles.pointer} onClick={() => this.setIsPasswordShow(!isPasswordShow)}>
              <img src={!isPasswordShow ? hide : eye} alt="password visibility icon" />
            </div>
          </div>
          <div className={classNames("form-group", styles.formGroup, styles.password)}>
            <input
              type={isNewPasswordShow ? "text" : "password"}
              className={classNames("form-control", styles.formControl)}
              placeholder="Re-enter new password"
              id="confirmPassword"
              name="confirmPassword"
              value={confirmPassword}
              onChange={this.handleChange}
            />
            <div className={styles.pointer} onClick={() => this.setIsNewPasswordShow(!isNewPasswordShow)}>
              <img src={!isNewPasswordShow ? hide : eye} alt="password visibility icon" />
            </div>
          </div>
          <div className={classNames("form-group", styles.formGroup)}>
            <NavLink to="#" onClick={this.handleChangePassword} className={classNames("btn", styles.otherBtn)}>
              Submit
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  </div>; */
}
