/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:5dbbfe9a-0b87-49e9-9632-f1a7fdf5bf22",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_0C2h85n1m",
    "aws_user_pools_web_client_id": "7lp7ngpvrfsk8fj4ukl7d2ctsi",
    "oauth": {
        "domain": "busby2-production.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "phone",
            "profile"
        ],
        "redirectSignIn": "busby2://",
        "redirectSignOut": "busby2://",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE",
        "APPLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://cj6zmkkrsvbaphbqk6vhcojn4e.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-nkrfr6wom5d4dgxahrk6ix3f3q",
    "aws_cloud_logic_custom": [
        {
            "name": "busby2MessagingTwilio",
            "endpoint": "https://ept1tiiao5.execute-api.eu-west-1.amazonaws.com/flare",
            "region": "eu-west-1"
        },
        {
            "name": "busby2what3words",
            "endpoint": "https://4xmxunwfk4.execute-api.eu-west-1.amazonaws.com/flare",
            "region": "eu-west-1"
        },
        {
            "name": "paymentlambda",
            "endpoint": "https://8a7arhey2k.execute-api.eu-west-1.amazonaws.com/flare",
            "region": "eu-west-1"
        },
        {
            "name": "stripePayment",
            "endpoint": "https://mt6cit10z9.execute-api.eu-west-1.amazonaws.com/flare",
            "region": "eu-west-1"
        },
        {
            "name": "sweatCoinAPI",
            "endpoint": "https://ychobp8lgc.execute-api.eu-west-1.amazonaws.com/flare",
            "region": "eu-west-1"
        },
        {
            "name": "mailChimp",
            "endpoint": "https://08npt2ra4a.execute-api.eu-west-1.amazonaws.com/flare",
            "region": "eu-west-1"
        },
        {
            "name": "busbyUserRegistrationProcess",
            "endpoint": "https://ga4oupemm8.execute-api.eu-west-1.amazonaws.com/flare",
            "region": "eu-west-1"
        },
        {
            "name": "busbyUserManagement",
            "endpoint": "https://food9nuzj5.execute-api.eu-west-1.amazonaws.com/flare",
            "region": "eu-west-1"
        },
        {
            "name": "busby2TwilioApi",
            "endpoint": "https://xwgf1xdhy2.execute-api.eu-west-1.amazonaws.com/flare",
            "region": "eu-west-1"
        },
        {
            "name": "busbySecureKeys",
            "endpoint": "https://tqn5o7c6z0.execute-api.eu-west-1.amazonaws.com/flare",
            "region": "eu-west-1"
        },
        {
            "name": "busbyDeleteUserAccountAPI",
            "endpoint": "https://k673shy7w9.execute-api.eu-west-1.amazonaws.com/flare",
            "region": "eu-west-1"
        },
        {
            "name": "busbyAppSecureKeysApi",
            "endpoint": "https://kekvq7mhzk.execute-api.eu-west-1.amazonaws.com/flare",
            "region": "eu-west-1"
        },
        {
            "name": "busbyCrowdinHash",
            "endpoint": "https://jd0trpsjt0.execute-api.eu-west-1.amazonaws.com/flare",
            "region": "eu-west-1"
        },
        {
            "name": "busbyStravaApi",
            "endpoint": "https://yhrnnioohk.execute-api.eu-west-1.amazonaws.com/flare",
            "region": "eu-west-1"
        },
        {
            "name": "sideMlPythonApi",
            "endpoint": "https://234wlaq0gf.execute-api.eu-west-1.amazonaws.com/flare",
            "region": "eu-west-1"
        },
        {
            "name": "busbyPubnubSecureKeys",
            "endpoint": "https://h3ws0rfiig.execute-api.eu-west-1.amazonaws.com/flare",
            "region": "eu-west-1"
        },
        {
            "name": "nativeSdkApi",
            "endpoint": "https://i48eiyodt8.execute-api.eu-west-1.amazonaws.com/flare",
            "region": "eu-west-1"
        },
        {
            "name": "flareVerifyRiderID",
            "endpoint": "https://ohwsgbbv60.execute-api.eu-west-1.amazonaws.com/flare",
            "region": "eu-west-1"
        },
        {
            "name": "flareGenerateAPIKey",
            "endpoint": "https://d7t1p6xtd0.execute-api.eu-west-1.amazonaws.com/flare",
            "region": "eu-west-1"
        }
    ],
    "aws_user_files_s3_bucket": "busby2145731-production",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
