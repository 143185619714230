import React, { Component } from 'react';
import { Tooltip } from 'reactstrap';

class ViewMore extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            tooltipOpen: false
        };
    }

    toggle() {
        const { tooltipOpen } = this.state;
        this.setState({
            tooltipOpen: !tooltipOpen
        });
    }

    render() {
        const { id, content, maxChar } = this.props;
        return (
            <div>
                <span id={id} style={{ cursor: "pointer" }}>{content.toString().substring(0, parseInt(maxChar))}....</span>
                <Tooltip placement="bottom" isOpen={this.state.tooltipOpen} target={id} toggle={this.toggle}>
                    {content}
                </Tooltip>
            </div>

        );
    }
}

export default ViewMore;
