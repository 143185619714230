import React from 'react';
import { Route } from 'react-router-dom';
import routes from './routes';
import { Redirect } from 'react-router-dom';
import LandingLayout from '../components/landinglayout';
import Helper from '../services/helper';


const PrivateRoutes = () => {
    // Get private routes from all routes
    const publicRoutes = routes.filter((route) => !route.isPublic);

    // Render private routes
    return publicRoutes.map((route) => {
        const RouteVal = route.component;
        return (
            <Route
                key={route.id}
                path={route.path}
                exact
                render={(props) => (
                    Helper.isAuthenticated()
                        ? (
                            <LandingLayout>
                                <RouteVal {...props} />
                            </LandingLayout>
                        )
                        : <Redirect to="/login" />
                )}
            />
        );
    });
};




export default PrivateRoutes;
