import React, { Component } from "react";
import styles from "../styles.module.scss";
import "../../homepage/index.scss";
import { Modal } from "react-bootstrap";
import QRCode from "qrcode.react";
import { API, graphqlOperation, Storage } from "aws-amplify";
import {
    getFleetOwners,
    getFleetOwnersByEmail,
    getFleetUsersByFleetOwnerId,
    listCountrys,
    getLicenseByFleetOwnerId
} from "../../../graphql/queries";
import * as mutations from "../../../graphql/mutations";
import Helper from "../../../services/helper";
import logo from "../../../assests/images/logo.png";
import classNames from "classnames";
import Select from "react-select";
import loader from "../../../assests/images/loading_dots.gif";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
} from "reactstrap";

var fleetUserData = [];
var licenseData = [];

export default class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fleetOwnerId:
                localStorage.getItem("fleetOwnerId") === null
                    ? this.props.location.state
                        ? this.props.location.state.fleetOwnerId
                        : ""
                    : localStorage.getItem("fleetOwnerId"),
            riderAlerts: 0,
            countryList: [],
            email: "",
            firstName: "",
            lastName: "",
            companyName: "",
            companyNo: "",
            addressLine1: "",
            addressLine2: "",
            city: "",
            postcode: "",
            phoneNo: "",
            noOfVehicles: "",
            country: "",
            uploadedProfilePicture: "",
            profilePicture: "",
            profilePicURL: logo,
            imageButtonText: "Choose Image",
            selectedCountry: { value: "", label: "" },
            selectedCompanyType: { value: "", label: "" },
            selectedNoOfVehicle: { value: "", label: "" },
            profile: {
                Email: "",
                FirstName: "",
                LastName: "",
                CompanyName: "",
                CompanyNo: "",
                PhoneNo: "",
                NoOfVehicles: "",
                Country: "",
                CompanyType: "",
                TotalLicenses: 0,
                UnassignedLicenses: 0,
                PdfFile: "",
                ProfilePicture: logo,
                PdfFileUrl: "",
                AddressLine1: "",
                AddressLine2: "",
                City: "",
                Postcode: "",
                qrCode: "",
            },
            isImageChanged: false,
            version: 0,
            qrCodeDataUri: '',
            codeImg: '',
            showModal: false,
            companyType: '',
            licenseCode: "",
            totalLincenses: 0
        };
    }
    componentDidMount() {
        this.getFleetOwnerLicense();
        this.getFleetUsers();
        this.loadCountries();
    }

    getFleetOwnerLicense = async () => {
        var hasMoreRec = true;
        let licenses = [];

        this.setState({ rNextToken: undefined });

        while (hasMoreRec) {
            var variables = {
                fleetOwnerId: this.state.fleetOwnerId,
                nextToken: this.state.rNextToken,
                filter: {
                    isDeleted: { eq: false }
                },
                limit: 500,
                sortDirection: "DESC",
            };

            await API.graphql(graphqlOperation(getLicenseByFleetOwnerId, variables)).then(result => {
                this.setState({ rNextToken: result.data.getLicenseByFleetOwnerId.nextToken });

                result.data.getLicenseByFleetOwnerId.items.forEach(function (itm) {
                    licenses.push({
                        id: itm.id,
                        code: itm.code,
                        fleetOwnerPurchaseId: itm.fleetOwnerPurchaseId
                    });
                });

                hasMoreRec = result.data.getLicenseByFleetOwnerId.nextToken !== null;
            });
        }

        this.setState({
            totalLincenses: licenses.length,
            licenseCode: licenses.filter(
                (l) => l.fleetOwnerPurchaseId === "Fleet Owner License"
            )[0].code
        }, function () {
            this.getFleetOwnerDetails();
        });
    }

    loadCountries = async () => {
        var variables = {
            nextToken: null,
            limit: 500,
        };
        await API.graphql(graphqlOperation(listCountrys, variables)).then(async (result) => {
            let data = [];

            data.push({ value: "GBR", label: "United Kingdom" });

            result.data.listCountrys.items
                .filter((c) => c.isoCode !== "GBR")
                .sort(Helper.sortJsonArray("countryName", "ASC"))
                .map((itm) => data.push({ value: itm.countryName, label: itm.countryName }));
            this.setState({ countryList: data });
        });
    };

    getFleetUsers = async () => {
        let fId =
            localStorage.getItem("fleetOwnerId") === null
                ? this.props.location.state
                    ? this.props.location.state.fleetOwnerId
                    : ""
                : localStorage.getItem("fleetOwnerId");

        var variables = {
            fleetOwnerId: fId,
            nextToken: this.state.nextToken,
            filter: {
                isDeleted: { eq: false },
                deletedFleetUser: { eq: "false" },
            },
            limit: this.state.pageSize,
            sortDirection: "DESC",
        };

        fleetUserData = await API.graphql(graphqlOperation(getFleetUsersByFleetOwnerId, variables));

        this.setState({ nextNextToken: fleetUserData.data.getFleetUsersByFleetOwnerId.nextToken });

        let objFleetUser = fleetUserData.data.getFleetUsersByFleetOwnerId.items;

        var counts = 0;

        if (objFleetUser !== null && objFleetUser.length > 0) {
            counts = objFleetUser.map((x) => x.riderAlerts).reduce((alert, x) => x + alert);
        }

        this.setState({
            fleetUsers: objFleetUser,
            loading: false,
            hasNext: fleetUserData.data.getFleetUsersByFleetOwnerId.nextToken !== null,
            isLoading: false,
            riderAlerts: counts === null ? 0 : counts,
            showCurrentPageNo: this.state.currentPageNo,
            inProgress: false,
        });
    };

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState(
            {
                [name]: value,
            },
            function () {
                if (name === "searchText") {
                    this.handleSearch();
                }
            }
        );
    };

    handleCountryChange = (e) => {
        this.setState({
            country: e.value,
            selectedCountry: e,
        });
    };

    handleNoOfVehicleChange = (e) => {
        var regexp = /^[0-9\b]+$/;
        let vehicles = e.target.value;

        if (vehicles === "" || regexp.test(vehicles)) {
            this.setState({ [e.target.name]: vehicles });
        }
    };

    handlePhoneChange = (e) => {
        var regexp = /^[0-9\b]+$/;
        let phone = e.target.value;

        if (phone === "" || regexp.test(phone)) {
            this.setState({ [e.target.name]: phone });
        }
    };

    getFleetOwnerDetails = async () => {
        let fId =
            localStorage.getItem("fleetOwnerId") === null
                ? this.props.location.state
                    ? this.props.location.state.fleetOwnerId
                    : ""
                : localStorage.getItem("fleetOwnerId");

        if (fId !== null && fId !== undefined) {
            await API.graphql(graphqlOperation(getFleetOwners, { id: fId })).then(async (result) => {
                let profileData = this.state.profile;
                let countryOption = this.state.selectedCountry;
                let noOfVehicleOption = this.state.selectedNoOfVehicle;
                let companyTypeOption = this.state.selectedCompanyType;

                let profileImg = "";
                let pdfFile = "";

                if (result.data.getFleetOwners !== null) {
                    if (result.data.getFleetOwners.profilePicture.trim() !== "") {
                        await Storage.get(result.data.getFleetOwners.profilePicture, { level: "public" })
                            .then((url) => {
                                profileImg = url;
                                this.setState({ imageButtonText: "Change Image" });
                                localStorage.removeItem("profileNotSet");
                            })
                            .catch((err) => console.log("error = " + err));
                    } else {
                        profileImg = "";
                        this.setState({ profilePicture: "", profilePicURL: "", imageButtonText: "Choose Image" });
                        localStorage.setItem("profileNotSet", "true");
                    }

                    if (result.data.getFleetOwners.pdfFilePath.trim() !== "") {
                        await Storage.get(result.data.getFleetOwners.pdfFilePath, { level: "public" })
                            .then((url) => {
                                pdfFile = url;
                            })
                            .catch((err) => console.log("error = " + err));
                    } else pdfFile = "";

                    profileData.CompanyName = result.data.getFleetOwners.companyName;
                    profileData.CompanyNo =
                        result.data.getFleetOwners.companyNo === "-" ? "" : result.data.getFleetOwners.companyNo;
                    profileData.Email = result.data.getFleetOwners.email;
                    profileData.FirstName = result.data.getFleetOwners.firstName;
                    profileData.LastName = result.data.getFleetOwners.lastName;
                    profileData.PhoneNo = result.data.getFleetOwners.phone;
                    profileData.NoOfVehicles = result.data.getFleetOwners.noOfVehicles;
                    profileData.Country = result.data.getFleetOwners.country;
                    profileData.TotalLicenses = this.state.totalLincenses;
                    profileData.UnassignedLicenses = result.data.getFleetOwners.unassignedLicenses
                        ? result.data.getFleetOwners.unassignedLicenses
                        : 0;
                    profileData.PdfFile = result.data.getFleetOwners.pdfFilePath;
                    profileData.PdfFileUrl = pdfFile;
                    profileData.ProfilePicture = profileImg;
                    profileData.AddressLine1 =
                        result.data.getFleetOwners.addressLine1 !== undefined ? result.data.getFleetOwners.addressLine1 : "";
                    profileData.AddressLine2 =
                        result.data.getFleetOwners.addressLine2 !== undefined ? result.data.getFleetOwners.addressLine2 : "";
                    profileData.City = result.data.getFleetOwners.city !== undefined ? result.data.getFleetOwners.city : "";
                    profileData.Postcode =
                        result.data.getFleetOwners.postcode !== undefined ? result.data.getFleetOwners.postcode : "";
                    profileData.qrCode = this.state.licenseCode;

                    countryOption.label = profileData.Country;
                    countryOption.value = profileData.Country;

                    noOfVehicleOption.label = profileData.NoOfVehicles;
                    noOfVehicleOption.value = profileData.NoOfVehicles;
                    companyTypeOption.label = result.data.getFleetOwners.companyType;
                    companyTypeOption.value = result.data.getFleetOwners.companyType;
                    localStorage.setItem("profileImg", profileImg);

                    console.log("companyTypeOption: " + companyTypeOption);

                    this.setState({
                        profile: profileData,
                        selectedCountry: countryOption,
                        selectedNoOfVehicle: noOfVehicleOption,
                        selectedCompanyType: companyTypeOption,
                        companyName: result.data.getFleetOwners.companyName,
                        companyNo: result.data.getFleetOwners.companyNo === "-" ? "" : result.data.getFleetOwners.companyNo,
                        email: result.data.getFleetOwners.email,
                        firstName: result.data.getFleetOwners.firstName,
                        lastName: result.data.getFleetOwners.lastName,
                        phoneNo: result.data.getFleetOwners.phone,
                        noOfVehicles: result.data.getFleetOwners.noOfVehicles,
                        country: result.data.getFleetOwners.country,
                        profilePicture: profileImg,
                        addressLine1:
                            result.data.getFleetOwners.addressLine1 !== undefined ? result.data.getFleetOwners.addressLine1 : "",
                        addressLine2:
                            result.data.getFleetOwners.addressLine2 !== undefined ? result.data.getFleetOwners.addressLine2 : "",
                        city: result.data.getFleetOwners.city !== undefined ? result.data.getFleetOwners.city : "",
                        postcode: result.data.getFleetOwners.postcode !== undefined ? result.data.getFleetOwners.postcode : "",
                        paymentDescription:
                            "Payment received from Road Radar Enterprise: " + result.data.getFleetOwners.companyName,
                        companyType: result.data.getFleetOwners.companyType,
                        version: result.data.getFleetOwners._version
                    });
                }
            });
        }
    };

    onSelectProfileImage = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            this.setState({
                profilePicture: e.target.files[0],
                profilePicURL: URL.createObjectURL(e.target.files[0]),
                isImageChanged: true,
            });
        }
    };

    handleCancelUpload = async () => {
        await this.getFleetOwnerDetails();
        this.setState({ isImageChanged: false });
    };

    handleSaveImage = async (e) => {
        localStorage.removeItem("profileNotSet");
        let fleetOwnerId = this.state.fleetOwnerId;

        const fileName = this.state.profilePicture.name;

        await Storage.put(fileName, this.state.profilePicture, {
            contentType: this.state.profilePicture.type,
        }).then((data) => {
            //console.log("Data : " + JSON.stringify(data));
            this.setState({ uploadedProfilePicture: data.key });
        }).catch((err) => console.log("Err : " + JSON.stringify(err)));

        if (fleetOwnerId !== null && fleetOwnerId !== "" && fleetOwnerId !== "0") {
            await API.graphql(graphqlOperation(getFleetOwners, { id: fleetOwnerId })).then(async (result) => {
                let version = result.data.getFleetOwners._version;

                var objFleetOwner = {
                    id: fleetOwnerId,
                    profilePicture: this.state.uploadedProfilePicture,
                    updatedAt: new Date(),
                    _version: version
                };

                await API.graphql(graphqlOperation(mutations.updateFleetOwners, { input: objFleetOwner }))
                    .then(async (data) => {
                        this.setState({ imageButtonText: "Change Image" });
                        localStorage.setItem("profileImg", this.state.uploadedProfilePicture);
                    })
                    .catch((error) => {
                        alert(JSON.stringify(error));
                    });

                await this.loadProfilePicture();
            });
        }
        window.location.reload(false);
    };

    loadProfilePicture = async () => {
        if (this.state.fleetOwnerId !== "0") {
            await API.graphql(graphqlOperation(getFleetOwners, { id: this.state.fleetOwnerId })).then(async (result) => {
                let profilePic = "";

                if (result.data.getFleetOwners.profilePicture !== "") {
                    await Storage.get(result.data.getFleetOwners.profilePicture, { level: "public" })
                        .then((url) => {
                            profilePic = url;
                        })
                        .catch((err) => console.log("error = " + err));
                }
                localStorage.setItem("profileImg", profilePic);
                this.setState({ profilePicture: profilePic, isImageChanged: false });
            });
        } else this.setState({ profilePicture: "", isImageChanged: false });
    };

    handleUpdateProfile = async () => {
        const {
            email,
            companyNo,
            firstName,
            lastName,
            companyName,
            phoneNo,
            noOfVehicles,
            country,
            companyType,
            addressLine1,
            addressLine2,
            city,
            postcode,
            selectedCountry,
            selectedCompanyType

        } = this.state;

        var isFleetOwnerExists = false;
        let isValid = true;

        const messages = [];

        if (email === "") {
            isValid = false;
            messages.push("- Please enter a email\n");
        } else {
            if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
                await API.graphql(graphqlOperation(getFleetOwnersByEmail, { email: email })).then(async (result) => {
                    if (result.data.getFleetOwnersByEmail.items.length > 0) {
                        if (
                            result.data.getFleetOwnersByEmail.items[0].id !== this.state.fleetOwnerId &&
                            result.data.getFleetOwnersByEmail.items[0].isDeleted === false
                        ) {
                            isFleetOwnerExists = true;
                        }
                    }
                });

                if (isFleetOwnerExists) {
                    alert("Email already exists, please enter another one");
                    return;
                }
            } else {
                isValid = false;
                messages.push("- Enter an email\n");
            }
        }

        if (companyName === "") {
            isValid = false;
            messages.push("- Enter company name\n");
        }

        if (firstName === "") {
            isValid = false;
            messages.push("- Enter first name\n");
        }

        if (lastName === "") {
            isValid = false;
            messages.push("- Enter last name\n");
        }

        if (addressLine1 === "") {
            isValid = false;
            messages.push("- Enter address Line 1\n");
        }

        if (addressLine2 === "") {
            isValid = false;
            messages.push("- Enter address Line 2\n");
        }

        if (city === "") {
            isValid = false;
            messages.push("- Enter city/town\n");
        }

        if (postcode === "") {
            isValid = false;
            messages.push("- Enter zip code/postcode\n");
        }

        if (phoneNo === "") {
            isValid = false;
            messages.push("- Enter phone\n");
        }

        if (parseInt(noOfVehicles === "" ? "0" : noOfVehicles) === 0) {
            isValid = false;
            messages.push("- Enter number of vehicles\n");
        }

        if (country === "") {
            isValid = false;
            messages.push("- Select country\n");
        }

        if (!isValid) {
            this.setState({ loading: false });
            alert(Helper.arrayToString(messages));
            return;
        } else {
            var objFleetOwner = {
                id: this.state.fleetOwnerId,
                email: email.trim(),
                companyName: companyName.trim(),
                companyNo: companyNo,
                firstName: firstName.trim(),
                lastName: lastName.trim(),
                firstNameLower: firstName.toString().toLowerCase().trim(),
                lastNameLower: lastName.toString().toLowerCase().trim(),
                emailLower: email.toString().toLowerCase().trim(),
                companyNameLower: companyName.toString().toLowerCase().trim(),
                phone: phoneNo.trim(),
                noOfVehicles: noOfVehicles,
                addressLine1: addressLine1.trim(),
                addressLine2: addressLine2.trim(),
                city: city.trim(),
                postcode: postcode,
                country: selectedCountry.label,
                companyType: selectedCompanyType.label,
                isActive: true,
                updatedAt: new Date(),
                _version: this.state.version
            };

            await API.graphql(graphqlOperation(mutations.updateFleetOwners, { input: objFleetOwner }))
                .then(async (data) => {
                    alert("Your profile has been updated successfully");

                    await API.graphql(graphqlOperation(getFleetOwners, { id: this.state.fleetOwnerId })).then(async (result) => {
                        localStorage.setItem("fleetOwnerId", result.data.getFleetOwners.id);
                        localStorage.setItem(
                            "fleetOwnerName",
                            result.data.getFleetOwners.firstName + " " + result.data.getFleetOwners.lastName
                        );

                        var str = result.data.getFleetOwners.firstName + " " + result.data.getFleetOwners.lastName;
                        var matches = str.match(/\b(\w)/g);
                        var acronym = matches.join("");

                        localStorage.setItem("fleetOwnerAcronym", acronym);

                        let profileData = this.state.profile;
                        let countryOption = this.state.selectedCountry;
                        let companyTypeOption = this.state.selectedCompanyType;

                        profileData.CompanyName = result.data.getFleetOwners.companyName;
                        profileData.CompanyNo = result.data.getFleetOwners.companyNo;
                        profileData.Email = result.data.getFleetOwners.email;
                        profileData.FirstName = result.data.getFleetOwners.firstName;
                        profileData.LastName = result.data.getFleetOwners.lastName;
                        profileData.PhoneNo = result.data.getFleetOwners.phone;
                        profileData.NoOfVehicles = result.data.getFleetOwners.noOfVehicles;
                        profileData.Country = result.data.getFleetOwners.country;
                        profileData.TotalLicenses = result.data.getFleetOwners.totalLicenses;
                        profileData.UnassignedLicenses = result.data.getFleetOwners.unassignedLicenses;
                        profileData.AddressLine1 =
                            result.data.getFleetOwners.addressLine1 !== undefined ? result.data.getFleetOwners.addressLine1 : "";
                        profileData.AddressLine2 =
                            result.data.getFleetOwners.addressLine2 !== undefined ? result.data.getFleetOwners.addressLine2 : "";
                        profileData.City = result.data.getFleetOwners.city !== undefined ? result.data.getFleetOwners.city : "";
                        profileData.Postcode =
                            result.data.getFleetOwners.postcode !== undefined ? result.data.getFleetOwners.postcode : "";

                        countryOption.label = profileData.Country;
                        countryOption.value = profileData.Country;

                        this.setState({
                            profile: profileData,
                            selectedCountry: countryOption,
                            selectedCompanyType: companyTypeOption,
                            companyName: result.data.getFleetOwners.companyName,
                            companyNo: result.data.getFleetOwners.companyNo,
                            email: result.data.getFleetOwners.email,
                            firstName: result.data.getFleetOwners.firstName,
                            lastName: result.data.getFleetOwners.lastName,
                            phoneNo: result.data.getFleetOwners.phone,
                            noOfVehicles: result.data.getFleetOwners.noOfVehicles,
                            country: result.data.getFleetOwners.country,
                            addressLine1:
                                result.data.getFleetOwners.addressLine1 !== undefined ? result.data.getFleetOwners.addressLine1 : "",
                            addressLine2:
                                result.data.getFleetOwners.addressLine2 !== undefined ? result.data.getFleetOwners.addressLine2 : "",
                            city: result.data.getFleetOwners.city !== undefined ? result.data.getFleetOwners.city : "",
                            postcode: result.data.getFleetOwners.postcode !== undefined ? result.data.getFleetOwners.postcode : "",
                            // show: false
                        });
                    });
                })
                .catch((error) => {
                    alert(JSON.stringify(error));
                });
        }
    };

    render() {
        const {
            email,
            companyNo,
            firstName,
            lastName,
            companyName,
            phoneNo,
            profile,
            riderAlerts,
            addressLine1,
            addressLine2,
            city,
            postcode,
            noOfVehicles,
            selectedCountry,
            selectedCompanyType,
            profilePicURL,
            countryList,
            imageButtonText,
            isImageChanged,
            profilePicture,
            totalLicense
        } = this.state;
        const profileNotSet = localStorage.getItem("profileNotSet");

        return (
            <>
                <div className={styles.homepageContent}>
                    <div className={styles.topLine}>
                        <div className={styles.titlefont}>MY PROFILE</div>{" "}
                    </div>
                    <div className="content">
                        <Row>
                            <Col md="4">
                                <Card className="card-user">
                                    <div className="image" style={{ height: "90px" }}></div>
                                    <CardBody style={{ minHeight: "220px" }}>
                                        <div className="author" >
                                            <div className={styles.userContent} style={{ flexDirection: "column" }}>
                                                <div className={styles.avatar} style={{ width: "150px", height: "100px" }}>
                                                    {profilePicture === "" ? (
                                                        profileNotSet === null ? (
                                                            <div style={{ width: "32px", height: "32px" }}>
                                                                <img src={loader} alt="loading" />
                                                            </div>
                                                        ) : (
                                                            <img
                                                                src={logo}
                                                                className="avatar border-gray"
                                                                style={{ objectFit: "contain", marginTop: "20px" }}
                                                                alt="company logo"
                                                            />
                                                        )
                                                    ) : (
                                                        <img
                                                            src={profilePicture.toString().startsWith("https") ? profilePicture : profilePicURL}
                                                            alt="user logo"
                                                            style={{ objectFit: "contain", height: "90px", width: "115px" }}
                                                        />
                                                    )}
                                                </div>

                                                <div className={styles.uploadImage}>
                                                    {!isImageChanged ? (
                                                        <>
                                                            <input type="file" onChange={this.onSelectProfileImage} />
                                                            <Button type="button" className={styles.uploadBtn} color="warning">
                                                                {imageButtonText}
                                                            </Button>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div
                                                                class="d-flex align-items-center styles_formActions__g4DSD"
                                                                style={{
                                                                    fontSize: "0.9rem",
                                                                    marginTop: "10px",
                                                                }}
                                                            >
                                                                Please click on the save button to upload the image
                                                            </div>
                                                            <div
                                                                className="d-flex align-items-center justify-content-center styles_formActions__g4DSD"
                                                                style={{
                                                                    marginTop: "10px",
                                                                    marginBottom: "10px",
                                                                    marginLeft: "-60px",
                                                                }}
                                                            >
                                                                <button
                                                                    type="button"
                                                                    onClick={this.handleCancelUpload}
                                                                    class="styles_linkChange__2qENy"
                                                                    style={{
                                                                        borderRadius: "30px",
                                                                        width: "100px",
                                                                        border: "none",
                                                                        height: "30px",
                                                                        marginLeft: "13%",
                                                                        marginRight: "10px",
                                                                        marginTop: "10px",
                                                                        outline: "none",
                                                                    }}
                                                                >
                                                                    Cancel
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className={styles.saveImageBtn}
                                                                    onClick={this.handleSaveImage}
                                                                    style={{
                                                                        borderRadius: "30px",
                                                                        width: "100px",
                                                                        border: "none",
                                                                        height: "30px",
                                                                        marginRight: "0px",
                                                                        marginTop: "10px",
                                                                        outline: "none",
                                                                    }}
                                                                >
                                                                    Save
                                                                </button>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <span className="title" style={{ color: "#51cbce", fontSize: "17px" }}>
                                                {localStorage.getItem("fleetOwnerName")}
                                            </span>

                                            <div className={styles.profile_description}>
                                                <span> {profile.CompanyName}</span>
                                                <br></br>
                                                <span> {profile.Email}</span>
                                            </div>
                                        </div>
                                    </CardBody>
                                    <CardFooter>
                                        <hr />
                                        <div className="button-container">
                                            <Row>
                                                <Col className="ml-auto p-0" lg="3" md="6" xs="6">
                                                    <h5>
                                                        {profile.NoOfVehicles} <br />
                                                        <span style={{ fontSize: "12px" }}>Vehicles</span>
                                                    </h5>
                                                </Col>

                                                <Col className="ml-auto mr-auto p-0" lg="4" md="6" xs="6">
                                                    <h5>
                                                        {profile.TotalLicenses} <br />
                                                        {localStorage.getItem("companyType") === "Enterprise Customer" ?
                                                            <span style={{ fontSize: "12px" }}>License</span>
                                                            :
                                                            <span style={{ fontSize: "12px" }}>Licenses</span>
                                                        }
                                                    </h5>
                                                </Col>
                                                <Col className="mr-auto p-0" lg="3">
                                                    <h5>
                                                        {riderAlerts} <br />
                                                        <span style={{ fontSize: "12px" }}>Alerts</span>
                                                    </h5>
                                                </Col>
                                            </Row>
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Col>
                            <Col md="8">
                                <Card>
                                    <CardHeader>
                                        <h5 className="title" style={{ margin: "0px", padding: "0px" }}>
                                            <span className={styles.edittitle}>Edit Profile</span>
                                        </h5>
                                    </CardHeader>
                                    <CardBody>
                                        <Form>
                                            <Row>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <label>Company Name</label>
                                                        <Input
                                                            placeholder="Company Name"
                                                            type="text"
                                                            value={companyName}
                                                            name="companyName"
                                                            onChange={this.handleChange}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <label>Company Number</label>
                                                        <Input
                                                            placeholder="Company Number"
                                                            type="text"
                                                            value={companyNo}
                                                            name="companyNo"
                                                            onChange={this.handleChange}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                {" "}
                                                <Col md="12">
                                                    <FormGroup>
                                                        <label htmlFor="exampleInputEmail1">Email address</label>
                                                        <Input
                                                            placeholder="Email address"
                                                            type="email"
                                                            value={email}
                                                            name="email"
                                                            onChange={this.handleChange}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <label>First Name</label>
                                                        <Input
                                                            placeholder="Company"
                                                            type="text"
                                                            value={firstName}
                                                            name="firstName"
                                                            onChange={this.handleChange}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <label>Last Name</label>
                                                        <Input
                                                            placeholder="Last Name"
                                                            type="text"
                                                            value={lastName}
                                                            name="lastName"
                                                            onChange={this.handleChange}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <label>Address Line 1</label>
                                                        <Input
                                                            name="addressLine1"
                                                            value={addressLine1}
                                                            onChange={this.handleChange}
                                                            placeholder="Address Line 1"
                                                            type="text"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <label>Address Line 2</label>
                                                        <Input
                                                            name="addressLine2"
                                                            value={addressLine2}
                                                            onChange={this.handleChange}
                                                            placeholder="Address Line 2"
                                                            type="text"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <label>City/Town</label>
                                                        <Input
                                                            placeholder="City/Town"
                                                            type="text"
                                                            name="city"
                                                            value={city}
                                                            onChange={this.handleChange}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <label>Zip Code/Postcode</label>
                                                        <Input
                                                            placeholder="Zip Code/Postcode"
                                                            type="text"
                                                            name="postcode"
                                                            value={postcode}
                                                            onChange={this.handleChange}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <label>Phone</label>
                                                        <Input
                                                            placeholder="Phone"
                                                            type="number"
                                                            value={phoneNo}
                                                            name="phoneNo"
                                                            onChange={this.handlePhoneChange}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <label>No of vehicles</label>
                                                        <Input
                                                            placeholder="No of vehicles"
                                                            type="text"
                                                            name="noOfVehicles"
                                                            value={noOfVehicles}
                                                            onChange={this.handleNoOfVehicleChange}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <label>Country</label>

                                                        <Select
                                                            options={countryList}
                                                            type="select"
                                                            defaultValue={selectedCountry}
                                                            value={selectedCountry}
                                                            placeholder="Country"
                                                            name="country"
                                                            onChange={this.handleCountryChange}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <label>Company Type</label>

                                                        <Select
                                                            type="select"
                                                            defaultValue={selectedCompanyType}
                                                            value={selectedCompanyType}
                                                            placeholder="CompanyType"
                                                            name="companyType"
                                                            isDisabled={true}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row style={{ textAlign: "end" }}>
                                                <Col classname="pl-1">
                                                    <Button color="warning" className="btn-round" onClick={this.handleUpdateProfile}>
                                                        Save
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        );
    }
}
