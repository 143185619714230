import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import Truck from "../../../assests/images/truck.png";
import Car from "../../../assests/images/car.png";
import styles from "../../homepage/styles.module.scss";
import PubNub from 'pubnub';
import classNames from "classnames";
import {
    Card,
    CardBody,
} from "reactstrap";
import { API } from "aws-amplify";
import Moment from 'moment'
let latitude;
let longitude;
let appThis;
let locationList = [];
let pubnub = null
let index = 0;

async function getSecureKeys() {
    let apiName = "busbySecureKeys";
    let path = "/getValues";

    let parameters = {};
    return await API.get(apiName, path, parameters);
}
async function getPubnubSecureKeys() {
    let apiName = "busbyPubnubSecureKeys";
    let path = "/getValues";

    let parameters = {};
    return await API.get(apiName, path, parameters);
}

class FleetMap extends Component {
    constructor(props) {
        super(props);
        appThis = this;
        latitude = localStorage.getItem('latitude')
        longitude = localStorage.getItem('longitude')
        this.state = {
            zoom: 11,
            fleetUsers: [],
            isLocationAdd: false,
            googleAPIKey: '',
        }
    }
    componentDidMount = async () => {
        var apiResponse = await getSecureKeys();
        let _this = this;

        if (apiResponse !== null) {
           /* console.log(apiResponse);
            console.log(getPubnubSecureKeys);*/
            let pubnubApiResponse = await getPubnubSecureKeys();

            if (pubnubApiResponse !== null) {
                if (pubnub === null) {
                    pubnub = new PubNub({
                        publishKey: pubnubApiResponse.response.pubnubPublishKey,
                        subscribeKey: pubnubApiResponse.response.pubnubSubscribeKey,
                        uuid: pubnubApiResponse.response.pubnubUuid,
                        secretKey: pubnubApiResponse.response.pubnubSecretKey
                    });
                }
            }
            this.setState({
                googleAPIKey: apiResponse.response.googleApiKey,
            }, function () {
                _this.getGeoHashData();
            });
        }
        else
            console.log("no response");
    }

    getGeoHashData = () => {
        let dt = Moment();
        let time = new Date(dt).getTime();
        let fleetOwnerId = localStorage.getItem('fleetOwnerId')
        //console.log(latitude)
        if (latitude === undefined || longitude === undefined) {
            return;
        }
        pubnub.addListener({     
            signal: function (msg) {
                appThis.processData(msg.message,msg.publisher);
            },
            presence: function (event) {
                console.log("user remove")
            }
        })
        pubnub.subscribe({ channels: [fleetOwnerId] });
    
    }
    processData = (message, publisher) => {
        message = {
            ...message,
            deviceId: publisher
        }
        let locationIndex = -1;
        if (message.state !== undefined && message.state === "logout") {
            if (locationList.length > 0) {
                locationList = locationList.filter(item => item.deviceId !== message.deviceId);
            }
            this.setState({ isLocationAdd: !this.state.isLocationAdd });
            return;
        }
        if (locationList.length > 0) {
            locationIndex = locationList.findIndex(item => item.deviceId === message.deviceId);
        }
        if (locationIndex === -1) {
            locationList.push(message);
            this.setState({ isLocationAdd: !this.state.isLocationAdd })
        } else {
            locationList[locationIndex] = message;
            this.setState({ isLocationAdd: !this.state.isLocationAdd })
        }
    }

    render() {
        const { googleAPIKey } = this.state;
        return (
            <><div className={classNames(styles.homepageContent)} style={{ marginTop: "0px" }}>
                <Card>
                    <CardBody >
                        <div style={{ height: '87vh', width: '100%' }}>
                            {this.state.googleAPIKey ?
                                <GoogleMapReact
                                    ref={(ref) => {
                                        this.map = ref;
                                    }}
                                    bootstrapURLKeys={{ key: googleAPIKey }}
                                    defaultCenter={{ lat: parseFloat(latitude), lng: parseFloat(longitude) }}
                                    defaultZoom={this.state.zoom}
                                >
                                    {locationList.map((item, index) => {
                                        return (
                                            <div lat={item.lat}
                                                lng={item.lng} className={classNames(styles.customMarker)}>
                                                <img
                                                    src={item.vt !== undefined || item.vt === 'c' ? Truck : Truck} alt="avatar"
                                                    key={index}
                                                    lat={item.lat}
                                                    lng={item.lng}
                                                >
                                                </img>
                                            </div>
                                        );
                                    })}
                                </GoogleMapReact>
                                : null}
                        </div>
                    </CardBody>
                </Card>
            </div></>
        );
    }
}

export default FleetMap;