import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import classNames from 'classnames';
// import styles from '../styles.module.scss';
import loader from '../../../assests/images/loading_dots.gif';
import logo from '../../../assests/images/logo.png';
import Helper from '../../../services/helper';
import QRCode from 'qrcode.react';
import { API, graphqlOperation, Auth, Storage } from 'aws-amplify';
import AWS from 'aws-sdk';
import { getFleetOwnersByEmail } from '../../../graphql/queries';
import * as mutations from '../../../graphql/mutations';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Col,
    Row,
} from "reactstrap";
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import Geocode from "react-geocode";
var randomstring = require("randomstring");

async function addUserToGroup(params) {
    let apiName = "busbyUserManagement";
    let path = "/user/addUserToGroup";

    let parameters = {
        body: params
    };

    return await API.post(apiName, path, parameters);
}

class VerifyAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isVerified: true,
            isAlreadyVerfied: false,

            firstName: (this.props.location.state) ? this.props.location.state.firstName : "",
            lastName: (this.props.location.state) ? this.props.location.state.lastName : "",
            userName: (this.props.location.state) ? this.props.location.state.userName : "",
            companyName: (this.props.location.state) ? this.props.location.state.companyName : "",
            companyType: (this.props.location.state) ? this.props.location.state.companyType : "",
            email: (this.props.location.state) ? this.props.location.state.email : "",
            password: (this.props.location.state) ? this.props.location.state.password : "",
            addressLine1: (this.props.location.state) ? this.props.location.state.addressLine1 : "",
            addressLine2: (this.props.location.state) ? this.props.location.state.addressLine2 : "",
            city: (this.props.location.state) ? this.props.location.state.city : "",
            postcode: (this.props.location.state) ? this.props.location.state.postcode : "",
            country: (this.props.location.state) ? this.props.location.state.country : "",
            phone: (this.props.location.state) ? this.props.location.state.phone : "",
            noOfVehicles: (this.props.location.state) ? this.props.location.state.noOfVehicles : "",
            verificationCode: '',
            latitude: 0,
            longitude: 0,
            roadRadarAppVersion: '',
            inProgress: false,
            code: '',
             loading: false,
        }
    }

    componentDidMount = async () => {
        this.loadConfig();

        if (navigator.geolocation) {
            await navigator.geolocation.getCurrentPosition(this.getCoordinates, this.errorHandler);
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
        var secureKeys = await Helper.getSecureKeys();
        Geocode.setApiKey(secureKeys.response.googleApiKey);
        Geocode.setLanguage("en");
        document.body.classList.toggle("login-page");

    }

    componentWillUnmount = () => {
        document.body.classList.toggle("login-page");
    }

    loadConfig = () => {
        fetch(`config.json`)
            .then((r) => r.json())
            .then((data) => {
                this.setState({
                    roadRadarAppVersion: data.roadRadarAppVersion
                });
            });
    }

    getCoordinates = (position) => {
        this.setState({ latitude: position.coords.latitude, longitude: position.coords.longitude });
    }

    errorHandler = (err) => {
        if (err.code === 1) {
            console.log("Error: Access is denied!");
        } else if (err.code === 2) {
            console.log("Error: Position is unavailable!");
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        });
    }

    handleCompleteRegistration = async () => {
        const { userName, email, verificationCode } = this.state;
        this.setState({ loading: true });
        let _this = this;
        let isValid = true;
        let isFleetOwnerExists = false;

        const messages = [];

        if (userName === "") {
            isValid = false;
            messages.push("- Something went wrong. Please try again later\n");
        }

        if (verificationCode === "") {
            isValid = false;
            messages.push("- Please enter your code\n");
        }

        if (!isValid) {
            this.setState({ loading: false });
            alert(Helper.arrayToString(messages));
            return;
        }

        await API.graphql(graphqlOperation(getFleetOwnersByEmail, { email: email })).then(async result => {

            if (result.data.getFleetOwnersByEmail.items.length > 0) {
                isFleetOwnerExists = result.data.getFleetOwnersByEmail.items[0].isActive && !result.data.getFleetOwnersByEmail.items[0].isDeleted;
            }
        });

        if (isFleetOwnerExists) {
            alert("An account has already been setup with this email. Login instead");
        }
        else {
            await Auth.confirmSignUp(userName, verificationCode, { forceAliasCreation: true }).then(async data => {

                var apiParams = {
                    userName: email.trim().toLowerCase(),
                    cognitoGroupName: "RoadRadarEnterpriseUsers"
                };

                var apiResponse = await addUserToGroup(apiParams);

                if (apiResponse !== null) {
                    if (apiResponse.success) {
                        _this.setState({ inProgress: true });
                        document.getElementById("btnSignIn").click();
                    } else {
                        _this.setState({ inProgress: false });
                        console.log(JSON.stringify(apiResponse.error));
                        alert("Something went wrong. Please try again later");
                    }
                } else {
                    _this.setState({ inProgress: false });
                    alert("Something went wrong. Please try again later");
                }
            }).catch(err => {
                console.log(JSON.stringify(err));
                if (err.code === "CodeMismatchException") {
                    alert("Invalid code! Please try again or click on resend to get a new code");
                }
                else if (err.code === "UserNotFoundException") {
                    alert("User does not exist");
                }
                else
                    alert("Something went wrong. Please try again later");
            });
        }
    }

    handleSignIn = async () => {
        const { companyName, companyType, firstName, lastName, userName, email, password, latitude, longitude, addressLine1, addressLine2, city, postcode, country, phone, noOfVehicles, roadRadarAppVersion } = this.state;

        await Auth.signIn(email, password).then(async result => {

            const credentials = await Auth.currentCredentials();
            let user = await Auth.currentAuthenticatedUser();

            await Auth.updateUserAttributes(user, {
                "custom:userType": "roadradarenterpriseuser",
                "custom:companyName": companyName
            });

            var objFleetOwner = {
                id: credentials.identityId,
                firstName: firstName.trim(),
                firstNameLower: firstName.trim().toLowerCase(),
                lastName: lastName.trim(),
                lastNameLower: lastName.trim().toLowerCase(),
                userName: userName.trim(),
                userNameLower: userName.trim().toLowerCase(),
                email: email.trim(),
                emailLower: email.trim().toLowerCase(),
                userName: userName.trim(),
                userNameLower: userName.trim().toLowerCase(),
                companyName: companyName.trim(),
                companyNameLower: companyName.trim().toLowerCase(),
                companyType: companyType.trim(),
                companyNo: '-',
                addressLine1: addressLine1.trim(),
                addressLine2: addressLine2.trim(),
                city: city.trim(),
                postcode: postcode.trim(),
                country: country,
                phone: phone.trim(),
                noOfVehicles: parseInt(noOfVehicles),
                profilePicture: '',
                totalLicenses: 0,
                unassignedLicenses: 0,
                isVerified: true,
                isActive: true,
                isDeleted: false,
                deletedFleetOwner: 'false',
                pdfFilePath: ' ',
                createdAt: new Date(),
                updatedAt: new Date()
            }

            await API.graphql(graphqlOperation(mutations.createFleetOwners, { input: objFleetOwner })).then(async data => {

                await this.generateQRForFleetOwner(objFleetOwner.id);

                alert("Your account has been created successfully");
                localStorage.setItem("fleetOwnerId", data.data.createFleetOwners.id);
                localStorage.setItem(
                    "fleetOwnerName",
                    data.data.createFleetOwners.firstName + " " + data.data.createFleetOwners.lastName
                );

                let { addressLine1, addressLine2, city, country } = data.data.createFleetOwners;
                var fullAddress = (addressLine1.trim() !== "" ? addressLine1.trim() + "," : '') + (addressLine2.trim() !== "" ? addressLine2.trim() + "," : '')
                    + (city !== "" ? "," + city.trim() : '') + (country !== "" ? "," + country : '');
                Geocode.fromAddress(fullAddress).then(
                    (response) => {
                        console.log("responce", response);
                        const { lat, lng } = response.results[0].geometry.location;
                        console.log(lat, lng);
                        localStorage.setItem("latitude", lat);
                        localStorage.setItem("longitude", lng);
                    },
                    (error) => {
                        console.error("geo rrrr", error);
                        navigator.geolocation.getCurrentPosition(function (position) {
                            localStorage.setItem("Latitude is :", position.coords.latitude);
                            localStorage.setItem("Longitude is :", position.coords.longitude);
                        });
                    }
                );
                localStorage.setItem("fleetOwnerId", objFleetOwner.id);
                localStorage.setItem("fleetOwnerName", objFleetOwner.firstName + " " + objFleetOwner.lastName);

                var str = objFleetOwner.firstName + " " + objFleetOwner.lastName;
                var matches = str.match(/\b(\w)/g);
                var acronym = matches.join('');

                localStorage.setItem("fleetOwnerAcronym", acronym);

                this.props.history.push({
                    pathname: '/dashboard',
                    state: {
                        fleetOwnerId: objFleetOwner.id
                    }
                });

            }).catch(error => {
                console.log("Error while creating Fleet Owner: " + error);
            });
        }).catch(error => {
            console.log("Error while authenticating Fleet Owner: " + error);
        });
    }

    generateQRForFleetOwner = async (fleetOwnerId) => {
        let vCode = randomstring.generate({
            length: 10,
            charset: 'alphanumeric'
        });

        this.setState({ code: vCode });

        const qrCodeCanvas = document.querySelector('canvas');
        const qrCodeDataUri = qrCodeCanvas.toDataURL('image/png', 0.3);

        var qrFile = this.dataURItoFile(qrCodeDataUri, 'image/png');
        var qrImageName = vCode.toUpperCase() + ".png";

        await Storage.put(qrImageName, qrFile, {
            contentType: "image/png",
        }).then((data) => {
            console.log("Qr Image : " + JSON.stringify(data.key));
        }).catch(err => console.log("Err : " + JSON.stringify(err)));
        let objLicense;
        {
            (this.state.companyType === "Standard Customer") ?
                objLicense = {
                    fleetOwnerId: fleetOwnerId,
                    fleetUserId: ' ',
                    fleetOwnerPurchaseId: 'Fleet Owner License',
                    code: vCode.trim().toUpperCase(),
                    price: 0,
                    qrImage: qrImageName,
                    isAssigned: true,
                    isActive: true,
                    isDeleted: false,
                    assignedLicense: 'true',
                    deletedLicense: 'false',
                    createdAt: new Date(),
                    expiryDate: this.state.companyType === "Standard Customer" ? new Date(new Date().setMonth(new Date().getMonth() + 1)) : '',
                } :
                objLicense = {
                    fleetOwnerId: fleetOwnerId,
                    fleetUserId: ' ',
                    fleetOwnerPurchaseId: 'Fleet Owner License',
                    code: vCode.trim().toUpperCase(),
                    price: 0,
                    qrImage: qrImageName,
                    isAssigned: true,
                    isActive: true,
                    isDeleted: false,
                    assignedLicense: 'true',
                    deletedLicense: 'false',
                    createdAt: new Date(),

                }
        }
        await API.graphql(graphqlOperation(mutations.createLicenses, { input: objLicense })).then(async result => {

            var objFleetOwnerLicense = {
                id: fleetOwnerId,
                fleetOwnersLicensesId: result.data.createLicenses.id,
                _version: 1
            }

            await API.graphql(graphqlOperation(mutations.updateFleetOwners, { input: objFleetOwnerLicense })).then(async data => {
            });
        }).catch(error => {
            console.log("Error creating license: " + error);
        });
    }

    dataURItoFile(dataURI, contentType) {
        var binary = atob(dataURI.split(',')[1]);
        var array = [];

        for (var i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        return new File([new Uint8Array(array)], {
            type: contentType
        });
    }

    setUpFleetUserAccount = async (fleetUser) => {

        await API.graphql(graphqlOperation(mutations.createFleetUsers, { input: fleetUser })).then(async data => {
            this.setState({ inProgress: false });
            this.props.history.push({
                pathname: '/dashboard',
                state: {
                    token: "1234"
                }
            });

        }).catch(error => {
            console.log(JSON.stringify(error));
        });
    }

    render() {
        const { email, verificationCode, inProgress, code, loading } = this.state;
        return (
            <div>
                <AuthNavbar />
                <div className="wrapper wrapper-full-page">
                    <div className="full-page section-image">
                        <div className="login-page">
                            <Container>
                                {
                                    code !== '' && code !== null ?
                                        <div style={{ display: "none" }}>
                                            <QRCode value={code} margin="true" size="300" />
                                        </div>
                                        : null
                                }
                                <Row>
                                    <Col className="ml-auto mr-auto" lg="4" md="6">
                                        <Form action="" className="form" method="">
                                            <Card className="card-login">
                                                <CardHeader>
                                                    <CardHeader>
                                                        <h3 className="header text-center mb-0">Verify Account</h3>
                                                        <p className="header text-center">A code has been sent to your email address {email}. <br />
                                                            Please enter your code below.</p>
                                                    </CardHeader>
                                                </CardHeader>
                                                <CardBody>
                                                    <InputGroup>
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                                <i className="nc-icon nc-single-02" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>

                                                        <Input type="text" id="verificationCode" name="verificationCode" placeholder="Your Code" value={verificationCode} onChange={this.handleChange} />
                                                    </InputGroup>
                                                </CardBody>
                                                <CardFooter>
                                                    <Button
                                                        block
                                                        className="btn-round mb-3"
                                                        color="warning"
                                                        onClick={this.handleCompleteRegistration}
                                                    >
                                                        {loading ? (
                                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        ) : (
                                                            "Complete Registration"
                                                        )}
                                                        
                                                    </Button>
                                                    <Button id="btnSignIn" onClick={this.handleSignIn} style={{ display: "none" }}>
                                                    </Button>
                                                </CardFooter>
                                            </Card>
                                        </Form>
                                    </Col>
                                </Row>
                                <Modal show={inProgress}>
                                    <Modal.Body className="modalBody">
                                        <div className="row">
                                            <div className="col-md-12" style={{ textAlign: "center" }}>
                                                <label>Please wait...</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12" style={{ textAlign: "center" }}>
                                                <img src={loader} alt="loading" style={{ width: "50px", height: "50px" }} />
                                            </div>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            </Container>
                            <div
                                className="full-page-background"
                                style={{
                                    backgroundImage: `url(${require("../../../assests/images/signup-banner.png")})`,
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
        // return (
        //     <>
        //         {
        //             code !== '' && code !== null ?
        //                 <div style={{ display: "none" }}>
        //                     <QRCode value={code} margin="true" size="300" />
        //                 </div>
        //                 : null
        //         }
        //         <div className={styles.authContent}>
        //             <div className={styles.background} />
        //             <div className={classNames(styles.loginPage, styles.loginForm)}>
        //                 <div className={styles.logo}>
        //                     <img src={logo} alt="logo" />
        //                 </div>
        //                 <div className={styles.form}>
        //                     <p className={styles.infoLeft}>
        //                         A code has been sent to your email address {email}. <br />
        //                     Please enter your code below.
        //                 </p>
        //                     <div>
        //                         <div className={styles.parentTwoChild}>
        //                             <div className={classNames('form-group', styles.formGroup)}>
        //                                 <input type="text" className={classNames('form-control', styles.formControl)} id="verificationCode" name="verificationCode" placeholder="Your Code" value={verificationCode} onChange={this.handleChange} />
        //                             </div>
        //                             <div className={classNames('form-group', styles.formGroup)}>
        //                                 <NavLink to="#" onClick={this.handleCompleteRegistration} className={classNames('btn', styles.regBtn)}>Complete Registration</NavLink>
        //                                 <button id="btnSignIn" onClick={this.handleSignIn} style={{ display: "none" }}>
        //                                 </button>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //                 <Modal show={inProgress}>
        //                     <Modal.Body className="modalBody">
        //                         <div className="row">
        //                             <div className="col-md-12" style={{ textAlign: "center" }}>
        //                                 <label>Please wait...</label>
        //                             </div>
        //                         </div>
        //                         <div className="row">
        //                             <div className="col-md-12" style={{ textAlign: "center" }}>
        //                                 <img src={loader} alt="loading" style={{ width: "50px", height: "50px" }} />
        //                             </div>
        //                         </div>
        //                     </Modal.Body>
        //                 </Modal>
        //             </div>
        //         </div>
        //     </>
        // );
    }
}

export default VerifyAccount;
