import React, { Component } from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import styles from "../styles.module.scss";
import logo from "../../../assests/images/logo.png";
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import { withRouter } from "react-router-dom";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
} from "reactstrap";
class PasswordChanged extends Component {
  render() {
    return (
      <div>
        <AuthNavbar />
        <div className="wrapper wrapper-full-page">
          <div className="full-page section-image">
            <div className="login-page">
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto" lg="4" md="6">
                    <Form action="" className="form" method="">
                      <Card className="card-login">
                        <CardHeader>
                          <CardHeader>
                            <h3 className="header text-center mb-3"> Password Changed </h3>
                            <p className="header text-center"> Your password had been changed successfully.</p>
                          </CardHeader>
                        </CardHeader>

                        <CardFooter>
                          <Button
                            block
                            className="btn-round mb-3"
                            color="warning"
                            href="#pablo"
                            onClick={() => {
                              this.props.history.push("/");
                            }}
                          >
                            Login Now
                          </Button>
                        </CardFooter>
                      </Card>
                    </Form>
                  </Col>
                </Row>
              </Container>
              <div
                className="full-page-background"
                style={{
                  backgroundImage: `url(${require("../../../assests/images/signup-banner.png")})`,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(PasswordChanged);

// <div className={styles.authContent}>
// <div className={styles.background} />
// <div className={styles.loginForm}>
//   <div className={styles.logo}>
//     <img src={logo} alt="logo" width="450px" />
//   </div>
//   <div className={styles.form}>
//     <p className={styles.infoLeft}>Your password had been changed successfully.</p>
//     <div>
//       <div className={classNames("form-group", styles.formGroup)}>
//         <NavLink to="/" className={classNames("btn", styles.otherBtn)}>
//           Login Now
//         </NavLink>
//       </div>
//     </div>
//   </div>
// </div>
// </div>
