import React, { Component } from "react";
// import { Modal } from "react-bootstrap";

import classNames from "classnames";
import "../homepage/index.scss";
import styles from "../homepage/styles.module.scss";
import paginationStyles from "../../components/pagination/styles.module.scss";
import upArrow from "../../assests/images/arrow-up.png";
import downArrow from "../../assests/images/arrow-down.png";
import loader from "../../assests/images/loading_dots.gif";
import search from "../../assests/images/search.svg";
import arrowLeft from "../../assests/images/arrow-left.png";
import arrowRight from "../../assests/images/arrow-right.png";
import Helper from "../../services/helper";
import QRCode from "qrcode.react";
import html2pdf from "html2pdf.js";
import { jsPDF } from "jspdf";
import logo from "../../assests/images/logo.png";
import ReactTable from "../homepage/ReactTable";
import { Amplify, PubSub, API, Auth, graphqlOperation, Storage } from "aws-amplify";
import AWS from "aws-sdk";
import { Button, Card, CardHeader, CardTitle, Row, Col, Input, Modal } from "reactstrap";
import BuyLicensesPopBox from "../homepage/BuyLicensesPopBox";
import {
    getLicenseByFleetOwnerId,
    getLicensesSortedByCode,
    getLicensesSortedByAssignedLicense,
    getLicensesSortedByCreatedAt,
} from "../../graphql/queries";

var licenseData = [];
var lstCodes = [];

class RoadRadarManageLicenses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileName:
                "Licenses_" + new Date().toLocaleDateString().split(" ").join("") + "-" + new Date().getTime().toString(),
            code: "",
            // checkoutId: this.props.match.params["cid"] === undefined ? "-" : this.props.match.params["cid"],
            pdfFileName:
                localStorage.getItem("fleetOwnerId") +
                "-" +
                new Date().getDate().toString().replace(" ", "-") +
                "-" +
                new Date().getTime().toString() +
                ".pdf",

            licenseCodes: [],
            fleetOwnerId:
                localStorage.getItem("fleetOwnerId") === null
                    ? this.props.location.state
                        ? this.props.location.state.fleetOwnerId
                        : ""
                    : localStorage.getItem("fleetOwnerId"),
            licenses: [],
            // fleetOwnerId: localStorage.getItem("fleetOwnerId"),
            loading: true,
            searchText: "",
            purchaseDateSortOrder: "DESC",
            statusSortOrder: "ASC",
            codeSortOrder: "ASC",
            nextToken: undefined,
            currentPageNo: 1,
            showCurrentPageNo: 1,
            pageSize: 30,
            hasMoreData: true,
            isLoading: true,
            nextNextToken: null,
            previousTokens: [],
            hasPrev: false,
            hasNext: true,
            licenseCost: 0,
            profilePicURL: logo,
            isSortedByPurchaseDate: false,
            isSortedByCode: false,
            isSortedByStatus: false,

            inProgress: false,
            noOfLicense: "",
            perLicenseRate: 0,
            errorMessage: "",
            paymentDescription: "",

            paymentInProgress: false,
            VATRate: 0,
            VATValue: 0,
            totalValue: 0,
            currency: "",
            StripeDomesticRate: 0,
            StripeInternationalRate: 0,
            StripeAdditionalCharge: 0,
            StripeSecretKey: "",
            selectedCountry: { value: "", label: "" },
            selectedNoOfVehicle: { value: "", label: "" },
            id: "",
            modalNotice: false,
            codeLic: "",
            modalClassic: false,
            codeImg: "",
            qrCodeDataUri: "",
            companyType: localStorage.getItem("companyType"),
            showKey: false,
            keyBtn: false,
            trialLicense: [{
                code: "",
                qrImage: "",
            }]
        };

        this.handleDownloadPDF = this.handleDownloadPDF.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount = async () => {
        this.getLicense();
    };
    componentDidUpdate() {
        let _this = this;

        if (this.state.errorMessage.trim() !== "") {
            setTimeout(function () {
                _this.setState({ errorMessage: "" });
            }, 5000);
        }
    }

    toggleModalNotice = (value, imgvalue) => {
        this.setState({
            modalNotice: true,
            codeLic: value,
            codeImg: imgvalue,
        });

        if (value !== "" && value !== null) {
            this.setState({ code: value }, function () {
                const qrCodeCanvas = document.getElementById(value);
                const qrCodeDataUri = qrCodeCanvas.toDataURL("image/png", 0.3);
                this.setState({ qrCodeDataUri: qrCodeDataUri });
            });
        }
    };

    toggleCloseModalNotice = () => {
        this.setState({ modalNotice: false });
    };

    handleCloseProgressPopup = (e) => {
        this.setState({
            inProgress: false,
        });
    };
    handleShowLicencePopup = (e) => {
        this.setState({
            showLicencePopup: true,
            noOfLicense: "",
            licenseCost: 0,
            VATValue: 0,
            totalValue: 0,
        });
    };

    handleSearch = async () => {
        this.setState(
            {
                pageSize: 10000,
                nextToken: undefined,
                hasNext: false,
                hasPrev: false,
                nextNextToken: null,
                previousTokens: [],
            },
            async function () {
                var variables = {
                    fleetOwnerId: this.state.fleetOwnerId,
                    nextToken: this.state.nextToken,
                    filter: {
                        and: [
                            { isDeleted: { eq: false }, deletedLicense: { eq: "false" } },
                            {
                                or: [
                                    { code: { contains: this.state.searchText.trim().toUpperCase() } },
                                    { assignedLicense: { contains: this.state.searchText.trim().toLowerCase() } },
                                ],
                            },
                        ],
                    },
                    limit: this.state.pageSize,
                    sortDirection: "DESC",
                };

                licenseData = await API.graphql(graphqlOperation(getLicenseByFleetOwnerId, variables));

                this.setState({ nextNextToken: licenseData.data.getLicenseByFleetOwnerId.nextToken });

                let objLicenses = licenseData.data.getLicenseByFleetOwnerId.items;

                lstCodes = [];

                for (var i = 0; i < objLicenses.length; i++) {
                    lstCodes.push(objLicenses[i].code);
                }

                this.setState({
                    licenseCodes: lstCodes,
                    licenses: objLicenses,
                    loading: false,
                    hasNext: licenseData.data.getLicenseByFleetOwnerId.nextToken !== null,
                    isLoading: false,
                });
            }
        );
    };

    handleCloseLicencePopup = (e) => {
        this.setState({
            showLicencePopup: false,
        });
    };

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState(
            {
                [name]: value,
            },
            function () {
                if (name === "searchText") {
                    this.handleSearch();
                }
            }
        );
    };

    onPressEnter = (e) => {
        if (e.key === "Enter") this.handleSearch();
    };

    handleDownloadPDF = async () => {
        const key = this.state.codeLic;
        if (key !== "" && key !== null) {
            this.setState({ code: key }, function () {
                var fileName = key + ".pdf";
                const doc = new jsPDF();

                doc.rect(45, 45, 125, 130);
                doc.text(key, 90, 165);

                const qrCodeCanvas = document.getElementById(key);
                const qrCodeDataUri = qrCodeCanvas.toDataURL("image/png", 0.3);

                doc.addImage(qrCodeDataUri, 60, 60, 95, 95);
                doc.save(fileName);
            });
        }
    };

    getLicense = async () => {
        var company = this.state.companyType;
        var variables = {
            fleetOwnerId: this.state.fleetOwnerId,
            nextToken: this.state.nextToken,
            filter: {
                isDeleted: { eq: false },
                deletedLicense: { eq: "false" },
            },
            limit: this.state.pageSize,
            sortDirection: "DESC",
        };

        licenseData = await API.graphql(graphqlOperation(getLicenseByFleetOwnerId, variables, this.state.companyType));

        this.setState({ nextNextToken: licenseData.data.getLicenseByFleetOwnerId.nextToken });
        let objLicenses
        if (company === "Standard Customer") {
            objLicenses = licenseData.data.getLicenseByFleetOwnerId.items
                .filter(
                    (l) => l.fleetOwnerPurchaseId !== "Fleet Owner License"
                );
        }
        else {
            objLicenses = licenseData.data.getLicenseByFleetOwnerId.items
                .filter(
                    (l) => l.fleetOwnerPurchaseId === "Fleet Owner License"
                );
        }

        lstCodes = [];
        for (var i = 0; i < objLicenses.length; i++) {
            lstCodes.push(objLicenses[i].code);
        }
        var variableTrial = {
            fleetOwnerId: this.state.fleetOwnerId,
            filter: {
                fleetOwnerPurchaseId: { eq: "Fleet Owner License" },
            },

        };
        let trialCode;
        let trialImage;

        await API.graphql(graphqlOperation(getLicenseByFleetOwnerId, variableTrial)).then(async (result1) => {
            trialCode = result1.data.getLicenseByFleetOwnerId.items[0].code;
            trialImage = result1.data.getLicenseByFleetOwnerId.items[0].qrImage;

        });
        let trial = this.state.trialLicense;

        this.setState({
            trialLicense: [{
                code: trialCode,
                qrImage:trialImage
            }],
            licenseCodes: lstCodes,
            licenses: objLicenses,
            loading: false,
            hasNext: licenseData.data.getLicenseByFleetOwnerId.nextToken !== null,
            isLoading: false,
            showCurrentPageNo: this.state.currentPageNo,
            inProgress: false,
        });
    };

    setNextToken = (tToken) => {
        this.setState({ nextToken: tToken });
    };

    setNextNextToken = (nextToken) => {
        this.setState({ nextNextToken: nextToken }, function () {
            if (this.state.isSortedByPurchaseDate) this.sortByPurchasedAt("");
            else if (this.state.isSortedByCode) this.sortByCode("");
            else if (this.state.isSortedByStatus) this.sortByStatus("");
            else this.getLicense();
        });
    };

    setPreviousTokens = (pToken) => {
        var arPToken = this.state.previousTokens;

        arPToken.push(pToken);

        this.setState({ previousTokens: arPToken, hasPrev: arPToken.length });
    };

    setPreviousTokensArray = (pToken) => {
        this.setState({ previousTokens: pToken, hasPrev: pToken.length });
    };

    next = () => {
        this.setState({ currentPageNo: this.state.currentPageNo + 1, inProgress: true }, function () {
            this.setPreviousTokens(this.state.nextToken);
            this.setNextToken(this.state.nextNextToken);
            this.setNextNextToken(null);
        });
    };

    prev = () => {
        this.setState({ currentPageNo: this.state.currentPageNo - 1, inProgress: true }, function () {
            this.setNextToken(this.state.previousTokens.pop());
            this.setPreviousTokensArray(this.state.previousTokens);
            this.setNextNextToken(null);
        });
    };

    setSortIcon = (sortDirection) => {
        var arrow = "";

        if (sortDirection === "ASC") {
            arrow = upArrow;
        } else arrow = downArrow;

        return <img src={arrow} alt="downArrow" />;
    };

    sortByPurchasedAt = async (key) => {
        var sortOrder = key;

        if (key === "") {
            sortOrder = this.state.purchaseDateSortOrder;
        } else {
            if (sortOrder === "ASC") sortOrder = "DESC";
            else sortOrder = "ASC";
        }

        this.setState({
            isSortedByPurchaseDate: true,
            isSortedByCode: false,
            isSortedByStatus: false,
            purchaseDateSortOrder: sortOrder,
        });

        var variables = {
            nextToken: this.state.nextToken,
            filter: {
                isDeleted: { eq: false },
                fleetOwnerId: { eq: this.state.fleetOwnerId },
            },
            limit: this.state.pageSize,
            deletedLicense: "false",
            sortDirection: sortOrder,
        };
        licenseData = await API.graphql(graphqlOperation(getLicensesSortedByCreatedAt, variables));

        this.setState({ nextNextToken: licenseData.data.getLicensesSortedByCreatedAt.nextToken });

        let objLicenses = licenseData.data.getLicensesSortedByCreatedAt.items;

        lstCodes = [];

        for (var i = 0; i < objLicenses.length; i++) {
            lstCodes.push(objLicenses[i].code);
        }

        this.setState({
            licenseCodes: lstCodes,
            licenses: objLicenses,
            hasNext: licenseData.data.getLicensesSortedByCreatedAt.nextToken !== null,
            isLoading: false,
            showCurrentPageNo: this.state.currentPageNo,
            inProgress: false,
        });
    };

    setNextToken = (tToken) => {
        this.setState({ nextToken: tToken });
    };

    setNextNextToken = (nextToken) => {
        this.setState({ nextNextToken: nextToken }, function () {
            if (this.state.isSortedByPurchaseDate) this.sortByPurchasedAt("");
            else if (this.state.isSortedByCode) this.sortByCode("");
            else if (this.state.isSortedByStatus) this.sortByStatus("");
            else this.getLicense();
        });
    };

    setPreviousTokens = (pToken) => {
        var arPToken = this.state.previousTokens;

        arPToken.push(pToken);

        this.setState({ previousTokens: arPToken, hasPrev: arPToken.length });
    };

    setPreviousTokensArray = (pToken) => {
        this.setState({ previousTokens: pToken, hasPrev: pToken.length });
    };

    next = () => {
        this.setState({ currentPageNo: this.state.currentPageNo + 1, inProgress: true }, function () {
            this.setPreviousTokens(this.state.nextToken);
            this.setNextToken(this.state.nextNextToken);
            this.setNextNextToken(null);
        });
    };

    prev = () => {
        this.setState({ currentPageNo: this.state.currentPageNo - 1, inProgress: true }, function () {
            this.setNextToken(this.state.previousTokens.pop());
            this.setPreviousTokensArray(this.state.previousTokens);
            this.setNextNextToken(null);
        });
    };

    setSortIcon = (sortDirection) => {
        var arrow = "";

        if (sortDirection === "ASC") {
            arrow = upArrow;
        } else arrow = downArrow;

        return <img src={arrow} alt="downArrow" />;
    };

    sortByPurchasedAt = async (key) => {
        var sortOrder = key;

        if (key === "") {
            sortOrder = this.state.purchaseDateSortOrder;
        } else {
            if (sortOrder === "ASC") sortOrder = "DESC";
            else sortOrder = "ASC";
        }

        this.setState({
            isSortedByPurchaseDate: true,
            isSortedByCode: false,
            isSortedByStatus: false,
            purchaseDateSortOrder: sortOrder,
        });

        var variables = {
            nextToken: this.state.nextToken,
            filter: {
                isDeleted: { eq: false },
                fleetOwnerId: { eq: this.state.fleetOwnerId },
            },
            limit: this.state.pageSize,
            deletedLicense: "false",
            sortDirection: sortOrder,
        };
        licenseData = await API.graphql(graphqlOperation(getLicensesSortedByCreatedAt, variables));

        this.setState({ nextNextToken: licenseData.data.getLicensesSortedByCreatedAt.nextToken });

        let objLicenses = licenseData.data.getLicensesSortedByCreatedAt.items;

        lstCodes = [];

        for (var i = 0; i < objLicenses.length; i++) {
            lstCodes.push(objLicenses[i].code);
        }

        this.setState({
            licenseCodes: lstCodes,
            licenses: objLicenses,
            hasNext: licenseData.data.getLicensesSortedByCreatedAt.nextToken !== null,
            isLoading: false,
            showCurrentPageNo: this.state.currentPageNo,
            inProgress: false,
        });
    };

    sortByCode = async (key) => {
        var sortOrder = key;

        if (key === "") {
            sortOrder = this.state.codeSortOrder;
        } else {
            if (sortOrder === "ASC") sortOrder = "DESC";
            else sortOrder = "ASC";
        }

        this.setState({
            isSortedByPurchaseDate: false,
            isSortedByCode: true,
            isSortedByStatus: false,
            codeSortOrder: sortOrder,
        });

        var variables = {
            nextToken: this.state.nextToken,
            filter: {
                isDeleted: { eq: false },
                fleetOwnerId: { eq: this.state.fleetOwnerId },
            },
            limit: this.state.pageSize,
            deletedLicense: "false",
            sortDirection: sortOrder,
        };
        licenseData = await API.graphql(graphqlOperation(getLicensesSortedByCode, variables));

        this.setState({ nextNextToken: licenseData.data.getLicensesSortedByCode.nextToken });

        let objLicenses = licenseData.data.getLicensesSortedByCode.items;

        lstCodes = [];

        for (var i = 0; i < objLicenses.length; i++) {
            lstCodes.push(objLicenses[i].code);
        }

        this.setState({
            licenseCodes: lstCodes,
            licenses: objLicenses,
            hasNext: licenseData.data.getLicensesSortedByCode.nextToken !== null,
            isLoading: false,
            showCurrentPageNo: this.state.currentPageNo,
            inProgress: false,
        });
    };

    sortByStatus = async (key) => {
        var sortOrder = key;

        if (key === "") {
            sortOrder = this.state.statusSortOrder;
        } else {
            if (sortOrder === "ASC") sortOrder = "DESC";
            else sortOrder = "ASC";
        }

        this.setState({
            isSortedByPurchaseDate: false,
            isSortedByCode: false,
            isSortedByStatus: true,
            statusSortOrder: sortOrder,
        });

        var variables = {
            nextToken: this.state.nextToken,
            filter: {
                isDeleted: { eq: false },
                fleetOwnerId: { eq: this.state.fleetOwnerId },
            },
            limit: this.state.pageSize,
            deletedLicense: "false",
            sortDirection: sortOrder,
        };
        licenseData = await API.graphql(graphqlOperation(getLicensesSortedByAssignedLicense, variables));

        this.setState({ nextNextToken: licenseData.data.getLicensesSortedByAssignedLicense.nextToken });

        let objLicenses = licenseData.data.getLicensesSortedByAssignedLicense.items;

        lstCodes = [];

        for (var i = 0; i < objLicenses.length; i++) {
            lstCodes.push(objLicenses[i].code);
        }

        this.setState({
            licenseCodes: lstCodes,
            licenses: objLicenses,
            hasNext: licenseData.data.getLicensesSortedByAssignedLicense.nextToken !== null,
            isLoading: false,
            showCurrentPageNo: this.state.currentPageNo,
            inProgress: false,
        });
    };

    handleDownloadAllLicence = async () => {
        if (lstCodes !== [] && lstCodes.length > 0) {
            this.setState({ inProgress: true });

            var fileName = this.state.fileName + ".pdf";
            var element = document.getElementById(this.state.fileName);
            var opt = {
                margin: [0.01, 0.01, 0.01, 0.01],
                filename: fileName,
                image: { type: "jpeg", quality: 0.99 },
                pagebreak: { before: ".html2pdf__page-break" },
                html2canvas: { scale: 2 },
                jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
            };

            await html2pdf().from(element).set(opt).save();

            this.setState({ inProgress: false });
        }
    };
    numberFormate2Decimal = (x) => {
        return Number.parseFloat(x).toFixed(2);
    };

    handleReveal = (id) => {
        window.setTimeout(function () {
            document.getElementById(id).focus();
        }, 0);
        this.setState({
            showKey: id,
            keyBtn: id
        });
    };
    handleHide = () => {
        this.setState({ showKey: false, keyBtn: false });
    }
    render() {
        const {
            paymentInProgress,
            searchText,
            inProgress,
            currency,
            profile,
            licenseCodes,
            profilePicURL,
            modalNotice,
            codeLic,

            showLicencePopup,
            code,
            loading,
            licenses,
            licenses1,
            // purchaseDateSortOrder,
            // statusSortOrder,
            // codeSortOrder,
            isLoading,
            hasNext,
            hasPrev,
            showCurrentPageNo,
            StripeSecretKey,
            codeImg,
            qrCodeDataUri,
            showKey, keyBtn
        } = this.state;

        const disabledPrev = !hasPrev || isLoading;
        const disabledNext = !hasNext || isLoading;
        return (
            <>
                <div className={styles.homepageContent}>
                    {(this.state.companyType === "Standard Customer") ?

                        <div>
                            <Col md="12" className={styles.trialCard}>
                                <Card>
                                    {loading ? (
                                        <div className="d-flex justify-content-center">
                                            <img src={loader} alt="loading" style={{ width: "32px", height: "32px" }} />
                                        </div>
                                    ) : (
                                        <ReactTable
                                            className={{ border: "0" }}
                                            data={this.state.trialLicense}
                                            columns={[
                                                {
                                                    Header: "License",
                                                    accessor: "code",
                                                    isSortingEnable: false,
                                                },

                                                {
                                                    Header: "QR Code",
                                                    accessor: "qrImage",
                                                    Cell: (props) => (
                                                        <div classname="flex">
                                                            <i
                                                                class={`fa fa-qrcode d-flex justify-content-center ${styles.handCursor}`}
                                                                aria-hidden="true"
                                                                onClick={() => {
                                                                    // this.handleDownloadPDF();
                                                                    this.toggleModalNotice(props.cell.row.values.code, props.value);
                                                                }}
                                                                style={{ fontSize: "32px" }}
                                                                title="Download QR Code"
                                                            ></i>
                                                        </div>
                                                    ),

                                                    sortable: false,
                                                    filterable: false,
                                                },
                                            ]}
                                        />
                                    )}
                                </Card>
                            </Col>

                            <div className={styles.topLine} style={{ marginBottom: "0px" }}>
                                {/* <div className={classNames("title", styles.titlefont)}>
                            <a href="/dashboard">Dashboard</a> {`> Licenses`}
                        </div> */}
                                <div className={styles.notifications} style={{ justifyContent: "flex-end" }}>
                                   
                                    <Button
                                        className="btn-round"
                                        style={{ backgroundColor: "black" }}
                                        onClick={this.handleDownloadAllLicence}
                                        disabled={this.state.licenses.length > 0 ? false:true}
                                    >
                                        Download QR Codes
                                    </Button>

                                    <div className={styles.notifications}>
                                        <Button
                                            className="btn-round"
                                            style={{ backgroundColor: "black" }}
                                            onClick={this.handleShowLicencePopup}
                                        >
                                            Buy Licenses
                                        </Button>
                                    </div>

                                </div>
                               
                            </div>
                            <div className={styles.topLine} style={{ marginBottom: "0px" }}>
                                <div className="title"></div>
                                <div
                                    className={styles.notifications}
                                    style={{ marginRight: "160px", marginTop: "-25px", justifyContent: "flex-end" }}
                                >
                                    (This page only)
                                </div>
                            </div>
                            
                        </div>

                        : null}

                    {/* <NavLink to="#" className={classNames('btn', styles.notificationBtn)}>
                            <span>Riders Alerted</span>
                            <img src={bell} alt="bell" width="24" />
                            <div className={styles.badge}>{riderAlerts}</div>
                        </NavLink> */}

                    <div className={styles.dataTable}>
                        <Row className={styles.dataTable}>
                            {(this.state.companyType === "Standard Customer") ?
                                <Col md="12">
                                    <Card>
                                        <CardHeader>
                                            <CardTitle tag="h4">LICENSES</CardTitle>
                                            <Row className={classNames("align-items-center", styles.search)}>
                                                <Col md="3">
                                                    <Input
                                                        type="text"
                                                        className="search"
                                                        id="searchText"
                                                        name="searchText"
                                                        value={searchText}
                                                        onChange={this.handleChange}
                                                        placeholder="Search"
                                                        onKeyDown={(e) => {
                                                            this.onPressEnter(e);
                                                        }}
                                                    />
                                                </Col>
                                                <Col md="2.01">
                                                    <Button
                                                        className={classNames("btn-round", styles.searchButton)}
                                                        onClick={this.handleSearch}
                                                        color="warning"
                                                        type="button"
                                                    >
                                                        Search
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </CardHeader>

                                        {loading ? (
                                            <div className="d-flex justify-content-center">
                                                <img src={loader} alt="loading" style={{ width: "32px", height: "32px" }} />
                                            </div>
                                        ) : (
                                            <ReactTable
                                                data={licenses}
                                                columns={[
                                                    {
                                                        Header: "License",
                                                        accessor: "code",
                                                    },
                                                    {
                                                        Header: "Purchase Date",
                                                        accessor: "createdAt",
                                                        Cell: (props) => Helper.formatDate(props.value),
                                                    },
                                                    {
                                                        Header: "Price",
                                                        accessor: "price",
                                                        Cell: (props) => `${"£"} ${this.numberFormate2Decimal(props.value)}`,
                                                    },
                                                    {
                                                        Header: "Payment Type",
                                                        accessor: "paymentType",
                                                        Cell: (props) => (props.value === null ? "Card" : props.value),
                                                    },
                                                    {
                                                        Header: "Status",
                                                        accessor: "isAssigned",
                                                        Cell: (props) => (props.value ? "Assigned" : "Unassigned"),
                                                    },

                                                    {
                                                        Header: "QR Code",
                                                        accessor: "qrImage",
                                                        Cell: (props) => (
                                                            <div classname="flex">
                                                                <i
                                                                    class={`fa fa-qrcode d-flex justify-content-center ${styles.handCursor}`}
                                                                    aria-hidden="true"
                                                                    onClick={() => {
                                                                        // this.handleDownloadPDF();
                                                                        this.toggleModalNotice(props.cell.row.values.code, props.value);
                                                                    }}
                                                                    style={{ fontSize: "32px" }}
                                                                    title="Download QR Code"
                                                                ></i>
                                                            </div>
                                                        ),

                                                        sortable: false,
                                                        filterable: false,
                                                    },
                                                ]}
                                            />
                                        )}
                                    </Card>
                                </Col>
                                :
                                (this.state.companyType === "Enterprise Customer" ?
                                    <Col md="12" className={styles.cardSize} style={{padding:"200px",paddingTop:"0px"}}>
                                        <Card>
                                            {loading ? (
                                                <div className="d-flex justify-content-center">
                                                    <img src={loader} alt="loading" style={{ width: "32px", height: "32px" }} />
                                                </div>
                                            ) : (
                                                    <ReactTable
                                                        className={{ border: "0" }}
                                                    data={licenses}
                                                    columns={[
                                                        {
                                                            Header: "License",
                                                            accessor: "code",
                                                            isSortingEnable: false,
                                                        },

                                                        {
                                                            Header: "QR Code",
                                                            accessor: "qrImage",
                                                            Cell: (props) => (
                                                                <div classname="flex">
                                                                    <i
                                                                        class={`fa fa-qrcode d-flex justify-content-center ${styles.handCursor}`}
                                                                        aria-hidden="true"
                                                                        onClick={() => {
                                                                            // this.handleDownloadPDF();
                                                                            this.toggleModalNotice(props.cell.row.values.code, props.value);
                                                                        }}
                                                                        style={{ fontSize: "32px" }}
                                                                        title="Download QR Code"
                                                                    ></i>
                                                                </div>
                                                            ),

                                                            sortable: false,
                                                            filterable: false,
                                                        },
                                                    ]}
                                                />
                                            )}
                                        </Card>
                                    </Col>
                                    : null)}
                        </Row>

                    </div>

                    <BuyLicensesPopBox
                        showLicencePopup={showLicencePopup}
                        handleCloseLicencePopup={this.handleCloseLicencePopup}
                        onSuccess={() => this.getLicense()}
                    />

                    <Modal isOpen={modalNotice} toggle={this.toggleModalNotice}>
                        <div className="modal-header">
                            <button
                                aria-hidden={true}
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={this.toggleCloseModalNotice}
                            >
                                <i className="nc-icon nc-simple-remove" />
                            </button>
                            <h5 className="modal-title" id="myModalLabel">
                                QR Code Preview
                            </h5>
                        </div>
                        <div className="modal-body">
                            <div className="instruction">
                                <div style={{ border: "1px solid black", padding: "25px" }}>
                                    <Row className="justify-content-center">
                                        <img src={qrCodeDataUri} className="d-flex justify-content-center"></img>
                                    </Row>
                                    <Row className="justify-content-center">
                                        <span style={{ marginTop: "20px", fontWeight: "600" }}>{code}</span>
                                    </Row>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-center">
                            <Button
                                className="btn-round"
                                color="info"
                                data-dismiss="modal"
                                type="button"
                                onClick={this.handleDownloadPDF}
                            >
                                Download
                            </Button>
                        </div>
                    </Modal>

                    {disabledPrev && disabledNext ? null : (
                        <div className={paginationStyles.pagination}>
                            <button
                                disabled={disabledPrev}
                                onClick={this.prev}
                                style={{
                                    backgroundColor: disabledPrev ? "#D3D3D3" : "#fff",
                                    cursor: disabledPrev ? "text" : "pointer",
                                }}
                                className={paginationStyles.item}
                            >
                                <img src={arrowLeft} alt="arrow left" />
                            </button>
                            <div className={classNames(paginationStyles.active, paginationStyles.item)}>{showCurrentPageNo}</div>
                            <button
                                disabled={disabledNext}
                                onClick={this.next}
                                style={{
                                    backgroundColor: disabledNext ? "#D3D3D3" : "#fff",
                                    cursor: disabledNext ? "text" : "pointer",
                                }}
                                className={paginationStyles.item}
                            >
                                <img src={arrowRight} alt="arrow right" />
                            </button>
                        </div>
                    )}
                    {code !== "" && code !== null ? (
                        <div style={{ display: "none" }}>
                            <QRCode id={code} value={code} size="300" />
                        </div>
                    ) : null}
                    {licenseCodes !== [] && licenseCodes.length > 0 ? (
                        <div style={{ display: "none" }}>
                            <div id={this.state.fileName}>
                                {licenseCodes.map((data, x) => (
                                    <>
                                        <div>
                                            <div style={{ marginLeft: "auto", marginRight: "auto", marginTop: "100px", width: "430px" }}>
                                                <div className="card" style={{ border: "2px solid" }}>
                                                    <div className="row">
                                                        <div
                                                            className="col-md-12"
                                                            style={{
                                                                textAlign: "center",
                                                                marginLeft: "auto",
                                                                marginRight: "auto",
                                                                marginTop: "61px",
                                                            }}
                                                        >
                                                            <QRCode value={data} size="300" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div
                                                            className="col-md-12"
                                                            style={{
                                                                textAlign: "center",
                                                                marginLeft: "auto",
                                                                marginRight: "auto",
                                                                marginTop: "15px",
                                                                marginBottom: "61px",
                                                                fontSize: "18px",
                                                            }}
                                                        >
                                                            <lable>{data}</lable>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="html2pdf__page-break"></div>
                                        </div>
                                    </>
                                ))}
                            </div>
                        </div>
                    ) : null}
                </div>

                {/* <Modal show={inProgress} onHide={this.handleCloseProgressPopup}>
          <Modal.Body className={styles.modalBody}>
            <div className="row">
              <div className="col-md-12" style={{ textAlign: "center" }}>
                <label>Please wait...</label>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12" style={{ textAlign: "center" }}>
                <img src={loader} alt="loading" style={{ width: "50px", height: "50px" }} />
              </div>
            </div>
          </Modal.Body>
        </Modal> */}
            </>
        );
    }
}

export default RoadRadarManageLicenses;